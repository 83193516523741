import { DOCUMENT_CATEGORY, EVENT_TYPE } from '@enums';
import { LaunchEventWizardPage } from '@pages';
import './event-create-document.css';

export const LaunchSaleEventWizardPage = () => {
  const eventType = EVENT_TYPE.RAISE;
  const documentCategory = DOCUMENT_CATEGORY.PURCHASE_AGREEMENTS;

  return (
    <LaunchEventWizardPage
      eventType={eventType}
      documentCategory={documentCategory}
    />
  );
};
