import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * A hook to get a query string parameter from the URL
 * @param parameter - the query string parameter to get
 * @returns - the value of the query string parameter
 */
export const useQueryParameter = (parameter: string): Maybe<string> => {
  const [searchParams, setSearchParams] = useSearchParams();
  return useMemo(() => searchParams.get(parameter), [searchParams, parameter]);
};
