import { useLayout } from '@contexts';
import { useHideSidebar } from '@hooks';
import { useEffect } from 'react';

/**
 * Encapsulates layout changes for event page.
 * - Hide sidebar
 * - Update top level layout styles (width, padding)
 */
export const useEventPageLayout = () => {
  // No sidebar on event page
  useHideSidebar();

  // Update layout classnames for event page
  const { setClassName } = useLayout();
  useEffect(() => {
    setClassName('!px-0 max-w-full !pt-[3.75rem]');
    return () => {
      setClassName(null);
    };
  }, []);
};
