import { IconProps } from '../icon-props';

export const Globe = ({ color, className, size = 20 }: IconProps) => {
  return (
    <div className={`${className && className}`}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 14 14'
        fill='none'
        stroke={color !== undefined ? color : '#101828'}
        strokeLinecap='round'
        strokeLinejoin='round'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_461_11380)'>
          <path d='M1.75 7C1.75 7.68944 1.8858 8.37213 2.14963 9.00909C2.41347 9.64605 2.80018 10.2248 3.28769 10.7123C3.7752 11.1998 4.35395 11.5865 4.99091 11.8504C5.62787 12.1142 6.31056 12.25 7 12.25C7.68944 12.25 8.37213 12.1142 9.00909 11.8504C9.64605 11.5865 10.2248 11.1998 10.7123 10.7123C11.1998 10.2248 11.5865 9.64605 11.8504 9.00909C12.1142 8.37213 12.25 7.68944 12.25 7C12.25 5.60761 11.6969 4.27226 10.7123 3.28769C9.72774 2.30312 8.39239 1.75 7 1.75C5.60761 1.75 4.27226 2.30312 3.28769 3.28769C2.30312 4.27226 1.75 5.60761 1.75 7Z' />
          <path d='M2.10107 5.25H11.9011' />
          <path d='M2.10107 8.75H11.9011' />
          <path d='M6.7083 1.75C5.72559 3.32477 5.20459 5.14375 5.20459 7C5.20459 8.85625 5.72559 10.6752 6.7083 12.25' />
          <path d='M7.2915 1.75C8.27422 3.32477 8.79522 5.14375 8.79522 7C8.79522 8.85625 8.27422 10.6752 7.2915 12.25' />
        </g>
        <defs>
          <clipPath id='clip0_461_11380'>
            <rect width='14' height='14' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
