import {
  Alert,
  ButtonLink,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CheckboxInput,
  Col,
  Divider,
  DownloadIcon,
  EditIcon,
  PrivateEventParticipantsTable,
  Text,
  TextLink,
} from '@components';
import { EventPrivacyConfiguration } from '@customTypes';
import { useValidateParticipantsFile } from '@hooks';
import { useState } from 'react';

type EventPrivacyOverviewPageProps = {
  onEdit?: () => void;
  showDownload?: boolean;
  privacyConfiguration: EventPrivacyConfiguration;
};

export const EventPrivacyOverviewPage = ({
  privacyConfiguration,
  onEdit,
  showDownload = true,
}: EventPrivacyOverviewPageProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const { isWalletRequired, isEmailRequired, participantsFileUri } =
    privacyConfiguration;

  // Download participants list
  const { isPending, uploadedData, uploadFileErrorMessage } =
    useValidateParticipantsFile({
      participantsFileUri,
      isEmailRequired,
      isWalletRequired,
    });

  return (
    <Card>
      <CardHeader place={'center'}>
        <CardTitle open={isOpen} onOpen={setIsOpen}>
          Private Event Members List
        </CardTitle>
        {onEdit && (
          <TextLink width={'fit'} onClick={onEdit}>
            <EditIcon />
            <Text>Edit Details</Text>
          </TextLink>
        )}
      </CardHeader>
      <CardBody open={isOpen}>
        <Col xalign='start'>
          <CheckboxInput
            name='email-required'
            checked={isEmailRequired}
            disabled
          >
            Email Required
          </CheckboxInput>
          <CheckboxInput
            name='wallet-required'
            checked={isWalletRequired}
            disabled
          >
            Wallet Address Required
          </CheckboxInput>
          {showDownload && (
            <ButtonLink
              fill='outline'
              size='sm'
              href={participantsFileUri}
              target={'blank'}
              data-testid={'download-template-button'}
            >
              <Text>Download File</Text>
              <DownloadIcon />
            </ButtonLink>
          )}
          <div className='w-full my-4'>
            <Divider />
          </div>
          {!isPending && !uploadedData && uploadFileErrorMessage && (
            <Alert type='alert-danger'>{uploadFileErrorMessage}</Alert>
          )}
          {/* Participants table with email and walletAddress headers */}
          {uploadedData && (
            <div className='w-full overflow-auto'>
              <PrivateEventParticipantsTable data={uploadedData} />
            </div>
          )}
        </Col>
      </CardBody>
    </Card>
  );
};
