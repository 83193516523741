import { useGetEventIPAddressEligibility } from '@apiServices';
import { useEffect } from 'react';
import { EligibilityItemStatus } from './EligibilityItemStatus';

interface LocationEligibilityStatusProps {
  eventId: ID;
  highlightColor: HexColorCode;
  onMetRequirement: () => void;
}

export const LocationEligibilityStatus = ({
  eventId,
  highlightColor,
  onMetRequirement,
}: LocationEligibilityStatusProps) => {
  const {
    data: ipAddressEligibility,
    isPending: isIPAddressEligibilityPending,
    isError: isIPAddressEligibilityError,
  } = useGetEventIPAddressEligibility(eventId);

  useEffect(() => {
    if (ipAddressEligibility?.isEligible === true) {
      onMetRequirement();
    }
  }, [ipAddressEligibility]);

  const getLocationEligibilityStatus = () => {
    if (ipAddressEligibility?.isEligible === true) {
      return 'completed';
    }

    if (isIPAddressEligibilityPending) {
      return 'in-progress';
    }

    if (ipAddressEligibility?.isEligible === false) {
      return 'failed';
    }

    return 'incomplete';
  };

  return (
    <EligibilityItemStatus
      title='Located in eligible region'
      progressStatus={getLocationEligibilityStatus()}
      highlightColor={highlightColor}
    />
  );
};
