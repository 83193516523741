import { useEventEligibility, useGetChains, useGetSale } from '@apiServices';
import {
  Error,
  EventHeaderWrapper,
  LoadingIndicator,
  SwitchChainWarning,
} from '@components';
import {
  DEFAULT_EVENT_HEADER_BG_COLOR,
  DEFAULT_EVENT_HEADER_FONT_COLOR,
} from '@constants';
import {
  SaleContext,
  useAuth,
  useToast,
  useTSWagmi,
  useWallet,
} from '@contexts';
import { Receipt } from '@customTypes';
import { PAYMENT_STEP, SALE_STATUS } from '@enums';
import { useAsync, useDelay } from '@hooks';
import { getStakingABI, getUtcNowUnix } from '@utils';
import { FC, ReactNode, useEffect, useState } from 'react';
import { VscLinkExternal } from 'react-icons/vsc';
import { useParams } from 'react-router-dom';
import { readContract } from 'viem/actions';
import { useClient } from 'wagmi';

interface SaleProviderProps {
  children?: ReactNode;
}

export const SaleProvider: FC<SaleProviderProps> = ({ children }) => {
  const { wagmiConfig } = useTSWagmi();
  const { data: chains } = useGetChains();
  const { connectedChainId } = useWallet();
  const { eventId } = useParams();
  const { sale, loading, refresh } = useGetSale(false, eventId ?? '');
  const { data: eligibilityData } = useEventEligibility(
    sale?.event?.id,
    !!sale?.event?.id,
  );
  const [correctNetwork, setCorrectNetwork] = useState<boolean>(true);
  const { showSuccessToast } = useToast();
  const {
    user: { walletAddress },
  } = useAuth();
  const { delay, isSuccess } = useDelay(sale, walletAddress);
  const [step, setStep] = useState<Maybe<any>>(null);
  const [receipt, setReceipt] = useState<Maybe<Receipt>>(null);
  const [delayRemaining, setDelayRemaining] = useState(0);

  /** Fee level is the *divisor* for the fee calculation; if 0, the fee is 0. */
  const { run: runFeeLevelQuery, data: feeLevelData } = useAsync({
    feeLevel: '0',
  });

  const documentsToAcceptOrSign = sale?.event?.eventUsers[0]?.documents?.filter(
    (doc) => !doc.acceptedAt,
  );

  const contentHeaderBgColor =
    sale?.event?.contentHeaderBgColor || DEFAULT_EVENT_HEADER_BG_COLOR;
  const contentHeaderBgImage = sale?.event?.contentHeaderBgImage;
  const contentHeaderFontColor =
    sale?.event?.contentHeaderFontColor || DEFAULT_EVENT_HEADER_FONT_COLOR;

  useEffect(() => {
    if (delay && delayRemaining > 0) {
      setStep(PAYMENT_STEP.FAIR_QUEUE);
    }
  }, [delay, delayRemaining, isSuccess]);

  useEffect(() => {
    if (delay) {
      const start = parseInt(sale.startTime);
      const now = getUtcNowUnix();
      const time = start + delay - now + 90; // pad by 90 seconds
      setDelayRemaining(time > 0 ? time : 0);
    }
  }, [delay]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (delayRemaining > 0) {
        setDelayRemaining(delayRemaining - 1);
      }

      if (delayRemaining === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  });

  const client = useClient({ config: wagmiConfig });

  useEffect(() => {
    if (sale?.chainId && client !== undefined) {
      const isV3Sale = sale.implementation.version === '3.0';
      const isV4Sale = sale.implementation.version === '4.0';
      if (isV3Sale || isV4Sale || sale.implementation.feeBips === null) {
        const network = chains?.find((n) => n.id === sale.chainId);
        const stakingAddress = isV4Sale
          ? network?.stakingV4Address
          : isV3Sale
            ? network?.stakingAddress
            : undefined;

        runFeeLevelQuery(
          // TODO: reduce duplication by extracting this step
          //       into a util
          readContract(client, {
            address: stakingAddress!,
            abi: getStakingABI(),
            functionName: 'getFeeLevel',
            args: [walletAddress],
          }).then((result) => {
            if (`${result}` === '0') {
              // This is the *denominator* of the fee calculation, so '100' means:
              // <purchaseAmount>/100 -> 1% of purchase amount
              return '100';
            }

            return result;
          }),
        );
      } else {
        runFeeLevelQuery(Promise.resolve(sale.implementation.feeBips));
      }
    }
  }, [sale?.chainId, walletAddress]);

  const handleGetReceipt = (receipt: Receipt) => {
    const network = chains?.find((n) => n.id === sale.chainId);

    setReceipt(receipt);
    setStep(PAYMENT_STEP.RECEIPT);
    showSuccessToast({
      title: `You purchased ${sale.symbol}!`,
      description: (
        <div className='flex flex-row'>
          <a
            target='_blank'
            rel='noreferrer'
            href={`${network?.blockExplorerUri}tx/${receipt.receipt}`}
            className='flex items-center justify-center text-white'
            onClick={(e) => e.stopPropagation()}
          >
            The seller will provide instructions for claiming your tokens. Click
            here to see your transaction.
            <VscLinkExternal color='white' />
          </a>
        </div>
      ),
    });
  };

  useEffect(() => {
    if (!connectedChainId || !sale) {
      return;
    }

    setCorrectNetwork(connectedChainId === sale.chainId);
  }, [connectedChainId, sale]);

  if (loading) {
    return (
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImage={contentHeaderBgImage}
      >
        <LoadingIndicator text='Loading Event' color={contentHeaderFontColor} />
      </EventHeaderWrapper>
    );
  }

  if (!loading && !sale) {
    return (
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImage={contentHeaderBgImage}
      >
        <Error
          title='Sale Not Found'
          message="It looks like this isn't a valid sale."
        />
      </EventHeaderWrapper>
    );
  }

  if (!loading && sale) {
    if (!sale.validSale) {
      return (
        <EventHeaderWrapper
          backgroundColor={contentHeaderBgColor}
          backgroundImage={contentHeaderBgImage}
        >
          <Error />
        </EventHeaderWrapper>
      );
    }

    if (sale.status === SALE_STATUS.CURRENT && !correctNetwork) {
      return (
        <EventHeaderWrapper
          backgroundColor={contentHeaderBgColor}
          backgroundImage={contentHeaderBgImage}
        >
          <SwitchChainWarning
            chainId={sale.chainId}
            style={{ color: contentHeaderFontColor }}
          />
        </EventHeaderWrapper>
      );
    }
  }

  const handleSaleCapMet = () => {
    sale.status = SALE_STATUS.COMPLETED;
    refresh();
  };

  return (
    <SaleContext.Provider
      value={{
        sale,
        refresh,
        step,
        setStep,
        receipt,
        handleGetReceipt,
        correctNetwork,
        setReceipt,
        status: sale?.status,
        documentsToAcceptOrSign,
        hasQueue: Number(sale?.maxQueueTime) > 0,
        handleSaleCapMet,
        eligibilityData,
        delay: {
          hasDelay: delayRemaining > 0,
          delayRemaining: delayRemaining || 0,
        },
        /** Fee level is the *divisor* for the fee calculation; if 0,
         * the fee is 0. */
        feeLevel: feeLevelData ?? '0',
      }}
    >
      {children}
    </SaleContext.Provider>
  );
};
