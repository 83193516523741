import { Col, ProgressBar } from '@components';
import { ReactNode } from 'react';
import { useWizard } from 'react-use-wizard';

interface StepFooterProps {
  children: ReactNode;
  hideProgressBar?: boolean;
}

export const StepFooter = ({
  children,
  hideProgressBar = false,
}: StepFooterProps) => {
  const { activeStep, stepCount } = useWizard();

  return (
    <Col yalign='end' gap={12} className='mt-auto'>
      {children}
      {hideProgressBar || stepCount === 1 ? null : (
        <ProgressBar
          progress={[{ value: ((activeStep + 1) / stepCount) * 100 }]}
        />
      )}
    </Col>
  );
};
