import {
  useGetProjectTheme,
  useRefreshEventUserEligibility,
} from '@apiServices';
import {
  EventContent,
  EventHeader,
  EventHeaderWrapper,
  Timeline,
} from '@components';
import { SaleProvider } from '@contexts';
import { Card, CollapsibleCard, PageContent } from '@newComponents';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SocialLinks, TSEvent } from 'tokensoft-shared-types';
import { ClaimCard } from './distribution/claim-card';
import { EligibilityRequirementsMet } from './eligibility-requirements-met';
import { EventEligibilityCard } from './eligibility/EventEligibilityCard';
import { EventModal } from './eligibility/EventModal';
import { EventCountdown } from './event-countdown';
import { EventHeaderCard } from './event-header-card';
import { PurchaseCard } from './sale/purchase-card';
import { SaleCard } from './sale/sale-card';
import './ts-event-page.css';

interface TSEventPageProps {
  event: TSEvent;
  socials: SocialLinks;
}

export const TSEventPage = ({ event, socials }: TSEventPageProps) => {
  const { data: projectTheme } = useGetProjectTheme();
  const navigate = useNavigate();

  const isEventUpcoming = event.timeline.isEventUpcoming() === true;

  /**
   * Eligibility requirements should not be fake-able, so it is tracked on the front end,
   * and once the front end has determined that the user has met the requirements, it will
   * send a request to the backend to update the user's eligibility status. The mutation
   * below is that request.
   */
  const [
    hasMetEligibilityRequirementsLocally,
    setHasMetEligibilityRequirementsLocally,
  ] = useState(false);

  const {
    mutate: refreshEventUserEligibility,
    data: eligibilityData,
    isPending,
    isError,
  } = useRefreshEventUserEligibility();

  const [shouldShowRequirementsMetModal, setShouldShowRequirementsMetModal] =
    useState(false);
  const [showRequirementsMetModal, setShowRequirementsMetModal] =
    useState(false);

  const eventBadgeTitles = event.timeline.getRegistrationAndEventStatuses();
  if (event.isPrivateEvent) {
    eventBadgeTitles.push('Private Event');
  }

  useEffect(() => {
    let timeout: Maybe<NodeJS.Timeout> = null;
    if (shouldShowRequirementsMetModal) {
      timeout = setTimeout(() => setShowRequirementsMetModal(true), 2000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [shouldShowRequirementsMetModal]);

  const handleBackToEvents = () => navigate('/');

  const handleDidMeetEligibilityRequirements = async () => {
    setHasMetEligibilityRequirementsLocally(true);

    // Request refresh to the user's eligibility status on back end
    try {
      await refreshEventUserEligibility(event.id);
    } catch (e) {
      console.error('Error refreshing event user eligibility');
    }

    if (isEventUpcoming || event.timeline.startTime === null) {
      setShouldShowRequirementsMetModal(true);
    }
  };

  const shouldRenderDistributionDetails =
    event.type === 'distribution' && event.distributorAddress !== null;

  const saleIsOpen =
    event.type === 'sale' &&
    event.saleAddress !== null &&
    event.timeline.isEventOpen() === true;

  return (
    <div className='overflow-hidden h-full'>
      {/* Render only on md & lg screens */}
      <div className='md:block hidden'>
        {!!event.content.header.backgroundImage && (
          <EventHeaderWrapper
            backgroundColor={event.content.header.backgroundColor}
            backgroundImage={event.content.header.backgroundImage}
          >
            <EventHeader
              headerTitle={''}
              headerSubtitle={''}
              contentHeaderImage={event.content.header.backgroundColor}
              contentHeaderFontColor={event.content.header.fontColor}
              themeLayoutVersion={'title_100'}
            />
          </EventHeaderWrapper>
        )}
      </div>
      <div className='overflow-y-auto h-full bg-[#f0f3fd] flex justify-center'>
        <PageContent onBack={handleBackToEvents}>
          <div className='main-grid mb-8'>
            <div className='main-col'>
              <EventHeaderCard
                className='event-header'
                eventTitle={event.name}
                logo={event.iconLargeUrl ?? projectTheme?.logo ?? ''}
                eventDescription={event.content.header.subtitle ?? ''}
                shouldShowReferralButton={!event.isPrivateEvent}
                socials={socials}
                badgeTitles={eventBadgeTitles}
              />
              {event.type === 'sale' && event.saleAddress !== null && (
                <div className='main-item'>
                  <SaleProvider>
                    <SaleCard
                      eventId={event.id}
                      saleAddress={event.saleAddress}
                    />
                  </SaleProvider>
                </div>
              )}
              <CollapsibleCard
                className='main-item order-2'
                cardTitle='Event Description'
              >
                <EventContent
                  bodyContent={event.getCurrentBodyContent() ?? ''}
                />
              </CollapsibleCard>
            </div>
            <div className='side-col'>
              <EventCountdown
                eventType={event.type}
                timeline={event.timeline}
              />
              <EventEligibilityCard
                eventId={event.id}
                kycRequirement={event.kycRestriction}
                highlightColor={projectTheme?.colors.primary ?? '#000'}
                onMetEligibilityRequirements={
                  handleDidMeetEligibilityRequirements
                }
                isRegistrationOpen={
                  event.timeline.isRegistrationOpen() === true
                }
              />
              {saleIsOpen &&
                hasMetEligibilityRequirementsLocally &&
                eligibilityData?.isEligible === true &&
                event.saleAddress !== null && (
                  <div>
                    <SaleProvider>
                      <PurchaseCard
                        eventId={event.id}
                        saleAddress={event.saleAddress}
                      />
                    </SaleProvider>
                  </div>
                )}
              {shouldRenderDistributionDetails &&
                event.distributorAddress !== null && (
                  <ClaimCard
                    className='order-1'
                    distributorAddress={event.distributorAddress}
                    chainId={event.chainId!}
                    isClaimAvailable={
                      event.timeline.isEventOpen() === true &&
                      hasMetEligibilityRequirementsLocally
                    }
                  />
                )}
              <Card className='min-w-[286px] order-1'>
                <Timeline timeline={event.timeline} />
              </Card>
            </div>
          </div>
        </PageContent>
      </div>
      <EventModal
        isOpen={showRequirementsMetModal}
        onRequestClose={() => setShowRequirementsMetModal(false)}
        contentLabel='Event Requirements Met!'
      >
        <EligibilityRequirementsMet
          eventType={event.type}
          isKycRequired={event.kycRestriction !== 'none'}
          onFinished={() => setShowRequirementsMetModal(false)}
          eventStartsAt={event.timeline.startTime}
          themeColors={projectTheme?.colors}
        />
      </EventModal>
    </div>
  );
};
