import { CheckboxInput, Text } from '@components';
import { EventDocument } from 'tokensoft-shared-types';

interface DocumentReviewItemProps {
  document: EventDocument;
  requiresSignature: boolean;
  checked: boolean;
  onCheckboxChange: (changed: React.ChangeEvent<HTMLInputElement>) => void;
  isReviewing: boolean;
}

export const DocumentReviewItem: React.FC<DocumentReviewItemProps> = ({
  document,
  requiresSignature,
  checked,
  onCheckboxChange,
  isReviewing,
}) => {
  const linkStyles = 'text-blue-500 underline hover:text-blue-700';

  const getAgreementDocumentText = () => {
    if (requiresSignature) {
      return (
        <>
          By checking this box I agree to comply with and be bound by all terms
          of the{' '}
          <a
            href={document.uri}
            target='_blank'
            rel='noopener noreferrer'
            className={linkStyles}
          >
            {document.name}
          </a>
          .
        </>
      );
    }

    return (
      <>
        I have read, understood, and agree to the terms listed in the{' '}
        <a
          href={document.uri}
          target='_blank'
          rel='noopener noreferrer'
          className={linkStyles}
        >
          {document.name}
        </a>
        .
      </>
    );
  };

  return (
    <div>
      {isReviewing ? (
        <CheckboxInput
          onClick={onCheckboxChange}
          name={`acceptedDocs-${document.id}`}
          checked={checked}
        >
          <Text>{getAgreementDocumentText()}</Text>
        </CheckboxInput>
      ) : (
        <a
          href={document.uri}
          target='_blank'
          rel='noopener noreferrer'
          className={linkStyles}
        >
          {document.name}
        </a>
      )}
    </div>
  );
};
