import { useBreakpoint, useDisableBodyScroll } from '@hooks';
import React from 'react';
import Modal from 'react-modal';

type EventModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  contentLabel: string;
  shouldCloseOnOverlayClick?: boolean;
  children: React.ReactNode;
};

export const EventModal: React.FC<EventModalProps> = ({
  isOpen,
  onRequestClose,
  contentLabel,
  shouldCloseOnOverlayClick = true,
  children,
}) => {
  useDisableBodyScroll(isOpen);

  const currentBreakpoint = useBreakpoint();

  const getMinWidth = () => {
    switch (currentBreakpoint) {
      case 'sm':
        return '90vw';
      case 'md':
        return '80vw';
      case 'lg':
        return '60vw';
      case 'xl':
      case '2xl':
        return '50vw';
      default:
        return '70vw';
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          backdropFilter: 'blur(4px)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'auto',
          minWidth: getMinWidth(),
          minHeight: 'auto',
          maxHeight: '80vh',
          maxWidth: '90vw',
          transition: 'all',
          backdropFilter: 'blur(10px)',
        },
      }}
    >
      {!shouldCloseOnOverlayClick && (
        <button
          onClick={onRequestClose}
          style={{
            position: 'absolute',
            top: '0px',
            right: '12px',
            background: 'transparent',
            border: 'none',
            fontSize: '1.5rem',
            cursor: 'pointer',
          }}
          aria-label='Close modal'
        >
          &times;
        </button>
      )}
      {children}
    </Modal>
  );
};
