import { CardTitle, Col, Row, Text } from '@components';
import classNames from 'classnames';
import { MouseEventHandler, ReactNode } from 'react';
import './card.css';

type ColProps = Pick<React.ComponentProps<typeof Col>, 'width'>['width'];

interface CardProps {
  id?: string;
  title?: string;
  subtitle?: string;
  className?: any;
  children?: ReactNode;
  actions?: ReactNode;
  footer?: ReactNode;
  width?: ColProps;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const Card = ({
  id,
  title,
  subtitle,
  className,
  children,
  width = 'full',
  actions = <></>,
  footer,
  onClick,
}: CardProps) => {
  const key = id || 'card';

  return (
    <Col
      width={width}
      data-testid={key}
      onClick={onClick}
      className={classNames('card', className, onClick && 'cursor-pointer')}
    >
      {title ? (
        <>
          <Row className='card-header' data-testid={`${key}-header`}>
            <div>
              <CardTitle data-testid={`${key}-title`}>{title}</CardTitle>
              {subtitle ? (
                <Text data-testid={`${key}-subtitle`}>{subtitle}</Text>
              ) : null}
            </div>
            {actions}
          </Row>
        </>
      ) : null}

      <div data-testid={`${key}-body`} className='card-body'>
        {children}
      </div>

      {footer ? (
        <div className='card-footer' data-testid={`${key}-footer`}>
          {footer}
        </div>
      ) : null}
    </Col>
  );
};
