import { useTSWagmi } from '@contexts';
import { getFlatPriceSaleABI } from '@utils';
import { simulateContract, writeContract } from '@wagmi/core';
import { useState } from 'react';
import { useWaitForTransactionReceipt } from 'wagmi';

export const useUpdateSaleOwner = () => {
  const { wagmiConfig } = useTSWagmi();
  const [transactionHash, setTransactionHash] = useState<Maybe<any>>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const write = async (chainId, contractAddress, args) => {
    setSubmitting(true);

    console.log('updating sale owner');

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: contractAddress,
        abi: getFlatPriceSaleABI(),
        functionName: 'transferOwnership',
        chainId,
        args,
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      console.log(
        'update sale owner transaction response',
        writeContractResponse,
      );
      return writeContractResponse;
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};
