import { useAuth } from '@contexts';
import axios, { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';

interface RequestConfig extends AxiosRequestConfig {
  token?: string;
  signOut?: Function;
  shouldSignOutOnForbidden?: boolean;
}

const notificationApiClient = (
  endpoint: string,
  {
    data,
    token,
    signOut,
    headers: customHeaders,
    shouldSignOutOnForbidden = true,
    ...customConfig
  }: RequestConfig,
) => {
  const config: AxiosRequestConfig<any> = {
    method: data ? 'post' : 'get',
    url: import.meta.env.VITE_API_URL
      ? `${import.meta.env.VITE_API_URL}/notification/api/v1/${endpoint}`
      : `${import.meta.env.VITE_NOTIFICATION_API_URL}/${endpoint}`,
    data: data || undefined,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...(data ? { 'Content-Type': 'application/json' } : {}),
      ...customHeaders,
    },
    ...customConfig,
  };

  return axios.request(config).then(
    (res) => res.data,
    (error) => {
      const { status: statusCode } = error.toJSON();
      if (statusCode === 403 && shouldSignOutOnForbidden) {
        if (signOut) {
          signOut();
        }
      } else if (statusCode === 500) {
        return Promise.reject({
          message: error.response.data.message,
        });
      }

      return Promise.reject(error);
    },
  );
};

const useNotificationApiClient = () => {
  const {
    user: { token },
    disconnect: signOut,
  } = useAuth();
  return useCallback(
    (endpoint, config = {}) =>
      notificationApiClient(endpoint, { ...config, token, signOut }),
    [token],
  );
};

export { notificationApiClient, useNotificationApiClient };
