import { DropdownLargeIcon, Text } from '@components';
import { useInterval, useWindowSize } from '@hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { HTMLAttributes, useLayoutEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface CollapsibleCardProps extends HTMLAttributes<HTMLDivElement> {
  cardTitle?: string;
  className?: string;
  children: React.ReactNode;
}

export const CollapsibleCard = ({
  cardTitle = '',
  className,
  children,
  ...props
}: CollapsibleCardProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const animationDurationMs = 400;
  const animationDurationPlusMargin = 1.1 * animationDurationMs;

  // Use the window size hook to recalculate height on window resize
  const windowSize = useWindowSize();

  const updateHeight = () => {
    if (contentRef.current && height !== contentRef.current.scrollHeight) {
      setHeight(contentRef.current.scrollHeight);
    }
  };

  useLayoutEffect(() => {
    // Recalculate height on mount, open state change, children change, or window resize
    updateHeight();
  }, [isOpen, children, windowSize]);

  // Not the cleanest way of doing this, but recalculate height
  // every 2 seconds. Basically handles cases where height
  // progressively loads (eg loading a lot of images on the card)
  useInterval(() => {
    updateHeight();
  }, 2000);

  return (
    <div
      className={twMerge(
        'rounded-[8px] px-6 py-5 overflow-hidden bg-white transition-all duration-200',
        className,
      )}
      {...props}
    >
      <div className='flex flex-col'>
        <div className='flex flex-row justify-between'>
          <Text className='font-bold text-xl text-black content-center'>
            {cardTitle}
          </Text>
          <div onClick={() => setIsOpen(!isOpen)} className='cursor-pointer'>
            <DropdownLargeIcon
              size={36}
              className={twMerge(
                'transition-transform duration-[400ms] select-none',
                isOpen ? 'rotate-180' : '',
              )}
            />
          </div>
        </div>
        <AnimatePresence initial={false}>
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: isOpen ? height : 0, opacity: isOpen ? 1 : 0 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{
              duration: animationDurationMs / 1000,
              ease: 'easeInOut',
            }}
            style={{ overflow: 'hidden' }}
          >
            <div ref={contentRef}>
              <div className='py-4'>{children}</div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};
