import { PaymentMethod } from '@customTypes';
import { useCheckTransferAllowance } from '@hooks';
import { useEffect } from 'react';

interface Erc20PurchaseProps {
  connectedChainId: string;
  paymentMethod: PaymentMethod;
  walletAddress: string;
  saleId: string;
  value: string;
  onAllowanceCheck: Function;
}

export const Erc20Purchase = ({
  connectedChainId,
  paymentMethod,
  walletAddress,
  saleId,
  value,
  onAllowanceCheck,
}: Erc20PurchaseProps) => {
  if (!paymentMethod.address) {
    return <></>;
  }

  const { hasEnoughAllowance } = useCheckTransferAllowance(
    connectedChainId,
    paymentMethod,
    walletAddress,
    saleId,
    value,
  );

  useEffect(() => {
    onAllowanceCheck(hasEnoughAllowance);
  }, [hasEnoughAllowance]);

  return <></>;
};
