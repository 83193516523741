import { ProjectSocials } from '@newComponents';
import { SocialLinks } from 'tokensoft-shared-types';

interface FooterProps {
  projectSocials: SocialLinks;
  highlightColor: HexColorCode;
}

export const Footer = ({projectSocials, highlightColor}: FooterProps) => {

  return (
    <div className='m-auto h-[20px] pt-4 pb-8'>
      <div className={'flex flex-row gap-4 justify-center'}>
        <ProjectSocials
          socialLinks={projectSocials}
          color={highlightColor}
        />
      </div>
    </div>
  );
};
