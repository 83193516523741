import {
  useGetPurchaseSignature,
  usePurchaseCompleted,
  useSignEventDocuments,
} from '@apiServices';
import {
  ButtonRow,
  Card,
  CheckboxInput,
  InputGroup,
  PurchaseButton,
  PurchaseButtonV4,
  PurchaseNativeButton,
  PurchaseNativeButtonV4,
} from '@components';
import { useAnalytics, useAuth } from '@contexts';
import { Receipt } from '@customTypes';
import { useTotalFees } from '@hooks';
import { SignaturePage } from '@pages';
import { getTruncatedAddress } from '@utils';
import { useState } from 'react';

interface PurchaseConfirmStepPageProps {
  sale: any;
  handleGetReceipt: any;
  receipt: Maybe<Receipt>;
}

export const PurchaseConfirmStepPage = ({
  sale,
  handleGetReceipt,
  receipt,
}: PurchaseConfirmStepPageProps) => {
  const {
    user: { walletAddress },
  } = useAuth();
  const { data: purchaseSignatureData } = useGetPurchaseSignature(sale.id);
  const isV4Sale = sale.implementation.version === '4.0';
  const { pageEvent } = useAnalytics();
  const [signatureValid, setSignatureValid] = useState<boolean>(false);
  const [rightToCancel, setRightToCancel] = useState<boolean>(false);
  const [allPurchasesFinal, setAllPurchasesFinal] = useState<boolean>(false);
  const { totalFees, totalCostPresentation } = useTotalFees(receipt);

  const { mutate: purchaseCompleted } = usePurchaseCompleted();
  const { mutate: sign } = useSignEventDocuments();

  const validForm = signatureValid && allPurchasesFinal && rightToCancel;

  let documents = sale?.event?.documents
    ?.filter((doc) => doc.requiresSignature === true)
    .map((doc) => {
      return { eventDocumentVersion: doc };
    });

  useAnalytics(`/sale/${sale.id}/confirm`);

  const signDocuments = (mergedReceipt: Receipt) => {
    sign(
      {
        eventId: sale.event.id,
        documents: documents,
        transactionHash: mergedReceipt.receipt,
      },
      {
        onSuccess: () => {
          // do nothing
        },
        onError: (error) => {
          // do nothing, we don't want to prevent the user from completing their purchase.
        },
      },
    );
  };

  return (
    <>
      <Card title='Payment Confirmation' className='mt-8'>
        <p>
          Please make sure your purchase details are correct before making a
          payment.
        </p>

        <div
          className={'border border-neutral-light my-8 p-4 rounded vertical'}
        >
          <InputGroup
            label={`You are buying`}
            value={`${receipt?.saleTokensPurchased} ${sale?.symbol}`}
            readonly
            colWidths={{ second: '2/3' }}
          />

          <InputGroup
            label={`Contribution amount`}
            value={`${receipt?.contributionAmount} ${receipt?.selectedPaymentMethod.label}`}
            readonly
            colWidths={{ second: '2/3' }}
          />

          <InputGroup
            label={`Processing fee amount`}
            value={`${receipt?.platformFee?.amount} ${receipt?.nativeTokenName}`}
            readonly
            colWidths={{ second: '2/3' }}
          />

          <InputGroup
            label={`Network fee amount`}
            value={`${receipt?.paymentTokenFeeAmount?.amount} ${receipt?.selectedPaymentMethod.label}`}
            readonly
            colWidths={{ second: '2/3' }}
          />

          <InputGroup
            label={`Total cost`}
            value={totalCostPresentation}
            readonly
            type='textarea'
            textareaStyles={{ resizeTextarea: false, height: '24' }}
            hint={`You may see a slightly higher total cost than shown based on the network conditions of the blockchain you're transacting on.`}
            colWidths={{ first: '2/3', second: '1/3' }}
          />

          <InputGroup
            label={`Receiving wallet`}
            value={getTruncatedAddress(walletAddress)}
            readonly
            colWidths={{ second: '2/3' }}
          />
        </div>

        <div className='my-4'>
          <CheckboxInput
            onClick={() => setAllPurchasesFinal(!allPurchasesFinal)}
            name='allPurchasesFinal'
            checked={allPurchasesFinal}
          >
            {`I acknowledge and accept that all purchases from the Company (as defined in the purchase agreement) are final and there are no refunds or cancellations except as may be required by applicable law or regulations.`}
          </CheckboxInput>
        </div>

        <div className='my-4'>
          <CheckboxInput
            onClick={() => setRightToCancel(!rightToCancel)}
            name='rightToCancel'
            checked={rightToCancel}
          >
            {`I further acknowledge and accept that the Company (as defined in the purchase agreement) reserves the right to refuse or cancel these documents at any time at its sole discretion.`}
          </CheckboxInput>
        </div>

        <div className='mt-4 mb-8'>
          <SignaturePage
            setSignatureValid={setSignatureValid}
            nameLabel={'Purchaser'}
          />
        </div>

        <ButtonRow place={'end'}>
          {receipt?.selectedPaymentMethod.native ? (
            isV4Sale && purchaseSignatureData ? (
              <PurchaseNativeButtonV4
                disabled={!validForm}
                paymentMethod={receipt?.selectedPaymentMethod}
                toAddress={sale.id}
                purchaseSignature={purchaseSignatureData?.signature}
                userPurchaseLimit={purchaseSignatureData?.userLimit}
                signatureExpiresAt={purchaseSignatureData?.expiresAt}
                value={totalFees[receipt?.nativeTokenName || ''] || '0'}
                saleId={sale.id}
                getReceipt={(purchaseReceipt) => {
                  pageEvent('altPurchase', 'buy');

                  const receiptDetails = {
                    ...purchaseReceipt,
                    ...receipt,
                  };
                  purchaseCompleted(
                    {
                      receipt: receiptDetails,
                      sale,
                    },
                    {
                      onSuccess: () =>
                        console.log('Purchase completed send Success'),
                      onError: () =>
                        console.log('Purchase completed send Error'),
                    },
                  );
                  signDocuments(receiptDetails);
                  handleGetReceipt(receiptDetails);
                }}
              />
            ) : (
              <PurchaseNativeButton
                disabled={!validForm}
                paymentMethod={receipt?.selectedPaymentMethod}
                toAddress={sale.id}
                proof={sale.proof}
                value={totalFees[receipt?.nativeTokenName || ''] || '0'}
                saleId={sale.id}
                getReceipt={(purchaseReceipt) => {
                  pageEvent('altPurchase', 'buy');

                  const receiptDetails = {
                    ...purchaseReceipt,
                    ...receipt,
                  };
                  purchaseCompleted(
                    {
                      receipt: receiptDetails,
                      sale,
                    },
                    {
                      onSuccess: () =>
                        console.log('Purchase completed send Success'),
                      onError: () =>
                        console.log('Purchase completed send Error'),
                    },
                  );
                  signDocuments(receiptDetails);
                  handleGetReceipt(receiptDetails);
                }}
              />
            )
          ) : isV4Sale && purchaseSignatureData ? (
            <PurchaseButtonV4
              disabled={!validForm}
              paymentMethod={receipt?.selectedPaymentMethod}
              platformFee={receipt?.platformFee?.amount || '0'}
              toAddress={sale.id}
              signature={purchaseSignatureData?.signature}
              value={receipt?.tokensPaid?.amount}
              saleId={sale.id}
              signatureExpiresAt={purchaseSignatureData?.expiresAt}
              userPurchaseLimit={purchaseSignatureData?.userLimit}
              getReceipt={(purchaseReceipt) => {
                pageEvent('altPurchase', 'buy');

                const receiptDetails = {
                  ...purchaseReceipt,
                  ...receipt,
                };
                purchaseCompleted(
                  {
                    receipt: receiptDetails,
                    sale,
                  },
                  {
                    onSuccess: () =>
                      console.log('Purchase completed send Success'),
                    onError: () => console.log('Purchase completed send Error'),
                  },
                );
                signDocuments(receiptDetails);
                handleGetReceipt(receiptDetails);
              }}
            />
          ) : (
            <PurchaseButton
              disabled={!validForm}
              paymentMethod={receipt?.selectedPaymentMethod}
              platformFee={receipt?.platformFee?.amount || '0'}
              toAddress={sale.id}
              proof={sale.proof}
              value={receipt?.tokensPaid?.amount}
              saleId={sale.id}
              getReceipt={(purchaseReceipt) => {
                pageEvent('altPurchase', 'buy');

                const receiptDetails = {
                  ...purchaseReceipt,
                  ...receipt,
                };
                purchaseCompleted(
                  {
                    receipt: receiptDetails,
                    sale,
                  },
                  {
                    onSuccess: () =>
                      console.log('Purchase completed send Success'),
                    onError: () => console.log('Purchase completed send Error'),
                  },
                );
                signDocuments(receiptDetails);
                handleGetReceipt(receiptDetails);
              }}
            />
          )}
        </ButtonRow>
      </Card>
    </>
  );
};
