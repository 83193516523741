export const SaveDiskIcon = ({ fill = 'var(--primary)', size = 18 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.7756 0H2.10938C0.946266 0 0 0.946266 0 2.10938V15.8906C0 17.0537 0.946266 18 2.10938 18H15.8906C17.0537 18 18 17.0537 18 15.8906V3.22439L14.7756 0ZM5.51953 1.40625H9.70312V4.07812H11.1094V1.40625H12.5156V4.78125C12.5156 5.16895 12.2002 5.48438 11.8125 5.48438H6.22266C5.83495 5.48438 5.51953 5.16895 5.51953 4.78125V1.40625ZM13.9219 16.5938H4.11328V10.4062C4.11328 10.0185 4.4287 9.70312 4.81641 9.70312H13.2188C13.6065 9.70312 13.9219 10.0185 13.9219 10.4062V16.5938ZM16.5938 15.8906C16.5938 16.2783 16.2783 16.5938 15.8906 16.5938H15.3281V10.4062C15.3281 9.24314 14.3819 8.29688 13.2188 8.29688H4.81641C3.6533 8.29688 2.70703 9.24314 2.70703 10.4062V16.5938H2.10938C1.72167 16.5938 1.40625 16.2783 1.40625 15.8906V2.10938C1.40625 1.72167 1.72167 1.40625 2.10938 1.40625H4.11328V4.78125C4.11328 5.94436 5.05955 6.89062 6.22266 6.89062H11.8125C12.9756 6.89062 13.9219 5.94436 13.9219 4.78125V1.40625H14.1931L16.5938 3.80686V15.8906Z'
        fill={fill}
      />
    </svg>
  );
};
