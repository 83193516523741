import {
  KYCNotificationBanner,
  LoadingIndicator,
  PageHeader,
  ProfileAccreditation,
  ProfileDetails,
  ProfileIdentity,
  ProfileSetup,
  ProfileVerifyEmail,
  ProfileWallets,
} from '@components';
import { useAnalytics, useProfile } from '@contexts';
import { MySignedDocuments } from '@newComponents';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ProfileDashboardPage = () => {
  const { account, view, setView } = useProfile();
  const { hash } = useLocation();
  useAnalytics('/account/profile');

  useEffect(() => {
    if (hash) {
      setView(hash.replace('#', ''));
    }
  }, [hash]);

  const renderView = () => {
    switch (view) {
      case 'profile-wallets':
        return <ProfileWallets />;
      case 'profile-accreditation':
        return <ProfileAccreditation />;
      case 'profile-identity':
        return <ProfileIdentity />;
      case 'profile-verify-email':
        return <ProfileVerifyEmail />;
      default:
        return <ProfileDetails />;
    }
  };

  if (!account) {
    return <LoadingIndicator text={'Loading profile'} />;
  }

  return (
    <>
      <PageHeader title={'My Profile'} />

      <div className='pb-5'>
        <KYCNotificationBanner />
      </div>
      <div className={'grid grid-cols-3 gap-x-8 gap-y-8'}>
        <div className={'col-span-3 lg:col-span-2'}>{renderView()}</div>

        <div className={'col-span-3 lg:col-span-1 flex flex-col gap-y-8'}>
          <ProfileSetup />
          <MySignedDocuments />
        </div>
      </div>
    </>
  );
};
