import {
  AdminEventDetails,
  EventDistributorSummary,
  EventSaleSummary,
  EventUsersContainer,
  PageHeader,
  Stacked,
} from '@components';
import { useEvent, useProject } from '@contexts';
import { EVENT_TYPE } from '@enums';
import { useNavigate } from 'react-router-dom';

export const EventDashboardPage = () => {
  const { event } = useEvent();
  const { project } = useProject();
  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        title={`Event Dashboard`}
        breadcrumb={
          <>
            <a
              className='cursor-pointer'
              onClick={() => {
                navigate(`/admin/project/${project?.id}`);
              }}
            >
              &lt; {project?.name}
            </a>{' '}
            &lt; Event Dashboard
          </>
        }
      />

      <Stacked>
        <AdminEventDetails />

        {event.type === EVENT_TYPE.DISTRIBUTE ? (
          <EventDistributorSummary event={event} />
        ) : null}

        {[EVENT_TYPE.RAISE, EVENT_TYPE.SALE].includes(event.type) ? (
          <EventSaleSummary event={event} />
        ) : null}

        <EventUsersContainer />
      </Stacked>
    </>
  );
};
