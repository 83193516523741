import { useTSWagmi } from '@contexts';
import { getErc20ABI } from '@utils';
import { simulateContract, writeContract } from '@wagmi/core';
import { useState } from 'react';
import { useWaitForTransactionReceipt } from 'wagmi';

export const useFundDistributor = () => {
  const { wagmiConfig } = useTSWagmi();
  const [transactionHash, setTransactionHash] = useState<Maybe<any>>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [prepareError, setPrepareError] = useState<Maybe<any>>(null);

  const write = async (
    contractAddress: string,
    toAddress: string,
    amount: string,
    chainId: number,
  ) => {
    setSubmitting(true);
    setPrepareError(null);

    try {
      let abi =
        contractAddress.toLowerCase() ===
        `0xdAC17F958D2ee523a2206206994597C13D831ec7`.toLowerCase()
          ? getErc20ABI(true)
          : getErc20ABI();
      const { request } = await simulateContract(wagmiConfig, {
        address: contractAddress,
        abi,
        functionName: 'transfer',
        chainId,
        args: [toAddress, amount],
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      return writeContractResponse;
    } catch (e: any) {
      setPrepareError(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
    error: prepareError || waitForTransactionResponse?.error,
  };
};
