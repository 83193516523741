import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const shouldShowFeedback = () => {
  const EXPIRY_HOURS = 24;
  const storageKey = 'sentryFeedbackId';
  const expiryKey = 'sentryFeedbackExpiry';
  const showPercentage = 0.2;

  // Check if we have a non-expired ID
  const expiry = localStorage.getItem(expiryKey);
  const userId = localStorage.getItem(storageKey);
  const now = new Date().getTime();

  // Clear expired values and generate new ones
  if (!userId || !expiry || parseInt(expiry) < now) {
    const newUserId = Math.random().toString();
    const newExpiry = now + EXPIRY_HOURS * 60 * 60 * 1000;
    localStorage.setItem(storageKey, newUserId);
    localStorage.setItem(expiryKey, newExpiry.toString());
    return parseFloat(newUserId) < showPercentage;
  }

  // Use existing valid ID
  return parseFloat(userId) < showPercentage;
};

Sentry.init({
  dsn: 'https://86673d47ef33ef7bffb4bc1d8adcbe0d@o141072.ingest.us.sentry.io/4508253853581312',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    ...(shouldShowFeedback()
      ? [
          Sentry.feedbackIntegration({
            colorScheme: 'system',
            submitButtonLabel: 'Send Feedback',
            formTitle: 'Send Feedback',
            triggerLabel: 'Feedback',
            messagePlaceholder:
              'What do you like? What do you dislike? Share your thoughts.',
            showBranding: false,
            successMessageText: 'Thanks for your feedback!',
          }),
        ]
      : []),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
