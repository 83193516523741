export const CollapseIcon = ({ fill = 'var(--primary)', ...restProps }) => {
  return (
    <svg
      {...restProps}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 10.8L4.4 7.2L5.536 6.072L8 8.536L10.464 6.072L11.6 7.2L8 10.8Z'
        fill={fill}
      />
    </svg>
  );
};
