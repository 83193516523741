import { useParams } from 'react-router-dom';

/**
 * Parses an integer path parameter from current url, returning the value
 * as an integer, or null if the parameter is not present and valid.
 * Rejects numbers with leading zeros and zero itself.
 */
export const usePositiveIntegerParam = (param: string): Maybe<ID> => {
  const params = useParams();
  const paramStr = params[param];

  if (paramStr === undefined || paramStr === '') {
    return null;
  }

  return parsePositiveInteger(paramStr);
};

const parsePositiveInteger = (param: string): Maybe<ID> => {
  // Don't allow leading zeros
  if (param.startsWith('0')) {
    return null;
  }

  const paramInt = Number(param);

  if (!isPositiveInteger(paramInt)) {
    return null;
  }

  return paramInt;
};

const isPositiveInteger = (num: number): boolean => {
  return Number.isInteger(num) && num > 0;
};
