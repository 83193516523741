import { Wallet } from '@customTypes';
import { BLOCKCHAIN_KEY, FEATURE } from '@enums';

export const getWalletRequirementsFromFeatures = (
  features: string[],
): BLOCKCHAIN_KEY[] => {
  const requirements: BLOCKCHAIN_KEY[] = [];

  features.forEach((feature) => {
    if (feature.includes(FEATURE.ADD_POLKADOT_WALLETS)) {
      requirements.push(BLOCKCHAIN_KEY.POLKADOT);
    }
  });

  // By default add ethereum as a wallet requirement
  requirements.push(BLOCKCHAIN_KEY.ETHEREUM);
  return requirements;
};

export const isSmartContractWallet = (
  wallets: Wallet[],
  walletAddress: string,
) => {
  if (!wallets) {
    return false;
  }

  const foundWallet = wallets.find(
    (wallet) =>
      wallet?.walletAddress?.toLowerCase() === walletAddress?.toLowerCase(),
  );
  return foundWallet ? foundWallet.smartContract : false;
};
