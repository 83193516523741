import { useWebApiClient } from '@apiClients';
import { useQuery } from '@tanstack/react-query';
import { TSEvent } from 'tokensoft-shared-types';

export const useGetEvent = (eventId: ID) => {
  const client = useWebApiClient();

  return useQuery<TSEvent>({
    queryKey: ['event', eventId],
    queryFn: async () => {
      const rawEvent = await client(`events/${eventId}`, {
        shouldSignOutOnForbidden: false,
      });
      return new TSEvent(rawEvent);
    },
  });
};
