import { Text } from '@components';
import classNames from 'classnames';

export type ModalTitleProps = {
  className?: string;
  children: React.ReactNode;
} & React.ComponentProps<typeof Text>;

export const ModalTitle: React.FC<ModalTitleProps> = ({
  className,
  children,
  ...restProps
}) => {
  return (
    <Text
      textAlign={'center'}
      className={classNames('text-2xl font-bold high-contrast', className)}
      {...restProps}
    >
      {children}
    </Text>
  );
};
