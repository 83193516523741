import {
  useGetProjectSEO,
  useGetProjectTheme,
  useGetProjectWelcomeContent,
} from '@apiServices';
import {
  Error,
  LoadingIndicator,
  Modal,
  ProjectConfiguration,
  Toast,
} from '@components';
import { useAuth } from '@contexts';
import { ConnectPage } from '@pages';
// import * as Sentry from '@sentry/react';
import { Outlet } from 'react-router-dom';
import { useReferralTracking } from './hooks';

export const App = () => {
  const {
    data: projectSEO,
    isPending: isProjectSEOPending,
    isError: isProjectSEOError,
  } = useGetProjectSEO();
  const {
    data: projectTheme,
    isPending: isProjectThemePending,
    isError: isProjectThemeError,
  } = useGetProjectTheme();
  const {
    data: projectWelcomeContent,
    isPending: isProjectWelcomeContentPending,
    isError: isProjectWelcomeContentError,
  } = useGetProjectWelcomeContent();

  const isPending =
    isProjectSEOPending ||
    isProjectThemePending ||
    isProjectWelcomeContentPending;

  const { isAuthenticated } = useAuth();

  useReferralTracking();

  // const sentryBoundary = (child: ReactNode) => {
  //   if (import.meta.env.VITE_SENTRY_DSN) {
  //     return (
  //       <Sentry.ErrorBoundary
  //         fallback={(error) => {
  //           return <Error exception={error} />;
  //         }}
  //       >
  //         {child}
  //       </Sentry.ErrorBoundary>
  //     );
  //   }

  //   return <>{child}</>;
  // };

  if (isPending) {
    return (
      <div className='p-0 m-0 h-screen w-screen'>
        <LoadingIndicator text={''} fullScreen />
      </div>
    );
  }

  if (
    isProjectSEOError ||
    isProjectWelcomeContentError ||
    isProjectThemeError
  ) {
    return <Error exception={{ message: 'Error loading project.' }} />;
  }

  return (
    <>
      <ProjectConfiguration
        projectTheme={projectTheme}
        seoContent={projectSEO}
        projectWelcomeContent={projectWelcomeContent}
      />

      <div id='main-wrapper' className={`show`}>
        {isAuthenticated ? (
          <Outlet />
        ) : (
          <div className='h-screen w-screen'>
            <ConnectPage />
          </div>
        )}
        <Toast />
        <Modal />
      </div>
    </>
  );
};
