import { useWebApiClient } from '@apiClients';
import { useQuery } from '@tanstack/react-query';

export const useGetPurchaseSignature = (saleAddress: EvmAddress) => {
  const client = useWebApiClient();

  return useQuery({
    queryKey: ['sales', saleAddress, 'purchase-signature'],
    queryFn: () => client(`sales/${saleAddress}/purchase-signature`),
  });
};
