import { CreateEventPayload } from '@customTypes';
import { DOCUMENT_CATEGORY, EVENT_TYPE } from '@enums';
import { LaunchEventWizardPage } from '@pages';

export const LaunchIdentityEventWizardPage = () => {
  const eventType = EVENT_TYPE.IDENTITY;
  const documentCategory = DOCUMENT_CATEGORY.COMPLIANCE_DOCUMENTS;

  /**
   * Assign the registration start/end to the actual event start/end
   * Because identity events only consist of registration, the start/end
   * times are the same.
   */
  function addIdentitySpecificFields(
    payload: CreateEventPayload,
  ): CreateEventPayload {
    payload.startTime = payload.registrationStartTime;
    payload.endTime = payload.registrationEndTime;

    return payload;
  }

  return (
    <LaunchEventWizardPage
      eventType={eventType}
      documentCategory={documentCategory}
      adjustEventCreationFields={addIdentitySpecificFields}
    />
  );
};
