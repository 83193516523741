import { Flex } from '@components';
import { max } from '@utils';
import classNames from 'classnames';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

type StretchedProps = React.ComponentProps<typeof Flex> & {};

/**
 * This container will force all items to stay on the same row for larger
 * screens and turn into a column layout for smaller screens. Useful when
 * you want to prevent items from naturally wrapping into new rows.
 */
export const Stretched: React.FC<StretchedProps> = ({
  children,
  gap = 0,
  className = '',
  ...restProps
}) => {
  const isMediumScreen = useMediaQuery({ maxWidth: 864 });
  let appliedGap = isMediumScreen ? Number(max(gap / 2, 2.5)) : gap;

  return (
    <Flex
      className={classNames('stretched md:flex-row', className)}
      nowrap
      gap={appliedGap}
      {...restProps}
    >
      {children}
    </Flex>
  );
};
