export const InstantIcon = ({ fill = 'var(--primary)' }) => {
  return (
    <svg
      data-testid={'instant-icon'}
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56ZM35.8573 25.6098C35.8764 25.513 35.8661 25.4127 35.8276 25.3217C35.7895 25.2311 35.7252 25.1538 35.6429 25.0994C35.5605 25.0451 35.4638 25.0162 35.3649 25.0164L31.3363 25.0412L35.7976 17.5448C35.8413 17.4696 35.8644 17.3842 35.8646 17.2973C35.8647 17.2105 35.8419 17.125 35.7983 17.0497C35.7548 16.9743 35.6921 16.9116 35.6165 16.8678C35.5408 16.824 35.455 16.8007 35.3674 16.8002H24.2969C24.1865 16.7974 24.0784 16.831 23.9893 16.8957C23.9002 16.9603 23.8351 17.0524 23.8042 17.1576L20.5533 28.3277C20.5316 28.4017 20.5275 28.4797 20.5414 28.5555C20.5553 28.6313 20.5868 28.7029 20.6334 28.7646C20.6799 28.8262 20.7404 28.8763 20.8098 28.9107C20.8792 28.9452 20.9558 28.9632 21.0335 28.9632H24.1993L20.5608 39.4755C20.5241 39.5815 20.5246 39.6966 20.5623 39.8023C20.5999 39.908 20.6724 39.9979 20.768 40.0574C20.8637 40.117 20.9768 40.1427 21.089 40.1303C21.2012 40.1179 21.3059 40.0682 21.3861 39.9893L35.72 25.8653C35.7904 25.7956 35.8381 25.7066 35.8573 25.6098Z'
        fill={fill}
      />
    </svg>
  );
};
