import { Stretched } from '@components';

type StretchedProps = React.ComponentProps<typeof Stretched>;

type CardFooterProps = StretchedProps & {};

export const CardFooter: React.FC<CardFooterProps> = ({
  children,
  ...restProps
}) => {
  return (
    <Stretched className='inner-card-footer' {...restProps}>
      {children}
    </Stretched>
  );
};
