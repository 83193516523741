import { div, getClaimableAmountABI, getVestedFractionABI } from '@utils';
import BigNumber from 'bignumber.js';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useReadContract, useAccount as useWagmiAccount } from 'wagmi';
import { calculateClaimableAmountInWei } from '../../../pages/event/distribution/claim-util';
import { useGetDistributorByContractAddress } from './use-get-distributor-by-contract-address';
import { useGetMyDistributorMerkleLeaf } from './use-get-my-distributor-merkle-tree-leaf';

/**
 * Attempts to read getClaimableAmount from distributor contracts.
 * In cases where the user has not claimed on the contract before,
 * this call *will* return a Wagmi error of type `ContractFunctionExecutionError`,
 * in which case we calculate the claimable amount manually, multiplying
 * getVestedFraction by the user's allocation.
 */
export const useGetMyDistributorClaimableAmount = (
  distributorAddress: EvmAddress,
) => {
  const [claimableAmountInWei, setClaimableAmountInWei] =
    useState<Maybe<BigNumber>>(null);

  const { address } = useWagmiAccount();

  const {
    data: distributor,
    isPending: isDistributorPending,
    isError: isDistributorError,
    error: distributorError,
  } = useGetDistributorByContractAddress(distributorAddress);

  const claimableAmount = useMemo(() => {
    if (!distributor || claimableAmountInWei === null) {
      return null;
    }

    return new BigNumber(
      div(claimableAmountInWei, Math.pow(10, distributor.token.decimals)),
    );
  }, [distributor?.token?.decimals, claimableAmountInWei]);

  const {
    data: _claimableAmountInWei,
    isPending: isClaimablePending,
    isError: isClaimableError,
    error: claimableError,
  } = useReadContract({
    address: distributorAddress,
    abi: getClaimableAmountABI(),
    functionName: 'getClaimableAmount',
    args: [address],
  });

  useEffect(() => {
    if (distributor && _claimableAmountInWei !== undefined) {
      const claimableAmountInWei = new BigNumber(
        _claimableAmountInWei as string,
      );
      setClaimableAmountInWei(new BigNumber(claimableAmountInWei));
    }
  }, [_claimableAmountInWei, distributor]);

  const isFallbackEnabled = isClaimableError === true;

  const {
    data: distributorLeaf,
    isPending: isLeafPending,
    isError: isLeafError,
    error: leafError,
  } = useGetMyDistributorMerkleLeaf(distributorAddress, isFallbackEnabled);

  const timestampInSecondsRef = useRef<number>(Math.floor(Date.now() / 1000));
  const {
    data: vestedFraction,
    isPending: isVestedFractionPending,
    isError: isVestedFractionError,
    error: vestedFractionError,
  } = useReadContract({
    address: distributorAddress,
    abi: getVestedFractionABI(),
    functionName: 'getVestedFraction',
    args: [address, timestampInSecondsRef.current],
    query: {
      enabled: isFallbackEnabled,
    },
  });

  useEffect(() => {
    if (
      !isFallbackEnabled ||
      isVestedFractionPending ||
      isVestedFractionError ||
      isDistributorPending ||
      isDistributorError ||
      isLeafPending ||
      isLeafError ||
      !distributor ||
      !distributorLeaf ||
      !vestedFraction ||
      distributor.fractionDenominator === null
    ) {
      return;
    }

    const calculatedAmountInWei = calculateClaimableAmountInWei(
      distributorLeaf.amount,
      // @ts-ignore
      vestedFraction,
      distributor.fractionDenominator,
    );

    setClaimableAmountInWei(new BigNumber(calculatedAmountInWei));
  }, [isFallbackEnabled, distributorLeaf, distributor, vestedFraction]);

  return {
    data: claimableAmount,
    isPending: isClaimablePending,
    isError: isClaimableError,
    error: claimableError,
  };
};
