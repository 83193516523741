import { Toast, ToastContext } from '@contexts';
import { isEmptyObject } from '@utils';
import { FC, ReactNode, useState } from 'react';
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaRegTimesCircle,
} from 'react-icons/fa';

interface ToastProviderProps {
  children?: ReactNode;
}

interface ToastOptions {
  title?: string;
  description?: ReactNode | string;
  dismissTime?: number;
  position?: string;
  icon?: ReactNode | string;
  autoDelete?: boolean;
  permanent?: boolean;
}

const DEFAULT_TOAST_DISMISS_TIME = 7000;
const DEFAULT_TOAST_POSITION = 'top-right';

export const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  const [toast, setToast] = useState<Maybe<Toast>>(null);

  const showSuccessToast = (options: ToastOptions = {}) => {
    setToast({
      title: options.title || '',
      description: options.description || '',
      type: 'success',
      backgroundColor: 'var(--success)',
      icon: <FaCheckCircle color='#ffffff' size='16px' />,
      dismissTime: options.dismissTime || DEFAULT_TOAST_DISMISS_TIME,
      position: options.position || DEFAULT_TOAST_POSITION,
      autoDelete: options.autoDelete !== undefined ? options.autoDelete : true,
      permanent: options.permanent || false,
    });
  };

  const showErrorToast = (options: ToastOptions = {}) => {
    if (isEmptyObject(options)) {
      options.title = 'Oops! Something went wrong.';
      options.description = (
        <span>
          Please try again. If the problem persists, visit our{' '}
          <a
            href='https://docs.tokensoft.io/my-tokensoft-account/faq'
            rel='noreferrer'
            target='_blank'
            className='underline text-white'
            onClick={(e) => e.stopPropagation()}
          >
            FAQs
          </a>
          .
        </span>
      );
    }

    setToast({
      title: options.title || '',
      description: options.description || '',
      type: 'error',
      backgroundColor: 'var(--danger)',
      icon: options.icon || <FaRegTimesCircle color='#ffffff' size='16px' />,
      dismissTime: options.dismissTime || DEFAULT_TOAST_DISMISS_TIME,
      position: options.position || DEFAULT_TOAST_POSITION,
      autoDelete: options.autoDelete !== undefined ? options.autoDelete : true,
      permanent: options.permanent || false,
    });
  };

  const showWarningToast = (options: ToastOptions = {}) => {
    setToast({
      title: options.title || '',
      description: options.description || '',
      type: 'warning',
      backgroundColor: 'var(--warning)',
      icon: <FaExclamationCircle color='#ffffff' size='16px' />,
      dismissTime: options.dismissTime || DEFAULT_TOAST_DISMISS_TIME,
      position: options.position || DEFAULT_TOAST_POSITION,
      autoDelete: options.autoDelete !== undefined ? options.autoDelete : true,
      permanent: options.permanent || false,
    });
  };

  const showInfoToast = (options: ToastOptions = {}) => {
    setToast({
      title: options.title || '',
      description: options.description || '',
      type: 'info',
      backgroundColor: 'var(--info)',
      icon: <FaExclamationCircle color='#ffffff' size='16px' />,
      dismissTime: options.dismissTime || DEFAULT_TOAST_DISMISS_TIME,
      position: options.position || DEFAULT_TOAST_POSITION,
      autoDelete: options.autoDelete !== undefined ? options.autoDelete : true,
      permanent: options.permanent || false,
    });
  };

  const clearToast = () => {
    setToast(null);
  };

  return (
    <ToastContext.Provider
      value={{
        toast: toast!,
        setToast: setToast!,
        showSuccessToast,
        showErrorToast,
        showWarningToast,
        showInfoToast,
        clearToast,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};
