import { type NetworkDetails } from '@customTypes';
import { PAYMENT_METHOD } from '@enums';
import { Blockchain } from 'tokensoft-shared-types';

export const hexToDecimal = (hex: string): number => {
  return parseInt(hex, 16);
};

export const decimalToHex = (num: any) => {
  const val = Number(num);
  return '0x' + val.toString(16);
};

export const getTxUrl = (transactionHash: string, network: Blockchain) => {
  return `${network.blockExplorerUri}tx/${transactionHash}`;
};

export const getAddressUrl = (address: string, network: Blockchain) => {
  return `${network.blockExplorerUri}address/${address}`;
};

export const getPaymentMethodUrlBySymbol = (
  symbol: PAYMENT_METHOD,
  network: NetworkDetails,
) => {
  const method = network.paymentMethods?.find(
    (paymentMethod) => paymentMethod.symbol === symbol,
  );
  return method?.address
    ? `${network.blockExplorerUri}token/${method.address}`
    : null;
};

export const getPaymentMethodUrlByAddress = (
  address: string,
  network: NetworkDetails,
) => {
  return `${network.blockExplorerUri}token/${address}`;
};

/**
 * Create block exporer url from legacy purchase
 * @param paymentType string
 * @param txHash string
 **/
export const getBlockExplorerUrlForLegacyPurchase = (
  paymentType: string,
  txHash: string,
) => {
  let url = '';
  if (paymentType === 'btc') {
    url = 'https://blockstream.info/tx/';
  } else if (
    paymentType === 'eth' ||
    paymentType === 'dai' ||
    paymentType === 'usdc' ||
    paymentType === 'usdt'
  ) {
    url = 'https://etherscan.io/tx/';
  } else if (paymentType === 'dot') {
    url = 'https://polkadot.subscan.io/extrinsic/';
  } else {
    return '';
  }

  return `${url}${txHash}`;
};

export const getConnextScanUrl = (txHash?: string) => {
  if (!txHash) {
    return null;
  }

  let baseUrl = 'https://connextscan.io/tx/';

  if (import.meta.env.VITE_PROCESS_ENV !== 'production') {
    baseUrl = 'https://testnet.connextscan.io/tx/';
  }

  return baseUrl + txHash;
};
