import { useNetworks, useSale, useTSWagmi } from '@contexts';
import { getFlatPriceSaleABI, mult, toBaseUnits } from '@utils';
import { simulateContract, writeContract } from '@wagmi/core';
import { useState } from 'react';
import { parseEther } from 'viem';
import { useWaitForTransactionReceipt } from 'wagmi';

export const useSendErc20Transaction = (chainId: number) => {
  const { wagmiConfig } = useTSWagmi();
  const [transactionHash, setTransactionHash] = useState<Maybe<any>>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { supportedNetworks } = useNetworks();
  const network = supportedNetworks?.find((n) => n.id === chainId);
  const { sale } = useSale();

  const write = async (
    chainId,
    paymentMethod,
    toAddress,
    value,
    proof,
    platformFee,
  ) => {
    if (network === undefined) {
      return;
    }

    setSubmitting(true);

    const txValue = toBaseUnits(value, paymentMethod.decimals);
    console.log('preparing purchase', txValue.toString());

    try {
      if (sale.implementation.version === '3.0') {
        const salePlatformFeeForOracle = Number(
          mult(sale.platformFeeInCents || 100, Math.pow(10, 6)),
        );

        const { request } = await simulateContract(wagmiConfig, {
          address: toAddress,
          abi: getFlatPriceSaleABI('v3.0'),
          functionName: 'buyWithToken',
          chainId,
          args: [
            paymentMethod.address,
            txValue,
            '0x',
            proof,
            network.platformFeeRecipient,
            salePlatformFeeForOracle,
          ],
          value: parseEther(platformFee),
        } as any);

        const writeContractResponse = await writeContract(wagmiConfig, request);
        setTransactionHash(writeContractResponse);
        console.log('purchase transaction response', writeContractResponse);
        return writeContractResponse;
      } else {
        const { request } = await simulateContract(wagmiConfig, {
          address: toAddress,
          abi: getFlatPriceSaleABI(),
          functionName: 'buyWithToken',
          chainId,
          args: [paymentMethod.address, txValue, '0x', proof],
        } as any);

        const writeContractResponse = await writeContract(wagmiConfig, request);
        setTransactionHash(writeContractResponse);
        console.log('purchase transaction response', writeContractResponse);
        return writeContractResponse;
      }
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};
