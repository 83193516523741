import { useGetDistributor } from '@apiServices';
import {
  BackButton,
  ButtonRow,
  DistributorVesting,
  LoadingIndicator,
  PageSubtitle,
  PageTitle,
  SaveButton,
  Step,
  StepFooter,
  StepHeader,
  SwitchChainWarning,
} from '@components';
import { DEFAULT_DATE_DISPLAY } from '@constants';
import {
  useAccount,
  useEvent,
  useNetworks,
  useProject,
  useToast,
  useWallet,
  WizardContext,
} from '@contexts';
import { IWizardStateEditDistributor } from '@customTypes';
import { DEPLOY_VESTING_TYPE_OPTIONS } from '@enums';
import { useUpdateVestingConfig } from '@hooks';
import {
  getDifference,
  getTxUrl,
  isSame,
  localToUtcUnixDateTime,
  unixToLocalDate,
  unixToUTCString,
  utcToLocalDate,
} from '@utils';
import { useEffect, useState } from 'react';
import { VscLinkExternal } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';

export const EditDistributorVestingWizardPage = () => {
  const navigate = useNavigate();
  const { account } = useAccount();
  const { event } = useEvent();
  const { project } = useProject();
  const { connectedChainId } = useWallet();
  const { showErrorToast, showSuccessToast } = useToast();
  const { getNetworkDetails } = useNetworks();
  const { distributor, loading } = useGetDistributor(event.distributor?.id);
  const supportedContinuousUnits = ['day', 'week', 'month', 'year'];

  const {
    error: updateConfigError,
    write: sendUpdateConfig,
    isLoading: updateConfigLoading,
    data: updateConfigReceipt,
  } = useUpdateVestingConfig();

  const [wizardState, setWizardState] =
    useState<IWizardStateEditDistributor | null>(null);

  useEffect(() => {
    if (distributor) {
      const vestingType = distributor.trancheVesting
        ? DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY
        : distributor.continuousVesting &&
            isSame(
              distributor.continuousVesting.cliff,
              distributor.continuousVesting.end,
            )
          ? DEPLOY_VESTING_TYPE_OPTIONS.INSTANT
          : DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS;

      let startTime = event?.startTime;
      let cliffTime = event?.startTime;
      let endTime = event?.endTime;
      let cliffIntervalLength = 0;
      let endIntervalLength = 12;
      let cliffIntervalUnits = 'months';
      let endIntervalUnits = 'months';

      if (vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY) {
        const sortedTranches = distributor?.trancheVesting?.tranches.sort(
          function (a, b) {
            return Number(a.index) - Number(b.index);
          },
        );

        const totalTranches = sortedTranches.length;
        const firstTranche = sortedTranches[0];
        const lastTranche = sortedTranches[totalTranches - 1];

        cliffTime = firstTranche.time;
        endTime = lastTranche.time;
      } else {
        cliffTime = distributor?.continuousVesting?.cliff;
        endTime = distributor?.continuousVesting?.end;
      }

      supportedContinuousUnits.forEach((unit) => {
        let cliffIntervalTemp = getDifference(
          utcToLocalDate(startTime, account?.timezone, DEFAULT_DATE_DISPLAY),
          unixToLocalDate(cliffTime, account?.timezone),
          unit,
          true,
        );
        let endIntervalTemp = getDifference(
          utcToLocalDate(startTime, account?.timezone, DEFAULT_DATE_DISPLAY),
          unixToLocalDate(endTime, account?.timezone),
          unit,
          true,
        );

        if (cliffIntervalTemp - parseInt(`${cliffIntervalTemp}`) === 0) {
          cliffIntervalLength = cliffIntervalTemp;
          cliffIntervalUnits = unit;
        }

        if (endIntervalTemp - parseInt(`${endIntervalTemp}`) === 0) {
          if (vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY) {
            endIntervalLength = endIntervalTemp + 1;
          } else {
            endIntervalLength = endIntervalTemp;
          }
          endIntervalUnits = unit;
        }
      });

      setWizardState({
        ...distributor,
        totalAllocations: distributor?.totalAllocations,
        vestingTypeFormValid: false,
        distributorId: distributor?.id,
        eventId: event?.id,
        projectId: project?.id,
        startTime: startTime,
        cliffTime: unixToUTCString(cliffTime),
        cliffIntervalLength,
        cliffIntervalUnits,
        endIntervalLength,
        endIntervalUnits,
        endTime: unixToUTCString(endTime),
        vestingType,
      });
    }
  }, [distributor]);

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (updateConfigError) {
      showErrorToast({ description: updateConfigError.toString() });
    }
  }, [updateConfigError]);

  useEffect(() => {
    if (updateConfigReceipt) {
      if (updateConfigReceipt.status === 'success') {
        showSuccessToast({
          description: (
            <div className='flex flex-row'>
              Successfully submitted transaction.
              <a
                target='_blank'
                rel='noreferrer'
                href={getTxUrl(
                  updateConfigReceipt.transactionHash,
                  getNetworkDetails(distributor.chainId),
                )}
                className='w-[30px] flex items-center justify-center text-white'
                onClick={(e) => e.stopPropagation()}
              >
                <VscLinkExternal color='white' />
              </a>
            </div>
          ),
        });
        goBack();
      }
    }
  }, [updateConfigReceipt]);

  const handleSave = async () => {
    if (
      !wizardState?.startTime ||
      !wizardState?.cliffTime ||
      !wizardState?.endTime
    ) {
      showErrorToast({ description: 'Please fill out all fields' });
      return;
    }

    const unixStartTime = localToUtcUnixDateTime(
      wizardState?.startTime,
      account?.timezone,
    );
    const unixCliffTime = localToUtcUnixDateTime(
      wizardState?.cliffTime,
      account?.timezone,
    );
    const unixEndTime = localToUtcUnixDateTime(
      wizardState?.endTime,
      account?.timezone,
    );

    try {
      const newOnChainConfig = {
        startTime: unixStartTime,
        cliffTime: unixCliffTime,
        endTime: unixEndTime,
      };

      await sendUpdateConfig(
        distributor.chainId,
        distributor.id,
        newOnChainConfig,
        wizardState?.vestingType,
      );
    } catch (e: any) {
      showErrorToast({ description: e.message });
      console.log(e);
    }
  };

  const handleCancel = () => {
    goBack();
  };

  if (!distributor || !wizardState) {
    return <LoadingIndicator text={'Loading event'} />;
  }

  if (!loading && distributor && distributor.chainId !== connectedChainId) {
    const correctNetworkDetails = getNetworkDetails(distributor.chainId);
    return (
      <SwitchChainWarning
        chainId={distributor.chainId}
        subtitle={`Please connect to the ${correctNetworkDetails.name} network to continue.`}
      />
    );
  }

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <Step>
            <StepHeader>
              <PageTitle>Vesting</PageTitle>
              <PageSubtitle>
                Specify how tokens unlock over time. Participants can only claim
                tokens once they are vested or unlocked.
              </PageSubtitle>
            </StepHeader>
            {wizardState.vestingType && (
              <DistributorVesting
                context={wizardState}
                setContext={setWizardState}
                hideCards={true}
              />
            )}

            <StepFooter>
              <ButtonRow place='between'>
                <BackButton
                  label={'Cancel'}
                  onClick={handleCancel}
                  disabled={updateConfigLoading}
                />
                <SaveButton
                  label={'Done'}
                  disabled={
                    !wizardState.vestingTypeFormValid || updateConfigLoading
                  }
                  saving={updateConfigLoading}
                  onClick={handleSave}
                />
              </ButtonRow>
            </StepFooter>
          </Step>
        )}
      </Wizard>
    </WizardContext.Provider>
  );
};
