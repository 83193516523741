import {
  Col,
  DocumentSignatureContainer,
  FileUploadButton,
  FormLabel,
  HelpText,
  InputGroup,
  PDFIcon,
  Row,
  Stretched,
  Text,
} from '@components';
import {
  documentShowTypeToString,
  useDocumentUpload,
  useProject,
} from '@contexts';
import { FileUploadResult } from '@customTypes';
import { DOCUMENT_SHOW_TO_TYPE } from '@enums';
import classNames from 'classnames';
import { BsTrash3 } from 'react-icons/bs';

export const DocumentUploadSection = () => {
  const { project } = useProject();
  const { documentData, setDocumentData, isUSRestricted } = useDocumentUpload();

  const onDocumentShowToClick = (documentShowToType: DOCUMENT_SHOW_TO_TYPE) => {
    setDocumentData({ ...documentData, showDocumentTo: documentShowToType });
  };

  const handleDocumentDelete = () => {
    setDocumentData({ ...documentData, uploadedFile: null });
  };

  const handleSignatureRequiredChanged = (value) => {
    setDocumentData({ ...documentData, signatureRequired: value });
  };

  const handleDocumentTitleChanged = (value) => {
    setDocumentData({ ...documentData, documentTitle: value });
  };

  const handleUploadedFileChanged = (uploadResult: FileUploadResult) => {
    setDocumentData({
      ...documentData,
      uploadedFile: uploadResult.file,
      uri: uploadResult.s3Uri,
    });
  };

  return (
    <>
      <div>
        <Stretched gap={2.5} place={'center'}>
          <Col>
            <InputGroup
              label={'Document Title'}
              value={documentData?.documentTitle}
              name='title'
              maxLength={50}
              placeholder={'Enter Here'}
              required={true}
              onChange={(e) => {
                handleDocumentTitleChanged(e.target.value);
              }}
            />

            <HelpText className='-mt-4'>
              *We only accept .pdf files, please make sure to use the correct
              format and that your file is under 2MB.
            </HelpText>
          </Col>

          <FileUploadButton
            className={'w-full md:w-fit'}
            projectId={project.id}
            onFileUploaded={handleUploadedFileChanged}
          />
        </Stretched>

        {documentData?.uploadedFile && (
          <div className='mt-4 flex flex-row justify-between document-section'>
            <div className='flex flex-row justify-center items-center'>
              <PDFIcon />
              <span className='ml-2'>{documentData.uploadedFile?.name}</span>
            </div>
            <div className='flex flex-row justify-center items-center'>
              <span
                className='cursor-pointer'
                onClick={() => handleDocumentDelete()}
              >
                <BsTrash3 size={16} />
              </span>
            </div>
          </div>
        )}
        <Col className='mt-6'>
          <FormLabel>
            <label>Show This Document to</label>
          </FormLabel>

          <Col className='mt-2' gap={2}>
            <Row yalign='center'>
              <input
                className={classNames('form-check-input cursor-pointer')}
                type='radio'
                name={DOCUMENT_SHOW_TO_TYPE.ALL_ELIGIBLE_PERSONS}
                checked={
                  documentData?.showDocumentTo ===
                  DOCUMENT_SHOW_TO_TYPE.ALL_ELIGIBLE_PERSONS
                }
                onChange={() =>
                  onDocumentShowToClick(
                    DOCUMENT_SHOW_TO_TYPE.ALL_ELIGIBLE_PERSONS,
                  )
                }
              />
              <span className='ml-2'>
                {documentShowTypeToString(
                  DOCUMENT_SHOW_TO_TYPE.ALL_ELIGIBLE_PERSONS,
                )}
              </span>
            </Row>
            {!isUSRestricted && (
              <>
                <Row yalign='center'>
                  <input
                    className={classNames('form-check-input cursor-pointer')}
                    type='radio'
                    name={DOCUMENT_SHOW_TO_TYPE.OUTSIDE_US}
                    checked={
                      documentData?.showDocumentTo ===
                      DOCUMENT_SHOW_TO_TYPE.OUTSIDE_US
                    }
                    onChange={() =>
                      onDocumentShowToClick(DOCUMENT_SHOW_TO_TYPE.OUTSIDE_US)
                    }
                  />
                  <span className='ml-2'>
                    {documentShowTypeToString(DOCUMENT_SHOW_TO_TYPE.OUTSIDE_US)}
                  </span>
                </Row>
                <Row yalign='center'>
                  <input
                    className={classNames('form-check-input cursor-pointer')}
                    type='radio'
                    name={DOCUMENT_SHOW_TO_TYPE.US_ONLY}
                    checked={
                      documentData?.showDocumentTo ===
                      DOCUMENT_SHOW_TO_TYPE.US_ONLY
                    }
                    onChange={() =>
                      onDocumentShowToClick(DOCUMENT_SHOW_TO_TYPE.US_ONLY)
                    }
                  />
                  <span className='ml-2'>
                    {documentShowTypeToString(DOCUMENT_SHOW_TO_TYPE.US_ONLY)}
                  </span>
                </Row>
              </>
            )}
          </Col>
        </Col>

        <Col className='mt-6'>
          <FormLabel>
            <label className={''} htmlFor={'upload'}>
              Signature Required?
            </label>
          </FormLabel>

          <Row className='mt-2' gap={4}>
            <div className='flex flex-row justify-center'>
              <input
                className={classNames('form-check-input cursor-pointer')}
                type='radio'
                name={'yes'}
                checked={documentData?.signatureRequired}
                onChange={() => handleSignatureRequiredChanged(true)}
              />
              <span className='ml-2 font-semibold'>Yes</span>
            </div>
            <div className='flex flex-row justify-center'>
              <input
                className={classNames('form-check-input cursor-pointer')}
                type='radio'
                name={'no'}
                checked={documentData?.signatureRequired === false}
                onChange={() => handleSignatureRequiredChanged(false)}
              />
              <span className='ml-2 font-semibold'>No</span>
            </div>
          </Row>
        </Col>

        {documentData?.signatureRequired && (
          <>
            <Col className='mt-6'>
              <FormLabel>
                <label>Signature Page Content</label>
              </FormLabel>

              <Text className='mt-2'>
                Once a user signs this document, we will add the following
                signature page to the end of the document including your
                countersignature, and save the fully signed document for you and
                that user. You can customize the fields below to match
                terminology in the rest of this document.
              </Text>

              <div className='mt-6'>
                <DocumentSignatureContainer />
              </div>
            </Col>
          </>
        )}
      </div>
    </>
  );
};
