import { useWebApiClient } from '@apiClients';
import { useAuth } from '@contexts';
import { useInfiniteQuery } from '@tanstack/react-query';
import { EventOverview } from 'tokensoft-shared-types';

interface EventsResponse {
  events: EventOverview[];
  total: number;
}

interface RawEventsResponse {
  records: any[];
  total: number;
}

export const useGetEventOverviews = (eventTypes: EventType[] = []) => {
  const {
    user: { walletAddress },
  } = useAuth();

  const client = useWebApiClient();
  const limit = 10;

  const fetchEvents = async ({
    pageParam,
  }: {
    pageParam: number;
  }): Promise<EventsResponse> => {
    let url = `events/search?offset=${pageParam}&limit=${limit}`;

    if (eventTypes.length > 0) {
      url += `&eventTypes=${eventTypes.join(',')}`;
    }

    const rawEventData: RawEventsResponse = await client(url, {
      method: 'get',
    });

    if (rawEventData === undefined) {
      throw new Error('Failed to fetch events');
    }

    const parsedEvents = rawEventData.records.map(
      (rawEventOverview) => new EventOverview(rawEventOverview),
    );
    return {
      events: parsedEvents,
      total: rawEventData.total,
    };
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending,
    isError,
    error,
  } = useInfiniteQuery({
    queryKey: ['events', eventTypes],
    queryFn: fetchEvents,
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      const nextOffset = allPages.length * limit;
      return nextOffset < lastPage.total ? nextOffset : undefined;
    },
    enabled: walletAddress !== undefined,
  });

  const loadMore = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const flattenedEvents = data?.pages.flatMap((page) => page.events) ?? [];

  return {
    events: flattenedEvents,
    totalFetchableCount: data?.pages[0]?.total ?? 0,
    isPending,
    isError,
    error,
    loadMore,
    hasNextPage,
    isFetchingNextPage,
  };
};
