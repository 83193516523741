import { useGetChains, useGetOraclePrice } from '@apiServices';
import { useToast, useTSWagmi } from '@contexts';
import { getContinuousVestingMerkleDistributorFactoryABI } from '@utils';
import { simulateContract, writeContract } from '@wagmi/core';
import { useState } from 'react';
import { TransactionReceipt } from 'viem';
import {
  useReadContract,
  useAccount as useWagmiAccount,
  useWaitForTransactionReceipt,
} from 'wagmi';

const NATIVE_TOKEN_DECIMALS = 18;

export const useDeployContinuousVestingMerkleDistributorWithFactory = () => {
  const { wagmiConfig } = useTSWagmi();
  const { showErrorToast } = useToast();
  const { chain: chainUserIsConnectedTo } = useWagmiAccount();
  const { data: chains } = useGetChains();
  const [transactionHash, setTransactionHash] = useState<Maybe<any>>(null);
  const [isDeploying, setIsDeploying] = useState<boolean>(false);
  const [args, setArgs] = useState<any[]>([]);
  const { mutate: getOraclePrice } = useGetOraclePrice();

  const currentChainInfo = chains?.find(
    (n) => n.id === chainUserIsConnectedTo?.id,
  );
  const factoryAddress =
    currentChainInfo?.continuousVestingDistributorFactoryAddress;
  // const factoryV4Address =
  //   currentChainInfo?.continuousVestingDistributorFactoryV4Address;

  // const isV4 = Boolean(factoryV4Address);

  const config = {
    // address: isV4 ? factoryV4Address : factoryAddress,
    address: factoryAddress,
    abi: getContinuousVestingMerkleDistributorFactoryABI(),
    functionName: 'predictDistributorAddress',
    args,
    enabled: args?.length > 0,
  } as any;

  const { data: deployedAddress } = useReadContract(config);

  const write = async ({ args }) => {
    setArgs(args);
    setIsDeploying(true);

    try {
      let value: Maybe<string> = null;

      // if (isV4) {
      //   const predictedAddress = await readContract(wagmiConfig, {
      //     address: config.address,
      //     functionName: 'predictDistributorAddress',
      //     abi: getContinuousVestingMerkleDistributorFactoryABI(),
      //     args,
      //   });

      //   const { request: approveRequest } = await simulateContract(
      //     wagmiConfig,
      //     {
      //       address: args[0],
      //       abi: getErc20ABI(),
      //       functionName: 'approve',
      //       args: [predictedAddress, add(args[1], div(args[1], 100))],
      //     } as any,
      //   );

      //   const hash = await writeContract(wagmiConfig, approveRequest);
      //   await waitForTransactionReceipt(wagmiConfig, { hash });

      //   const result = await new Promise<{ price: string }>(
      //     (resolve, reject) => {
      //       getOraclePrice(
      //         {
      //           networkId: currentChainInfo?.id,
      //           oracleAddress: currentChainInfo?.nativePriceOracleAddress,
      //         },
      //         { onSuccess: resolve, onError: reject },
      //       );
      //     },
      //   );
      //   const nativeOraclePrice = result.price;

      //   value = fix(
      //     mult(
      //       div('100000001', nativeOraclePrice),
      //       exp(10, NATIVE_TOKEN_DECIMALS),
      //     ),
      //     '0',
      //   );
      // }

      const { request } = await simulateContract(wagmiConfig, {
        address: config.address,
        // abi: isV4
        //   ? getContinuousVestingMerkleDistributorFactoryV4ABI()
        //   : getContinuousVestingMerkleDistributorFactoryABI(),
        abi: getContinuousVestingMerkleDistributorFactoryABI(),
        functionName: 'deployDistributor',
        args,
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      return writeContractResponse;
    } catch (error) {
      console.error({ error });
    } finally {
      setIsDeploying(false);
    }
  };

  const response = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    deployedContinuousVestingMerkleDistributorAddress: deployedAddress,
    deployContinuousVestingMerkleDistributor: write,
    isDeployingContinuousVestingMerkleDistributor: isDeploying,
    deployContinuousVestingMerkleDistributorResponse: { ...response },
    deployContinuousVestingMerkleDistributorReceipt:
      response?.data || ({} as TransactionReceipt),
    deployContinuousVestingMerkleDistributorError:
      response?.error || ({} as Error),
  };
};
