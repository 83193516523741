import { useGetChains } from '@apiServices';
import { useTSWagmi } from '@contexts';
import {
  getTrancheVestingMerkleDistributorFactoryABI
} from '@utils';
import { simulateContract, writeContract } from '@wagmi/core';
import { useState } from 'react';
import { TransactionReceipt } from 'viem';
import {
  useReadContract,
  useAccount as useWagmiAccount,
  useWaitForTransactionReceipt,
} from 'wagmi';

export const useDeployTrancheVestingMerkleDistributorWithFactory = () => {
  const { wagmiConfig } = useTSWagmi();
  const { chain } = useWagmiAccount();
  const { data: chains } = useGetChains();
  const [transactionHash, setTransactionHash] = useState<Maybe<any>>(null);
  const [isDeploying, setIsDeploying] = useState<boolean>(false);
  const [args, setArgs] = useState<any[]>([]);

  const currentChainInfo = chains?.find((n) => n.id === chain?.id);

  const factoryAddress =
    currentChainInfo?.trancheVestingDistributorFactoryAddress;
  // const factoryV4Address =
  //   currentChainInfo?.trancheVestingDistributorFactoryV4Address;
  // const isV4 = Boolean(factoryV4Address);

  const config = {
    // address: isV4 ? factoryV4Address : factoryAddress,
    address: factoryAddress,
    abi: getTrancheVestingMerkleDistributorFactoryABI(),
    functionName: 'predictDistributorAddress',
    args,
    enabled: args?.length > 0,
  } as any;

  const { data: deployedAddress } = useReadContract(config);

  const write = async ({ args }) => {
    setArgs(args);
    setIsDeploying(true);

    try {
      // if (isV4) {
      //   const predictedAddress = await readContract(wagmiConfig, {
      //     address: config.address,
      //     functionName: 'predictDistributorAddress',
      //     abi: getTrancheVestingMerkleDistributorFactoryV4ABI(),
      //     args,
      //   });

      //   const { request: approveRequest } = await simulateContract(
      //     wagmiConfig,
      //     {
      //       address: args[0],
      //       abi: getErc20ABI(),
      //       functionName: 'approve',
      //       args: [predictedAddress, add(args[1], div(args[1], 100))],
      //     } as any,
      //   );

      //   // TODO: wait for confirmations

      //   await writeContract(wagmiConfig, approveRequest);
      // }

      const { request } = await simulateContract(wagmiConfig, {
        address: config.address,
        // abi: isV4
        //   ? getTrancheVestingMerkleDistributorFactoryV4ABI()
        //   : getTrancheVestingMerkleDistributorFactoryABI(),
        abi: getTrancheVestingMerkleDistributorFactoryABI(),
        functionName: 'deployDistributor',
        args,
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      return writeContractResponse;
    } catch (error) {
      console.error({ error });
    } finally {
      setIsDeploying(false);
    }
  };

  const response = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    deployedTrancheVestingMerkleDistributorAddress: deployedAddress,
    deployTrancheVestingMerkleDistributor: write,
    isDeployingTrancheVestingMerkleDistributor: isDeploying,
    deployTrancheVestingMerkleDistributorResponse: { ...response },
    deployTrancheVestingMerkleDistributorReceipt:
      response?.data || ({} as TransactionReceipt),
    deployTrancheVestingMerkleDistributorError:
      response?.error || ({} as Error),
  };
};
