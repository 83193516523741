import { type Account } from '@customTypes';
import { KYC_TIER_STATE, VERIFICATION_STATUS } from '@enums';
import { createContext, useContext } from 'react';

interface AccountContextProps {
  account: Account;
  loading: boolean;
  refetch: Function;
  isUsAccount: boolean;
  isEntity: boolean;
  tier1Status: VERIFICATION_STATUS;
  tier1Expiration: Date;
  isTier1Started: boolean;
  isTier1Finished: boolean;
  isTier1Completed: boolean;
  tier1CurrentState: Maybe<KYC_TIER_STATE>;
  tier3Status: VERIFICATION_STATUS;
  tier3Expiration: Date;
  isTier3Started: boolean;
  isTier3Finished: boolean;
  isTier3Eligible: boolean;
  isTier3Completed: boolean;
  tier3CurrentState: Maybe<KYC_TIER_STATE>;
  isProfileSetupComplete: boolean;
  configuration: any;
}

export const AccountContext = createContext<Maybe<AccountContextProps>>(null);
AccountContext.displayName = 'AccountContext';

export const useAccount = (): AccountContextProps => {
  const context = useContext(AccountContext);

  if (!context) {
    throw new Error(
      'AccountProvider context is undefined, please verify you are calling useAccount() as child of an <AccountProvider> component.',
    );
  }

  return context;
};
