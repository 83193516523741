export const TITLE_ONLY_100_LEFT = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 252 152'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='10' y='44' width='230' height='30' rx='5' fill='#E5E7EB' />
      <path
        d='M30.3506 63.9003C30.2172 63.9003 30.0893 63.8474 29.995 63.753C29.9006 63.6587 29.8477 63.5308 29.8477 63.3974V58.9502C29.8477 58.8168 29.9006 58.6889 29.995 58.5946C30.0893 58.5003 30.2172 58.4473 30.3506 58.4473C30.484 58.4473 30.6119 58.5003 30.7063 58.5946C30.8006 58.6889 30.8536 58.8168 30.8536 58.9502V63.3974C30.8536 63.5308 30.8006 63.6587 30.7063 63.753C30.6119 63.8474 30.484 63.9003 30.3506 63.9003Z'
        fill='#6B7280'
      />
      <path
        d='M32.5732 61.6768H28.126C27.9926 61.6768 27.8647 61.6238 27.7704 61.5295C27.676 61.4352 27.623 61.3072 27.623 61.1739C27.623 61.0405 27.676 60.9125 27.7704 60.8182C27.8647 60.7239 27.9926 60.6709 28.126 60.6709H32.5732C32.7066 60.6709 32.8345 60.7239 32.9288 60.8182C33.0231 60.9125 33.0761 61.0405 33.0761 61.1739C33.0761 61.3072 33.0231 61.4352 32.9288 61.5295C32.8345 61.6238 32.7066 61.6768 32.5732 61.6768Z'
        fill='#6B7280'
      />
      <path
        d='M30.9856 55.0059H20.503C20.3696 55.0059 20.2416 54.9529 20.1473 54.8586C20.053 54.7643 20 54.6364 20 54.503C20 54.3696 20.053 54.2416 20.1473 54.1473C20.2416 54.053 20.3696 54 20.503 54H30.9856C31.119 54 31.2469 54.053 31.3413 54.1473C31.4356 54.2416 31.4886 54.3696 31.4886 54.503C31.4886 54.6364 31.4356 54.7643 31.3413 54.8586C31.2469 54.9529 31.119 55.0059 30.9856 55.0059Z'
        fill='#6B7280'
      />
      <path
        d='M30.9856 55.0059H20.503C20.3696 55.0059 20.2416 54.9529 20.1473 54.8586C20.053 54.7643 20 54.6364 20 54.503C20 54.3696 20.053 54.2416 20.1473 54.1473C20.2416 54.053 20.3696 54 20.503 54H30.9856C31.119 54 31.2469 54.053 31.3413 54.1473C31.4356 54.2416 31.4886 54.3696 31.4886 54.503C31.4886 54.6364 31.4356 54.7643 31.3413 54.8586C31.2469 54.9529 31.119 55.0059 30.9856 55.0059Z'
        fill='#6B7280'
      />
      <path
        d='M30.9856 57.5469H20.503C20.3696 57.5469 20.2416 57.4939 20.1473 57.3996C20.053 57.3053 20 57.1774 20 57.044C20 56.9106 20.053 56.7827 20.1473 56.6883C20.2416 56.594 20.3696 56.541 20.503 56.541H30.9856C31.119 56.541 31.2469 56.594 31.3413 56.6883C31.4356 56.7827 31.4886 56.9106 31.4886 57.044C31.4886 57.1774 31.4356 57.3053 31.3413 57.3996C31.2469 57.4939 31.119 57.5469 30.9856 57.5469Z'
        fill='#6B7280'
      />
      <path
        d='M25.9032 60.0884H20.503C20.3696 60.0884 20.2416 60.0354 20.1473 59.9411C20.053 59.8468 20 59.7189 20 59.5855C20 59.4521 20.053 59.3242 20.1473 59.2298C20.2416 59.1355 20.3696 59.0825 20.503 59.0825H25.9032C26.0366 59.0825 26.1645 59.1355 26.2589 59.2298C26.3532 59.3242 26.4062 59.4521 26.4062 59.5855C26.4062 59.7189 26.3532 59.8468 26.2589 59.9411C26.1645 60.0354 26.0366 60.0884 25.9032 60.0884Z'
        fill='#6B7280'
      />
      <path
        d='M25.9032 62.6299H20.503C20.3696 62.6299 20.2416 62.577 20.1473 62.4826C20.053 62.3883 20 62.2604 20 62.127C20 61.9936 20.053 61.8657 20.1473 61.7713C20.2416 61.677 20.3696 61.624 20.503 61.624H25.9032C26.0366 61.624 26.1645 61.677 26.2589 61.7713C26.3532 61.8657 26.4062 61.9936 26.4062 62.127C26.4062 62.2604 26.3532 62.3883 26.2589 62.4826C26.1645 62.577 26.0366 62.6299 25.9032 62.6299Z'
        fill='#6B7280'
      />
      <rect x='10' y='79' width='230' height='30' rx='5' fill='#E5E7EB' />
      <path
        d='M30.3506 98.9003C30.2172 98.9003 30.0893 98.8474 29.995 98.753C29.9006 98.6587 29.8477 98.5308 29.8477 98.3974V93.9502C29.8477 93.8168 29.9006 93.6889 29.995 93.5946C30.0893 93.5003 30.2172 93.4473 30.3506 93.4473C30.484 93.4473 30.6119 93.5003 30.7063 93.5946C30.8006 93.6889 30.8536 93.8168 30.8536 93.9502V98.3974C30.8536 98.5308 30.8006 98.6587 30.7063 98.753C30.6119 98.8474 30.484 98.9003 30.3506 98.9003Z'
        fill='#6B7280'
      />
      <path
        d='M32.5732 96.6768H28.126C27.9926 96.6768 27.8647 96.6238 27.7704 96.5295C27.676 96.4352 27.623 96.3072 27.623 96.1739C27.623 96.0405 27.676 95.9125 27.7704 95.8182C27.8647 95.7239 27.9926 95.6709 28.126 95.6709H32.5732C32.7066 95.6709 32.8345 95.7239 32.9288 95.8182C33.0231 95.9125 33.0761 96.0405 33.0761 96.1739C33.0761 96.3072 33.0231 96.4352 32.9288 96.5295C32.8345 96.6238 32.7066 96.6768 32.5732 96.6768Z'
        fill='#6B7280'
      />
      <path
        d='M30.9856 90.0059H20.503C20.3696 90.0059 20.2416 89.9529 20.1473 89.8586C20.053 89.7643 20 89.6364 20 89.503C20 89.3696 20.053 89.2416 20.1473 89.1473C20.2416 89.053 20.3696 89 20.503 89H30.9856C31.119 89 31.2469 89.053 31.3413 89.1473C31.4356 89.2416 31.4886 89.3696 31.4886 89.503C31.4886 89.6364 31.4356 89.7643 31.3413 89.8586C31.2469 89.9529 31.119 90.0059 30.9856 90.0059Z'
        fill='#6B7280'
      />
      <path
        d='M30.9856 90.0059H20.503C20.3696 90.0059 20.2416 89.9529 20.1473 89.8586C20.053 89.7643 20 89.6364 20 89.503C20 89.3696 20.053 89.2416 20.1473 89.1473C20.2416 89.053 20.3696 89 20.503 89H30.9856C31.119 89 31.2469 89.053 31.3413 89.1473C31.4356 89.2416 31.4886 89.3696 31.4886 89.503C31.4886 89.6364 31.4356 89.7643 31.3413 89.8586C31.2469 89.9529 31.119 90.0059 30.9856 90.0059Z'
        fill='#6B7280'
      />
      <path
        d='M30.9856 92.5469H20.503C20.3696 92.5469 20.2416 92.4939 20.1473 92.3996C20.053 92.3053 20 92.1774 20 92.044C20 91.9106 20.053 91.7827 20.1473 91.6883C20.2416 91.594 20.3696 91.541 20.503 91.541H30.9856C31.119 91.541 31.2469 91.594 31.3413 91.6883C31.4356 91.7827 31.4886 91.9106 31.4886 92.044C31.4886 92.1774 31.4356 92.3053 31.3413 92.3996C31.2469 92.4939 31.119 92.5469 30.9856 92.5469Z'
        fill='#6B7280'
      />
      <path
        d='M25.9032 95.0884H20.503C20.3696 95.0884 20.2416 95.0354 20.1473 94.9411C20.053 94.8468 20 94.7189 20 94.5855C20 94.4521 20.053 94.3242 20.1473 94.2298C20.2416 94.1355 20.3696 94.0825 20.503 94.0825H25.9032C26.0366 94.0825 26.1645 94.1355 26.2589 94.2298C26.3532 94.3242 26.4062 94.4521 26.4062 94.5855C26.4062 94.7189 26.3532 94.8468 26.2589 94.9411C26.1645 95.0354 26.0366 95.0884 25.9032 95.0884Z'
        fill='#6B7280'
      />
      <path
        d='M25.9032 97.6299H20.503C20.3696 97.6299 20.2416 97.577 20.1473 97.4826C20.053 97.3883 20 97.2604 20 97.127C20 96.9936 20.053 96.8657 20.1473 96.7713C20.2416 96.677 20.3696 96.624 20.503 96.624H25.9032C26.0366 96.624 26.1645 96.677 26.2589 96.7713C26.3532 96.8657 26.4062 96.9936 26.4062 97.127C26.4062 97.2604 26.3532 97.3883 26.2589 97.4826C26.1645 97.577 26.0366 97.6299 25.9032 97.6299Z'
        fill='#6B7280'
      />
      <defs>
        <filter
          id='filter0_d_4322_2416'
          x='0'
          y='0'
          width='252'
          height='152'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1' dy='1' />
          <feGaussianBlur stdDeviation='0.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0666667 0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0.06 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_4322_2416'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_4322_2416'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
