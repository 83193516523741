export const TITLE_ONLY_100_CENTERED = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 252 152'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='10' y='44' width='230' height='30' rx='5' fill='#E5E7EB' />
      <path
        d='M129.351 63.9003C129.217 63.9003 129.089 63.8474 128.995 63.753C128.901 63.6587 128.848 63.5308 128.848 63.3974V58.9502C128.848 58.8168 128.901 58.6889 128.995 58.5946C129.089 58.5003 129.217 58.4473 129.351 58.4473C129.484 58.4473 129.612 58.5003 129.706 58.5946C129.801 58.6889 129.854 58.8168 129.854 58.9502V63.3974C129.854 63.5308 129.801 63.6587 129.706 63.753C129.612 63.8474 129.484 63.9003 129.351 63.9003Z'
        fill='#6B7280'
      />
      <path
        d='M131.573 61.6768H127.126C126.993 61.6768 126.865 61.6238 126.77 61.5295C126.676 61.4352 126.623 61.3072 126.623 61.1739C126.623 61.0405 126.676 60.9125 126.77 60.8182C126.865 60.7239 126.993 60.6709 127.126 60.6709H131.573C131.707 60.6709 131.834 60.7239 131.929 60.8182C132.023 60.9125 132.076 61.0405 132.076 61.1739C132.076 61.3072 132.023 61.4352 131.929 61.5295C131.834 61.6238 131.707 61.6768 131.573 61.6768Z'
        fill='#6B7280'
      />
      <path
        d='M129.986 55.0059H119.503C119.37 55.0059 119.242 54.9529 119.147 54.8586C119.053 54.7643 119 54.6364 119 54.503C119 54.3696 119.053 54.2416 119.147 54.1473C119.242 54.053 119.37 54 119.503 54H129.986C130.119 54 130.247 54.053 130.341 54.1473C130.436 54.2416 130.489 54.3696 130.489 54.503C130.489 54.6364 130.436 54.7643 130.341 54.8586C130.247 54.9529 130.119 55.0059 129.986 55.0059Z'
        fill='#6B7280'
      />
      <path
        d='M129.986 55.0059H119.503C119.37 55.0059 119.242 54.9529 119.147 54.8586C119.053 54.7643 119 54.6364 119 54.503C119 54.3696 119.053 54.2416 119.147 54.1473C119.242 54.053 119.37 54 119.503 54H129.986C130.119 54 130.247 54.053 130.341 54.1473C130.436 54.2416 130.489 54.3696 130.489 54.503C130.489 54.6364 130.436 54.7643 130.341 54.8586C130.247 54.9529 130.119 55.0059 129.986 55.0059Z'
        fill='#6B7280'
      />
      <path
        d='M129.986 57.5469H119.503C119.37 57.5469 119.242 57.4939 119.147 57.3996C119.053 57.3053 119 57.1774 119 57.044C119 56.9106 119.053 56.7827 119.147 56.6883C119.242 56.594 119.37 56.541 119.503 56.541H129.986C130.119 56.541 130.247 56.594 130.341 56.6883C130.436 56.7827 130.489 56.9106 130.489 57.044C130.489 57.1774 130.436 57.3053 130.341 57.3996C130.247 57.4939 130.119 57.5469 129.986 57.5469Z'
        fill='#6B7280'
      />
      <path
        d='M124.903 60.0884H119.503C119.37 60.0884 119.242 60.0354 119.147 59.9411C119.053 59.8468 119 59.7189 119 59.5855C119 59.4521 119.053 59.3242 119.147 59.2298C119.242 59.1355 119.37 59.0825 119.503 59.0825H124.903C125.037 59.0825 125.165 59.1355 125.259 59.2298C125.353 59.3242 125.406 59.4521 125.406 59.5855C125.406 59.7189 125.353 59.8468 125.259 59.9411C125.165 60.0354 125.037 60.0884 124.903 60.0884Z'
        fill='#6B7280'
      />
      <path
        d='M124.903 62.6299H119.503C119.37 62.6299 119.242 62.577 119.147 62.4826C119.053 62.3883 119 62.2604 119 62.127C119 61.9936 119.053 61.8657 119.147 61.7713C119.242 61.677 119.37 61.624 119.503 61.624H124.903C125.037 61.624 125.165 61.677 125.259 61.7713C125.353 61.8657 125.406 61.9936 125.406 62.127C125.406 62.2604 125.353 62.3883 125.259 62.4826C125.165 62.577 125.037 62.6299 124.903 62.6299Z'
        fill='#6B7280'
      />
      <rect x='10' y='79' width='230' height='30' rx='5' fill='#E5E7EB' />
      <path
        d='M129.351 98.9003C129.217 98.9003 129.089 98.8474 128.995 98.753C128.901 98.6587 128.848 98.5308 128.848 98.3974V93.9502C128.848 93.8168 128.901 93.6889 128.995 93.5946C129.089 93.5003 129.217 93.4473 129.351 93.4473C129.484 93.4473 129.612 93.5003 129.706 93.5946C129.801 93.6889 129.854 93.8168 129.854 93.9502V98.3974C129.854 98.5308 129.801 98.6587 129.706 98.753C129.612 98.8474 129.484 98.9003 129.351 98.9003Z'
        fill='#6B7280'
      />
      <path
        d='M131.573 96.6768H127.126C126.993 96.6768 126.865 96.6238 126.77 96.5295C126.676 96.4352 126.623 96.3072 126.623 96.1739C126.623 96.0405 126.676 95.9125 126.77 95.8182C126.865 95.7239 126.993 95.6709 127.126 95.6709H131.573C131.707 95.6709 131.834 95.7239 131.929 95.8182C132.023 95.9125 132.076 96.0405 132.076 96.1739C132.076 96.3072 132.023 96.4352 131.929 96.5295C131.834 96.6238 131.707 96.6768 131.573 96.6768Z'
        fill='#6B7280'
      />
      <path
        d='M129.986 90.0059H119.503C119.37 90.0059 119.242 89.9529 119.147 89.8586C119.053 89.7643 119 89.6364 119 89.503C119 89.3696 119.053 89.2416 119.147 89.1473C119.242 89.053 119.37 89 119.503 89H129.986C130.119 89 130.247 89.053 130.341 89.1473C130.436 89.2416 130.489 89.3696 130.489 89.503C130.489 89.6364 130.436 89.7643 130.341 89.8586C130.247 89.9529 130.119 90.0059 129.986 90.0059Z'
        fill='#6B7280'
      />
      <path
        d='M129.986 90.0059H119.503C119.37 90.0059 119.242 89.9529 119.147 89.8586C119.053 89.7643 119 89.6364 119 89.503C119 89.3696 119.053 89.2416 119.147 89.1473C119.242 89.053 119.37 89 119.503 89H129.986C130.119 89 130.247 89.053 130.341 89.1473C130.436 89.2416 130.489 89.3696 130.489 89.503C130.489 89.6364 130.436 89.7643 130.341 89.8586C130.247 89.9529 130.119 90.0059 129.986 90.0059Z'
        fill='#6B7280'
      />
      <path
        d='M129.986 92.5469H119.503C119.37 92.5469 119.242 92.4939 119.147 92.3996C119.053 92.3053 119 92.1774 119 92.044C119 91.9106 119.053 91.7827 119.147 91.6883C119.242 91.594 119.37 91.541 119.503 91.541H129.986C130.119 91.541 130.247 91.594 130.341 91.6883C130.436 91.7827 130.489 91.9106 130.489 92.044C130.489 92.1774 130.436 92.3053 130.341 92.3996C130.247 92.4939 130.119 92.5469 129.986 92.5469Z'
        fill='#6B7280'
      />
      <path
        d='M124.903 95.0884H119.503C119.37 95.0884 119.242 95.0354 119.147 94.9411C119.053 94.8468 119 94.7189 119 94.5855C119 94.4521 119.053 94.3242 119.147 94.2298C119.242 94.1355 119.37 94.0825 119.503 94.0825H124.903C125.037 94.0825 125.165 94.1355 125.259 94.2298C125.353 94.3242 125.406 94.4521 125.406 94.5855C125.406 94.7189 125.353 94.8468 125.259 94.9411C125.165 95.0354 125.037 95.0884 124.903 95.0884Z'
        fill='#6B7280'
      />
      <path
        d='M124.903 97.6299H119.503C119.37 97.6299 119.242 97.577 119.147 97.4826C119.053 97.3883 119 97.2604 119 97.127C119 96.9936 119.053 96.8657 119.147 96.7713C119.242 96.677 119.37 96.624 119.503 96.624H124.903C125.037 96.624 125.165 96.677 125.259 96.7713C125.353 96.8657 125.406 96.9936 125.406 97.127C125.406 97.2604 125.353 97.3883 125.259 97.4826C125.165 97.577 125.037 97.6299 124.903 97.6299Z'
        fill='#6B7280'
      />
      <defs>
        <filter
          id='filter0_d_4322_2388'
          x='0'
          y='0'
          width='252'
          height='152'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1' dy='1' />
          <feGaussianBlur stdDeviation='0.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0666667 0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0.06 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_4322_2388'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_4322_2388'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
