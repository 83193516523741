import { useCreateMessage } from '@apiServices';
import { ButtonRow, FileUpload, InputGroup } from '@components';
import { useAccount, useToast } from '@contexts';
import { UploadedDoc } from '@customTypes';
import { ReactElement, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

interface DocumentUploaderProps {
  onSetIsShowing: Function;
}

export const DocumentUploader = ({
  onSetIsShowing,
}: DocumentUploaderProps): ReactElement => {
  const { account } = useAccount();
  const { mutate: createMessage, isPending: createMessagePending } =
    useCreateMessage();
  const { showSuccessToast } = useToast();

  const [messageText, setMessageText] = useState<string>('');
  const [docsUploaded, setDocsUploaded] = useState<UploadedDoc[]>([]);

  const handleSubmit = () => {
    const documentIds = docsUploaded.map(({ documentId }) => ({
      id: documentId,
    }));

    createMessage(
      {
        account: {
          id: account.id,
          kycTiers: account.kyc?.tiers,
        },
        message: messageText || 'Additional documentation submitted for review',
        documents: documentIds,
      },
      {
        onSuccess: () => {
          setMessageText('');
          setDocsUploaded([]);
          onSetIsShowing(false);
          showSuccessToast({
            title: 'Documents received!',
            description:
              'If a response is required, you should expect one within 3-5 business days.',
          });
        },
      },
    );
  };

  const disablePost = docsUploaded.length === 0 || createMessagePending;

  return (
    <div className='mt-4'>
      <div>
        <p className={'mb-4'}>Additional documents</p>

        <FileUpload
          uploadUrl='accounts/documents'
          onFileChange={setDocsUploaded}
          docsUploaded={docsUploaded}
        />

        <p className='text-xs text-neutral-light pt-2 pb-4'>
          * By providing information, you represent and warrant that it is true,
          complete, and correct.
        </p>

        <div>
          <p className='py-4'>Notes or comments (optional)</p>
          <InputGroup
            type='textarea'
            value={messageText}
            name='messageText'
            onChange={(e) => setMessageText(e.target.value)}
          />
        </div>
      </div>

      <ButtonRow place={'end'}>
        <button
          className='btn btn-outline-light'
          onClick={() => onSetIsShowing(false)}
          disabled={createMessagePending}
        >
          Cancel
        </button>

        <button
          className='btn btn-primary'
          onClick={handleSubmit}
          disabled={disablePost}
        >
          {createMessagePending ? (
            <div className='flex flex-row items-center'>
              <div className='animate-spin'>
                <AiOutlineLoading3Quarters size={24} />
              </div>
              <span className='pl-2 self-center'>Submitting Docs...</span>
            </div>
          ) : (
            'Submit'
          )}
        </button>
      </ButtonRow>
    </div>
  );
};
