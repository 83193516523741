import { Text } from '@components';
import classNames from 'classnames';

export type TableHeaderProps = {
  className?: string;
  children: React.ReactNode;
} & React.ComponentProps<typeof Text>;

export const TableHeader: React.FC<TableHeaderProps> = ({
  className,
  children,
  ...restProps
}) => {
  return (
    <Text
      className={classNames('text-sm font-bold high-contrast', className)}
      {...restProps}
    >
      {children}
    </Text>
  );
};
