export const getMinimumInvestment = (saleContext: any): number => {
  return Number(saleContext.sale.purchaseMinimum) / Math.pow(10, 8);
};

export const getMaximumInvestment = (saleContext: any): number => {
  return Number(saleContext.sale.userMaximum) / Math.pow(10, 8);
};

export const getPricePerToken = (
  saleContext: any,
  toFixed: number = 2,
): string => {
  const price = Number(saleContext.sale.price) / Math.pow(10, 8);
  const priceNoDecimalPadding = price.toFixed(toFixed).replace(/\.?0+$/, '');
  return priceNoDecimalPadding;
};

export const getVestingTerms = (sale: any): string | null => {
  // Assuming vesting is part of the terms in the uris or documentation
  return sale.uris?.length ? sale.uris[0] : null;
};

export const getTotalRaise = (saleContext: any): number => {
  const saleMax = BigInt(saleContext.sale.saleMaximum);
  return Number(saleMax / BigInt(10 ** 8));
};

export const getCurrentInvestment = (saleContext: any): number => {
  return Number(
    (Number(saleContext.sale.purchaseTotal) / Math.pow(10, 8)).toFixed(2),
  );
};
