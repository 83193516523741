import { useGetSaleByContractAddress } from '@apiServices';
import { Button } from '@components';
import { useSale } from '@contexts';
import { Card } from '@newComponents';
import { useState } from 'react';
import { EventModal } from '../eligibility/EventModal';
import { PurchaseFlow } from './purchase-flow/purchase-flow';

interface PurchaseCardProps {
  eventId: ID;
  saleAddress: EvmAddress;
}

export const PurchaseCard = ({ eventId, saleAddress }: PurchaseCardProps) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const {
    data: sale,
    isLoading,
    isError,
    error,
  } = useGetSaleByContractAddress(saleAddress);

  // const {
  //   data: chain,
  //   isPending: isChainPending,
  //   isError: isChainError,
  //   error: chainError,
  // } = useGetChain(sale?.chainId ?? null);

  const saleContext = useSale();

  // const minPurchase = getMinimumInvestment(saleContext);
  // const maxPurchase = getMaximumInvestment(saleContext);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleFinishedPurchasing = () => {
    closeModal();
  };

  const isUserOnMerkleTree =
    !!saleContext.sale.proof && saleContext.sale.authorization !== null;

  // @ts-ignore - conflicts with latest tokensoft-shared-types version, but is correct for now
  const isV4Sale = sale?.subgraphDetails?.implementation.version === '4.0';
  // Sale v4 is realtime access, so merkle tree doesn't matter
  const isUserAuthorizedToPurchase = isV4Sale || isUserOnMerkleTree;

  return (
    <Card>
      <div className='font-bold text-black mb-2 text-xl'>Buy Tokens</div>
      <div className='flex flex-col gap-4'>
        {/* Purchase button */}
        <Button
          className='bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 rounded-full w-3/4 mx-auto'
          onClick={openModal}
          disabled={!isUserAuthorizedToPurchase}
        >
          Purchase
        </Button>

        {/* Purchase limits section */}
        {/* <div className='bg-blue-50 p-4 rounded-md'>
          <div className='flex items-center gap-2 mb-2'>
            <IoInformationCircleOutline className='text-blue-500' />
            <p className='font-medium text-gray-700'>
              Purchase Limits for this event:
            </p>
          </div>
          <div className='text-gray-600'>
            <EventPropertyRow
              className='justify-start'
              separator='&nbsp;-&nbsp;'
              label='Min Amount'
              value={`${minPurchase} USD`}
            />
            <EventPropertyRow
              className='justify-start'
              separator='&nbsp;-&nbsp;'
              label='Max Amount'
              value={`${maxPurchase} USD`}
            />
          </div>
        </div> */}

        {/* Friendly message for users not on the Merkle Tree */}
        {!isUserOnMerkleTree && !isV4Sale && (
          <div className='text-center bg-yellow-100 border border-yellow-300 text-yellow-700 p-4 rounded-md'>
            <p className='font-bold mb-1'>Pending Review</p>
            <p>
              You are eligible to participate in this event! Your request is
              being reviewed, after which you'll be able to make a purchase.
              Please check back soon!
            </p>
          </div>
        )}
      </div>

      {/* Modal for the purchase flow */}
      <EventModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel='Purchase Modal'
      >
        <PurchaseFlow
          eventId={eventId}
          onCancel={closeModal}
          onFinished={handleFinishedPurchasing}
        />
      </EventModal>
    </Card>
  );
};
