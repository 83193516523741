import {
  useGetUserEventsByAuthIdAndProjectId,
  viewEventUserDocumentVersion,
} from '@apiServices';
import {
  ButtonRow,
  Card,
  Col,
  EventIcon,
  LoadingIndicator,
  Row,
  SectionTitle,
  Stretched,
  TableHeader,
  Text,
  TextLink,
} from '@components';
import { useAuth } from '@contexts';
import { useEffect, useState } from 'react';
import { IoIosArrowDropdownCircle } from 'react-icons/io';
import { PiDownloadSimpleBold } from 'react-icons/pi';
import { useParams } from 'react-router-dom';

export const UserEvents = ({ data }) => {
  const { authId, id } = useParams();
  const {
    user: { token },
  } = useAuth();
  const [allData, setAllData] = useState<Maybe<any>>(null);
  const [dataToShow, setDataToShow] = useState<Maybe<any>>(null);
  const [displayAllEvents, setDisplayAllEvents] = useState<boolean>(false);

  const { mutate: getUserEventsByAuthIdAndProjectId } =
    useGetUserEventsByAuthIdAndProjectId();

  useEffect(() => {
    if (data) {
      if (data.consent === false) {
        setAllData([]);
        setDataToShow([]);
      } else {
        getUserEventsByAuthIdAndProjectId(
          {
            authId,
            projectId: id,
          },
          {
            onSuccess: (data) => {
              setAllData(data);
              setDataToShow(data.slice(0, 3));
            },
            onError: (err) => console.error(err),
          },
        );
      }
    }
  }, [data]);

  useEffect(() => {
    if (displayAllEvents) {
      setDataToShow(allData);
    }
  }, [displayAllEvents]);

  const structureDocument = (documents) => {
    let signedDocuments: any[] = [];
    let acceptedDocuments: any[] = [];
    documents.forEach((document) => {
      if (document.signedAt) {
        if (document.uri) {
          signedDocuments.push(document);
        }
      } else {
        if (document.acceptedAt) {
          acceptedDocuments.push(document);
        }
      }
    });
    return { signedDocuments, acceptedDocuments };
  };

  return (
    <Col className={data?.consent === false ? 'blur' : ''}>
      <SectionTitle className='mb-5'>Past Events</SectionTitle>
      {data && allData ? (
        dataToShow?.length !== 0 ? (
          <>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
              {dataToShow?.map((event, index) => (
                <Card key={index}>
                  <Col gap={5}>
                    <Stretched className={'items-center'} gap={5}>
                      <EventIcon
                        icon={event.type}
                        size={'lg'}
                        className={'w-10'}
                      />
                      <Text className='text-lg font-bold capitalize high-contrast'>
                        {event.name}
                      </Text>
                    </Stretched>
                    <Col gap={5}>
                      <Col gap={2.5}>
                        <TableHeader>Signed Documents</TableHeader>
                        <Col gap={1}>
                          {structureDocument(event.eventUsers[0].documents)
                            .signedDocuments.length !== 0 ? (
                            structureDocument(
                              event.eventUsers[0].documents,
                            ).signedDocuments.map((doc, index) => (
                              <Row gap={2} key={index} yalign={'center'}>
                                <div className='capitalize'>
                                  {
                                    doc.eventDocumentVersion.documentVersion
                                      .document.name
                                  }
                                </div>
                                <a
                                  className='font-bold'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    viewEventUserDocumentVersion(doc.id, token);
                                  }}
                                  href=''
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  <PiDownloadSimpleBold size={22} />
                                </a>
                              </Row>
                            ))
                          ) : (
                            <div>--</div>
                          )}
                        </Col>
                      </Col>

                      <Col gap={2.5}>
                        <TableHeader>Acknowledged Documents</TableHeader>
                        <Col gap={1}>
                          {structureDocument(event.eventUsers[0].documents)
                            .acceptedDocuments.length !== 0 ? (
                            structureDocument(
                              event.eventUsers[0].documents,
                            ).acceptedDocuments.map((doc, index) => (
                              <Row gap={2} key={index}>
                                <div>
                                  {
                                    doc.eventDocumentVersion.documentVersion
                                      .document.name
                                  }
                                </div>
                                {doc.uri ? (
                                  <a
                                    className='mr-2 font-bold'
                                    onClick={() => {
                                      viewEventUserDocumentVersion(
                                        doc.id,
                                        token,
                                      );
                                    }}
                                    target='_blank'
                                    rel='noreferrer'
                                  >
                                    <PiDownloadSimpleBold size={22} />
                                  </a>
                                ) : null}
                              </Row>
                            ))
                          ) : (
                            <div>--</div>
                          )}
                        </Col>
                      </Col>
                    </Col>
                  </Col>
                </Card>
              ))}
            </div>
            {!displayAllEvents && allData.length > 3 ? (
              <ButtonRow place={'start'}>
                <TextLink
                  width='fit'
                  onClick={() => setDisplayAllEvents(!displayAllEvents)}
                >
                  <IoIosArrowDropdownCircle size={25} />
                  More Events
                </TextLink>
              </ButtonRow>
            ) : null}
          </>
        ) : (
          <Row className={''}>No events to display.</Row>
        )
      ) : (
        <Row className={'h-52'} yalign={'center'}>
          <LoadingIndicator />
        </Row>
      )}
    </Col>
  );
};
