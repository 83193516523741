import { HelpPopover, InfoIcon, Row, Text } from '@components';
import classNames from 'classnames';
import { useState } from 'react';
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from 'react-icons/ai';
import './editable-document-field.css';

interface EditableDocumentFieldProps {
  className?: string;
  uneditedText: string;
  value?: string;
  textClassName?: string;
  popoverText?: string;
  maxLength?: number;
  onSave?: (value: string) => void;
  required?: boolean;
}

export const EditableDocumentField = ({
  className,
  uneditedText,
  value = '',
  popoverText,
  textClassName,
  maxLength,
  onSave,
  required = false,
}: EditableDocumentFieldProps) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState(value || '');

  const handleOnSave = () => {
    let _value = inputValue;
    onSave && onSave(_value);
    setEditing(false);
  };

  const text = value || uneditedText;

  return (
    <Row className={className} yalign={'center'}>
      {!editing && (
        <Text breakAnywhere className={classNames('', textClassName)}>
          {text}
        </Text>
      )}
      {editing && (
        <input
          maxLength={maxLength || 50}
          autoFocus
          className='editable-document-input'
          type='text'
          id={uneditedText}
          placeholder={uneditedText}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      )}
      {required ? <Text className='-translate-y-1'>&nbsp;*</Text> : <></>}
      {onSave && !editing && (
        <div
          className='ml-3 cursor-pointer'
          onClick={() => {
            setEditing(true);
          }}
        >
          <AiOutlineEdit fill='var(--primary)' size={22} />
        </div>
      )}
      {editing && (
        <>
          <div className='ml-3 cursor-pointer'>
            <AiOutlineCheck
              fill='var(--success)'
              size={22}
              onClick={handleOnSave}
            />
          </div>
          <div
            className='ml-2 cursor-pointer'
            onClick={() => {
              setEditing(false);
            }}
          >
            <AiOutlineClose fill='var(--danger)' size={22} />
          </div>
        </>
      )}
      {popoverText && (
        <HelpPopover
          divClassName='ml-2'
          className='max-w-md'
          button={
            <div className='block cursor-pointer'>
              <InfoIcon fill={'var(--neutral)'} />
            </div>
          }
        >
          <Text>{popoverText}</Text>
        </HelpPopover>
      )}
    </Row>
  );
};
