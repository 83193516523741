import { useGetEventSummary } from '@apiServices';
import {
  Alert,
  Button,
  ChartCaption,
  ChartLabel,
  Col,
  Divider,
  DonutChart,
  HelpPopover,
  InfoIcon,
  LinkIcon,
  LoadingIndicator,
  Row,
  Stretched,
  Text,
  Title,
} from '@components';
import { EVENT_STATUS } from '@enums';
import { useClipboard } from '@hooks';
import { eq, formatValue } from '@utils';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

export const EventSummary = ({ event }) => {
  const { copyToClipboard } = useClipboard();
  const [summaryData, setSummaryData] = useState<Maybe<any>>(null);
  const { eventSummary, loading } = useGetEventSummary(event?.id);

  const hideSummaryData =
    eq(summaryData?.eventUsers, 0) && event?.status !== EVENT_STATUS.COMPLETED;

  // replace with endpoint when materialized view is ready
  useEffect(() => {
    let eventUsers = 0;
    let registeredUsers = 0;
    let appliedUsers = 0;
    let eligibleUsers = 0;
    let registeredUsersPercentage = 0;
    let appliedUsersPercentage = 0;
    let eligibleUsersPercentage = 0;

    if (!eventSummary) {
      // sample data
      const mockTotalUsers = Math.ceil(Math.random() * (5000 - 1000) + 1000);
      registeredUsersPercentage = Math.ceil(Math.random() * (100 - 40) + 40);
      registeredUsers = Math.ceil(
        mockTotalUsers * (registeredUsersPercentage / 100),
      );
      appliedUsersPercentage = Math.ceil(
        Math.random() * (registeredUsersPercentage - 1) + 1,
      );
      appliedUsers = Math.ceil(mockTotalUsers * (appliedUsersPercentage / 100));
      eligibleUsersPercentage = Math.ceil(
        Math.random() * (appliedUsersPercentage - 1) + 1,
      );
      eligibleUsers = Math.ceil(
        mockTotalUsers * (eligibleUsersPercentage / 100),
      );
    } else {
      eventUsers = eventSummary.totalUserCount || 0;
      registeredUsers = eventSummary.registeredUserCount || 0;
      appliedUsers = eventSummary.appliedUserCount || 0;
      eligibleUsers = eventSummary.eligibleUserCount || 0;
      if (eventUsers > 0) {
        registeredUsersPercentage = Math.ceil(
          (registeredUsers / eventUsers) * 100,
        );
        appliedUsersPercentage = Math.ceil((appliedUsers / eventUsers) * 100);
        eligibleUsersPercentage = Math.ceil((eligibleUsers / eventUsers) * 100);
      }
    }

    setSummaryData({
      eventUsers: eventUsers,
      registeredUsersPercentage: registeredUsersPercentage,
      registeredUsers: registeredUsers,
      appliedUsersPercentage: appliedUsersPercentage,
      appliedUsers: appliedUsers,
      eligibleUsersPercentage: eligibleUsersPercentage,
      eligibleUsers: eligibleUsers,
    });
  }, [eventSummary]);

  const getReferralUrl = () => {
    const location = `https://${event.project?.domains[0]?.domainName}/event/${event.id}`;
    const url = new URL(location || window.location.href);
    url.searchParams.append(
      'referral_code',
      event.referralCodes[0].referralCode,
    );
    return url.toString();
  };

  const handleCopyReferralLink = () => {
    copyToClipboard(getReferralUrl(), 'Invite link copied to clipboard');
  };

  const buildDataset = (amount, color, disabled) => {
    return [
      {
        highlightEnabled: !disabled,
        type: 'doughnut',
        innerRadius: '85%',
        startAngle: -90,
        dataPoints: [
          { y: amount, color: color },
          { y: 100 - amount, color: '#D9D9D9' },
        ],
      },
    ];
  };

  if (!summaryData) {
    return (
      <LoadingIndicator text={'Loading Data'} className={'no-padding h-32'} />
    );
  }

  return (
    <>
      {hideSummaryData && !loading && event.hidden ? (
        <Alert type={'alert-warning'} className={'mb-5'}>
          <Text>Event not published yet.</Text>
        </Alert>
      ) : null}
      <Row place={'center'} className={'relative'}>
        {hideSummaryData && !loading && !event.hidden ? (
          <Button className={'btn-floating'} onClick={handleCopyReferralLink}>
            <LinkIcon />
            <Text>Invite Users</Text>
          </Button>
        ) : null}

        <Stretched
          xgap={5}
          ygap={5}
          className={classNames(hideSummaryData ? 'blur' : '')}
        >
          <Col place={'center-start'} gap={2.5}>
            <Row xgap={2.5} ygap={5} place={'center'}>
              <ChartLabel>Total</ChartLabel>
              <HelpPopover
                className='md'
                button={
                  <div className='cursor-pointer'>
                    <InfoIcon />
                  </div>
                }
              >
                <Text textAlign={'center'}>
                  Users invited to the event based on the Ethereum addresses you
                  provided
                </Text>
              </HelpPopover>
            </Row>

            <Title className={'mt-2.5 md:mt-10'}>
              {summaryData.eventUsers > 0
                ? formatValue(summaryData.eventUsers, { commas: true })
                : 0}{' '}
              Users
            </Title>
          </Col>

          <Divider smallScreenOnly />

          <Col place={'center-between'} gap={2.5}>
            <Row gap={2.5} place={'center'}>
              <ChartLabel>Registered</ChartLabel>
              <HelpPopover
                className='md'
                button={
                  <div className='cursor-pointer'>
                    <InfoIcon />
                  </div>
                }
              >
                <Text textAlign={'center'}>
                  Users who have created a Tokensoft account by signing into the
                  platform with the provided Ethereum address at least once
                </Text>
              </HelpPopover>
            </Row>

            <DonutChart
              options={{
                title: {
                  text: `${summaryData.registeredUsersPercentage}%`,
                },
                data: buildDataset(
                  summaryData.registeredUsersPercentage,
                  '#8BB5FD',
                  hideSummaryData,
                ),
              }}
            ></DonutChart>
            <ChartCaption>
              {summaryData.registeredUsers > 0
                ? formatValue(summaryData.registeredUsers, { commas: true })
                : 0}{' '}
              Users
            </ChartCaption>
          </Col>

          <Divider smallScreenOnly />

          <Col place={'center-between'} gap={2.5}>
            <Row gap={2.5} place={'center'}>
              <ChartLabel>Applied</ChartLabel>
              <HelpPopover
                className='md'
                button={
                  <div className='cursor-pointer'>
                    <InfoIcon />
                  </div>
                }
              >
                <Text textAlign={'center'}>
                  Users who have submitted a Tokensoft identity verification
                  application
                </Text>
              </HelpPopover>
            </Row>

            <DonutChart
              options={{
                title: {
                  text: `${summaryData.appliedUsersPercentage}%`,
                },
                data: buildDataset(
                  summaryData.appliedUsersPercentage,
                  '#FCD34D',
                  hideSummaryData,
                ),
              }}
            ></DonutChart>
            <ChartCaption>
              {summaryData.appliedUsers > 0
                ? formatValue(summaryData.appliedUsers, { commas: true })
                : 0}{' '}
              Users
            </ChartCaption>
          </Col>

          <Divider smallScreenOnly />

          <Col place={'center-between'} gap={2.5}>
            <Row gap={2.5} place={'center'}>
              <ChartLabel>Eligible</ChartLabel>
              <HelpPopover
                className='md'
                button={
                  <div className='cursor-pointer'>
                    <InfoIcon />
                  </div>
                }
              >
                <Text textAlign={'center'}>
                  Users who meet all of the eligibility requirements you
                  specified for this event
                </Text>
              </HelpPopover>
            </Row>

            <DonutChart
              options={{
                title: {
                  text: `${summaryData.eligibleUsersPercentage}%`,
                },
                data: buildDataset(
                  summaryData.eligibleUsersPercentage,
                  '#22C55E',
                  hideSummaryData,
                ),
              }}
            ></DonutChart>
            <ChartCaption>
              {summaryData.eligibleUsers > 0
                ? formatValue(summaryData.eligibleUsers, { commas: true })
                : 0}{' '}
              Users
            </ChartCaption>
          </Col>
        </Stretched>
      </Row>
    </>
  );
};
