import { useWebApiClient } from '@apiClients';
import { useQuery } from '@tanstack/react-query';
import { ProjectFeature } from 'tokensoft-shared-types';

export const useGetProjectFeatures = () => {
  const fetchWithClient = useWebApiClient();

  return useQuery<ProjectFeature[]>({
    queryKey: ['projectFeatures'],
    queryFn: async () => {
      return fetchWithClient(`project/features`);
    },
  });
};
