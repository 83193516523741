import * as Sentry from '@sentry/react';
import * as React from 'react';
import { useRouteError } from 'react-router-dom';

export function SentryErrorBoundary() {
  const error = useRouteError() as Error;

  React.useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <div>
      <h1>Something went wrong.</h1>
      <p>You are currently in the Sentry Error Boundary.</p>
      <details style={{ whiteSpace: 'pre-wrap' }}>{error.toString()}</details>
    </div>
  );
}
