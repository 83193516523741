import { Card, Divider, EventStatusBadge, IconLogo } from '@components';
import { useAccount, useNetworks, useProject } from '@contexts';
import { EVENT_TYPE } from '@enums';
import {
  getResourceUri,
  getValidIPFSHash,
  utcToLocalDate,
  utcToLocalTime,
} from '@utils';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

interface EventCardProps {
  event: any;
  eventUrl: string;
}

export const EventCard: React.FC<EventCardProps> = ({ event, eventUrl }) => {
  const { account } = useAccount();
  const { getNetworkDetails } = useNetworks();
  const navigate = useNavigate();
  const chainDetails = getNetworkDetails(event.chainId);
  const { project } = useProject();
  const logoUrl = project?.theme?.logoSmall ?? chainDetails?.logoUri;
  const networkName = chainDetails?.name ?? '';
  const eventLogo = event.project?.theme?.logoSmall || logoUrl;
  const logo = getValidIPFSHash(eventLogo)
    ? getResourceUri(eventLogo)
    : eventLogo;

  return (
    <Card
      onClick={() => navigate(eventUrl)}
      className={classNames('small-padding hover-card')}
    >
      <div className={'flex gap-3 flex-wrap xl:flex-nowrap'}>
        <div className={`flex flew-row space-x-2 items-center w-full xl:w-72`}>
          <IconLogo className='large mr-2' logo={logo} />
          <div className={'flex flex-col self-center flex-grow'}>
            <div
              className={classNames(
                'font-semibold high-contrast-link no-underline cursor-pointer',
              )}
            >
              {event.name.toUpperCase()}
            </div>
            <div>
              <span className='text-sm text-primary-medium'>{event.type}</span>
            </div>
          </div>
          <div
            className={classNames(
              'flex items-end flex-col w-28 self-start xl:hidden',
            )}
          >
            <EventStatusBadge
              startTime={event.startTime}
              status={event.status}
              className={'!w-fit'}
            />
          </div>
        </div>
        <Divider className='flex w-full xl:hidden' />

        <div className='flex flex-row grow justify-between gap-3'>
          <div className={classNames('flex flex-col cursor-pointer w-28')}>
            <div className='mb-2'>
              <span className='text-xs uppercase text-neutral-medium'>
                Network
              </span>
            </div>
            <div className={'text-sm high-contrast'}>
              {event.type === EVENT_TYPE.IDENTITY ? 'Cross-Chain' : networkName}
            </div>
          </div>

          <div className='grid grid-cols-2 grow gap-3'>
            <div className={classNames('flex flex-col col-span-1')}>
              <div className='mb-2'>
                <span className='text-xs uppercase text-neutral-medium'>
                  Start
                </span>
              </div>
              <div className={'flex flex-col items-start'}>
                <div className='flex flex-wrap text-sm high-contrast'>
                  <div>
                    {`${utcToLocalDate(event.registrationStartTime, account?.timezone)} 
                      ${utcToLocalTime(event.registrationStartTime, account?.timezone)}`}
                  </div>
                </div>
              </div>
            </div>

            <div className={classNames('flex flex-col grow col-span-1')}>
              <div className='mb-2'>
                <span className='text-xs uppercase text-neutral-medium'>
                  End
                </span>
              </div>
              <div className={'flex flex-col items-start'}>
                <div className='flex flex-wrap text-sm high-contrast'>
                  {event.registrationEndTime ? (
                    <>
                      <div>{`${utcToLocalDate(
                        event.registrationEndTime,
                        account?.timezone,
                      )} ${utcToLocalTime(
                        event.registrationEndTime,
                        account?.timezone,
                      )}`}</div>
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={classNames('hidden flex-col w-28 xl:flex')}>
            <div className='mb-2'>
              <span className='text-xs uppercase text-neutral-medium'>
                Status
              </span>
            </div>
            <EventStatusBadge
              startTime={event.startTime}
              status={event.status}
              className={'!w-fit'}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};
