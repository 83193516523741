import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

/**
 * Renders an <div> element.  Can be configured to only show up on small screens.
 */
export const VerticalDivider = ({
  className,
  width = '1px',
  smallScreenOnly = false,
  ...restProps
}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 864 });

  return (
    <div
      style={{ width: width }}
      className={classNames(
        'v-divider h-auto bg-gray-200',
        !isSmallScreen && smallScreenOnly ? 'hidden' : 'block',
        className,
      )}
      {...restProps}
    >
      {' '}
    </div>
  );
};
