import { useGetOraclePrice } from '@apiServices';
import { useNetworks, useSale, useTSWagmi } from '@contexts';
import {
  div,
  exp,
  fix,
  getFlatPriceSaleABI,
  getStakingABI,
  mult,
  toBaseUnits,
} from '@utils';
import { simulateContract, writeContract } from '@wagmi/core';
import { useState } from 'react';
import { useReadContract, useWaitForTransactionReceipt } from 'wagmi';

const NATIVE_TOKEN_DECIMALS = 18;

export const useV4BuyTransaction = (chainId: number) => {
  const { wagmiConfig } = useTSWagmi();
  const [transactionHash, setTransactionHash] = useState<Maybe<any>>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { supportedNetworks } = useNetworks();
  const { mutate: getOraclePrice } = useGetOraclePrice();
  const { sale } = useSale();
  const network = supportedNetworks?.find((n) => n.id === chainId);
  const stakingAddress = network?.stakingV4Address;
  const config = {
    address: stakingAddress,
    abi: getStakingABI(),
    functionName: 'getFeeLevel',
    chainId,
    args: [],
  } as any;
  const { data: getFeeLevelResult } = useReadContract(config);

  const write = async (
    chainId,
    paymentMethod,
    toAddress,
    value,
    signature,
    signatureExpiresAt,
    userPurchaseLimit,
  ) => {
    if (network === undefined) {
      return;
    }
    setSubmitting(true);

    const txValue = toBaseUnits(value, paymentMethod.decimals);
    console.log('preparing purchase', txValue.toString());

    try {
      const result = await new Promise<{ price: string }>((resolve, reject) => {
        getOraclePrice(
          {
            networkId: chainId,
            oracleAddress: network.nativePriceOracleAddress,
          },
          { onSuccess: resolve, onError: reject },
        );
      });

      const nativeOraclePrice = result.price;
      let feeLevel;

      if (stakingAddress) {
        // this is handling a bug where the staking contract returns '0'
        // by default for users who haven't staked at all
        if (`${getFeeLevelResult}` === '0') {
          feeLevel = '100';
        } else {
          feeLevel = `${getFeeLevelResult}`;
        }
      } else {
        feeLevel = '100';
      }

      const value = fix(
        mult(
          div('100000001', nativeOraclePrice),
          exp(10, NATIVE_TOKEN_DECIMALS),
        ),
        '0',
      );

      const salePlatformFeeForOracle = Number(
        mult(sale.platformFeeInCents || 100, Math.pow(10, 6)),
      );

      let request: Maybe<any> = null;
      const response = await simulateContract(wagmiConfig, {
        address: toAddress,
        abi: getFlatPriceSaleABI('v4.0'),
        functionName: 'buyWithNative',
        chainId,
        args: [
          userPurchaseLimit,
          signatureExpiresAt,
          signature,
          network.platformFeeRecipient,
          salePlatformFeeForOracle,
        ],
        value: txValue,
      } as any);

      request = response.request;

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      console.log(
        'native purchase transaction response',
        writeContractResponse,
      );
      return writeContractResponse;
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};
