import { useCallback, useLayoutEffect, useReducer, useRef } from 'react';

const defaultInitialState = { status: 'idle', data: null, error: null };

const useSafeDispatch = (dispatch) => {
  const mounted = useRef(false);

  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return useCallback(
    (...args) => (mounted.current ? dispatch(...args) : undefined),
    [dispatch],
  );
};

export const useAsync = (initialState = {}) => {
  const initialStateRef = useRef({
    ...defaultInitialState,
    ...initialState,
  });

  const [{ status, data, error }, setState] = useReducer(
    (s, a) => ({ ...s, ...a }),
    initialStateRef.current,
  );

  const safeSetState = useSafeDispatch(setState);

  const setData = useCallback(
    (data) => safeSetState({ data, status: 'resolved' }),
    [safeSetState],
  );
  const setError = useCallback(
    (error) => safeSetState({ error, status: 'rejected' }),
    [safeSetState],
  );
  const reset = useCallback(
    () => safeSetState(initialStateRef.current),
    [safeSetState],
  );

  const run = useCallback(
    (promise) => {
      if (!promise || !promise.then) {
        throw new Error(
          'The argument passed to useAsync().run must be a promise. Maybe a function that is passed is not returning anything?',
        );
      }

      safeSetState({ status: 'pending' });

      return promise.then(
        (data) => {
          setData(data);
          return data;
        },
        (error) => {
          setError(error);
          return Promise.reject(error);
        },
      );
    },
    [safeSetState, setData, setError],
  );

  return {
    // using the same names that @tanstack/react-query uses for convenience
    isIdle: status === 'idle',
    isPending: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  };
};
