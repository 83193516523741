import { Events, PageHeader } from '@components';
import { useAnalytics } from '@contexts';

export const Dashboard = () => {
  useAnalytics('/dashboard');

  return (
    <>
      <PageHeader title={'Events'} />
      <Events />
    </>
  );
};
