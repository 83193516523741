import { KYC_TIER_STATE } from '@enums';
import { createContext, useContext } from 'react';

interface ProfileContextProps {
  account: any;
  loading: boolean;
  view: string;
  setView: Function;
  hasVerifiedEmail: boolean;
  verificationEmailSent: boolean;
  tier1Status: string;
  isTier1Started: boolean;
  isTier1Finished: boolean;
  isTier1Completed: boolean;
  tier1CurrentState: Maybe<KYC_TIER_STATE>;
  tier3Status: string;
  isTier3Started: boolean;
  isTier3Finished: boolean;
  hasSetProfileDetails: boolean;
  isProfileSetupComplete: boolean;
  isTier3Eligible: boolean;
  isTier3Completed: boolean;
  tier3CurrentState: Maybe<KYC_TIER_STATE>;
}

export const ProfileContext = createContext<Maybe<ProfileContextProps>>(null);
ProfileContext.displayName = 'ProfileContext';

export const useProfile = (): ProfileContextProps => {
  const context = useContext(ProfileContext);

  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }

  return context;
};
