import { IconProps } from '../icon-props';

export const Telegram = ({ color, className, size = 20 }: IconProps) => {
  return (
    <div className={`${className && className}`}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        stroke={color !== undefined ? color : '#101828'}
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g clipPath='url(#clip0_461_11388)'>
          <path d='M5.8335 8.16667L12.2502 1.75' />
          <path d='M12.2502 1.75L8.4585 12.25C8.4329 12.3058 8.39181 12.3532 8.34011 12.3864C8.28841 12.4195 8.22826 12.4372 8.16683 12.4372C8.1054 12.4372 8.04525 12.4195 7.99355 12.3864C7.94185 12.3532 7.90076 12.3058 7.87516 12.25L5.8335 8.16667L1.75016 6.125C1.69431 6.09941 1.64699 6.05832 1.61381 6.00661C1.58063 5.95491 1.56299 5.89477 1.56299 5.83333C1.56299 5.7719 1.58063 5.71176 1.61381 5.66005C1.64699 5.60835 1.69431 5.56726 1.75016 5.54167L12.2502 1.75Z' />
        </g>
        <defs>
          <clipPath id='clip0_461_11388'>
            <rect width='14' height='14' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
