import {
  interpolateClaimbleForInterface,
  useGetOraclePrice,
} from '@apiServices';
import {
  Button,
  Card,
  Col,
  ProgressBar,
  Row,
  Section,
  SectionTitle,
  Stacked,
  Stretched,
  TableData,
  TableHeader,
  Text,
} from '@components';
import { ProgressValue } from '@customTypes';
import {
  convertBaseUnitsToDecimal,
  div,
  eq,
  formatValue,
  gt,
  isContinuousVestingType,
  mult,
  sub,
} from '@utils';
import { useEffect, useState } from 'react';
import { RiFundsLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

export const DistributorProgress = ({ context, className }) => {
  const navigate = useNavigate();
  const { mutate: getOraclePrice } = useGetOraclePrice();
  const [oraclePrice, setOraclePrice] = useState();

  const key = 'event-progress';

  // get oraclePrice and tierVesting from context if interface is IPriceTierVesting
  useEffect(() => {
    if (context.priceTierVesting) {
      getOraclePrice(
        {
          networkId: context.networkId,
          oracleAddress: context.priceTierVesting.oracleAddress,
        },
        {
          onSuccess: (result) => {
            setOraclePrice(result.price);
          },
          onError: (error) => {
            console.error('error:', error);
          },
        },
      );
    }
  }, []);

  const interpolatedTotalAvailableToClaim = interpolateClaimbleForInterface(
    context.interfaces,
    context.totalAmount,
    context.startTime,
    context.cliffTime,
    context.endTime,
    context.trancheVesting?.tranches,
    context.fractionDenominator,
    oraclePrice,
    context.tierVesting,
  );

  const claimableFixedNumber = isContinuousVestingType(context.interfaces)
    ? 4
    : 2;

  const calculatePercentageFormated = (
    value,
    total,
    decimals = 2,
    commas = false,
  ) => {
    let percent = div(mult(value, 100), total);

    //check if percentage is integer that way we dont show 0,00%
    if (gt(sub(percent, parseInt(percent)), 0)) {
      return formatValue(percent, { commas, decimalPlaces: decimals });
    }
    return formatValue(percent, { commas, decimalPlaces: 0 });
  };

  const progress: (ProgressValue & { label: string; amount: string })[] = [
    {
      value: parseFloat(
        calculatePercentageFormated(context.claimed, context.totalAmount),
      ),
      color: 'var(--primary)',
      label: 'Claimed',
      amount: formatValue(
        convertBaseUnitsToDecimal(
          context.claimed,
          context?.tokenInfo?.decimals || 18,
        ),
        { commas: true, decimalPlaces: claimableFixedNumber },
      ),
    },
    {
      value: parseFloat(
        calculatePercentageFormated(
          interpolatedTotalAvailableToClaim,
          context.totalAmount,
        ),
      ),
      color: 'var(--primary-light)',
      label: 'Claimable',
      amount: formatValue(
        convertBaseUnitsToDecimal(
          context.totalAmount,
          context?.tokenInfo?.decimals || 18,
        ),
        { commas: true, decimalPlaces: claimableFixedNumber },
      ),
    },
  ];

  const additionalAmountToFund =
    context.tokenBalance &&
    sub(sub(context.totalAmount, context.claimed), context.tokenBalance);
  const requiresAdditionalFunding = gt(additionalAmountToFund, 0);
  const hasExcessFunds =
    context.tokenBalance &&
    gt(context.tokenBalance, sub(context.totalAmount, context.claimed));
  const correctlyFunded =
    context.tokenBalance &&
    eq(context.tokenBalance, sub(context.totalAmount, context.claimed));

  const renderActionButton = () => {
    if (correctlyFunded) {
      return null;
    }

    if (requiresAdditionalFunding) {
      return (
        <Button
          size='xs'
          onClick={() => {
            navigate('fund');
          }}
        >
          <div>
            <RiFundsLine size={20} />
          </div>
          <Text>Add More Funds</Text>
        </Button>
      );
    }

    if (hasExcessFunds) {
      return (
        <Button
          size='xs'
          fill='outline'
          onClick={() => {
            navigate('fund');
          }}
        >
          <div className='-scale-y-100'>
            <RiFundsLine size={20} />
          </div>
          <Text>Withdraw Excess Funds</Text>
        </Button>
      );
    }
  };

  const renderDistributorDetails = () => {
    return (
      <>
        <ProgressBar progress={progress} />
        <Stretched className={'pt-3'}>
          <Col gap={2}>
            <Row yalign={'center'} gap={2}>
              <div
                className='w-3 h-3 rounded-full'
                style={{ backgroundColor: 'var(--neutral-light)' }}
              ></div>
              <TableHeader data-testid={`${key}-total-header`}>
                Total Amount
              </TableHeader>
            </Row>
            <TableData data-testid={`${key}-total-data`}>
              {formatValue(
                convertBaseUnitsToDecimal(
                  context.tokenBalance,
                  context?.tokenInfo?.decimals || 18,
                ),
                { commas: true },
              )}{' '}
              {context.tokenSymbol}
            </TableData>
          </Col>
          {progress.map((item, index) => {
            if (!item.value || isNaN(item.value)) {
              return;
            }

            return (
              <Col gap={2} key={index}>
                <Row yalign={'center'} gap={2}>
                  <div
                    className='w-3 h-3 rounded-full'
                    style={{ backgroundColor: item.color || 'var(--primary)' }}
                  ></div>
                  <TableHeader data-testid={`${key}-progress-${index}-header`}>
                    {item.label}
                  </TableHeader>
                </Row>
                <TableData data-testid={`${key}-progress-${index}-data`}>
                  <span className='font-bold high-contrast'>{item.value}%</span>{' '}
                  | {item.amount} {context.tokenSymbol}
                </TableData>
              </Col>
            );
          })}
          <Col yalign={'end'} className={'w-56'}>
            {renderActionButton()}
          </Col>
        </Stretched>
      </>
    );
  };

  const renderBody = () => {
    return renderDistributorDetails();
  };

  return (
    <>
      <Card data-testid={`${key}`} className={className}>
        <Stacked>
          <Section gap={2.5} place={'start'}>
            <SectionTitle data-testid={`${key}-title`}>
              Distribution Progress
            </SectionTitle>
            {renderBody()}
          </Section>
        </Stacked>
      </Card>
    </>
  );
};
