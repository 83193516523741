import classNames from 'classnames';
import './radio-input.css';

type RadioInputProps = {
  children?: React.ReactNode;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  onClick: () => void;
};

export const RadioInput: React.FC<RadioInputProps> = ({
  children,
  name,
  checked,
  disabled,
  className,
  onClick,
}) => {
  return (
    <label
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      className={classNames('pb-2 block form-check-label', className)}
    >
      <div className='mr-3 inline-block'>
        {/* onChange must be set to silence warnings; we're using onClick from the Label, as for="id" doesn't work. */}
        <input
          className={classNames(
            'form-check-input',
            disabled ? 'cursor-default' : 'cursor-pointer',
          )}
          type='radio'
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={() => {}}
        />
      </div>
      {children}
    </label>
  );
};
