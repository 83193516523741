import { BackButton, SaveDiskIcon } from '@components';
import { useDocumentUpload, useToast } from '@contexts';
import { EVENT_TYPE } from '@enums';
import {
  validateDocumentSignatureInfo,
  validateDocumentUploadinfo,
} from '@utils';
import { useWizard } from 'react-use-wizard';

export const DocumentUploadSaveButton = () => {
  const { previousStep } = useWizard();
  const {
    documentData,
    handleOnSave,
    eventType: eventTypeRaw,
  } = useDocumentUpload();
  const { showErrorToast } = useToast();

  if (!documentData || !eventTypeRaw) {
    return <></>;
  }

  const eventType = EVENT_TYPE[eventTypeRaw as keyof typeof EVENT_TYPE];

  const documentUploadInfoValid = validateDocumentUploadinfo(documentData);
  const documentSignatureInfoValid = documentData.signatureRequired
    ? validateDocumentSignatureInfo(eventType, documentData)
    : true;

  const handleOnClick = () => {
    if (!documentUploadInfoValid) {
      showErrorToast({
        description: 'Please fill out the required document information.',
      });
      return;
    }

    if (!documentSignatureInfoValid) {
      showErrorToast({
        description: 'Please fill out the required signature information.',
      });
      return;
    }

    handleOnSave();
    previousStep();
  };

  return (
    <BackButton
      icon={<SaveDiskIcon fill={'currentColor'} size={14} />}
      label={'Save'}
      onClick={handleOnClick}
    />
  );
};
