import { Tier } from '@customTypes';
import {
  COMPLIANCE_TIER,
  INVESTOR_TYPE,
  KYC_TIER_STATE,
  TIER_ID,
  TIER_NAME,
  VERIFICATION_STATUS,
} from '@enums';
import {
  allowedRegionsToCountryList,
  blockedRegionsFromCountryList,
} from '@utils';

export const getTierId = (tierName: TIER_NAME): string => {
  if (tierName === TIER_NAME.IDENTITY) {
    return '1';
  } else if (tierName === TIER_NAME.ADDRESS_DOCUMENT) {
    return '2';
  } else {
    return TIER_ID.ACCREDITATION_KYC_TIER_ID;
  }
};

export const getVerificationStatusForTier = (
  tiers: [Tier],
  forTier: TIER_NAME,
): VERIFICATION_STATUS => {
  if (!tiers || tiers.length < 1) {
    return VERIFICATION_STATUS.NONE;
  }

  const tierId = getTierId(forTier);

  for (let i = 0; i < tiers.length; i++) {
    const tier = tiers[i];

    if (Number(tierId) === Number(tier.kycTier.id)) {
      if (!tier.status) {
        return VERIFICATION_STATUS.NONE;
      }

      return tier.status as VERIFICATION_STATUS;
    }
  }

  return VERIFICATION_STATUS.NONE;
};

export const getCurrentStateForTier = (
  tiers: [Tier],
  forTier: TIER_NAME,
): KYC_TIER_STATE | null => {
  if (!tiers || tiers.length < 1) {
    return null;
  }

  const tierId = getTierId(forTier);

  for (let i = 0; i < tiers.length; i++) {
    const tier = tiers[i];

    if (Number(tierId) === Number(tier.kycTier?.id)) {
      if (tier.kycTierStates && tier.kycTierStates.length > 0) {
        return tier.kycTierStates[0]?.id
          ? (tier.kycTierStates[0].id as KYC_TIER_STATE)
          : null;
      }
    }
  }

  return null;
};

export const getVerificationExpirationForTier = (
  tiers: [Tier],
  forTier: TIER_NAME,
) => {
  if (!tiers || tiers.length < 1) return;

  const tierId = getTierId(forTier);

  for (let i = 0; i < tiers.length; i++) {
    const tier = tiers[i];

    // Find tier
    if (tierId === tier.kycTier.id) {
      return tier.expiresAt;
    }
  }
};

export const isVerificationFinished = (
  tiers: [Tier],
  forTier: TIER_NAME,
): boolean => {
  if (!tiers || tiers.length < 1) {
    return false;
  }

  const tierId = getTierId(forTier);

  for (let i = 0; i < tiers.length; i++) {
    const tier = tiers[i];

    // Find tier
    if (tierId === tier.kycTier.id) {
      if (!tier.status) {
        return false;
      }

      const verificationStatuses: string[] = [
        VERIFICATION_STATUS.FAILED,
        VERIFICATION_STATUS.COMPLETED,
      ];

      return verificationStatuses.includes(tier.status);
    }
  }

  return false;
};

export const isVerificationStarted = (
  tiers: [Tier],
  forTier: TIER_NAME,
): boolean => {
  if (!tiers || tiers.length < 1) {
    return false;
  }

  const tierId = getTierId(forTier);

  for (let i = 0; i < tiers.length; i++) {
    const tier = tiers[i];

    // Find tier
    if (tierId === tier.kycTier.id) {
      if (!tier.status) {
        return false;
      }

      const verificationStatuses: string[] = [
        VERIFICATION_STATUS.PENDING,
        VERIFICATION_STATUS.FAILED,
        VERIFICATION_STATUS.COMPLETED,
      ];

      return verificationStatuses.includes(tier.status);
    }
  }

  return false;
};

export const getKycTier = (tiers: [Tier], tierName: TIER_NAME): Maybe<Tier> => {
  for (let i = 0; i < tiers?.length; i++) {
    const tier = tiers[i];

    if (!tier.id || !tier?.kycTier?.id) {
      continue;
    }

    const tierId = getTierId(tierName);

    if (tier.kycTier.id === tierId) {
      return tier;
    }
  }

  return null;
};

export const isUsAccount = (account) => {
  if (!account || account.addresses.length === 0) return false;

  const { country } =
    account.addresses.find(
      ({ addressType }) => addressType === INVESTOR_TYPE.MYSELF,
    ) || {};
  return country === 'US';
};

/**
 * TODO: consider city restrictions as well
 */
export const isRestrictedByRegion = (country, regions) => {
  if (!country) {
    return true;
  }

  if (!regions) {
    return false;
  }

  const allowedRegions = allowedRegionsToCountryList(regions);
  const blockedRegions = blockedRegionsFromCountryList(allowedRegions);

  return (
    blockedRegions.find(
      (region) => region.value?.toLowerCase() === country.toLowerCase(),
    ) !== undefined
  );
};

export const isEntity = (account) => {
  if (!account) return false;

  return account.investorType === INVESTOR_TYPE.ENTITY;
};

export const getTruncatedEmail = (email) => {
  if (!email) {
    return '';
  } else if (email.length <= 22) {
    return email;
  }
  return email.substring(0, 9) + '...' + email.slice(-10);
};

export const complianceTiertoKycRestriction = (
  complianceTier?: COMPLIANCE_TIER,
): string | null => {
  if (complianceTier === COMPLIANCE_TIER.IDENTITY_VERIFICATION) {
    return TIER_ID.IDENTITY_KYC_TIER_ID;
  }
  if (complianceTier === COMPLIANCE_TIER.ID_VERIFICATION_WITH_ACCREDITATION) {
    return TIER_ID.ACCREDITATION_KYC_TIER_ID;
  }

  return TIER_ID.NONE;
};

export const kycRestrictionToComplianceTier = (
  kycRestriction: string | null,
): COMPLIANCE_TIER => {
  if (String(kycRestriction) === TIER_ID.IDENTITY_KYC_TIER_ID) {
    return COMPLIANCE_TIER.IDENTITY_VERIFICATION;
  }

  if (String(kycRestriction) === TIER_ID.ACCREDITATION_KYC_TIER_ID) {
    return COMPLIANCE_TIER.ID_VERIFICATION_WITH_ACCREDITATION;
  }

  return COMPLIANCE_TIER.NO_VERIFICATION;
};

export const kycRestrictionToComplianceSummary = (tier: TIER_ID): String => {
  if (tier === TIER_ID.IDENTITY_KYC_TIER_ID) {
    return 'Identity Verification Required';
  } else if (tier === TIER_ID.ACCREDITATION_KYC_TIER_ID) {
    return 'ID Verification + US Accredited Investor Required';
  }
  return 'No Identity Verification Required';
};

export const complianceTierToComplianceSummary = (tier: COMPLIANCE_TIER) => {
  if (tier === COMPLIANCE_TIER.IDENTITY_VERIFICATION) {
    return 'Identity Verification Required';
  } else if (tier === COMPLIANCE_TIER.ID_VERIFICATION_WITH_ACCREDITATION) {
    return 'ID Verification + US Accredited Investor Required';
  }
  return 'No Identity Verification Required';
};
