export const InfoIcon = ({ fill = '#6B7280' }) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.625'
        y='0.625'
        width='16.75'
        height='16.75'
        rx='2.375'
        stroke='#6B7280'
        strokeWidth='1.25'
      />
      <path
        d='M8.49663 13V6.45455H9.50231V13H8.49663ZM9.00799 5.36364C8.81197 5.36364 8.64293 5.29688 8.50089 5.16335C8.36168 5.02983 8.29208 4.86932 8.29208 4.68182C8.29208 4.49432 8.36168 4.33381 8.50089 4.20028C8.64293 4.06676 8.81197 4 9.00799 4C9.20401 4 9.37163 4.06676 9.51083 4.20028C9.65288 4.33381 9.7239 4.49432 9.7239 4.68182C9.7239 4.86932 9.65288 5.02983 9.51083 5.16335C9.37163 5.29688 9.20401 5.36364 9.00799 5.36364Z'
        fill={fill}
      />
    </svg>
  );
};
