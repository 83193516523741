import { InputGroup } from '@components';
import { DEFAULT_EVENT_HEADER_FONT_COLOR } from '@constants';
import classNames from 'classnames';
import { ReactNode } from 'react';

const isValidHexColor = (value: string) => {
  return !!value?.match(/^#([A-F0-9]{8}|[A-F0-9]{6})$/i);
};

export const HexColorInput = ({
  className,
  label = 'color',
  defaultColor = DEFAULT_EVENT_HEADER_FONT_COLOR,
  color,
  onChange,
  name = 'color',
  required = false,
  prepend = (
    <div className='my-auto mr-4'>
      <div
        className='rounded-full w-8 h-8 border border-neutral-light'
        style={
          color
            ? isValidHexColor(color)
              ? { background: color }
              : { visibility: 'hidden' }
            : { background: defaultColor }
        }
      />
    </div>
  ),
  flexPrepend = false,
}: {
  className?: string;
  label?: string;
  color: string;
  defaultColor: string;
  onChange: Function;
  name: string;
  required: boolean;
  prepend?: ReactNode;
  flexPrepend?: boolean;
}) => (
  <InputGroup
    className={classNames(className, 'hex-color-input')}
    label={label}
    prepend={prepend}
    flexPrepend={flexPrepend}
    placeholder={defaultColor}
    value={color}
    onChange={onChange}
    name={name}
    required={required}
    maxLength={7}
    valid={!color || isValidHexColor(color)}
  />
);
