import { useGetSale, useGetSaleContractOwner } from '@apiServices';
import {
    BackButton,
    ButtonRow,
    ContinueButton,
    LoadingIndicator,
    PageSubtitle,
    PageTitle,
    SaleOwner,
    Step,
    StepFooter,
    StepHeader,
    SwitchChainWarning,
} from '@components';
import { useEvent, useNetworks, useWallet, WizardContext } from '@contexts';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';

export const EditSaleOwnerWizardPage = () => {
  const navigate = useNavigate();
  const { event } = useEvent();
  const { connectedChainId } = useWallet();
  const { getNetworkDetails } = useNetworks();
  const { sale, loading } = useGetSale(true, event?.id);

  const [wizardState, setWizardState] = useState<any>({});

  const saleOwner = useGetSaleContractOwner(sale);

  useEffect(() => {
    if (saleOwner) {
      sale.owner.id = saleOwner;
      setWizardState({
        ...sale,
        owner: sale.owner.id,
        recipient: sale.recipient.id,
      });
    }
  }, [saleOwner]);

  const goBack = () => {
    navigate(-1);
  };

  const handleDone = () => {
    goBack();
  };

  const handleCancel = () => {
    goBack();
  };

  if (!loading && sale && sale.chainId !== connectedChainId) {
    const correctNetworkDetails = getNetworkDetails(sale.chainId);
    return (
      <SwitchChainWarning
        chainId={sale.chainId}
        subtitle={`Please connect to the ${correctNetworkDetails.name} network to continue.`}
      />
    );
  }

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <Step>
            <StepHeader>
              <PageTitle>Owner &amp; Recipient</PageTitle>
              <PageSubtitle>
                The owner of the sale is always defaulted to the wallet address
                of the admin who set it up. If you would like to change it or
                your sweep recipient you can do that below in two separate
                transactions.
              </PageSubtitle>
            </StepHeader>

            <SaleOwner context={wizardState} setContext={setWizardState} />

            <StepFooter>
              <ButtonRow place='between'>
                <BackButton label={'Cancel'} onClick={handleCancel} />
                <ContinueButton label={'Done'} onClick={handleDone} />
              </ButtonRow>
            </StepFooter>
          </Step>
        )}
      </Wizard>
    </WizardContext.Provider>
  );
};
