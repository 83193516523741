import { useEffect, useState } from 'react';
import { Receipt } from '../custom-types';
import { add } from '../utils';

interface Fee {
  label: string;
  amount: string;
}

export const useTotalFees = (receipt: Maybe<Receipt>) => {
  const [totalFees, setTotalFees] = useState<Record<string, string>>({});
  const [totalCostPresentation, setTotalCostPresentation] =
    useState<string>('');

  useEffect(() => {
    const summedFees: Record<string, string> = {};
    const fees: (Fee | undefined)[] = [
      receipt?.platformFee,
      receipt?.tokensPaid,
    ];

    for (const fee of fees) {
      if (fee && fee.label && fee.amount) {
        if (summedFees[fee.label]) {
          summedFees[fee.label] = add(summedFees[fee.label], fee.amount);
        } else {
          summedFees[fee.label] = fee.amount;
        }
      }
    }

    setTotalFees(summedFees);

    const presentation = Object.entries(summedFees)
      .map(([currency, amount]) => `${amount} ${currency}`)
      .join('\n');

    setTotalCostPresentation(presentation);
  }, [receipt?.platformFee, receipt?.tokensPaid]);

  return { totalFees, totalCostPresentation };
};
