import { Card, InputGroup } from '@components';
import { AccountDetails } from '@customTypes';
import { INVESTOR_TYPE } from '@enums';
import { formatAccountDetailsAddress, isEntity, utcToLocalDate } from '@utils';
import { FC } from 'react';

type ReviewPanelProps = {
  accountDetails?: AccountDetails;
};
export const ReviewPanel: FC<ReviewPanelProps> = ({ accountDetails }) => {
  return (
    <>
      <Card title='Applicant Summary' className='vertical short overflow-x-hidden'>
        <InputGroup
          label='Name'
          value={`${accountDetails?.firstName}${
            accountDetails?.middleName
              ? ' ' + accountDetails?.middleName + ' '
              : ' '
          }${accountDetails?.lastName}`}
          readonly
          colWidths={{ second: '2/3' }}
        />

        {accountDetails?.dob && (
          <InputGroup
            label='Date of birth'
            value={utcToLocalDate(accountDetails?.dob)}
            readonly
            colWidths={{ second: '2/3' }}
          />
        )}

        <InputGroup
          label='Phone number'
          value={accountDetails?.phoneNumber}
          readonly
          colWidths={{ second: '2/3' }}
        />

        <InputGroup
          label='Email address'
          value={accountDetails?.email}
          readonly
          colWidths={{ second: '2/3' }}
        />
        {accountDetails && (
          <InputGroup
            label='Permanent address'
            value={formatAccountDetailsAddress(
              accountDetails,
              INVESTOR_TYPE.MYSELF,
            ).join(' ')}
            readonly
            colWidths={{ second: '2/3' }}
          />
        )}
      </Card>

      {isEntity(accountDetails) && (
        <Card title='Entity Summary' className='vertical short'>
          <InputGroup
            label='Name'
            value={accountDetails?.entityName}
            readonly
            colWidths={{ second: '2/3' }}
          />
          {accountDetails?.entityDba && (
            <InputGroup
              label='DBA name'
              value={accountDetails?.entityDba}
              readonly
              colWidths={{ second: '2/3' }}
            />
          )}
          {accountDetails?.entityFormationDate && (
            <InputGroup
              label='Formation date'
              value={utcToLocalDate(accountDetails?.entityFormationDate)}
              readonly
              colWidths={{ second: '2/3' }}
            />
          )}
          <InputGroup
            label='EIN / TIN'
            value={accountDetails?.entityTaxId}
            readonly
            colWidths={{ second: '2/3' }}
          />
          {accountDetails && (
            <InputGroup
              label='Address'
              value={formatAccountDetailsAddress(
                accountDetails,
                INVESTOR_TYPE.ENTITY,
              ).join(' ')}
              readonly
              colWidths={{ second: '2/3' }}
            />
          )}
        </Card>
      )}
    </>
  );
};
