import { createContext, Dispatch, SetStateAction, useContext } from 'react';

interface WizardContextProps {
  wizardState: any;
  setWizardState: Dispatch<SetStateAction<any>>;
}

export const WizardContext = createContext<Maybe<WizardContextProps>>(null);
WizardContext.displayName = 'WizardContext';

export const useWizard = (): WizardContextProps => {
  const context = useContext(WizardContext);

  if (!context) {
    throw new Error(
      'WizardContext context is undefined, please verify you are calling useWizard() as child of a <WizardContext> component.',
    );
  }

  return context;
};
