import { CreateDocument } from '@customTypes';
import { createContext, useContext } from 'react';

interface DocumentUploadContextProps {
  isUSRestricted: boolean;
  documentData: CreateDocument | null;
  setDocumentData: Function;
  handleOnSave: Function;
  handleDeleteSection: Function;
  hideStepFooter: boolean;
  eventType: string;
}

export const DocumentUploadContext =
  createContext<Maybe<DocumentUploadContextProps>>(null);
DocumentUploadContext.displayName = 'DocumentUploadContext';

export const useDocumentUpload = (): DocumentUploadContextProps => {
  const context = useContext(DocumentUploadContext);

  if (!context) {
    throw new Error(
      'useDocumentUpload must be used within a DocumentUploadProvider',
    );
  }

  return context;
};
