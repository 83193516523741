import { useGetAccount } from '@apiServices';
import { AccountContext } from '@contexts';
import { TIER_NAME, VERIFICATION_STATUS } from '@enums';
import {
  getCurrentStateForTier,
  getVerificationExpirationForTier,
  getVerificationStatusForTier,
  isEmptyObject,
  isEntity,
  isUsAccount,
  isVerificationFinished,
  isVerificationStarted,
} from '@utils';
import { FC, ReactNode } from 'react';

interface AccountProviderProps {
  children?: ReactNode;
}

export const AccountProvider: FC<AccountProviderProps> = ({ children }) => {
  const { account, isLoading, refetch } = useGetAccount();

  const usAccount = isUsAccount(account);
  const entity = isEntity(account);
  const tier1Status = getVerificationStatusForTier(
    account?.kycTiers,
    TIER_NAME.IDENTITY,
  );
  const tier1Expiration = getVerificationExpirationForTier(
    account?.kycTiers,
    TIER_NAME.IDENTITY,
  );
  const isTier1Started = isVerificationStarted(
    account?.kycTiers,
    TIER_NAME.IDENTITY,
  );
  const isTier1Finished = isVerificationFinished(
    account?.kycTiers,
    TIER_NAME.IDENTITY,
  );
  const isTier1Completed = tier1Status === VERIFICATION_STATUS.COMPLETED;
  const tier1CurrentState = getCurrentStateForTier(
    account?.kycTiers,
    TIER_NAME.IDENTITY,
  );

  const tier3Status = getVerificationStatusForTier(
    account?.kycTiers,
    TIER_NAME.ACCREDITATION,
  );
  const tier3Expiration = getVerificationExpirationForTier(
    account?.kycTiers,
    TIER_NAME.ACCREDITATION,
  );
  const isTier3Started = isVerificationStarted(
    account?.kycTiers,
    TIER_NAME.ACCREDITATION,
  );
  const isTier3Finished = isVerificationFinished(
    account?.kycTiers,
    TIER_NAME.ACCREDITATION,
  );
  const isTier3Completed = tier3Status === VERIFICATION_STATUS.COMPLETED;
  const isTier3Eligible = usAccount && isTier1Started;
  const tier3CurrentState = getCurrentStateForTier(
    account?.kycTiers,
    TIER_NAME.ACCREDITATION,
  );

  const completeProfile =
    !isEmptyObject(account) &&
    account.emailVerified &&
    isTier1Completed &&
    ((isTier3Eligible && isTier3Completed) || !isTier3Eligible);

  return isLoading ? (
    <></>
  ) : (
    <AccountContext.Provider
      value={{
        account: account || {},
        loading: isLoading,
        refetch: refetch,
        isUsAccount: usAccount,
        isEntity: entity,
        tier1Status,
        isTier1Started,
        isTier1Finished,
        isTier1Completed,
        tier1Expiration: new Date(tier1Expiration!),
        tier1CurrentState,
        tier3Status,
        isTier3Started,
        isTier3Finished,
        isTier3Completed,
        isTier3Eligible,
        tier3Expiration: new Date(tier3Expiration!),
        tier3CurrentState,
        isProfileSetupComplete: completeProfile,
        configuration: account?.configuration || {},
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
