import { Erc20Purchase } from '@components';
import {
  useAnalytics,
  useAuth,
  useNetworks,
  useToast,
  useWallet,
} from '@contexts';
import { PaymentMethod } from '@customTypes';
import { useApproveErc20Transfer, useSendErc20Transaction } from '@hooks';
import { getTxUrl, lte } from '@utils';
import { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { IoChevronDownCircleOutline } from 'react-icons/io5';
import { VscLinkExternal } from 'react-icons/vsc';
import { BaseError } from 'viem';

interface PurchaseButtonProps {
  disabled: boolean;
  paymentMethod: PaymentMethod;
  platformFee: string;
  toAddress: string;
  proof?: string[];
  value?: string;
  getReceipt?: Function;
  saleId: string;
}

export const PurchaseButton = ({
  disabled,
  paymentMethod,
  platformFee,
  toAddress,
  proof,
  value,
  getReceipt,
  saleId,
}: PurchaseButtonProps) => {
  const { pageEvent } = useAnalytics();
  const { showSuccessToast, showErrorToast } = useToast();
  const { connectedChainId, supportedNetwork } = useWallet();
  const {
    user: { walletAddress },
  } = useAuth();
  const { getNetworkDetails } = useNetworks();
  const [approved, setApproved] = useState<boolean>(false);
  const [approvalPending, setApprovalPending] = useState<boolean>(false);

  const {
    error: approveErc20TransferError,
    write: sendApproveErc20Transfer,
    data: approveErc20TransferReceipt,
    isError: isApproveErc20TransferError,
    isSubmitting: isApproveErc20TransferSubmitting,
    isLoading: isApproveErc20TransferLoading,
  } = useApproveErc20Transfer();

  const {
    data: sendErc20TransactionData,
    error: sendErc20TransactionError,
    write: sendErc20Transaction,
    isError: isSendErc20TransactionError,
    isSubmitting: isSendErc20TransactionSubmitting,
    isLoading: isSendErc20TransactionLoading,
  } = useSendErc20Transaction(connectedChainId);

  useEffect(() => {
    if (approveErc20TransferReceipt) {
      setApproved(true);
      setApprovalPending(false);
      pageEvent('purchase', 'approveTransfer');

      showSuccessToast({
        description: (
          <div className='flex flex-row'>
            Successfully approved {paymentMethod.symbol}.
            <a
              target='_blank'
              rel='noreferrer'
              href={getTxUrl(
                approveErc20TransferReceipt.transactionHash,
                getNetworkDetails(connectedChainId),
              )}
              className='w-[30px] flex items-center justify-center text-white'
              onClick={(e) => e.stopPropagation()}
            >
              <VscLinkExternal color='white' />
            </a>
          </div>
        ),
      });
    }
  }, [approveErc20TransferReceipt]);

  useEffect(() => {
    if (sendErc20TransactionData) {
      if (sendErc20TransactionData.status === 'success') {
        if (getReceipt) {
          getReceipt({
            receipt: sendErc20TransactionData.transactionHash,
            toAddress,
            fromAddress: sendErc20TransactionData.from,
            paymentMethod: paymentMethod.symbol,
            value,
            saleId,
          });
        }
      } else {
        showErrorToast({
          description: 'Error completing transaction.',
        });
      }
    }
  }, [sendErc20TransactionData]);

  useEffect(() => {
    if (approveErc20TransferError) {
      console.error(approveErc20TransferError.toString());
      showErrorToast({
        description:
          'The transaction could not be completed, please try again later.',
      });
    }
  }, [approveErc20TransferError]);

  useEffect(() => {
    if (sendErc20TransactionError) {
      console.error(sendErc20TransactionError.toString());
      showErrorToast({
        description:
          'The transaction could not be completed, please try again later.',
      });
    }
  }, [sendErc20TransactionError]);

  const handleOnAllowanceCheck = (hasEnoughAllowance) => {
    setApproved(hasEnoughAllowance);
  };

  const approveErc20 = async () => {
    if (!approved) {
      setApprovalPending(true);

      try {
        // Call approve on erc20 contract
        await sendApproveErc20Transfer(
          connectedChainId,
          paymentMethod,
          toAddress,
          value,
        );
      } catch (e: any) {
        if (e instanceof BaseError) {
          showErrorToast({
            description: e.shortMessage,
          });
        } else {
          showErrorToast({
            description:
              'The transaction could not be completed, please try again later.',
          });
        }
      } finally {
        setApprovalPending(false);
      }
    }
  };

  const purchaseErc20 = async () => {
    try {
      const transactionHash = await sendErc20Transaction(
        connectedChainId,
        paymentMethod,
        toAddress,
        value,
        proof,
        platformFee,
      );

      if (transactionHash) {
        showSuccessToast({
          description: (
            <div className='flex flex-row'>
              Submitted transaction.
              <a
                target='_blank'
                rel='noreferrer'
                href={getTxUrl(
                  transactionHash,
                  getNetworkDetails(connectedChainId),
                )}
                className='w-[30px] flex items-center justify-center text-white'
                onClick={(e) => e.stopPropagation()}
              >
                <VscLinkExternal color='white' />
              </a>
            </div>
          ),
        });
      }
    } catch (e: any) {
      console.error(e.message);
      showErrorToast({
        description:
          'The transaction could not be completed, please try again later.',
      });
    }
  };

  const disableApprovalButton =
    value === '0' ||
    lte(value ?? 0, 0) ||
    disabled ||
    approvalPending ||
    approved ||
    !supportedNetwork ||
    isApproveErc20TransferLoading ||
    isApproveErc20TransferSubmitting;
  const disablePurchaseButton =
    value === '0' ||
    disabled ||
    approvalPending ||
    !approved ||
    !supportedNetwork ||
    isSendErc20TransactionLoading ||
    isSendErc20TransactionSubmitting;

  const showApproveLoadingIndicator =
    (isApproveErc20TransferLoading || isApproveErc20TransferSubmitting) &&
    !isApproveErc20TransferError;
  const showPurchaseLoadingIndicator =
    (isSendErc20TransactionLoading || isSendErc20TransactionSubmitting) &&
    !isSendErc20TransactionError;

  return (
    <>
      <Erc20Purchase
        connectedChainId={connectedChainId}
        paymentMethod={paymentMethod}
        walletAddress={walletAddress}
        saleId={toAddress}
        value={value ?? ''}
        onAllowanceCheck={handleOnAllowanceCheck}
      />

      <div>
        <button
          className={'btn btn-primary mr-2'}
          onClick={approveErc20}
          disabled={disableApprovalButton}
        >
          {showApproveLoadingIndicator ? (
            <div className='flex flex-row'>
              <div className='animate-spin'>
                <AiOutlineLoading3Quarters size={24} />
              </div>
              <span className='pl-2'>Approving {paymentMethod.symbol}...</span>
            </div>
          ) : (
            `Approve ${paymentMethod.symbol}`
          )}
        </button>

        <button
          className={'btn btn-primary'}
          onClick={purchaseErc20}
          disabled={disablePurchaseButton}
        >
          {showPurchaseLoadingIndicator ? (
            <div className='flex flex-row'>
              <div className='animate-spin'>
                <AiOutlineLoading3Quarters size={24} />
              </div>
              <span className='pl-2'>Purchasing...</span>
            </div>
          ) : (
            <div className='flex flex-row justify-center'>
              <IoChevronDownCircleOutline className='text-xl mr-1 mt-[2px]' />
              <span className='self-center font-semibold'>{'Purchase'}</span>
            </div>
          )}
        </button>
      </div>
    </>
  );
};
