import { CheckFilledIcon } from '@components';
import { twMerge } from 'tailwind-merge';

type CheckableCircleProps = {
  isChecked: boolean;
  checkedColor?: HexColorCode;
  className?: string;
};

export const CheckableCircle = ({
  isChecked,
  checkedColor = '#11c069',
  className,
}: CheckableCircleProps) => {
  return isChecked ? (
    <CheckFilledIcon
      className={twMerge('w-5 h-5', className)}
      style={{
        color: checkedColor,
      }}
    />
  ) : (
    <div className={`flex-shrink-0 h-5 w-5 border border-[#bababa] rounded-full`} />
  );
};
