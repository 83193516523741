import { useEffect, useState } from 'react';

export const useWindowSize = (throttleTime = 300) => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    let timeoutId: Maybe<NodeJS.Timeout> = null;

    const handleResize = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, throttleTime);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [throttleTime]);

  return windowSize;
};
