export const TITLE_ONLY_100_RIGHT = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 252 152'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='10' y='44' width='230' height='30' rx='5' fill='#E5E7EB' />
      <path
        d='M227.351 63.9003C227.217 63.9003 227.089 63.8474 226.995 63.753C226.901 63.6587 226.848 63.5308 226.848 63.3974V58.9502C226.848 58.8168 226.901 58.6889 226.995 58.5946C227.089 58.5003 227.217 58.4473 227.351 58.4473C227.484 58.4473 227.612 58.5003 227.706 58.5946C227.801 58.6889 227.854 58.8168 227.854 58.9502V63.3974C227.854 63.5308 227.801 63.6587 227.706 63.753C227.612 63.8474 227.484 63.9003 227.351 63.9003Z'
        fill='#6B7280'
      />
      <path
        d='M229.573 61.6768H225.126C224.993 61.6768 224.865 61.6238 224.77 61.5295C224.676 61.4352 224.623 61.3072 224.623 61.1739C224.623 61.0405 224.676 60.9125 224.77 60.8182C224.865 60.7239 224.993 60.6709 225.126 60.6709H229.573C229.707 60.6709 229.834 60.7239 229.929 60.8182C230.023 60.9125 230.076 61.0405 230.076 61.1739C230.076 61.3072 230.023 61.4352 229.929 61.5295C229.834 61.6238 229.707 61.6768 229.573 61.6768Z'
        fill='#6B7280'
      />
      <path
        d='M227.986 55.0059H217.503C217.37 55.0059 217.242 54.9529 217.147 54.8586C217.053 54.7643 217 54.6364 217 54.503C217 54.3696 217.053 54.2416 217.147 54.1473C217.242 54.053 217.37 54 217.503 54H227.986C228.119 54 228.247 54.053 228.341 54.1473C228.436 54.2416 228.489 54.3696 228.489 54.503C228.489 54.6364 228.436 54.7643 228.341 54.8586C228.247 54.9529 228.119 55.0059 227.986 55.0059Z'
        fill='#6B7280'
      />
      <path
        d='M227.986 55.0059H217.503C217.37 55.0059 217.242 54.9529 217.147 54.8586C217.053 54.7643 217 54.6364 217 54.503C217 54.3696 217.053 54.2416 217.147 54.1473C217.242 54.053 217.37 54 217.503 54H227.986C228.119 54 228.247 54.053 228.341 54.1473C228.436 54.2416 228.489 54.3696 228.489 54.503C228.489 54.6364 228.436 54.7643 228.341 54.8586C228.247 54.9529 228.119 55.0059 227.986 55.0059Z'
        fill='#6B7280'
      />
      <path
        d='M227.986 57.5469H217.503C217.37 57.5469 217.242 57.4939 217.147 57.3996C217.053 57.3053 217 57.1774 217 57.044C217 56.9106 217.053 56.7827 217.147 56.6883C217.242 56.594 217.37 56.541 217.503 56.541H227.986C228.119 56.541 228.247 56.594 228.341 56.6883C228.436 56.7827 228.489 56.9106 228.489 57.044C228.489 57.1774 228.436 57.3053 228.341 57.3996C228.247 57.4939 228.119 57.5469 227.986 57.5469Z'
        fill='#6B7280'
      />
      <path
        d='M222.903 60.0884H217.503C217.37 60.0884 217.242 60.0354 217.147 59.9411C217.053 59.8468 217 59.7189 217 59.5855C217 59.4521 217.053 59.3242 217.147 59.2298C217.242 59.1355 217.37 59.0825 217.503 59.0825H222.903C223.037 59.0825 223.165 59.1355 223.259 59.2298C223.353 59.3242 223.406 59.4521 223.406 59.5855C223.406 59.7189 223.353 59.8468 223.259 59.9411C223.165 60.0354 223.037 60.0884 222.903 60.0884Z'
        fill='#6B7280'
      />
      <path
        d='M222.903 62.6299H217.503C217.37 62.6299 217.242 62.577 217.147 62.4826C217.053 62.3883 217 62.2604 217 62.127C217 61.9936 217.053 61.8657 217.147 61.7713C217.242 61.677 217.37 61.624 217.503 61.624H222.903C223.037 61.624 223.165 61.677 223.259 61.7713C223.353 61.8657 223.406 61.9936 223.406 62.127C223.406 62.2604 223.353 62.3883 223.259 62.4826C223.165 62.577 223.037 62.6299 222.903 62.6299Z'
        fill='#6B7280'
      />
      <rect x='10' y='79' width='230' height='30' rx='5' fill='#E5E7EB' />
      <path
        d='M227.351 98.9003C227.217 98.9003 227.089 98.8474 226.995 98.753C226.901 98.6587 226.848 98.5308 226.848 98.3974V93.9502C226.848 93.8168 226.901 93.6889 226.995 93.5946C227.089 93.5003 227.217 93.4473 227.351 93.4473C227.484 93.4473 227.612 93.5003 227.706 93.5946C227.801 93.6889 227.854 93.8168 227.854 93.9502V98.3974C227.854 98.5308 227.801 98.6587 227.706 98.753C227.612 98.8474 227.484 98.9003 227.351 98.9003Z'
        fill='#6B7280'
      />
      <path
        d='M229.573 96.6768H225.126C224.993 96.6768 224.865 96.6238 224.77 96.5295C224.676 96.4352 224.623 96.3072 224.623 96.1739C224.623 96.0405 224.676 95.9125 224.77 95.8182C224.865 95.7239 224.993 95.6709 225.126 95.6709H229.573C229.707 95.6709 229.834 95.7239 229.929 95.8182C230.023 95.9125 230.076 96.0405 230.076 96.1739C230.076 96.3072 230.023 96.4352 229.929 96.5295C229.834 96.6238 229.707 96.6768 229.573 96.6768Z'
        fill='#6B7280'
      />
      <path
        d='M227.986 90.0059H217.503C217.37 90.0059 217.242 89.9529 217.147 89.8586C217.053 89.7643 217 89.6364 217 89.503C217 89.3696 217.053 89.2416 217.147 89.1473C217.242 89.053 217.37 89 217.503 89H227.986C228.119 89 228.247 89.053 228.341 89.1473C228.436 89.2416 228.489 89.3696 228.489 89.503C228.489 89.6364 228.436 89.7643 228.341 89.8586C228.247 89.9529 228.119 90.0059 227.986 90.0059Z'
        fill='#6B7280'
      />
      <path
        d='M227.986 90.0059H217.503C217.37 90.0059 217.242 89.9529 217.147 89.8586C217.053 89.7643 217 89.6364 217 89.503C217 89.3696 217.053 89.2416 217.147 89.1473C217.242 89.053 217.37 89 217.503 89H227.986C228.119 89 228.247 89.053 228.341 89.1473C228.436 89.2416 228.489 89.3696 228.489 89.503C228.489 89.6364 228.436 89.7643 228.341 89.8586C228.247 89.9529 228.119 90.0059 227.986 90.0059Z'
        fill='#6B7280'
      />
      <path
        d='M227.986 92.5469H217.503C217.37 92.5469 217.242 92.4939 217.147 92.3996C217.053 92.3053 217 92.1774 217 92.044C217 91.9106 217.053 91.7827 217.147 91.6883C217.242 91.594 217.37 91.541 217.503 91.541H227.986C228.119 91.541 228.247 91.594 228.341 91.6883C228.436 91.7827 228.489 91.9106 228.489 92.044C228.489 92.1774 228.436 92.3053 228.341 92.3996C228.247 92.4939 228.119 92.5469 227.986 92.5469Z'
        fill='#6B7280'
      />
      <path
        d='M222.903 95.0884H217.503C217.37 95.0884 217.242 95.0354 217.147 94.9411C217.053 94.8468 217 94.7189 217 94.5855C217 94.4521 217.053 94.3242 217.147 94.2298C217.242 94.1355 217.37 94.0825 217.503 94.0825H222.903C223.037 94.0825 223.165 94.1355 223.259 94.2298C223.353 94.3242 223.406 94.4521 223.406 94.5855C223.406 94.7189 223.353 94.8468 223.259 94.9411C223.165 95.0354 223.037 95.0884 222.903 95.0884Z'
        fill='#6B7280'
      />
      <path
        d='M222.903 97.6299H217.503C217.37 97.6299 217.242 97.577 217.147 97.4826C217.053 97.3883 217 97.2604 217 97.127C217 96.9936 217.053 96.8657 217.147 96.7713C217.242 96.677 217.37 96.624 217.503 96.624H222.903C223.037 96.624 223.165 96.677 223.259 96.7713C223.353 96.8657 223.406 96.9936 223.406 97.127C223.406 97.2604 223.353 97.3883 223.259 97.4826C223.165 97.577 223.037 97.6299 222.903 97.6299Z'
        fill='#6B7280'
      />
      <defs>
        <filter
          id='filter0_d_4322_2444'
          x='0'
          y='0'
          width='252'
          height='152'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1' dy='1' />
          <feGaussianBlur stdDeviation='0.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0666667 0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0.06 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_4322_2444'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_4322_2444'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
