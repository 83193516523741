import { usePrepareSale, useSaleDeployed } from '@apiServices';
import { useModal, useNetworks, useToast } from '@contexts';
import { DEPLOY_STATUS } from '@enums';
import { getAddressUrl } from '@utils';

import {
  DeploySaleModal,
  DeploySaleSuccessModal,
  PreparingSaleModal,
} from '@components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface CreateSaleModalProps {
  context?: any;
  event?: any;
}

export const CreateSaleModal = ({ context, event }: CreateSaleModalProps) => {
  const { showErrorToast } = useToast();
  const navigate = useNavigate();
  const { closeModal } = useModal();
  const { getNetworkDetails } = useNetworks();
  const { mutate: saleDeployed } = useSaleDeployed();

  const [deployStatus, setDeployStatus] = useState(
    context.deployStatus || DEPLOY_STATUS.NOT_STARTED,
  );
  const [preparedData, setPreparedData] = useState<Maybe<any>>(null);
  const [receipt, setReceipt] = useState(context.deployReceipt || null);

  const { mutate: prepareSale } = usePrepareSale();

  useEffect(() => {
    prepareSale(
      {
        ...context,
      },
      {
        onSuccess: (result) => {
          if (result.valid) {
            setPreparedData(result);
            setDeployStatus(DEPLOY_STATUS.PREPARED);
          } else {
            closeModal();
            setPreparedData(null);
            setDeployStatus(DEPLOY_STATUS.NOT_STARTED);
            showErrorToast({
              description:
                result.errorMessage ||
                'Error preparing sale. Please try again.',
            });
          }
        },
        onError: (error) => {
          closeModal();
          setPreparedData(null);
          setDeployStatus(DEPLOY_STATUS.NOT_STARTED);
          showErrorToast({
            description:
              error.toString() || 'Error preparing sale.  Please try again.',
          });
        },
      },
    );
  }, []);

  return (
    <>
      {deployStatus === DEPLOY_STATUS.NOT_STARTED ? (
        <PreparingSaleModal />
      ) : null}

      {deployStatus === DEPLOY_STATUS.PREPARED ? (
        <DeploySaleModal
          preparedData={preparedData}
          onSuccess={async (receipt) => {
            setReceipt(receipt);
            await saleDeployed({
              id: receipt.contractAddress,
              chainId: context?.networkId,
              eventId: event.id,
              startTime: context?.startTime,
              endTime: context?.endTime,
            });
            setDeployStatus(DEPLOY_STATUS.DEPLOYED);
          }}
          onError={() => {
            showErrorToast({
              description: 'Error deploying sale.  Please try again.',
            });
          }}
        />
      ) : null}

      {deployStatus === DEPLOY_STATUS.DEPLOYED ? (
        <DeploySaleSuccessModal
          explorerUrl={getAddressUrl(
            receipt.contractAddress,
            getNetworkDetails(context.networkId),
          )}
          onFinish={() => {
            closeModal();
            navigate(`/admin/project/${event.project.id}/event/${event.id}`);
          }}
        />
      ) : null}
    </>
  );
};
