import { removeSessionStorageKey, sessionStorageKey } from '@apiClients';
import { AuthContext } from '@contexts';
import { User } from '@customTypes';
// import * as Sentry from '@sentry/browser';
import { FC, ReactNode, useState } from 'react';

declare const window: any;

interface AuthProviderProps {
  children?: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(window.sessionStorage.getItem(sessionStorageKey)) || null,
  );

  // useEffect(() => {
  //   if (user) {
  //     setContext(user);
  //   }
  // }, [user]);

  // const setContext = (user: User) => {
  // if (
  //   import.meta.env.VITE_SENTRY_DSN &&
  //   import.meta.env.VITE_PROCESS_ENV !== 'development'
  // ) {
  //   console.log('Setting user context...');
  //   Sentry.setTag('walletAddress', user.walletAddress);
  //   Sentry.setTag('authId', user.authId);
  // }
  // };

  const authenticate = (user: User) => {
    user = {
      ...user,
      walletAddress: user.walletAddress.toLowerCase() as EvmAddress,
    };
    setUser(user);

    window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(user));
  };

  // return auth to initial state
  const disconnect = () => {
    removeSessionStorageKey();
    setUser(null);
  };

  return (
    <>
      <AuthContext.Provider
        value={{
          user: user || {},
          disconnect,
          isAuthenticated: !!user,
          authenticate: authenticate,
          removeWalletAddress: (walletAddress: string) => {
            authenticate({
              ...user,
              walletAddresses: user.walletAddresses.filter(
                (wa) => wa.walletAddress !== walletAddress,
              ),
            });
          },
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};
