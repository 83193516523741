import { PageHeader } from '@components';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface StepHeaderProps {
  children: ReactNode;
  className?: string;
}

export const StepHeader: React.FC<StepHeaderProps> = ({
  children,
  className,
}) => {
  return (
    <PageHeader className={classNames(className, '!mb-2')}>
      {children}
    </PageHeader>
  );
};
