import { AxiosRequestConfig } from 'axios';
declare const window: any;

interface RequestConfig extends AxiosRequestConfig {}

export const sessionStorageKey = '__auth_provider_user__';

export const removeSessionStorageKey = () => {
  window.sessionStorage.removeItem(sessionStorageKey);
};
