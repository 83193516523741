import { getEventStatus } from '@apiServices';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  EventCard,
  Text,
} from '@components';
import { FaRocket } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

interface ProjectEventsProps {
  events: any[]
}

export const ProjectEvents: React.FC<ProjectEventsProps> = ({ events }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <>
      <Card>
        <CardHeader place={'center'}>
          <CardTitle>Events</CardTitle>
          <Button
            size='sm'
            onClick={() => {
              navigate(`/admin/project/${id}/event/launch`);
            }}
          >
            <FaRocket />
            <Text>Launch Event</Text>
          </Button>
        </CardHeader>

        <CardBody>
          <Col gap={2}>
            {events.length !== 0 ? (
              <div className='grid gap-4 grid-cols-1'>
                {events.map((event, i) => {
                  event.status = getEventStatus(event);
                  return (
                    <div key={i}>
                      <EventCard event={event} eventUrl={`event/${event.id}`} />
                    </div>
                  );
                })}
              </div>
            ) : (
              <Text>No events found.</Text>
            )}
          </Col>
        </CardBody>
      </Card>
    </>
  );
};
