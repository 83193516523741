import { useDistributorApiClient } from '@apiClients';
import { useAuth, useNetworks } from '@contexts';
import { useAsync } from '@hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { uniqueValues } from '@utils';
import { useEffect, useState } from 'react';
import { useGetProject } from './web-api';

export const usePrepareDistributorParticipantsUpdate = () => {
  const client = useDistributorApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`distributors/prepare/participants`, {
        method: 'post',
        data,
      });
    },
  });
};

export const useValidateParticipants = () => {
  const client = useDistributorApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`distributors/participants/validate`, {
        method: 'post',
        data,
      });
    },
  });
};

export const usePrepareDistributor = () => {
  const client = useDistributorApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`distributors/prepare`, {
        method: 'post',
        data,
      });
    },
  });
};

export const useDistributorDeployed = () => {
  const client = useDistributorApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`distributors/distributorDeployed`, {
        method: 'post',
        data,
      });
    },
  });
};

export const useGetDistributorParticipantsSummary = (saleId?: string) => {
  const client = useDistributorApiClient();

  return useQuery({
    enabled: !!saleId,
    queryKey: ['distributors/participants', saleId],
    queryFn: () => client(`distributors/${saleId}/participants/summary`),
  });
};

export const useGetDistributor = (distributorId: string) => {
  const client = useDistributorApiClient();
  const { run, data } = useAsync();
  const { supportedNetworks } = useNetworks();
  const [distributor, setDistributor] = useState<Maybe<any>>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<Date | null>(null);

  useEffect(() => {
    if (!reload) {
      return;
    }

    const data = {
      distributorId: distributorId,
      chains: supportedNetworks,
    };

    run(
      client(`distributors/find`, {
        method: 'post',
        data,
      }),
    );
  }, [reload]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data?.distributor) {
      const formattedDistributor = formatDistributor(data.distributor);
      setDistributor(formattedDistributor);
    }

    setLoading(false);
  }, [data]);

  const refresh = () => {
    setDistributor(null);
    setLoading(true);
    setReload(new Date());
  };

  useEffect(() => {
    if (distributorId) {
      refresh();
    }
  }, [distributorId]);

  return { distributor: distributor, loading: loading, refresh: refresh };
};

export const useGetDistributors = (includeAccessListData?: boolean) => {
  const {
    user: { walletAddress },
  } = useAuth();
  const { supportedNetworks } = useNetworks();
  const client = useDistributorApiClient();
  const { data: project } = useGetProject();
  const { run, data } = useAsync();
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [moreResults, setMoreResults] = useState<Maybe<any>>(null);
  const [loadingComplete, setLoadingComplete] = useState<any[]>([]);
  const [reload, setReload] = useState<Date | null>(null);

  const PAGE_SIZE: number = 10;

  /**
   * Build API request
   */
  const getClient = (request: any) => {
    let url = 'distributors/search';

    if (request.nextLink) {
      url += '?' + request.nextLink.split('?')[1];
    } else {
      url += `?offset=0&queryLimit=${PAGE_SIZE}`;
    }

    return client(url, {
      method: 'post',
      data: request.searchRequest,
    });
  };

  /**
   * Iterate over each network and execute query
   */
  useEffect(() => {
    if (!reload || project === undefined) {
      return;
    }

    for (let i = 0; i < supportedNetworks.length; i++) {
      const network = supportedNetworks[i];

      /* Setup default query parameters */
      run(
        getClient({
          searchRequest: {
            beneficiary: includeAccessListData
              ? walletAddress.toLowerCase()
              : undefined,
            chainId: network.id,
            projectId: project.id,
          },
        }),
      );
    }
  }, [reload]);

  /**
   * Accumulate data and trigger fetching of additional pages (if available)
   */
  useEffect(() => {
    if (!data) {
      return;
    }

    if (data?.distributors?.length > 0) {
      const formattedDistributors = formatDistributors(data);
      setSearchResults(
        uniqueValues(searchResults.concat(formattedDistributors)),
      );

      if (data.nextLink) {
        setMoreResults(data);
      } else {
        setLoadingComplete(
          loadingComplete.concat([data.searchRequest.chainId]),
        );
      }
    } else {
      setLoadingComplete(loadingComplete.concat([data.searchRequest.chainId]));
    }
  }, [data]);

  /**
   * Fetch additional page of data
   */
  useEffect(() => {
    if (moreResults) {
      run(getClient(moreResults));
    }
  }, [moreResults]);

  const refresh = () => {
    setSearchResults([]);
    setMoreResults(null);
    setLoadingComplete([]);
    setReload(new Date());
  };

  useEffect(() => {
    if (project !== undefined && includeAccessListData && walletAddress) {
      refresh();
    }
  }, [project, walletAddress]);

  return {
    results: searchResults,
    loading: loadingComplete.length !== supportedNetworks.length,
    refresh: refresh,
  };
};

export const formatDistributor = (distributor: any) => {
  const interfaces: any[] = [];
  distributor.contracts?.forEach((contract) => {
    const address = contract.address;
    const interfacesWithAddress = contract.interfaces.map((iFace) => {
      return {
        ...iFace,
        address: address,
      };
    });
    interfaces.push(...interfacesWithAddress);
  });

  const formattedDistributor: any = {
    ...distributor,
    interfaces: interfaces,
  };

  // TODO: rename domain to something else
  const amountObj = distributor.authorization?.data?.find(
    (d) => d.name === 'amount',
  );
  const beneficiaryObj = distributor.authorization?.data?.find(
    (d) => d.name === 'beneficiary',
  );
  const indexObj = distributor.authorization?.data?.find(
    (d) => d.name === 'index',
  );
  const domainObj = distributor.authorization?.data?.find(
    (d) => d.name === 'domain',
  );

  formattedDistributor.proof =
    distributor.authorization?.proof || distributor.proof;
  formattedDistributor.proofAmount = amountObj?.value;
  formattedDistributor.proofBeneficiaryAddress =
    beneficiaryObj?.value.toLowerCase();
  formattedDistributor.proofIndex = indexObj?.value;
  formattedDistributor.domain = domainObj?.value;

  return formattedDistributor;
};

const formatDistributors = (data: any) => {
  return data?.distributors.map((distributor) => {
    return formatDistributor(distributor);
  });
};

export const useGetOraclePrice = () => {
  const client = useDistributorApiClient();
  return useMutation({
    mutationFn: (data: any) => {
      return client(
        `oracle/price?networkId=${data.networkId}&oracleAddress=${data.oracleAddress}`,
        {
          method: 'get',
        },
      );
    },
  });
};
