import { FC, ReactNode, useState } from 'react';
import { LayoutContext } from './layout-context';

interface LayoutProviderProps {
  children?: ReactNode;
}

export const LayoutProvider: FC<LayoutProviderProps> = ({ children }) => {
  const [shouldHideSidebar, setShouldHideSidebar] = useState(false);
  const [className, setClassName] = useState<Maybe<string>>(null);

  return (
    <LayoutContext.Provider
      value={{
        shouldHideSidebar,
        setShouldHideSidebar,
        className,
        setClassName,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
