import { useWebApiClient } from '@apiClients';
import { useQuery } from '@tanstack/react-query';

export const useGetSaleByContractAddress = (address: EvmAddress) => {
  const fetchWithClient = useWebApiClient();

  return useQuery<any>({
    queryKey: ['sale', address],
    queryFn: async () => {
      const saleRaw = await fetchWithClient(`sales/${address}`);

      const sale: any = {
        id: saleRaw.id,
        eventId: saleRaw.eventId,
        chainId: saleRaw.chainId,
        merkleProofUri: saleRaw.merkleProofUri,
        configUri: saleRaw.configUri,
        platformFeeInCents: saleRaw.platformFeeInCents,
        token: {
          name: saleRaw.tokenName,
          symbol: saleRaw.tokenSymbol,
          decimals: saleRaw.decimals,
          price: saleRaw.tokenPrice,
        },
        configuration: saleRaw.configuration,
        subgraphDetails: saleRaw.subgraphDetails,
      };

      return sale;
    },
  });
};
