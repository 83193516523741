import { ReactNode, createContext, useContext } from 'react';

export interface Toast {
  title: string;
  description: ReactNode | string;
  type: string;
  backgroundColor: string;
  icon: ReactNode | string;
  dismissTime: number;
  position: string;
  autoDelete: boolean;
  permanent?: boolean;
}

export interface ToastContextProps {
  toast: Toast;
  setToast(toast: Maybe<Toast>): void;
  showSuccessToast: Function;
  showInfoToast: Function;
  showErrorToast: Function;
  showWarningToast: Function;
  clearToast: Function;
}

export const ToastContext = createContext<Maybe<ToastContextProps>>(null);
ToastContext.displayName = 'ToastContext';

export const useToast = (): ToastContextProps => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error(
      'ToastProvider context is undefined, please verify you are calling useToast() as child of a <ToastProvider> component.',
    );
  }

  return context;
};
