import { Button, Text } from '@components';
import { useModal } from '@contexts';
import { AiFillEye } from 'react-icons/ai';
import { useWizard } from 'react-use-wizard';

interface PreviewPageButtonProps {
  previewContent: Function;
  disabled?: boolean;
  label?: string;
  fill?: 'outline' | 'solid';
  onContinue?: any;
}

export const PreviewPageButton: React.FC<PreviewPageButtonProps> = ({
  previewContent,
  disabled = false,
  label = 'Preview Page',
  fill = 'outline',
  onContinue = null,
}) => {
  const { showModal } = useModal();
  const { nextStep } = useWizard();

  return (
    <Button
      fill={fill}
      onClick={() =>
        showModal({
          content: previewContent(onContinue || nextStep),
          className: 'w-10/12',
        })
      }
      disabled={disabled}
    >
      <>
        <AiFillEye size={24} />
        <Text>{label}</Text>
      </>
    </Button>
  );
};
