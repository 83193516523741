import { Card, RadioInput } from '@components';
import { AccountDetails } from '@customTypes';
import { INVESTOR_TYPE } from '@enums';
import { FC } from 'react';

type ApplicationTypePanelProps = {
  accountDetails: AccountDetails;
  onChange: (AccountDetails) => void;
};

export const ApplicationTypePanel: FC<ApplicationTypePanelProps> = ({
  accountDetails,
  onChange,
}) => {
  return (
    <div id='application-type'>
      <Card title='Application Type'>
        <p className='pb-4'>
          Are you registering for yourself or on behalf of an entity?
        </p>

        <div className='pb-4'>
          <RadioInput
            name='applicationType'
            checked={accountDetails?.investorType === INVESTOR_TYPE.MYSELF}
            onClick={() =>
              onChange({
                ...accountDetails,
                investorType: INVESTOR_TYPE.MYSELF,
              })
            }
          >
            I am registering an individual account
          </RadioInput>
          <RadioInput
            name='applicationType'
            checked={accountDetails?.investorType === INVESTOR_TYPE.ENTITY}
            onClick={() =>
              onChange({
                ...accountDetails,
                investorType: INVESTOR_TYPE.ENTITY,
              })
            }
          >
            I am an authorized signer registering on behalf of an entity
          </RadioInput>
        </div>
      </Card>
    </div>
  );
};
