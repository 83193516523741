import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  EditIcon,
  Section,
  Stacked,
  Text,
  TextLink,
} from '@components';
import { useNetworks } from '@contexts';
import { getResourceUri, getValidIPFSHash } from '@utils';
import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface EventContentPageProps {
  context: any;
  className?: string;
}

export const EventContentPage = ({
  context,
  className = '',
}: EventContentPageProps) => {
  const navigate = useNavigate();
  const { getNetworkDetails } = useNetworks();
  const [isOpen, setIsOpen] = useState(!Object.keys(context).includes('id'));

  const networkDetails = getNetworkDetails(context?.networkId);
  const eventLogo =
    context.contentHeaderImage ||
    context?.project?.theme?.logoSmall ||
    networkDetails?.logoUri;
  const logo = getValidIPFSHash(eventLogo)
    ? getResourceUri(eventLogo)
    : eventLogo;

  const key = 'event-content';

  return (
    <Card className={classNames(className)}>
      <CardHeader>
        <CardTitle
          open={isOpen}
          onOpen={setIsOpen}
          data-testid={`${key}-title`}
        >
          Event Content
        </CardTitle>
        {context.id ? (
          <TextLink
            width={'fit'}
            onClick={() => {
              navigate(
                `/admin/project/${context.project.id}/event/${context.id}/content`,
              );
            }}
          >
            <EditIcon />
            <Text>Edit Details</Text>
          </TextLink>
        ) : null}
      </CardHeader>

      <CardBody open={isOpen}>
        <Stacked>
          <Section gap={5} place={'start'}>
            <Text data-testid={`${key}-help-text`}>
              This is the end user facing portion of the event. Here are your
              basic details, please check the preview for how the page will be
              laid out with full content. If you would like to edit the details
              use the link to edit details above.
            </Text>
          </Section>
        </Stacked>
      </CardBody>
    </Card>
  );
};
