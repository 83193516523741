import { Col, Row } from '@components';
import classNames from 'classnames';
import { IoCheckmarkSharp } from 'react-icons/io5';
import './modal-step.css';

interface ModalStepProps {
  activeStep: number;
  steps: string[];
  dividerWidth?: string;
}

export const ModalStep = ({
  activeStep,
  steps,
  dividerWidth,
}: ModalStepProps) => {
  const _dividerWidth = dividerWidth || 'w-9';

  const onStepClass = (step: number) => {
    return activeStep >= step ? 'active' : '';
  };

  return (
    <Col gap={2}>
      <Row place='center' textAlign='center'>
        <div className={classNames('modal-step-number', onStepClass(1))}>
          <span className={classNames('modal-step', onStepClass(1))}>
            {activeStep === 1 ? '1' : <IoCheckmarkSharp size={18} />}
          </span>
        </div>
        <div className='horizontal-divider w-[80px] self-center mx-6'></div>
        <div className={classNames('modal-step-number', onStepClass(2))}>
          <span className={classNames('modal-step', onStepClass(2))}>2</span>
        </div>
      </Row>
      <Row place='center' textAlign='center'>
        <span className={classNames('modal-step', onStepClass(1))}>
          {steps[0]}
        </span>
        <div className={classNames('self-center mx-6', _dividerWidth)}></div>
        <span className={classNames('modal-step', onStepClass(2))}>
          {steps[1]}
        </span>
      </Row>
    </Col>
  );
};
