import { useWebApiPublicClient } from '@apiClients';
import { useQuery } from '@tanstack/react-query';
import { Blockchain } from 'tokensoft-shared-types';

export const useGetChains = () => {
  const fetchWithClient = useWebApiPublicClient();

  return useQuery<Blockchain[]>({
    queryKey: ['chains'],
    queryFn: async () => {
      return fetchWithClient(`chains`);
    },
  });
};
