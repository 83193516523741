import {
  Button,
  Col,
  Row,
  SweepIcon,
  SweepSaleTokenModal,
  TableData,
  Text,
} from '@components';
import { useModal, useNetworks } from '@contexts';
import { PaymentMethod } from '@customTypes';
import { useExchangeRate } from '@hooks';
import { convertBaseUnitsToDecimal, gt } from '@utils';

export const PaymentMethodBalance = ({
  context,
  paymentMethod,
}: {
  context: any;
  paymentMethod: PaymentMethod;
}) => {
  const exchangeRate = useExchangeRate(context.networkId, paymentMethod);
  const { showModal, closeModal } = useModal();
  const { getNetworkDetails } = useNetworks();
  const {
    symbol: nativeSymbol,
    name: networkName,
    logoUri: networkLogo,
  } = getNetworkDetails(context.networkId);
  // let nativeTokenPerSaleToken = div(context.price, exchangeRate, null, 6);

  const key = 'payment-method-balance';

  const openModal = (paymentMethod) => {
    showModal({
      content: (
        <SweepSaleTokenModal sale={context} paymentMethod={paymentMethod} />
      ),
    });
  };

  return (
    <Col gap={3} width={'1/4'}>
      <Row yalign={'center'} gap={2}>
        {
          <Text className='font-bold text-black'>{`${
            paymentMethod.native ? nativeSymbol : paymentMethod.symbol
          } Balance`}</Text>
        }
      </Row>
      {paymentMethod.balance && (
        <TableData data-testid={`${key}-total-data`}>
          {`${convertBaseUnitsToDecimal(
            paymentMethod.balance,
            paymentMethod.decimals,
            6,
          )} ${!paymentMethod.native ? paymentMethod.symbol : nativeSymbol}`}
        </TableData>
      )}
      {paymentMethod.balance && (
        <Button
          fill='outline'
          size={'sm'}
          className={'w-fit'}
          disabled={gt(paymentMethod.balance, 0) ? false : true}
          onClick={() => openModal(paymentMethod)}
        >
          <SweepIcon />
          Sweep Funds
        </Button>
      )}
    </Col>
  );
};
