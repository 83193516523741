import { Stacked } from '@components';
import { EventDocument } from 'tokensoft-shared-types';
import { DocumentReviewItem } from './document-review-item';

interface EventDocumentsListProps {
  documents: EventDocument[];
  checkedState: boolean[];
  onCheckboxChange: (
    index: number,
    changed: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  isReviewing?: boolean;
}

export const EventDocumentsList: React.FC<EventDocumentsListProps> = ({
  documents,
  checkedState,
  onCheckboxChange,
  isReviewing = false,
}) => {
  return (
    <Stacked gap={2.5}>
      {documents.map((document, i) => (
        <DocumentReviewItem
          key={i}
          document={document}
          requiresSignature={document.requiresSignature}
          checked={checkedState[i]}
          onCheckboxChange={(changed) => onCheckboxChange(i, changed)}
          isReviewing={isReviewing}
        />
      ))}
    </Stacked>
  );
};
