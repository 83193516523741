import { useGetMySignedDocuments } from '@apiServices';
import { Card, CardTitle, LoadingIndicator } from '@components';
import { EventUserDocumentVersion } from 'tokensoft-shared-types';

export const MySignedDocuments = () => {
  const { data: documents, isLoading, error } = useGetMySignedDocuments();
  return (
    <Card
      className={`no-padding ${!isLoading && error === null && documents?.length === 0 ? 'hidden' : ''}`}
      id='signed-documents-card'
    >
      <CardTitle className='card-header'>Signed Documents</CardTitle>
      {isLoading ? (
        <div className='mb-12'>
          <LoadingIndicator />
        </div>
      ) : error ? (
        <div className='p-4 text-red-500'>
          An error occurred while fetching your signed documents.
        </div>
      ) : documents && documents.length > 0 ? (
        <div className='px-4 pb-6'>
          <ul role='list'>
            {documents.map((doc: EventUserDocumentVersion) => (
              <li key={doc.id} className='px-4 py-2'>
                <a
                  href={doc.uri || '#'}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-blue-500 underline'
                >
                  {doc.eventName}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className='p-4'>You have no signed documents.</div>
      )}
    </Card>
  );
};
