import { EventTimeline } from 'tokensoft-shared-types';
import { TimelineItem } from './timeline-item';

interface TimelineProps {
  timeline: EventTimeline;
}

export const Timeline = ({ timeline }: TimelineProps) => {
  const timelineItems: { date: Date; title: string }[] = [];

  if (timeline.registrationStartTime) {
    timelineItems.push({
      date: timeline.registrationStartTime,
      title: 'Registration Start',
    });
  }

  if (timeline.registrationEndTime) {
    timelineItems.push({
      date: timeline.registrationEndTime,
      title: 'Registration End',
    });
  }

  if (timeline.startTime) {
    timelineItems.push({ date: timeline.startTime, title: 'Event Start' });
  }

  if (timeline.startTime && timeline.endTime) {
    timelineItems.push({ date: timeline.endTime, title: 'Event End' });
  }

  timelineItems.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  return (
    <div>
      <div className='font-bold text-black mb-2 text-xl'>Event Timeline</div>
      <div className='flex flex-col items-center relative px-3'>
        {timelineItems.map((item, index) => (
          <TimelineItem key={index} date={item.date} title={item.title} />
        ))}
      </div>
    </div>
  );
};
