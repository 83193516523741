import { createContext, useContext } from 'react';

interface EventContextProps {
  event: any;
  loading: boolean;
  refresh: Function;
  hasSetEventDetails: boolean;
  isOnChainEvent: boolean;
}

export const EventContext = createContext<Maybe<EventContextProps>>(null);
EventContext.displayName = 'EventContext';

export const useEvent = (): EventContextProps => {
  const context = useContext(EventContext);

  if (!context) {
    throw new Error('useEvent must be used within an EventProvider');
  }

  return context;
};
