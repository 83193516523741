export const RegistrationIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
    >
      <rect width='50' height='50' rx='5' fill='#E5E7EB' />
      <path
        d='M31 19H30.3334V17.6666C30.3334 17.2987 30.0347 17 29.6666 17H29C28.632 17 28.3334 17.2987 28.3334 17.6666V19H21.6666V17.6666C21.6666 17.2987 21.368 17 21 17H20.3334C19.9653 17 19.6666 17.2987 19.6666 17.6666V19H19C17.8973 19 17 19.8973 17 21V31C17 32.1027 17.8973 33 19 33H31C32.1027 33 33 32.1027 33 31V21C33 19.8973 32.1027 19 31 19ZM31.6666 31C31.6666 31.3673 31.3673 31.6666 31 31.6666H19C18.6327 31.6666 18.3334 31.3673 18.3334 31V23.6934H31.6666V31Z'
        fill='#0350D7'
      />
    </svg>
  );
};
