import {
  Col,
  FormLabel,
  HelpPopover,
  InfoIcon,
  InputGroup,
  MultiSelect,
  RadioInput,
  Row,
  Section,
  SectionTitle,
  Stacked,
  Stretched,
  Text,
} from '@components';
import {
  DEFAULT_BASE_CURRENCY,
  DEFAULT_BASE_CURRENCY_DECIMALS,
} from '@constants';
import { useAccount } from '@contexts';
import { eq, toBaseUnits, validateOnChainConfig } from '@utils';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

interface SaleSetupProps {
  context: any;
  setContext: Function;
}

export const SaleSetup: React.FC<SaleSetupProps> = ({
  context,
  setContext,
}) => {
  const { account } = useAccount();
  const [tokenName, setTokenName] = useState(context?.tokenName || '');
  const [tokenSymbol, setTokenSymbol] = useState(context?.tokenSymbol || '');
  const [price, setPrice] = useState(context?.price || '');
  const [decimals, setDecimals] = useState(context?.decimals || '');
  const [recipient, setRecipient] = useState(context?.recipient || '');
  const [owner, setOwner] = useState(context?.owner || '');
  const [maxRaise, setMaxRaise] = useState(context?.maxRaise || '');
  const [minPurchase, setMinPurchase] = useState(context?.minPurchase || '');
  const [userLimit, setUserLimit] = useState(context?.userLimit || '');
  const [fairQueueEnabled, setFairQueueEnabled] = useState(
    context?.fairQueueEnabled || false,
  );
  const [maxQueueIntervalLength, setMaxQueueIntervalLength] = useState(
    context?.maxQueueIntervalLength || 0,
  );
  const [maxQueueIntervalUnits, setMaxQueueIntervalUnits] = useState(
    context?.maxQueueIntervalUnits || 'hour',
  );

  const supportedMaxQueueIntervalUnits = [
    { value: 'second', label: 'Seconds' },
    { value: 'minute', label: 'Minutes' },
    { value: 'hour', label: 'Hours' },
  ];

  const baseCurrencySymbol =
    context?.nativeBaseCurrency?.symbol === DEFAULT_BASE_CURRENCY
      ? '$'
      : context?.nativeBaseCurrency?.symbol;
  let maxQueueTimeSeconds = 0;

  if (maxQueueIntervalUnits === 'second') {
    maxQueueTimeSeconds = maxQueueIntervalLength;
  } else if (maxQueueIntervalUnits === 'minute') {
    maxQueueTimeSeconds = maxQueueIntervalLength * 60;
  } else if (maxQueueIntervalUnits === 'hour') {
    maxQueueTimeSeconds = maxQueueIntervalLength * 3600;
  }

  const { validMaxQueueTime, maxQueueTimeError } = validateOnChainConfig(
    {
      startTime: context?.startTime,
      endTime: context?.endTime,
      maxQueueTime: maxQueueTimeSeconds,
      purchaseMinimum: minPurchase,
    },
    account,
  );

  const now = dayjs();
  const isSaleOpened = context?.startTime
    ? now.isAfter(dayjs.unix(parseInt(context.startTime)))
    : false;

  const {
    validRecipient,
    recipientError,
    validOwner,
    ownerError,
    priceError,
    validSaleMaximum,
    saleMaximumError,
    validUserMaximum,
    userMaximumError,
    validPurchaseMinimum,
  } = validateOnChainConfig(
    {
      recipient: recipient,
      owner: owner,
      price: price,
      saleMaximum: maxRaise,
      userMaximum: userLimit,
      purchaseMinimum: minPurchase,
    },
    account,
  );

  const formInvalid =
    !tokenName ||
    !tokenSymbol ||
    !price ||
    !decimals ||
    !validRecipient ||
    !validOwner ||
    !validSaleMaximum ||
    !validPurchaseMinimum ||
    !validUserMaximum ||
    (fairQueueEnabled && eq(maxQueueIntervalLength, 0)) ||
    !validMaxQueueTime;

  useEffect(() => {
    setContext({
      ...context,
      tokenName: tokenName,
      tokenSymbol: tokenSymbol,
      price: price,
      decimals: decimals,
      recipient: recipient,
      owner: owner,
      maxRaise: maxRaise,
      minPurchase: minPurchase,
      userLimit: userLimit,
      priceBaseUnits: toBaseUnits(price, context?.nativeBaseCurrency?.decimals),
      maxRaiseBaseUnits: toBaseUnits(
        maxRaise,
        context?.nativeBaseCurrency?.decimals,
      ),
      minPurchaseBaseUnits: toBaseUnits(
        minPurchase,
        context?.nativeBaseCurrency?.decimals,
      ),
      userLimitBaseUnits: toBaseUnits(
        userLimit,
        context?.nativeBaseCurrency?.decimals,
      ),
      maxQueueIntervalLength: maxQueueIntervalLength,
      maxQueueIntervalUnits: maxQueueIntervalUnits,
      fairQueueEnabled: fairQueueEnabled,
      setupFormValid: !formInvalid,
    });
  }, [
    formInvalid,
    tokenName,
    tokenSymbol,
    price,
    decimals,
    recipient,
    owner,
    maxRaise,
    userLimit,
    minPurchase,
    maxQueueIntervalLength,
    maxQueueIntervalUnits,
    fairQueueEnabled,
  ]);

  const currencyDecimalScale =
    context?.nativeBaseCurrency?.decimals || DEFAULT_BASE_CURRENCY_DECIMALS;

  function getPurchaseError(validPurchaseMinimum: boolean, minPurchase) {
    if (!validPurchaseMinimum) {
      return 'Minimum purchase must be less than max purchase';
    }
    if (minPurchase < 1) {
      const baseCurrencySymbol =
        context?.nativeBaseCurrency?.symbol === DEFAULT_BASE_CURRENCY
          ? '$'
          : context?.nativeBaseCurrency?.symbol;
      // put dollar sign before for USD and after for other currencies
      const priceString =
        baseCurrencySymbol === '$'
          ? `${baseCurrencySymbol}10`
          : `10${baseCurrencySymbol}`;
      return `Minimum purchase amount must be at least ${priceString}`;
    }
    return null; // No error
  }

  return (
    <Stacked data-testid='distributor-token-selection'>
      <Section place={'start-center'}>
        <SectionTitle>Sale Setup</SectionTitle>
        <Stretched xgap={5} ygap={0}>
          <InputGroup
            label='Token Name'
            name={'tokenName'}
            placeholder={'Enter Name'}
            required={true}
            value={tokenName}
            maxLength={100}
            readonly={context.saleId}
            onChange={(changed) => {
              setTokenName(changed.target.value);
            }}
          />

          <InputGroup
            label='Token Symbol'
            name={'tokenSymbol'}
            placeholder={'Enter Symbol'}
            maxLength={10}
            required={true}
            value={tokenSymbol}
            readonly={context.saleId}
            onChange={(changed) => {
              setTokenSymbol(changed.target.value);
            }}
          />
        </Stretched>

        <Stretched xgap={5} ygap={0}>
          <InputGroup
            label={`Price (${baseCurrencySymbol})`}
            type='number'
            name={'price'}
            required={true}
            placeholder='1'
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={currencyDecimalScale}
            value={price}
            readonly={context.saleId}
            error={priceError}
            onChange={({ value }) => {
              setPrice(value);
            }}
          />

          <InputGroup
            type='number'
            label='Token Decimals'
            name={'decimals'}
            required={true}
            placeholder='Enter Decimals'
            allowNegative={false}
            decimalScale={0}
            value={decimals}
            readonly={context.saleId}
            onChange={({ value }) => {
              setDecimals(value);
            }}
            isAllowed={({ floatValue }) =>
              floatValue ? floatValue <= 77 : true
            }
          />
        </Stretched>

        <Row>
          <InputGroup
            label='Owner'
            name={'owner'}
            placeholder={'Enter Address'}
            required={true}
            value={owner}
            readonly={context.saleId}
            onChange={(changed) => {
              setOwner(changed.target.value);
            }}
            valid={validOwner}
            error={ownerError}
          />
        </Row>

        <Row>
          <InputGroup
            label='Payment Recipient'
            name={'recipient'}
            placeholder={'Enter Address'}
            required={true}
            value={recipient}
            readonly={context.saleId}
            onChange={(changed) => {
              setRecipient(changed.target.value);
            }}
            valid={validRecipient}
            error={recipientError}
          />
        </Row>

        <Stretched xgap={5} ygap={0}>
          <InputGroup
            label={`Max Total Raise (${baseCurrencySymbol})`}
            labelSuffix={
              <HelpPopover
                className='md'
                button={
                  <div className='cursor-pointer'>
                    <InfoIcon />
                  </div>
                }
              >
                <Text textAlign={'center'}>
                  Sale will close if total purchases reach this limit.
                </Text>
              </HelpPopover>
            }
            type='number'
            name={'maxRaise'}
            required={true}
            placeholder='0'
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={currencyDecimalScale}
            value={maxRaise}
            readonly={context.saleId && isSaleOpened}
            onChange={({ value }) => {
              setMaxRaise(value);
            }}
            valid={validSaleMaximum}
            error={saleMaximumError}
          />

          <InputGroup
            label={`Min Purchase (${baseCurrencySymbol})`}
            labelSuffix={
              <HelpPopover
                className='md'
                button={
                  <div className='cursor-pointer'>
                    <InfoIcon />
                  </div>
                }
              >
                <Text textAlign={'center'}>
                  Each purchase must be at least this amount.
                </Text>
              </HelpPopover>
            }
            type='number'
            name={'minPurchase'}
            required={true}
            placeholder='0'
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={currencyDecimalScale}
            value={minPurchase}
            onChange={({ value }) => {
              setMinPurchase(value);
            }}
            valid={validPurchaseMinimum}
            error={getPurchaseError(validPurchaseMinimum, minPurchase)}
          />

          <InputGroup
            label={`Max Purchase (${baseCurrencySymbol})`}
            labelSuffix={
              <HelpPopover
                className='md'
                button={
                  <div className='cursor-pointer'>
                    <InfoIcon />
                  </div>
                }
              >
                <Text textAlign={'center'}>
                  Each participant cannot purchase more than this amount.
                </Text>
              </HelpPopover>
            }
            type='number'
            name={'userLimit'}
            required={true}
            placeholder='0'
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={currencyDecimalScale}
            value={userLimit}
            onChange={({ value }) => {
              setUserLimit(value);
            }}
            valid={validUserMaximum}
            error={userMaximumError}
          />
        </Stretched>
      </Section>

      <Section gap={5} place={'start-center'}>
        <Col>
          <SectionTitle textAlign={'left'}>Fair Queue</SectionTitle>
          <Text>
            Reduce gas wars and chain congestion by placing each user in a
            randomized queue. Once a user’s queue time elapses, he or she will
            be able to participate if the sale cap has not been reached. Each
            user will be delayed by the max queue time at most.
          </Text>
        </Col>
        <Stretched gap={5}>
          <Col place={'start-top'}>
            <FormLabel>{`Do you expect high demand to participate in this sale? *`}</FormLabel>
            <Col>
              <RadioInput
                className={'flex'}
                name='fairQueueEnabled'
                checked={fairQueueEnabled}
                onClick={() => setFairQueueEnabled(true)}
              >
                <Text>Yes</Text>
              </RadioInput>

              <RadioInput
                className={'flex'}
                name='fairQueueEnabled'
                checked={!fairQueueEnabled}
                onClick={() => setFairQueueEnabled(false)}
              >
                <Text>No</Text>
              </RadioInput>
            </Col>
          </Col>

          <Col place={'start-top'}>
            {fairQueueEnabled ? (
              <>
                <FormLabel>{`Max Queue Time *`}</FormLabel>
                <Row nowrap gap={2} place={'start'}>
                  <InputGroup
                    width={'1/3'}
                    type='number'
                    name='maxQueueIntervalLength'
                    placeholder='0'
                    allowNegative={false}
                    value={maxQueueIntervalLength}
                    required={false}
                    isAllowed={(values) => {
                      const { floatValue } = values;
                      return floatValue < 9999;
                    }}
                    valid={validMaxQueueTime}
                    onChange={({ value }, { event }) => {
                      setMaxQueueIntervalLength(value);
                    }}
                  />

                  <MultiSelect
                    width={'2/3'}
                    name={'maxQueueIntervalUnits'}
                    className={'text-left'}
                    valid={validMaxQueueTime}
                    options={supportedMaxQueueIntervalUnits}
                    value={supportedMaxQueueIntervalUnits.find(
                      (unit) => unit.value == maxQueueIntervalUnits,
                    )}
                    defaultValue={supportedMaxQueueIntervalUnits[0]}
                    onChange={(selected) => {
                      setMaxQueueIntervalUnits(selected.value);
                    }}
                    isMulti={false}
                  />
                </Row>

                {maxQueueTimeError ? (
                  <Text className={'-mt-4 danger text-xs'}>
                    {maxQueueTimeError}
                  </Text>
                ) : null}
              </>
            ) : (
              <>&nbsp;</>
            )}
          </Col>
        </Stretched>
      </Section>
    </Stacked>
  );
};
