import { useWebApiClient } from '@apiClients';
import { useMutation } from '@tanstack/react-query';

export const useRefreshEventUserEligibility = () => {
  const fetchWithClient = useWebApiClient();

  return useMutation({
    mutationFn: async (eventId: number) => {
      const response = await fetchWithClient(`events/${eventId}/eligibility`, {
        method: 'PUT',
      });
      return response;
    },
  });
};
