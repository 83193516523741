import { CardTitle, Col, LegacyPurchasesDownload } from '@components';
import { useProject } from '@contexts';
import { FEATURE } from '@enums';
import { useFeature } from '@hooks';
import { useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { FaGlobe, FaUsers } from 'react-icons/fa';
import { VscPaintcan } from 'react-icons/vsc';
import { useNavigate, useParams } from 'react-router-dom';
import './project-quicklinks.css';

export const ProjectQuicklinks = () => {
  const { view, setView, project } = useProject();
  const { isFeatureEnabled } = useFeature();
  const { id } = useParams();
  const [onDownloadClick, setOnDownloadClick] = useState<Date | null>(null);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);

  const isLegacyPurchaseEnabled = isFeatureEnabled(FEATURE.LEGACY_PURCHASES);
  const isCustomDomainsEnabled = isFeatureEnabled(FEATURE.CUSTOM_DOMAINS);
  const showLegacyPurchases = isLegacyPurchaseEnabled && project?.tenantId;
  const navigate = useNavigate();

  const handleDownloadOnClick = () => {
    setOnDownloadClick(new Date());
  };

  const handleDownloadOnLoading = (loading: boolean) => {
    setLoadingDownload(loading);
  };

  return (
    <>
      <Col className='project-quick-links'>
        <CardTitle className={'card-header'}>Quick Links</CardTitle>
        <div className='px-4 pb-4'>
          <ul className={'metismenu'}>
            <li
              className={view === 'project-members' ? 'mm-active px-4' : 'px-4'}
            >
              <a
                onClick={() => {
                  setView('project-members');
                }}
              >
                <FaUsers />
                <span className={'nav-text !font-semibold'}>Team members</span>
              </a>
            </li>
            {isCustomDomainsEnabled ? (
              <li
                className={
                  view === 'project-domains' ? 'mm-active px-4' : 'px-4'
                }
              >
                <a
                  onClick={() => {
                    setView('project-domains');
                  }}
                >
                  <FaGlobe />
                  <span className={'nav-text !font-semibold'}>Domains</span>
                </a>
              </li>
            ) : null}
            <li
              className={view === 'project-theme' ? 'mm-active px-4' : 'px-4'}
            >
              <a onClick={() => navigate(`/admin/project/${id}/theme`)}>
                <VscPaintcan />
                <span className={'nav-text !font-semibold'}>
                  Theme settings
                </span>
              </a>
            </li>
            {showLegacyPurchases && id && (
              <li
                className={
                  view === 'project-members' ? 'mm-active px-4' : 'px-4'
                }
              >
                <a
                  onClick={handleDownloadOnClick}
                  className={
                    loadingDownload ? 'cursor-not-allowed' : 'cursor-pointer'
                  }
                >
                  <LegacyPurchasesDownload
                    projectId={id}
                    onDownload={onDownloadClick}
                    onLoading={handleDownloadOnLoading}
                    isLoading={loadingDownload}
                  >
                    <AiOutlineDownload />
                    <span className={'nav-text !font-semibold'}>
                      Legeacy Purchase Report
                    </span>
                  </LegacyPurchasesDownload>
                </a>
              </li>
            )}
          </ul>
        </div>
      </Col>
    </>
  );
};
