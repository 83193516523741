import { PageHeader, PageSubtitle, PageTitle } from '@components';
import classNames from 'classnames';
import { FC } from 'react';

interface ErrorProps {
  title?: string;
  message?: string;
  exception?: any;
  className?: any;
  style?: any;
}

export const Error: FC<ErrorProps> = ({
  title,
  message,
  exception,
  className,
  style,
}) => {
  const classes = classNames(className);

  const errorTitle = title || 'Oops! Something went wrong';

  const renderErrorMessage = () => {
    if (message) {
      return (
        <div
          className='md:text-base text-center'
          style={style}
          dangerouslySetInnerHTML={{ __html: message }}
        ></div>
      );
    }

    return (
      <>
        <PageSubtitle style={style}>
          Please check the URL and try again. If the problem persists, visit our{' '}
          <a
            className='underline text-secondary-medium'
            href='https://docs.tokensoft.io/my-tokensoft-account/faq'
            rel='noreferrer'
            target='_blank'
          >
            FAQs
          </a>
          .
        </PageSubtitle>

        {exception?.error && (
          <div
            className='text-xs md:text-base pt-4 text-center'
            style={style}
            dangerouslySetInnerHTML={{ __html: exception.error.toString() }}
          ></div>
        )}

        {exception?.stackTrace &&
          import.meta.env.VITE_PROCESS_ENV !== 'production' && (
            <div
              className='text-xs md:text-base pt-4 text-center'
              style={style}
              dangerouslySetInnerHTML={{ __html: exception.stackTrace }}
            ></div>
          )}
      </>
    );
  };

  return (
    <>
      <PageHeader className={classes}>
        <PageTitle style={style}>{errorTitle}</PageTitle>
        {renderErrorMessage()}
      </PageHeader>
    </>
  );
};
