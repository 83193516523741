import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import {
  DATE_TIME_EDIT_FORMAT,
  DEFAULT_DATE_DISPLAY,
  DEFAULT_DATE_TIME_DISPLAY,
  DEFAULT_DATE_TIME_DISPLAY_WITH_SECONDS,
  DEFAULT_TIMEZONE,
  DEFAULT_TIME_DISPLAY,
  MAX_DATE,
  MIN_AGE_YEARS,
} from '@constants';
import { TIMEZONES } from '@utils';
import 'dayjs/locale/af';
import 'dayjs/locale/am';
import 'dayjs/locale/ar';
import 'dayjs/locale/ar-dz';
import 'dayjs/locale/ar-iq';
import 'dayjs/locale/ar-kw';
import 'dayjs/locale/ar-ly';
import 'dayjs/locale/ar-ma';
import 'dayjs/locale/ar-sa';
import 'dayjs/locale/ar-tn';
import 'dayjs/locale/az';
import 'dayjs/locale/be';
import 'dayjs/locale/bg';
import 'dayjs/locale/bi';
import 'dayjs/locale/bm';
import 'dayjs/locale/bn';
import 'dayjs/locale/bn-bd';
import 'dayjs/locale/bo';
import 'dayjs/locale/br';
import 'dayjs/locale/bs';
import 'dayjs/locale/ca';
import 'dayjs/locale/cs';
import 'dayjs/locale/cv';
import 'dayjs/locale/cy';
import 'dayjs/locale/da';
import 'dayjs/locale/de';
import 'dayjs/locale/de-at';
import 'dayjs/locale/de-ch';
import 'dayjs/locale/dv';
import 'dayjs/locale/el';
import 'dayjs/locale/en';
import 'dayjs/locale/en-au';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en-ie';
import 'dayjs/locale/en-il';
import 'dayjs/locale/en-in';
import 'dayjs/locale/en-nz';
import 'dayjs/locale/en-sg';
import 'dayjs/locale/en-tt';
import 'dayjs/locale/eo';
import 'dayjs/locale/es';
import 'dayjs/locale/es-do';
import 'dayjs/locale/es-mx';
import 'dayjs/locale/es-pr';
import 'dayjs/locale/es-us';
import 'dayjs/locale/et';
import 'dayjs/locale/eu';
import 'dayjs/locale/fa';
import 'dayjs/locale/fi';
import 'dayjs/locale/fo';
import 'dayjs/locale/fr';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/fr-ch';
import 'dayjs/locale/fy';
import 'dayjs/locale/ga';
import 'dayjs/locale/gd';
import 'dayjs/locale/gl';
import 'dayjs/locale/gu';
import 'dayjs/locale/he';
import 'dayjs/locale/hi';
import 'dayjs/locale/hr';
import 'dayjs/locale/ht';
import 'dayjs/locale/hu';
import 'dayjs/locale/hy-am';
import 'dayjs/locale/id';
import 'dayjs/locale/is';
import 'dayjs/locale/it';
import 'dayjs/locale/it-ch';
import 'dayjs/locale/ja';
import 'dayjs/locale/jv';
import 'dayjs/locale/ka';
import 'dayjs/locale/kk';
import 'dayjs/locale/km';
import 'dayjs/locale/kn';
import 'dayjs/locale/ko';
import 'dayjs/locale/ku';
import 'dayjs/locale/ky';
import 'dayjs/locale/lb';
import 'dayjs/locale/lo';
import 'dayjs/locale/lt';
import 'dayjs/locale/lv';
import 'dayjs/locale/me';
import 'dayjs/locale/mi';
import 'dayjs/locale/mk';
import 'dayjs/locale/ml';
import 'dayjs/locale/mn';
import 'dayjs/locale/mr';
import 'dayjs/locale/ms';
import 'dayjs/locale/ms-my';
import 'dayjs/locale/mt';
import 'dayjs/locale/my';
import 'dayjs/locale/nb';
import 'dayjs/locale/ne';
import 'dayjs/locale/nl';
import 'dayjs/locale/nl-be';
import 'dayjs/locale/nn';
import 'dayjs/locale/pa-in';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/rn';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/rw';
import 'dayjs/locale/sd';
import 'dayjs/locale/se';
import 'dayjs/locale/si';
import 'dayjs/locale/sk';
import 'dayjs/locale/sl';
import 'dayjs/locale/sq';
import 'dayjs/locale/sr';
import 'dayjs/locale/ss';
import 'dayjs/locale/sv';
import 'dayjs/locale/sv-fi';
import 'dayjs/locale/sw';
import 'dayjs/locale/ta';
import 'dayjs/locale/te';
import 'dayjs/locale/tet';
import 'dayjs/locale/tg';
import 'dayjs/locale/th';
import 'dayjs/locale/tk';
import 'dayjs/locale/tl-ph';
import 'dayjs/locale/tlh';
import 'dayjs/locale/tr';
import 'dayjs/locale/tzl';
import 'dayjs/locale/tzm';
import 'dayjs/locale/ug-cn';
import 'dayjs/locale/uk';
import 'dayjs/locale/ur';
import 'dayjs/locale/uz';
import 'dayjs/locale/vi';
import 'dayjs/locale/yo';
import 'dayjs/locale/zh';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-hk';
import 'dayjs/locale/zh-tw';

export const LOCALES = [
  'af',
  'am',
  'ar-dz',
  'ar-iq',
  'ar-kw',
  'ar-ly',
  'ar-ma',
  'ar-sa',
  'ar-tn',
  'ar',
  'az',
  'be',
  'bg',
  'bi',
  'bm',
  'bn-bd',
  'bn',
  'bo',
  'br',
  'bs',
  'ca',
  'cs',
  'cv',
  'cy',
  'da',
  'de-at',
  'de-ch',
  'de',
  'dv',
  'el',
  'en-au',
  'en-ca',
  'en-gb',
  'en-ie',
  'en-il',
  'en-in',
  'en-nz',
  'en-sg',
  'en-tt',
  'en',
  'eo',
  'es-do',
  'es-mx',
  'es-pr',
  'es-us',
  'es',
  'et',
  'eu',
  'fa',
  'fi',
  'fo',
  'fr-ca',
  'fr-ch',
  'fr',
  'fy',
  'ga',
  'gd',
  'gl',
  'gu',
  'he',
  'hi',
  'hr',
  'ht',
  'hu',
  'hy-am',
  'id',
  'is',
  'it-ch',
  'it',
  'ja',
  'jv',
  'ka',
  'kk',
  'km',
  'kn',
  'ko',
  'ku',
  'ky',
  'lb',
  'lo',
  'lt',
  'lv',
  'me',
  'mi',
  'mk',
  'ml',
  'mn',
  'mr',
  'ms-my',
  'ms',
  'mt',
  'my',
  'nb',
  'ne',
  'nl-be',
  'nl',
  'nn',
  'pa-in',
  'pl',
  'pt-br',
  'pt',
  'rn',
  'ro',
  'ru',
  'rw',
  'sd',
  'se',
  'si',
  'sk',
  'sl',
  'sq',
  'sr',
  'ss',
  'sv-fi',
  'sv',
  'sw',
  'ta',
  'te',
  'tet',
  'tg',
  'th',
  'tk',
  'tl-ph',
  'tlh',
  'tr',
  'tzl',
  'tzm',
  'ug-cn',
  'uk',
  'ur',
  'uz',
  'vi',
  'yo',
  'zh-cn',
  'zh-hk',
  'zh-tw',
  'zh',
];

// day js extensions
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(relativeTime);

// identify the user's locale and try to load the appropriate dayjs config files
let locale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;
locale = locale.toLowerCase();
if (LOCALES.includes(locale)) {
  dayjs.locale(locale);
}

const YYYY_MM_DD = 'YYYY-MM-DD';

export const getTimezoneAbbreviation = (
  timezone: string = DEFAULT_TIMEZONE,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return TIMEZONES.find((tz) => tz.value === actualTimezone)?.abbr;
};

/********************** FORMATTING DATES FOR BACKEND  ***********************/

/**
 * Format a local date object to a UTC unix timestamp.
 * Used for transforming datetime-local input fields to on-chain timestamps
 */
export const localToUtcUnixDateTime = (
  d: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
  keepLocalTime?: boolean,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  let date: Maybe<dayjs.Dayjs> = null;
  try {
    date = dayjs.tz(d, actualTimezone).utc(keepLocalTime);
  } catch (e) {
    return null;
  }
  return date.isValid() ? date.unix() : null;
};

/**
 * Format a local date object to a UTC date/time string.
 * Used for transforming datetime-local input fields to api formatted strings
 */
export const localToUtcDateTime = (
  d: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  let date: Maybe<dayjs.Dayjs> = null;
  try {
    date = dayjs.tz(d, actualTimezone).utc();
  } catch (e) {
    return '';
  }
  return date.isValid() ? date.toISOString() : '';
};

/**
 * Format a utc date to a UTC unix timestamp.
 */
export const utcToUtcUnixDateTime = (
  d: string | Date | dayjs.Dayjs,
  format = DEFAULT_DATE_DISPLAY,
) => {
  let date: Maybe<dayjs.Dayjs> = null;
  try {
    date = dayjs.tz(d, format, DEFAULT_TIMEZONE);
  } catch (e) {
    return null;
  }
  return date.isValid() ? date.unix() : null;
};

/********************** FORMATTING DATES FOR DISPLAY  ***********************/

/**
 * Format a UTC date object so that it can be used in a datetime-local input field.
 * Used for transforming api formatted strings to datetime-local input formatted strings.
 */
export const utcToEditableDateTime = (
  d: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  let date: Maybe<dayjs.Dayjs> = null;
  try {
    date = dayjs.utc(d).tz(actualTimezone);
  } catch (e) {
    return '';
  }
  return date.isValid() ? date.format(DATE_TIME_EDIT_FORMAT) : '';
};

/**
 * Format a UTC date object as a local time string.
 * Used to display api formatted strings to local values.
 */
export const utcToLocalTime = (
  d: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
  format: string = DEFAULT_TIME_DISPLAY,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  let date: Maybe<dayjs.Dayjs> = null;
  try {
    date = dayjs.utc(d).tz(actualTimezone);
  } catch (e) {
    return '-';
  }
  return date.isValid()
    ? date.format(format) + ' ' + getTimezoneAbbreviation(actualTimezone)
    : '-';
};

/**
 * Format a UTC date object as a local date string
 * Used to display api formatted strings to local values.
 */
export const utcToLocalDate = (
  d: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
  format: string = DEFAULT_DATE_DISPLAY,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  let date: Maybe<dayjs.Dayjs> = null;
  try {
    date = dayjs.utc(d).tz(actualTimezone);
  } catch (e) {
    return '-';
  }
  return date.isValid() ? date.format(format) : '-';
};

export const utcToRelativeLocalDateTimeToDate = (
  d: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
  format: string = DEFAULT_DATE_DISPLAY,
) => {
  let date: Maybe<string> = null;
  try {
    date = dayjs().to(dayjs.utc(d));
  } catch (e) {
    return '-';
  }
  return date ? date : '-';
};

export const utcToRelativeLocalDateTimeFromDate = (
  d: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
  format: string = DEFAULT_DATE_DISPLAY,
) => {
  let date: Maybe<string> = null;
  try {
    date = dayjs().from(dayjs.utc(d));
  } catch (e) {
    return '-';
  }
  return date ? date : '-';
};

/**
 * Format a UTC date object as a local date/time string
 * Used to display api formatted strings to local values.
 */
export const utcToLocalDateTime = (
  d: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
  format: string = DEFAULT_DATE_TIME_DISPLAY,
  showTimezone = true,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  let date: Maybe<dayjs.Dayjs> = null;
  try {
    date = dayjs.utc(d).tz(actualTimezone);
  } catch (e) {
    return '-';
  }
  
  return date.isValid()
    ? showTimezone
      ? date.format(format) + ' ' + getTimezoneAbbreviation(actualTimezone)
      : date.format(format)
    : '-';
};

export const unixToLocalDateTime = (
  date: string | number,
  timezone?: string,
): string => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return utcToLocalDateTime(dayjs.unix(Number(date)), actualTimezone);
};

export const unixToLocalDate = (
  date: string | number,
  timezone?: string,
  format = DEFAULT_DATE_DISPLAY,
): string => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return utcToLocalDate(dayjs.unix(Number(date)), actualTimezone, format);
};

export const utcUnixToUtcDate = (
  d: string | number,
  format = DEFAULT_DATE_DISPLAY,
): Maybe<string> => {
  let date: Maybe<dayjs.Dayjs> = null;
  try {
    date = dayjs.unix(Number(d)).utc();
  } catch (e) {
    return null;
  }
  return date.isValid() ? date.format(format) : null;
};

export const unixToUTCString = (d: string | number) => {
  return dayjs.unix(Number(d)).utc().toISOString();
};

export const unixToEditableDateTime = (
  d: string | number,
  timezone?: string,
): string => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return utcToEditableDateTime(dayjs.unix(Number(d)), actualTimezone);
};

export const editableToLocalDateTime = (
  d: string | number,
  timezone = DEFAULT_TIMEZONE,
  format = DEFAULT_DATE_TIME_DISPLAY,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  let date: Maybe<dayjs.Dayjs> = null;
  try {
    date = dayjs(d);
  } catch (e) {
    return '-';
  }
  return date.isValid()
    ? date.format(format) + ' ' + getTimezoneAbbreviation(actualTimezone)
    : '-';
};

export const getDurationString = (delay) => {
  const remaining = dayjs.duration(delay.delayRemaining, 'second');
  let durationStrings: string[] = [];

  const years = remaining.years();
  years && durationStrings.push(`${years} year${years > 1 ? 's' : ''}`);

  const months = remaining.months();
  months && durationStrings.push(`${months} month${months > 1 ? 's' : ''}`);

  const days = remaining.days();
  days && durationStrings.push(`${days} day${days > 1 ? 's' : ''}`);

  const hours = remaining.hours();
  hours && durationStrings.push(`${hours} hour${hours > 1 ? 's' : ''}`);

  const minutes = remaining.minutes();
  minutes && durationStrings.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);

  const seconds = remaining.seconds();
  seconds && durationStrings.push(`${seconds} second${seconds > 1 ? 's' : ''}`);

  return durationStrings.splice(0, 3).join(', ');
};

export const getDurationObject = (time) => {
  const remaining = dayjs.duration(time, 'second');

  const years = remaining.years();
  const months = remaining.months();
  const days = remaining.days();
  const totalDays = Math.floor(remaining.asDays());
  const hours = remaining.hours();
  const minutes = remaining.minutes();
  const seconds = remaining.seconds();

  return {
    years,
    months,
    days,
    hours,
    minutes,
    seconds,
    totalDays,
  };
};

export const getLocalDateTimeFromDuration = (
  seconds: number,
  timezone: string = DEFAULT_TIMEZONE,
) => {
  const currentLocalTime = dayjs();
  const updatedLocalTime = currentLocalTime.add(seconds, 'second');
  return updatedLocalTime.format(DEFAULT_DATE_TIME_DISPLAY_WITH_SECONDS);
};

export const getRemainingFromUnix = (unixTime: number) => {
  const now = getUtcNowUnix();
  return now > unixTime ? 0 : unixTime - now;
};

/********************** DATE/TIME VALIDATION HELPER FUNCTIONS ***********************/

/**
 * Create a date/time string for limiting the start date input field.
 * Used by the create/edit sale flow
 */
export const getMinStartDate = (timezone: string = DEFAULT_TIMEZONE) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return utcToEditableDateTime(getUtcNow().add(1, 'day'), actualTimezone);
};

/**
 * Create a date/time string for limiting the end date input field.
 * Used by the create/edit sale flow
 */
export const getMinEndDate = (
  startDate: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return startDate
    ? startDate
    : utcToEditableDateTime(getUtcNow(), actualTimezone);
};

/**
 * Create a date/time string for limiting the start date input field.
 * Used by the create/edit sale flow
 */
export const getMaxStartDate = (
  endDate: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return endDate ? endDate : getMaxEndDate(actualTimezone);
};

/**
 * Create a date/time string for limiting the end date input field.
 * Used by the create/edit sale flow
 */
export const getMaxEndDate = (timezone: string = DEFAULT_TIMEZONE) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return utcToEditableDateTime(dayjs.utc(MAX_DATE), actualTimezone);
};

/**
 * Validate a start date.
 * Used by the create/edit sale flow
 */
export const validateStartDate = (
  start: string | Date | dayjs.Dayjs,
  end: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
): string | null => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  if (!start) {
    return null;
  }

  const maxDate = dayjs.utc(MAX_DATE).tz(actualTimezone);

  if (!isBefore(start, end)) {
    return 'Event must start before it ends';
  } else if (!isBefore(start, maxDate)) {
    return 'Event must start before Jan. 1, 2100';
  }

  return null;
};

/**
 * Validate an end date.
 * Used by the create/edit sale flow
 */
export const validateEndDate = (
  start: string | Date | dayjs.Dayjs,
  end: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
): string | null => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  if (!end) {
    return null;
  }

  const now = getLocalNow(actualTimezone);
  const maxDate = dayjs.utc(MAX_DATE).tz(actualTimezone);

  if (!isBefore(end, maxDate)) {
    return 'Event must end before Jan. 1, 2100';
  } else if (!isAfterOrSame(end, now)) {
    return 'Event cannot end in the past';
  } else if (!isAfterOrSame(end, start)) {
    return 'Event must end after it starts';
  }

  return null;
};

/**
 * Create a date string for limiting date of birth input fields
 */
export const getMaxDob = () => {
  return dayjs().subtract(MIN_AGE_YEARS, 'year').format(YYYY_MM_DD);
};

/**
 * Create a date string for limiting entity formation date input fields
 */
export const getMaxFormationDate = () => {
  return dayjs().format(YYYY_MM_DD);
};

/********************** DAYJS UTILITY FUNCTIONS ***********************/

export const isValidDate = (d: string | Date | dayjs.Dayjs) => {
  return dayjs(d).isValid();
};

// NOTE: This function can possibly adjust the time due to daylight savings boundaries.
// See addToUtcDate to add units to a date without timezone considerations.
export const addToDate = (
  d: string | Date | dayjs.Dayjs,
  length: number,
  units,
) => {
  if (!d) {
    return '';
  }

  let date: Maybe<dayjs.Dayjs> = null;
  try {
    date = dayjs(d);
  } catch (e) {
    return '';
  }
  return date.isValid() ? date.add(length, units) : '';
};

export const addToUtcDate = (
  d: string | Date | dayjs.Dayjs,
  length: number,
  units,
  format = DEFAULT_DATE_DISPLAY,
) => {
  if (!d) {
    return '';
  }

  let date: Maybe<dayjs.Dayjs> = null;
  try {
    date = dayjs(d);
  } catch (e) {
    return '';
  }
  return date.isValid() ? date.add(length, units).format(format) : '';
};

export const addToUtcUnixDateTime = (
  d: string | number,
  length: number,
  units,
) => {
  if (!d) {
    return '';
  }

  let date: Maybe<dayjs.Dayjs> = null;
  try {
    date = dayjs.unix(Number(d)).utc();
  } catch (e) {
    return '';
  }
  return date.isValid() ? date.add(length, units).unix() : '';
};

export const getDifference = (
  d1: string | Date | dayjs.Dayjs,
  d2: string | Date | dayjs.Dayjs,
  units: any = 'day',
  decimals: boolean = false,
) => {
  return dayjs(d2).diff(dayjs(d1), units, decimals);
};

export const subtractFromDate = (
  d: string | Date | dayjs.Dayjs,
  length: number,
  units: any = 'months',
) => {
  return dayjs(d).subtract(length, units);
};

// assumes date is in UTC
export const getDayOfMonth = (d: string | Date | dayjs.Dayjs) => {
  return dayjs.utc(d).get('D');
};

export const isBefore = (
  d: string | Date | dayjs.Dayjs,
  target: string | Date | dayjs.Dayjs,
) => {
  return target ? dayjs(d).isBefore(dayjs(target)) : true;
};

export const isBeforeOrSame = (
  d: string | Date | dayjs.Dayjs,
  target: string | Date | dayjs.Dayjs,
) => {
  return target ? dayjs(d).isSameOrBefore(dayjs(target)) : true;
};

export const isAfter = (
  d: string | Date | dayjs.Dayjs,
  target: string | Date | dayjs.Dayjs,
) => {
  return target ? dayjs(d).isAfter(dayjs(target)) : true;
};

export const isAfterOrSame = (
  d: string | Date | dayjs.Dayjs,
  target: string | Date | dayjs.Dayjs,
) => {
  return target ? dayjs(d).isSameOrAfter(dayjs(target)) : true;
};

export const isSame = (
  d1: string | Date | dayjs.Dayjs,
  d2: string | Date | dayjs.Dayjs,
) => {
  return dayjs(d1).isSame(dayjs(d2));
};

export const getUtcNow = () => {
  return dayjs.utc();
};

export const getLocalNow = (timezone: string = DEFAULT_TIMEZONE) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return getUtcNow().tz(actualTimezone).format(DATE_TIME_EDIT_FORMAT);
};

export const getUtcNowUnix = () => {
  return getUtcNow().unix();
};

export const getYear = (): string => {
  return getUtcNow().format('YYYY');
};

export const isAfterNow = (d: string | Date | dayjs.Dayjs) => {
  return isAfter(dayjs.utc(d), getUtcNow());
};

export const formatUtcDate = (
  d: string | Date | dayjs.Dayjs,
  format: string = DEFAULT_DATE_DISPLAY,
) => {
  let date: Maybe<dayjs.Dayjs> = null;
  try {
    date = dayjs.utc(d);
  } catch (e) {
    return '';
  }
  return date.isValid() ? date.format(format) : '';
};
