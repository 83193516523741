export const InfoAlertIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M8 0C3.58077 0 0 3.58077 0 8C0 12.4192 3.58077 16 8 16C12.4192 16 16 12.4192 16 8C16 3.58077 12.4192 0 8 0ZM8.73077 11.6923H7.26154V6.15H8.73077V11.6923ZM7.99615 5.54615C7.56154 5.54615 7.20769 5.21538 7.20769 4.77692C7.20769 4.33846 7.56538 4.01154 7.99615 4.01154C8.43462 4.01154 8.79231 4.33846 8.79231 4.77692C8.79231 5.21538 8.43462 5.54615 7.99615 5.54615Z'
        fill='#6B7280'
      />
    </svg>
  );
};
