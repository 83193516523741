import { useRerenderOnMsInterval } from '@hooks';
import {
  dateIsInFuture,
  getNumberOfDaysUntilDate,
  getNumberOfHoursUntilDate,
  getNumberOfMinutesUntilDate,
  getNumberOfSecondsUntilDate,
} from '@newUtils';
import { useState } from 'react';
import { CountdownItem } from './countdown-item';

type CountdownTimerProps = {
  date: Date;
  label?: string;
  onReachedZero?: () => void;
};

/**
 * A countdown timer that displays the number of days, hours, minutes, and seconds until a given date.
 * @param date The date to count down to
 * @param label The label to display above the countdown timer (optional)
 * @param onReachedZero A callback function to call when the countdown reaches zero (optional)
 * The onReachedZero callback will only be called once, when the countdown reaches zero.
 * If the date is in the past, the countdown will display 0 days, 0 hours, 0 minutes, and 0 seconds,
 * and the onReachedZero callback will be called immediately.
 */
export const CountdownTimer = ({
  label,
  date,
  onReachedZero,
}: CountdownTimerProps) => {
  /** Whether or not onReachedZero has been called */
  const [calledOnReachedZero, setCalledOnReachedZero] = useState(false);

  const daysRemaining = getNumberOfDaysUntilDate(date);
  const hoursRemaining = getNumberOfHoursUntilDate(date);
  const minutesRemaining = getNumberOfMinutesUntilDate(date);
  const secondsRemaining = getNumberOfSecondsUntilDate(date);

  useRerenderOnMsInterval(1000, () => {
    if (
      !dateIsInFuture(date) &&
      !calledOnReachedZero &&
      onReachedZero !== undefined
    ) {
      onReachedZero();
      setCalledOnReachedZero(true);
    }
  });

  if (daysRemaining > 999) {
    console.warn(
      'CountdownTimer: daysRemaining is greater than 999. This may cause layout issues.',
    );
  }

  return (
    <div
      data-testid='countdown-timer'
      className='flex flex-col justify-center gap-3'
    >
      <div className='font-semibold text-black text-lg'>{label}</div>
      <div className='flex gap-[8px]'>
        <CountdownItem key='days' label='Days' value={daysRemaining} />
        <CountdownItem key='hours' label='Hours' value={hoursRemaining} />
        <CountdownItem key='minutes' label='Minutes' value={minutesRemaining} />
        <CountdownItem key='seconds' label='Seconds' value={secondsRemaining} />
      </div>
    </div>
  );
};
