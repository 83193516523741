import { useUpdateProject } from '@apiServices';
import {
  Button,
  ButtonRow,
  Card,
  Col,
  FormField,
  FormLabel,
  InputGroup,
  LoadingIndicator,
  Row,
  Text,
} from '@components';
import { useProject, useToast } from '@contexts';
import { validateProjectDetailsFormSchema } from '@utils';
import { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { VscLinkExternal } from 'react-icons/vsc';

export const ProjectDetails = () => {
  const { showErrorToast, showSuccessToast } = useToast();
  const { project, loading } = useProject();
  const [edit, setEdit] = useState<boolean>(false);
  const { mutate: update, isPending: updateLoading } = useUpdateProject();

  const [projectUpdates, setProjectUpdates] = useState<Maybe<any>>(null);
  const [formValid, setFormValid] = useState<boolean>(false);

  useEffect(() => {
    if (project && !projectUpdates) {
      handleCancel();
    }
  }, [project]);

  useEffect(() => {
    if (projectUpdates) {
      const valid = validateProjectDetailsFormSchema(projectUpdates);
      setFormValid(valid);
    }
  }, [projectUpdates]);

  const handleProjectNameChange = (changed) => {
    const clonedChanges = { ...projectUpdates };
    clonedChanges.name = changed.target.value;
    setProjectUpdates(clonedChanges);
  };

  const handleProjectTaglineChange = (changed) => {
    const clonedChanges = { ...projectUpdates };
    clonedChanges.tagline = changed.target.value;
    setProjectUpdates(clonedChanges);
  };

  const handleProjectDescriptionChange = (changed) => {
    const clonedChanges = { ...projectUpdates };
    clonedChanges.description = changed.target.value;
    setProjectUpdates(clonedChanges);
  };

  const handleCancel = () => {
    setProjectUpdates({
      ...project,
      seo: { ...project.seo },
      content: { ...project.content },
    });
    setEdit(false);
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleSave = () => {
    update(projectUpdates, {
      onSuccess: () => {
        showSuccessToast({
          description: 'Successfully saved project details.',
        });
        setEdit(false);
      },
      onError: (error) => showErrorToast({ description: error.message }),
    });
  };

  return (
    <>
      <Card className={'h-full'} title='Project Details'>
        {loading ? (
          <div className={'mb-12'}>
            <LoadingIndicator />
          </div>
        ) : (
          <Col yalign={'between'} className={'h-full'}>
            <Col>
              <InputGroup
                label='Project name'
                value={projectUpdates?.name}
                name='name'
                required
                disabled={updateLoading}
                readonly={!edit}
                onChange={handleProjectNameChange}
              />

              <InputGroup
                label='Project tagline'
                value={projectUpdates?.tagline}
                name='tagline'
                required
                disabled={updateLoading}
                readonly={!edit}
                onChange={handleProjectTaglineChange}
              />

              <InputGroup
                type={'textarea'}
                label='Project description'
                value={projectUpdates?.description}
                name='description'
                required
                disabled={updateLoading}
                readonly={!edit}
                onChange={handleProjectDescriptionChange}
              />

              <FormField>
                <FormLabel>Project domains</FormLabel>
                {projectUpdates?.domains?.map((domain, i) => (
                  <Row place='start-center' key={i}>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href={`https://${domain.domainName}`}
                      className='truncate'
                    >
                      <Row nowrap gap={2} place={'center'}>
                        <Text className={'truncate'}>
                          https://{domain.domainName}
                        </Text>
                        <VscLinkExternal size={16} />
                      </Row>
                    </a>
                  </Row>
                ))}
              </FormField>
            </Col>

            <ButtonRow place={'end'}>
              {edit ? (
                <>
                  <Button
                    fill={'outline'}
                    disabled={updateLoading}
                    onClick={handleCancel}
                    className={'w-full md:w-fit'}
                  >
                    <Text>Cancel</Text>
                  </Button>

                  <Button
                    disabled={!formValid || updateLoading}
                    onClick={handleSave}
                    className={'w-full md:w-fit'}
                  >
                    {updateLoading ? (
                      <>
                        <div className='animate-spin'>
                          <AiOutlineLoading3Quarters size={24} />
                        </div>
                        <Text>Saving...</Text>
                      </>
                    ) : (
                      <Text>Save</Text>
                    )}
                  </Button>
                </>
              ) : (
                <Button
                  onClick={handleEdit}
                  className={'w-full md:w-fit'}
                >
                  <Text>Edit</Text>
                </Button>
              )}
            </ButtonRow>
          </Col>
        )}
      </Card>
    </>
  );
};
