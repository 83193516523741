export const PDFIcon = ({ fill = 'var(--primary)' }) => {
  return (
    <svg
      width='15'
      height='18'
      viewBox='0 0 15 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.62896 11.5807C3.62896 11.2817 3.42153 11.1035 3.05555 11.1035C2.9061 11.1035 2.80489 11.1182 2.75195 11.1323V12.0914C2.81464 12.1055 2.89169 12.1102 2.99791 12.1102C3.38801 12.1102 3.62896 11.9132 3.62896 11.5807Z'
        fill={fill}
      />
      <path
        d='M5.89496 11.1135C5.73106 11.1135 5.62515 11.1277 5.5625 11.1423V13.2665C5.62518 13.2812 5.72639 13.2812 5.8179 13.2812C6.48277 13.2859 6.9164 12.9199 6.9164 12.1444C6.92111 11.47 6.52597 11.1135 5.89496 11.1135Z'
        fill={fill}
      />
      <path
        d='M10.1044 0H2.87488C1.81871 0 0.959101 0.860204 0.959101 1.91578V8.99998H0.771985C0.345709 8.99998 0 9.34535 0 9.772V14.4538C0 14.8804 0.345672 15.2257 0.771985 15.2257H0.959101V16.0842C0.959101 17.141 1.81871 18 2.87488 18H12.7393C13.7948 18 14.6545 17.1409 14.6545 16.0842V4.53413L10.1044 0ZM2.02524 10.6169C2.25148 10.5787 2.56949 10.5498 3.01753 10.5498C3.47031 10.5498 3.79302 10.6363 4.00986 10.8099C4.21698 10.9735 4.35676 11.2435 4.35676 11.5613C4.35676 11.879 4.25084 12.1491 4.05813 12.3321C3.80747 12.568 3.43678 12.6739 3.00315 12.6739C2.90665 12.6739 2.82014 12.6692 2.7525 12.6598V13.8207H2.02524V10.6169ZM12.7393 16.8268H2.87488C2.46592 16.8268 2.13291 16.4937 2.13291 16.0842V15.2257H11.3286C11.7549 15.2257 12.1006 14.8804 12.1006 14.4538V9.772C12.1006 9.34535 11.7549 8.99998 11.3286 8.99998H2.13291V1.91578C2.13291 1.50742 2.46596 1.1744 2.87488 1.1744L9.66549 1.16731V3.67736C9.66549 4.41052 10.2604 5.00595 10.9941 5.00595L13.4529 4.9989L13.4806 16.0842C13.4806 16.4937 13.1482 16.8268 12.7393 16.8268ZM4.82571 13.806V10.6169C5.09544 10.574 5.44701 10.5498 5.81804 10.5498C6.43464 10.5498 6.83448 10.6605 7.14778 10.8964C7.48494 11.147 7.69674 11.5466 7.69674 12.1202C7.69674 12.7416 7.4705 13.1705 7.15716 13.4353C6.81529 13.7194 6.2949 13.8542 5.65914 13.8542C5.27842 13.8542 5.00868 13.8301 4.82571 13.806ZM10.0833 11.9179V12.5151H8.91736V13.8207H8.1804V10.574H10.1651V11.1759H8.91736V11.9179H10.0833Z'
        fill={fill}
      />
    </svg>
  );
};
