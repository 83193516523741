import {
  Card,
  Col,
  EventIcon,
  HelpPopover,
  InfoIcon,
  Row,
  Text,
  Title,
} from '@components';
import classNames from 'classnames';

export const EventTypeCard = ({
  type,
  name,
  description,
  helpText,
  className,
  setSelectedEventType,
  id,
}) => {
  const selectEventType = () => {
    setSelectedEventType(type);
  };

  return (
    <Card
      onClick={selectEventType}
      className={classNames('h-fit md:h-full hover-card', className || '')}
      id={id}
    >
      <Col className='h-full' gap={5}>
        <div className={classNames('text-primary-medium')}>
          <EventIcon icon={type} />
        </div>

        <Col>
          <Row nowrap xgap={2.5} place={'start-center'}>
            <Title>{name}</Title>
            {helpText ? (
              <HelpPopover
                className='md'
                button={
                  <div className='cursor-pointer'>
                    <InfoIcon />
                  </div>
                }
              >
                <Text textAlign={'center'}>{helpText}</Text>
              </HelpPopover>
            ) : null}
          </Row>

          <Text>{description}</Text>
        </Col>
      </Col>
    </Card>
  );
};
