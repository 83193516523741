import { useGetTokenInfo } from '@apiServices';
import { HelpText, Section, Stacked, TokenInfoCard } from '@components';
import { useNetworks } from '@contexts';
import {
  convertBaseUnitsToDecimal,
  formatValue,
  getAddressUrl,
  getTruncatedAddress,
} from '@utils';
import { useEffect } from 'react';
import { DistributorFundAmounts } from './distributor-fund-amounts';

interface DistributorFundProps {
  context: any;
  setContext?: Function;
}

export const DistributorFund = ({
  context,
  setContext,
}: DistributorFundProps) => {
  const { getNetworkDetails } = useNetworks();
  const additionalAmountToFund = formatValue(
    convertBaseUnitsToDecimal(
      context.tokenInfo?.additionalAmountToFund,
      context.tokenInfo?.tokenDecimals || 18,
      2,
    ),
    { commas: true },
  );
  const { mutate: getTokenInfo, data: tokenInfoData } = useGetTokenInfo();
  const token = context.tokenInfo?.token;
  const key = 'distributor-fund';

  useEffect(() => {
    if (token) {
      getTokenInfo({ networkId: context.networkId, tokenAddress: token });
    }
  }, [token]);

  const getWalletLink = (address, networkId) => {
    return (
      <a
        target='_blank'
        rel='noreferrer'
        href={getAddressUrl(address, getNetworkDetails(networkId))}
        onClick={(e) => e.stopPropagation()}
      >
        {getTruncatedAddress(address)}
      </a>
    );
  };

  return (
    <Stacked data-testid={`${key}`}>
      <Section>
        <TokenInfoCard
          tokenInfo={tokenInfoData}
          networkId={context.tokenInfo?.chainId}
          showSuccess={false}
        />
      </Section>

      <DistributorFundAmounts context={context}></DistributorFundAmounts>
      <HelpText data-testid={`${key}-help-text`}>
        You can also send {additionalAmountToFund}{' '}
        {context.tokenInfo?.tokenSymbol} to (
        {getWalletLink(context.tokenInfo?.id, context.networkId)}) from another
        wallet. The contract owner{' '}
        {getWalletLink(
          context.tokenInfo?.advanced?.owner?.id,
          context.networkId,
        )}{' '}
        can withdraw tokens later if needed.
      </HelpText>
    </Stacked>
  );
};
