import { Text } from '@components';
import classNames from 'classnames';

type TextProps = React.ComponentProps<typeof Text>;
interface TitleProps extends TextProps {
  className?: string;
  textAlign?: 'left' | 'center' | 'right';
  shrink?: boolean;
  children: React.ReactNode;
}

export const Title: React.FC<TitleProps> = ({
  className,
  textAlign = 'left',
  children,
  ...restProps
}) => {
  return (
    <Text
      textAlign={textAlign}
      className={classNames(
        'text-2xl font-bold high-contrast leading-8',
        className,
      )}
      {...restProps}
    >
      {children}
    </Text>
  );
};
