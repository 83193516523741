import axios, { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';

interface RequestConfig extends AxiosRequestConfig {}

const anonymousAuthApiClient = (
  endpoint: string,
  { data, headers: customHeaders, ...customConfig }: RequestConfig,
) => {
  const config: AxiosRequestConfig<any> = {
    method: data ? 'post' : 'get',
    url: import.meta.env.VITE_API_URL
      ? `${import.meta.env.VITE_API_URL}/auth/api/v1/${endpoint}`
      : `${import.meta.env.VITE_AUTH_API_URL}/${endpoint}`,
    data: data || undefined,
    headers: {
      ...(data ? { 'Content-Type': 'application/json' } : {}),
      ...customHeaders,
    },
    ...customConfig,
    // Only throw errors on 5xx status codes
    validateStatus: (status: number) => status < 500,
  };

  return axios.request(config).then(
    (res) => res.data,
    (error) => {
      return Promise.reject(error);
    },
  );
};

const useAnonymousAuthApiClient = () => {
  return useCallback(
    (endpoint, config = {}) => anonymousAuthApiClient(endpoint, { ...config }),
    [],
  );
};

export { anonymousAuthApiClient, useAnonymousAuthApiClient };
