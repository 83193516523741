import { RouteLink } from '@components';
import { useAccount } from '@contexts';
import { FEATURE } from '@enums';
import { useFeature } from '@hooks';
import { getYear } from '@utils';
import classNames from 'classnames';
import { AiOutlineGlobal } from 'react-icons/ai';
import { FaChartPie, FaUserAlt } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import './sidebar.css';

export const Sidebar = () => {
  const { getFeatureRestriction } = useFeature();
  const { pathname } = useLocation();
  const { account } = useAccount();

  const adminRestriction = getFeatureRestriction(FEATURE.ADMIN);

  const atIndexRoute =
    pathname === '' || pathname === '/' || pathname === '/dashboard';

  return (
    <div className='sidebar deznav' data-testid={'sidebar'}>
      <div className='deznav-scroll flex flex-col justify-between'>
        <ul className='metismenu' id='menu' role={'list'}>
          <li
            role={'listitem'}
            className={classNames(atIndexRoute ? 'mm-active pt-4' : 'pt-4')}
          >
            <RouteLink url={'/dashboard'}>
              <>
                <FaChartPie />
                <span className='nav-text'>Dashboard</span>
              </>
            </RouteLink>
          </li>
          <li
            role={'listitem'}
            className={classNames(
              pathname.startsWith('/account') ? 'mm-active' : '',
            )}
          >
            <RouteLink url={'/account'}>
              <>
                <FaUserAlt />
                <span className='nav-text'>My Profile</span>
              </>
            </RouteLink>
          </li>

          {account?.projects?.length !== undefined &&
            account.projects.length > 1 && (
              <li
                role={'listitem'}
                className={classNames(
                  pathname.startsWith('/admin') ? 'mm-active' : '',
                  adminRestriction ? `${adminRestriction}` : '',
                )}
              >
                <RouteLink url={'/admin'}>
                  <>
                    <AiOutlineGlobal />
                    <span className='nav-text'>Admin</span>
                  </>
                </RouteLink>
              </li>
            )}

          {account?.projects?.length === 1 && (
            <li
              role={'listitem'}
              className={classNames(
                pathname.startsWith('/admin') ? 'mm-active' : '',
                adminRestriction ? `${adminRestriction}` : '',
              )}
            >
              <RouteLink url={'/admin/project/' + account.projects[0].id}>
                <>
                  <AiOutlineGlobal />
                  <span className='nav-text'>Admin</span>
                </>
              </RouteLink>
            </li>
          )}
        </ul>
        <div className='copyright text-center'>
          {pathname.startsWith('/admin') ? (
            <a
              href='https://tokensoft.io/terms'
              target='_blank'
              className='sidebar-font-color'
            >
              Terms of Service
            </a>
          ) : null}
          <p className='sidebar-font-color'>© {getYear()} Tokensoft Inc.</p>
        </div>
      </div>
    </div>
  );
};
