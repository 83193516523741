import { DEPLOY_VESTING_TYPE_OPTIONS } from '@enums';
import {
  useDeployContinuousVestingMerkleDistributorWithFactory,
  useDeployTrancheVestingMerkleDistributorWithFactory,
} from '@hooks';
import { useState } from 'react';

export const useDeployDistributor = () => {
  const [vestingType, setVestingType] = useState();

  const {
    deployedContinuousVestingMerkleDistributorAddress,
    deployContinuousVestingMerkleDistributor,
    isDeployingContinuousVestingMerkleDistributor,
    deployContinuousVestingMerkleDistributorResponse,
    deployContinuousVestingMerkleDistributorReceipt,
    deployContinuousVestingMerkleDistributorError,
  } = useDeployContinuousVestingMerkleDistributorWithFactory();

  const {
    deployedTrancheVestingMerkleDistributorAddress,
    deployTrancheVestingMerkleDistributor,
    isDeployingTrancheVestingMerkleDistributor,
    deployTrancheVestingMerkleDistributorResponse,
    deployTrancheVestingMerkleDistributorReceipt,
    deployTrancheVestingMerkleDistributorError,
  } = useDeployTrancheVestingMerkleDistributorWithFactory();

  const deployDistributor = async (vestingType, args) => {
    setVestingType(vestingType);
    if (
      vestingType === DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS ||
      vestingType === DEPLOY_VESTING_TYPE_OPTIONS.INSTANT
    ) {
      return deployContinuousVestingMerkleDistributor(args);
    } else if (vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY) {
      return deployTrancheVestingMerkleDistributor(args);
    }
  };

  return {
    deployDistributor,
    ...(vestingType === DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS ||
    vestingType === DEPLOY_VESTING_TYPE_OPTIONS.INSTANT
      ? {
          deployedAddress: deployedContinuousVestingMerkleDistributorAddress,
          isPending:
            isDeployingContinuousVestingMerkleDistributor ||
            deployContinuousVestingMerkleDistributorResponse?.isLoading,
          receipt: deployContinuousVestingMerkleDistributorReceipt,
          error: deployContinuousVestingMerkleDistributorError,
        }
      : {
          deployedAddress: deployedTrancheVestingMerkleDistributorAddress,
          isPending:
            isDeployingTrancheVestingMerkleDistributor ||
            deployTrancheVestingMerkleDistributorResponse?.isLoading,
          receipt: deployTrancheVestingMerkleDistributorReceipt,
          error: deployTrancheVestingMerkleDistributorError,
        }),
  };
};
