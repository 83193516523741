import { IconProps } from '../icon-props';

export const Discord = ({ color, className, size = 20 }: IconProps) => {
  return (
    <div className={`${className && className}`}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        stroke={color !== undefined ? color : '#101828'}
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g clipPath='url(#clip0_461_11373)'>
          <path d='M4.6665 7.00002C4.6665 7.15473 4.72796 7.3031 4.83736 7.4125C4.94675 7.5219 5.09513 7.58335 5.24984 7.58335C5.40455 7.58335 5.55292 7.5219 5.66232 7.4125C5.77171 7.3031 5.83317 7.15473 5.83317 7.00002C5.83317 6.84531 5.77171 6.69694 5.66232 6.58754C5.55292 6.47815 5.40455 6.41669 5.24984 6.41669C5.09513 6.41669 4.94675 6.47815 4.83736 6.58754C4.72796 6.69694 4.6665 6.84531 4.6665 7.00002Z' />
          <path d='M8.1665 7.00002C8.1665 7.15473 8.22796 7.3031 8.33736 7.4125C8.44675 7.5219 8.59513 7.58335 8.74984 7.58335C8.90455 7.58335 9.05292 7.5219 9.16232 7.4125C9.27171 7.3031 9.33317 7.15473 9.33317 7.00002C9.33317 6.84531 9.27171 6.69694 9.16232 6.58754C9.05292 6.47815 8.90455 6.41669 8.74984 6.41669C8.59513 6.41669 8.44675 6.47815 8.33736 6.58754C8.22796 6.69694 8.1665 6.84531 8.1665 7.00002Z' />
          <path d='M4.9584 9.91665C4.9584 10.5 4.1674 11.6666 3.88973 11.6666C3.05615 11.6666 2.3159 10.6942 1.94548 9.91665C1.57506 8.94423 1.66781 6.51406 2.77848 3.20831C3.58815 2.61623 4.40131 2.42665 5.25006 2.33331L5.81881 3.45506C6.60111 3.32002 7.40077 3.32002 8.18306 3.45506L8.75006 2.33331C9.62506 2.42665 10.5251 2.61623 11.3751 3.20831C12.5417 6.51406 12.6391 8.94423 12.2501 9.91665C11.861 10.6942 11.0834 11.6666 10.2084 11.6666C9.91673 11.6666 9.04173 10.5 9.04173 9.91665' />
          <path d='M4.0835 9.625C6.12516 10.2083 7.87516 10.2083 9.91683 9.625' />
        </g>
        <defs>
          <clipPath id='clip0_461_11373'>
            <rect width='14' height='14' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
