import { twMerge } from 'tailwind-merge';

interface BadgeProps {
  className?: string;
  text: React.ReactNode;
}

export const Badge = ({ className, text }: BadgeProps) => {
  return (
    <div
      className={twMerge(
        'flex flex-row items-center py-1 px-3 bg-[#EFF3FF] rounded-full',
        className,
      )}
    >
      <span className='whitespace-nowrap text-sm'>{text}</span>
    </div>
  );
};
