import { useEffect, useState } from 'react';

/**
 * Rerenders the component at a given interval in milliseconds.
 * @param interval The interval in milliseconds at which to rerender the component.
 * @param callback An optional callback to run on each rerender.
 * */
export const useRerenderOnMsInterval = (
  interval: number,
  callback?: () => void,
) => {
  const [, setTick] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTick((tick) => tick + 1);
      if (callback) {
        callback();
      }
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [interval]);
};
