import { EVENT_STATUS } from '@enums';
import { getUtcNowUnix, localToUtcUnixDateTime, lte } from '@utils';
import { twMerge } from 'tailwind-merge';

interface EventStatusBadgeProps {
  startTime: ISO8601String;
  status: string;
  className?: string;
}

export const EventStatusBadge = ({
  startTime,
  status,
  className,
}: EventStatusBadgeProps) => {
  return (
    <div
      className={twMerge(
        'flex flex-row flex-wrap justify-start items-center',
        className,
      )}
      data-testid='event-status-badge'
    >
      {status === EVENT_STATUS.CURRENT ? (
        <span className='badge badge-success no-hover'>Live</span>
      ) : status === EVENT_STATUS.UPCOMING &&
        startTime !== null && lte(localToUtcUnixDateTime(startTime) ?? 0, getUtcNowUnix()) ? (
        <span className='badge badge-success no-hover'>Live</span>
      ) : status === EVENT_STATUS.UPCOMING ? (
        <span className='badge badge-light no-hover'>Coming Soon</span>
      ) : status === EVENT_STATUS.COMPLETED ? (
        <span className='badge badge-dark no-hover'>Closed</span>
      ) : null}
    </div>
  );
};
