import { useNetworks, useSale, useTSWagmi } from '@contexts';
import { getFlatPriceSaleABI, mult, toBaseUnits } from '@utils';
import { simulateContract, writeContract } from '@wagmi/core';
import { useState } from 'react';
import { parseEther } from 'viem';
import { useWaitForTransactionReceipt } from 'wagmi';

export const useSendV4Erc20Transaction = (chainId: number) => {
  const { wagmiConfig } = useTSWagmi();
  const [transactionHash, setTransactionHash] = useState<Maybe<any>>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { supportedNetworks } = useNetworks();
  const network = supportedNetworks?.find((n) => n.id === chainId);
  const { sale } = useSale();

  const writeV4 = async (
    chainId,
    paymentMethod,
    toAddress,
    value,
    signature,
    platformFee,
    signatureExpiresAt,
    userPurchaseLimit,
  ) => {
    if (network === undefined) {
      return;
    }

    setSubmitting(true);

    const txValue = toBaseUnits(value, paymentMethod.decimals);
    console.log('preparing purchase', txValue.toString());

    try {
      const salePlatformFeeForOracle = Number(
        mult(sale.platformFeeInCents || 100, Math.pow(10, 6)),
      );

      console.log('Args: ', [
        paymentMethod.address,
        txValue,
        userPurchaseLimit,
        signatureExpiresAt,
        signature,
        network.platformFeeRecipient,
        salePlatformFeeForOracle,
      ]);

      const { request } = await simulateContract(wagmiConfig, {
        address: toAddress,
        abi: getFlatPriceSaleABI('v4.0'),
        functionName: 'buyWithToken',
        chainId,
        args: [
          paymentMethod.address,
          txValue,
          userPurchaseLimit,
          signatureExpiresAt,
          signature,
          network.platformFeeRecipient,
          salePlatformFeeForOracle,
        ],
        value: parseEther(platformFee),
      });

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      console.log('purchase transaction response', writeContractResponse);
      return writeContractResponse;
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    writeV4,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};
