import { useAuth } from '@contexts';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { useCallback } from 'react';

interface RequestConfig extends AxiosRequestConfig {
  token?: string;
  signOut?: Function;
  shouldSignOutOnForbidden?: boolean;
}

const eventApiClient = (
  endpoint: string,
  {
    data,
    token,
    signOut,
    headers: customHeaders,
    shouldSignOutOnForbidden = true,
    ...customConfig
  }: RequestConfig,
) => {
  const method: Method = data ? 'post' : 'get';

  const config: AxiosRequestConfig<any> = {
    method,
    url: import.meta.env.VITE_API_URL
      ? `${import.meta.env.VITE_API_URL}/event/api/v1/${endpoint}`
      : `${import.meta.env.VITE_EVENT_API_URL}/${endpoint}`,
    data: data || undefined,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...(data ? { 'Content-Type': 'application/json' } : {}),
      ...customHeaders,
    },
    ...customConfig,
  };

  return axios.request(config).then(
    (res) => res.data,
    (error) => {
      console.log(error.toJSON());
      const { status: statusCode } = error.toJSON();
      // if (statusCode === 403) {
      //   if (signOut) {
      //     signOut();
      //   }
      // }

      if (statusCode === 500) {
        return Promise.reject({
          message: error.response.data.message,
        });
      }

      return Promise.reject(error);
    },
  );
};

const useEventApiClient = () => {
  const {
    user: { token },
    disconnect: signOut,
  } = useAuth();

  return useCallback(
    (endpoint, config = {}) =>
      eventApiClient(endpoint, { ...config, token, signOut }),
    [token],
  );
};

export { eventApiClient, useEventApiClient };
