import {
  interpolateClaimbleForInterface,
  useGetOraclePrice,
} from '@apiServices';
import { useNetworks, useTSWagmi } from '@contexts';
import {
  Account,
  ClaimSubgraphSchema,
  DistributorObject,
  Tranche,
} from '@customTypes';
import {
  div,
  getCrosschainDistributorABI,
  getDistributorInterfaces,
  max,
  mult,
  sub,
  toBigNumber,
  toNumber,
  unixToLocalDateTime,
  utcToLocalDateTime,
} from '@utils';
import { simulateContract, writeContract } from '@wagmi/core';
import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { getAddress } from 'viem';
import { useReadContract, useWaitForTransactionReceipt } from 'wagmi';
import { getAdvancedDistributorABI, getClaimABI } from './abi';

const NATIVE_TOKEN_DECIMALS = 18;

export const useSubmitClaim = () => {
  const { wagmiConfig } = useTSWagmi();
  const [transactionHash, setTransactionHash] =
    useState<Maybe<EvmAddress>>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const write = async (
    chainId: number,
    distributorAddress: EvmAddress,
    // [proofIndex, beneficiary, amount, merkleProof]
    args: [number, EvmAddress, BigNumber, SHA256Hash[]],
  ): Promise<EvmAddress> => {
    setSubmitting(true);

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: distributorAddress,
        abi: getClaimABI(),
        functionName: 'claim',
        chainId,
        args,
      });

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      console.log('claim transaction response', writeContractResponse);
      return writeContractResponse;
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash ?? undefined,
    query: {
      enabled: !!transactionHash,
    },
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};

export const useClaimableAmount = (
  walletAddress: string,
  distributor: DistributorObject,
  relayerFeeBips?: number,
  networkId?: number,
) => {
  const [totalAvailableToClaim, setTotalAvailableToClaim] = useState(0);
  const [interpolateData, setInterpolateData] = useState(null);
  const [totalAllocations, setTotalAllocations] = useState(0);
  const [totalClaimedToDate, setTotalClaimedToDate] = useState(0);
  const [claimRecords, setClaimRecords] = useState<any[]>([]);
  const interfaces = getDistributorInterfaces(distributor);
  const { mutate: getOraclePrice } = useGetOraclePrice();
  const [oraclePrice, setOraclePrice] = useState(0);

  const setRefresh = (interpolate: any) => {
    setInterpolateData(interpolate);
  };

  useEffect(() => {
    if (
      distributor.tierVesting &&
      distributor.tierVesting[0] &&
      distributor.tierVesting[0].oracle
    ) {
      getOraclePrice(
        { networkId, oracleAddress: distributor.tierVesting[0].oracle },
        {
          onSuccess: (result) => {
            setOraclePrice(result.price);
          },
          onError: (error) => {
            console.error('error:', error);
          },
        },
      );
    }
  }, []);

  useEffect(() => {
    if (distributor) {
      // total allocation comes from the authorization data
      let _totalAllocations = toNumber(distributor.proofAmount || '0');

      // if (relayerFeeBips) {
      //   const relayerFeeMult = sub(1, relayerFeeBips / 10000);
      //   _totalAllocations = parseFloat(mult(_totalAllocations, relayerFeeMult));
      //   console.info('relayer fee multiplier:', relayerFeeMult);
      // }

      let _totalClaimedToDate = toNumber(0);
      let _claimRecords: ClaimSubgraphSchema[] = [];

      // if the distribution record exists we can use the claimed value to total claimed to date.
      if (distributor.distributionRecords?.length > 0) {
        _totalClaimedToDate = toNumber(
          distributor.distributionRecords[0].claimed || '0',
        );
        _claimRecords = distributor.distributionRecords[0].claims;
      }

      // if allocations were changed and somehow the total claimed is greater than the total
      // allocation, just return 0
      const totalAvaibleToClaim = parseFloat(
        sub(_totalAllocations, _totalClaimedToDate),
      );
      if (totalAvaibleToClaim <= 0) {
        console.info('total available to claim is less than 0!');
        setTotalAllocations(_totalAllocations);
        setTotalClaimedToDate(_totalClaimedToDate);
        setClaimRecords(_claimRecords);
        setTotalAvailableToClaim(toNumber(0));
      } else {
        const interpolatedTotalAvailableToClaim =
          interpolateClaimbleForInterface(
            interfaces,
            _totalAllocations,
            distributor.continuousVesting?.start,
            distributor.continuousVesting?.cliff,
            distributor.continuousVesting?.end,
            distributor.trancheVesting?.tranches,
            toBigNumber(distributor.fractionDenominator),
            oraclePrice,
            [distributor.tierVesting],
          );

        console.info(
          'interpolated total available to claim:',
          interpolatedTotalAvailableToClaim,
        );

        setTotalAllocations(_totalAllocations);
        setTotalClaimedToDate(_totalClaimedToDate);
        setClaimRecords(_claimRecords);
        setTotalAvailableToClaim(
          max(0, sub(interpolatedTotalAvailableToClaim, _totalClaimedToDate)),
        );
      }
    }
  }, [distributor, interpolateData, oraclePrice]);

  return {
    totalAvailableToClaim,
    totalAllocations,
    totalClaimedToDate,
    claimRecords,
    loading: false,
    setRefresh,
  };
};

export const getTotalUserClaimable = (
  distributor: any,
  walletAddress: string,
): number => {
  if (!distributor || !walletAddress) {
    return 0;
  }

  if (distributor?.authorization) {
    const authorization = distributor.authorization;
    const amountObj = authorization?.data?.find((d) => d.name === 'amount');
    if (amountObj) {
      return Number(amountObj.value);
    }
  }

  // If not, use merkle root amount
  if (distributor.merkleProof && distributor.proofAmount) {
    return Number(distributor.proofAmount);
  }

  return 0;
};

export const useNativeOraclePrice = (networkId) => {
  const { getNetworkDetails } = useNetworks();
  const { mutate: getOraclePrice } = useGetOraclePrice();
  const [nativeOraclePrice, setNativeOraclePrice] = useState(0);

  useEffect(() => {
    getOraclePrice(
      {
        networkId,
        oracleAddress: getNetworkDetails(networkId).nativePriceOracleAddress,
      },
      {
        onSuccess: (result) => {
          setNativeOraclePrice(result.price);
        },
        onError: (error) => {
          console.error('error:', error);
        },
      },
    );
  }, []);

  return { nativeOraclePrice };
};

export const getVestedFractionForContinuous = (
  start: number,
  cliff: number,
  end: number,
  fractionDenominator: BigNumber,
): BigNumber => {
  const d = new Date();
  // Seconds since epoch
  const time = d.getTime() / 1000;

  // no tokens are vested
  if (time <= cliff) {
    return toBigNumber(0);
  }

  // all tokens are vested
  if (time >= end) {
    return fractionDenominator;
  }

  // some tokens are vested
  return toBigNumber(
    div(mult(fractionDenominator, sub(time, start)), sub(end, start)),
  );
};

export const getVestedFractionForTranche = (tranches: Tranche[]): BigNumber => {
  const sortedTranches = tranches.sort(function (a, b) {
    return Number(a.index) - Number(b.index);
  });

  const d = new Date();
  // Seconds since epoch
  const time = d.getTime() / 1000;
  let largestBips = toBigNumber(0);

  for (let i = 0; i < sortedTranches.length; i++) {
    if (time > sortedTranches[i].time) {
      largestBips = toBigNumber(sortedTranches[i].vestedFraction);
    }
  }

  return largestBips;
};

export const getVestedFractionForPriceTier = (
  tierVesting: any[],
  oraclePrice: number,
): BigNumber => {
  if (!tierVesting || !tierVesting.length || !oraclePrice) {
    return toBigNumber(0);
  }
  for (let i = tierVesting.length - 1; i != 0; i--) {
    if (oraclePrice > Number(tierVesting[i].price)) {
      return toBigNumber(tierVesting[i].vestedFraction);
    }
  }
  return toBigNumber(0);
};

export const getClaimsStartDate = (distributor: any, account?: Account) => {
  // if tranche
  if (distributor.trancheVesting && distributor.trancheVesting.length) {
    return unixToLocalDateTime(
      distributor.trancheVesting[0].time,
      account?.timezone,
    );
  }

  // if continuous
  if (distributor.continuousVesting) {
    return unixToLocalDateTime(
      distributor.continuousVesting.start,
      account?.timezone,
    );
  }

  // if sale
  if (distributor.sale?.startTime) {
    return unixToLocalDateTime(distributor.sale.startTime, account?.timezone);
  }

  // fallback to event
  return distributor.startTime
    ? utcToLocalDateTime(distributor.startTime)
    : '-';
};

export const useFairDelayTime = (
  distributorId?: any,
  chaindId?: number,
  walletAddress?: string,
) => {
  let delay: Maybe<number> = null;

  const config = {
    address: distributorId ? getAddress(distributorId) : null,
    abi: getAdvancedDistributorABI(),
    functionName: 'getFairDelayTime',
    chainId: chaindId ? chaindId : null,
    args: [walletAddress],
  } as any;

  const { data, isSuccess, isLoading } = useReadContract(config);

  if (data) {
    delay = Number(data);

    if (delay === 0) {
      delay = null;
    }
  }

  return {
    delay,
    isSuccess,
    isLoading,
  };
};

export const useGetTranches = (distributorId?: any, chaindId?: number) => {
  const config = {
    address: distributorId ? getAddress(distributorId) : null,
    abi: getCrosschainDistributorABI(),
    functionName: 'getTranches',
    chainId: chaindId ? chaindId : null,
  } as any;

  const { data, isSuccess, isLoading } = useReadContract(config);
  const tranches = data || [];

  return {
    tranches,
    isSuccess,
    isLoading,
  };
};
