import { ButtonRow, PageHeader } from '@components';
import { useAccount, useAnalytics } from '@contexts';
import { utcToLocalDate } from '@utils';
import { Link } from 'react-router-dom';

export const IdentityCompleted = () => {
  useAnalytics('/account/identity-completed');
  const { isTier3Eligible, isTier3Started, tier1Expiration } = useAccount();

  return (
    <PageHeader
      title={'Congratulations!'}
      instructions={
        <>
          <p className='text-xs md:text-base mb-8 text-center'>
            Your identity has been verified.{' '}
            {tier1Expiration
              ? `The information provided is valid through ${utcToLocalDate(
                  tier1Expiration,
                )}`
              : `The information provided expires 1 year from the date of approval.`}
          </p>

          <ButtonRow place={'center'}>
            <Link className='btn btn-primary' to='/account'>
              My Profile
            </Link>
            <Link className='btn btn-primary' to='/'>
              Dashboard
            </Link>
            {isTier3Eligible && !isTier3Started ? (
              <Link className='btn btn-primary' to='/account/accreditation'>
                Begin Accreditation
              </Link>
            ) : null}
          </ButtonRow>
        </>
      }
    />
  );
};
