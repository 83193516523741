import { useFavicon } from '@hooks';
import { getResourceUri, getValidIPFSHash } from '@utils';
import { Helmet } from 'react-helmet';
import { ProjectTheme, SEOContent } from 'tokensoft-shared-types';

interface SEOConfigurationProps {
  seoContent: SEOContent;
  projectTheme: ProjectTheme;
}

export const SEOConfiguration = ({
  seoContent,
  projectTheme,
}: SEOConfigurationProps) => {
  const title = 'Welcome';

  const favicon = projectTheme.favicon
    ? getValidIPFSHash(projectTheme.favicon)
      ? getResourceUri(projectTheme.favicon)
      : projectTheme.favicon
    : 'https://s3.amazonaws.com/media.tokensoft.io/logos/favicon.ico';

  useFavicon(favicon);

  const name = seoContent.title ?? 'Tokensoft';
  const description =
    seoContent.description ??
    'Welcome to Tokensoft - A Fair-launch platform for Web3 Projects & Communities';
  const logo =
    seoContent.logo ??
    'https://media.tokensoft.io.s3-website-us-east-1.amazonaws.com/logos/tokensoft-meta-logo.jpg';

  return (
    <>
      <Helmet titleTemplate={`%s | ${name}`} defaultTitle={title}>
        <meta charSet='utf-8' />

        <meta name='title' content={name} />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='theme-color' content='#000000' />
        <meta name='version' content={import.meta.env.VITE_VERSION} />
        <meta name='description' content={description} />

        <meta property='og:type' content='website' />
        <meta property='og:title' content={name} />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={logo} />

        <meta property='twitter:title' content={name} />
        <meta property='twitter:description' content={description} />
        <meta property='twitter:image' content={logo} />
        <meta name='twitter:card' content='summary_large_image' />
      </Helmet>
    </>
  );
};
