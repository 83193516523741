export enum FEATURE {
  CLAIMS = 'CLAIMS',
  ADMIN = 'ADMIN',
  REGISTRATION = 'REGISTRATION',
  TS_LAUNCH_EVENT = 'TS_LAUNCH_EVENT',
  MULTIPLE_WALLETS = 'MULTIPLE_WALLETS',
  LEGACY_PURCHASES = 'LEGACY_PURCHASES',
  CUSTOM_DOMAINS = 'CUSTOM_DOMAINS',
  CLAIM_HIDE_TOTAL_ALLOCATION = 'CLAIM_HIDE_TOTAL_ALLOCATION',
  CLAIM_CONNEXT_SIGNATURE_REQUIRED = 'CLAIM_CONNEXT_SIGNATURE_REQUIRED',
  CLAIM_DELEGATE_PRESELECT_REQUIRED = 'CLAIM_DELEGATE_PRESELECT_REQUIRED',
  HIDE_GOVERNANCE = 'HIDE_GOVERNANCE',
  CLAIM_HIDE_VESTING_SCHEDULE = 'CLAIM_HIDE_VESTING_SCHEDULE',
  CLAIM_CONNEXT_LAYOUT = 'CLAIM_CONNEXT_LAYOUT',
  ADD_POLKADOT_WALLETS = 'ADD_POLKADOT_WALLETS',
  ELIGIBLE_EVENT_ALLOCATIONS = 'ELIGIBLE_EVENT_ALLOCATIONS',
}
