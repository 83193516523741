import { Flex } from '@components';

type FlexProps = React.ComponentProps<typeof Flex>;

interface FormFieldProps extends FlexProps {}

export const FormField: React.FC<FormFieldProps> = ({
  children,
  ...restProps
}) => {
  return (
    <Flex direction={'col'} gap={2.5} {...restProps}>
      {children}
    </Flex>
  );
};
