import { useGetChain, useGetSaleByContractAddress } from '@apiServices';
import { useSale } from '@contexts';
import { CollapsibleCard } from '@newComponents';
import { EventPropertyRow } from '../event-property-row';
import {
  getCurrentInvestment,
  getMaximumInvestment,
  getMinimumInvestment,
  getPricePerToken,
  getTotalRaise,
  getVestingTerms,
} from './util';

type SaleCardProps = {
  className?: string;
  eventId: ID;
  saleAddress: EvmAddress;
};

export const SaleCard = ({
  className = '',
  eventId,
  saleAddress,
}: SaleCardProps) => {
  const {
    data: sale,
    isLoading,
    isError,
    error,
  } = useGetSaleByContractAddress(saleAddress);

  const {
    data: chain,
    isPending: isChainPending,
    isError: isChainError,
    error: chainError,
  } = useGetChain(sale?.chainId ?? null);

  const saleContext = useSale();

  const currentInvestment = getCurrentInvestment(saleContext);
  const totalRaise = getTotalRaise(saleContext);
  const vestingTerms = getVestingTerms(saleContext);

  return (
    <CollapsibleCard cardTitle='Sale Details'>
      <div className='flex flex-col items-start'>
        {/* Sale progress and goal */}
        {/* <div className='text-4xl font-bold'>
          ${currentInvestment.toLocaleString()} / ${totalRaise.toLocaleString()}
        </div>
        <div className='w-full bg-gray-300 h-3 rounded-md overflow-hidden my-2'>
          <div
            className='h-3 bg-gradient-to-r from-green-500 to-green-200'
            style={{
              width: `${(currentInvestment / totalRaise) * 100}%`,
            }}
          />
        </div>

        <div className='text-sm'>{`${(
          (currentInvestment / totalRaise) *
          100
        ).toFixed(0)}%`}</div> */}

        {/* Sale details using EventPropertyRow */}
        <div className='grid grid-cols-2 w-full gap-4 sm:gap-x-16 md:gap-x-32 mt-4'>
          <EventPropertyRow label='Chain' value={chain?.name} />
          <EventPropertyRow
            label='Min Investment Amount'
            value={`${getMinimumInvestment(saleContext).toLocaleString()} USD`}
          />
          <EventPropertyRow
            label='Total Raise'
            value={`$${totalRaise.toLocaleString()}`}
          />
          <EventPropertyRow
            label='Max Investment Amount'
            value={`${getMaximumInvestment(saleContext).toLocaleString()} USD`}
          />
          <EventPropertyRow
            label='Price per Token'
            value={`1 ${sale?.token.name} = ${getPricePerToken(saleContext, 6)} USD`}
          />
          {vestingTerms !== null && (
            <EventPropertyRow label='Vesting' value={vestingTerms} />
          )}
        </div>
      </div>
    </CollapsibleCard>
  );
};
