import { getFlatPriceSaleABI } from '@utils';
import { getAddress } from 'viem';
import { useReadContract } from 'wagmi';

export const useBuyerTotal = (
  chainId: number,
  saleId: string,
  walletAddress: string,
) => {
  const config = {
    address: getAddress(saleId),
    abi: getFlatPriceSaleABI(),
    functionName: 'buyerTotal',
    chainId,
    args: [walletAddress],
  };

  let buyerTotalNumber = '0';

  const { data } = useReadContract(config);

  if (data) {
    buyerTotalNumber = data.toString();
  }

  return buyerTotalNumber;
};
