import { getErc20ABI, gte, toBaseUnits } from '@utils';
import { getAddress, toHex } from 'viem';
import { useReadContract } from 'wagmi';

export const useCheckTransferAllowance = (
  chainId,
  paymentMethod,
  walletAddress,
  saleId,
  value,
) => {
  if (!paymentMethod?.address) {
    throw new Error('Payment method address is required');
  }

  const tokenAddress = getAddress(paymentMethod.address);
  let abi =
    tokenAddress === `0xdAC17F958D2ee523a2206206994597C13D831ec7`
      ? getErc20ABI(true)
      : getErc20ABI();
  const config = {
    address: tokenAddress,
    abi,
    functionName: 'allowance',
    chainId,
    args: [walletAddress, saleId],
  };

  const { data, error, isLoading, refetch } = useReadContract(config);
  let hasEnoughAllowance = false;

  if (data) {
    const allowance = toHex(data as any);
    const decimals = paymentMethod.decimals;
    const approvalValue = toBaseUnits(value, decimals);
    hasEnoughAllowance = gte(allowance, approvalValue);
  }

  return { data, error, isLoading, refetch, hasEnoughAllowance };
};
