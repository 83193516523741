export const ICON_100 = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 342 133'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='13.0771'
        y='13.0769'
        width='313.846'
        height='104.615'
        rx='5'
        fill='#E5E7EB'
      />
      <path
        d='M181.416 54.9231H158.583C157.666 54.9231 156.923 55.6666 156.923 56.5836V73.6044C156.923 74.5214 157.666 75.2649 158.583 75.2649H181.416C182.333 75.2649 183.077 74.5214 183.077 73.6044V56.5836C183.077 55.6666 182.333 54.9231 181.416 54.9231ZM181.416 56.5836V68.6604L178.144 65.6851C177.652 65.238 176.895 65.2559 176.425 65.7263L173.113 69.0378L166.583 61.2373C166.089 60.6478 165.185 60.642 164.684 61.2245L158.583 68.3142V56.5836H181.416ZM174.359 60.9426C174.359 59.6814 175.381 58.6593 176.642 58.6593C177.903 58.6593 178.925 59.6814 178.925 60.9426C178.925 62.2038 177.903 63.2258 176.642 63.2258C175.381 63.2259 174.359 62.2038 174.359 60.9426Z'
        fill='#6B7280'
      />
      <defs>
        <filter
          id='filter0_d_4322_1094'
          x='0'
          y='0'
          width='342'
          height='132.769'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1' dy='1' />
          <feGaussianBlur stdDeviation='0.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0666667 0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0.06 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_4322_1094'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_4322_1094'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
