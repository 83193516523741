import classNames from 'classnames';
import { ChangeEvent, FC } from 'react';
import './toggle.css';

interface ToggleProps {
  className?: string;
  onChange: (newValue: boolean) => void;
  checked: boolean;
}

export const Toggle: FC<ToggleProps> = ({ className, checked, onChange }) => {
  const classes = classNames('switch', className);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <label className={classes}>
      <input type='checkbox' checked={checked} onChange={handleOnChange} />
      <span className='slider round'></span>
    </label>
  );
};
