import {
  AnimatedCheckmark,
  Button,
  ButtonLink,
  Col,
  ModalTitle,
  Stacked,
  Text,
} from '@components';
import { VscLinkExternal } from 'react-icons/vsc';

interface DeployDistributorSuccessModalProps {
  onFinish: Function;
  explorerUrl: string;
}

export const DeployDistributorSuccessModal = ({
  onFinish,
  explorerUrl,
}: DeployDistributorSuccessModalProps) => {
  const finish = () => {
    onFinish && onFinish();
  };

  const key = 'deploy-distributor-success-modal';

  return (
    <Stacked>
      <Col data-testid={key} gap={5} place={'center'}>
        <AnimatedCheckmark size={'large'} />
        <ModalTitle data-testid={`${key}-title`}>
          {'Deploy Successful'}
        </ModalTitle>
        <Text data-testid={`${key}-subtitle`} width='3/4' textAlign={'center'}>
          You have successfully deployed your distributor contract. You’re ready
          to proceed to your event dashboard.
        </Text>
        <ButtonLink
          data-testid={`${key}-contract-details`}
          color={'primary'}
          fill={'ghost'}
          href={explorerUrl}
          target='_blank'
        >
          <Text className='text-base font-normal text-primary-medium'>
            Contract Details
          </Text>
          <VscLinkExternal color={'var(--primary)'} />
        </ButtonLink>
      </Col>

      <Button
        data-testid={`${key}-button`}
        color={'primary'}
        onClick={() => finish()}
      >
        <Text>Finish</Text>
      </Button>
    </Stacked>
  );
};
