import { IconProps } from '../icon-props';

export const Twitter = ({ color, className, size = 20 }: IconProps) => {
  return (
    <div className={`${className && className}`}>
      <svg
        width={size}
        height={size}
        fill='none'
        viewBox='0 0 14 14'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_461_11366)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            fill={color !== undefined ? color : '#101828'}
            d='M1.36621 1.3125L9.27547 12.8295H12.5594L4.69643 1.3125H1.36621ZM3.12369 2.23756L9.76239 11.9044H10.8078L4.2079 2.23756H3.12369Z'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            fill={color !== undefined ? color : '#101828'}
            d='M11.1609 1.35876H12.1913L7.76457 6.60904L7.26951 6.19164L7.32295 6.12826L7.17871 6.00462L11.1609 1.35876Z'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            fill={color !== undefined ? color : '#101828'}
            d='M2.34702 12.8297L1.31316 12.8297L5.74533 7.62442L6.23835 8.04423L6.18519 8.10667L6.3291 8.23129L2.34702 12.8297Z'
          />
        </g>
        <defs>
          <clipPath id='clip0_461_11366'>
            <rect width='14' height='14' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
