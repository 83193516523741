import { Card, DocumentUploader, StatusIcon } from '@components';
import { useAccount } from '@contexts';
import { VERIFICATION_STATUS } from '@enums';
import { utcToLocalDate } from '@utils';
import { useState } from 'react';

export const ProfileIdentity = () => {
  const { tier1Status, tier1Expiration } = useAccount();
  const [showMoreDocuments, setShowMoreDocuments] = useState<boolean>(false);

  return (
    <>
      <Card className='mb-8 h-full'>
        <div className={'h-full flex flex-col justify-between'}>
          <div className={'flex flex-col items-center'}>
            <div className={'mb-4'}>
              <StatusIcon status={tier1Status} size='xl' />
            </div>

            <div className={'mb-8 flex flex-col items-center justify-center'}>
              {tier1Status === VERIFICATION_STATUS.COMPLETED && (
                <>
                  <div className={'title-text text-center mb-4'}>
                    Congratulations!
                  </div>
                  <div className={'w-3/4 text-center'}>
                    Your identity has been verified.{' '}
                    {tier1Expiration
                      ? `The information provided is valid through ${utcToLocalDate(
                          tier1Expiration,
                        )}`
                      : `The information provided expires 1 year from the date of approval.`}
                  </div>
                </>
              )}

              {tier1Status === VERIFICATION_STATUS.FAILED && (
                <>
                  <div className={'title-text text-center mb-4'}>
                    We're sorry.
                  </div>
                  <div className={'w-3/4 text-center'}>
                    We could not approve your application.
                  </div>
                </>
              )}

              {tier1Status === VERIFICATION_STATUS.PENDING && (
                <>
                  <div className={'title-text text-center mb-4'}>
                    Application pending
                  </div>
                  {showMoreDocuments ? (
                    <DocumentUploader onSetIsShowing={setShowMoreDocuments} />
                  ) : (
                    <>
                      <div className={'w-3/4 text-center'}>
                        Your profile is being reviewed and a determination will
                        be made within 3-5 business days. Click{' '}
                        <a onClick={() => setShowMoreDocuments(true)}>here</a>{' '}
                        to submit additional documents for consideration.
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
