export const SuccessAlertIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M8.00002 3.2626e-05C12.4183 3.2626e-05 16 3.58176 16 8.00002C16 12.4183 12.4183 16 8.00002 16C3.58176 16 3.31829e-05 12.4183 3.31829e-05 8.00002C-0.0125387 3.59429 3.54879 0.0126045 7.95447 3.2626e-05C7.96965 -1.08753e-05 7.98483 -1.08753e-05 8.00002 3.2626e-05Z'
        fill='#22C55E'
      />
      <path
        d='M12.43 5.79616L6.74822 11.478L3.56641 8.31889L4.86188 7.04617L6.74822 8.90981L11.1346 4.52344L12.43 5.79616Z'
        fill='white'
      />
    </svg>
  );
};
