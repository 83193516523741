import { VERIFICATION_STATUS } from '@enums';
import { MouseEventHandler } from 'react';
import { HiOutlineRefresh } from 'react-icons/hi';
import { StatusIcon } from './status-icon';

export const DomainStatus = (props: {
  status: string;
  onRefresh: MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
}) => (
  <>
    <div className='col-form-label'>Domain Status</div>
    <div className='flex items-center space-x-2 pl-5'>
      <div className='flex items-center space-x-1 bg-warning-medium text-white py-1.5 px-4 rounded'>
        <StatusIcon color='white' status={VERIFICATION_STATUS.PENDING} />
        <span>{props.status}</span>
      </div>
      <button
        className='btn btn-outline-primary btn-xs'
        onClick={props.onRefresh}
        disabled={props.disabled}
      >
        <div className='flex items-center space-x-1'>
          <HiOutlineRefresh className='' />
          <span className='text-sm'>Refresh</span>
        </div>
      </button>
    </div>
  </>
);
