import { IconProps } from '@components';
import { RxCross1 } from 'react-icons/rx';

export const FailedCircleIcon = ({
  color,
  className,
  size = 24,
}: IconProps) => {
  return (
    <div
      className={`w-[20px] h-[20px] bg-red-600 text-white rounded-full flex justify-center items-center font-bold ${className}`}
    >
      <RxCross1 size={12} />
    </div>
  );
};
