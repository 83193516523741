import { usePurchaseTotal } from '@apiServices';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CheckboxInput,
  Col,
  Divider,
  EventEligibilityAllocationSummary,
  InputGroup,
  MultiSelect,
  Stacked,
} from '@components';
import { DEFAULT_BASE_CURRENCY_DECIMALS } from '@constants';
import { useAnalytics, useAuth, useNetworks } from '@contexts';
import { Sale } from '@customTypes';
import { useBuyerTotal, useExchangeRate } from '@hooks';
import {
  add,
  div,
  getPaymentMethods,
  gt,
  lt,
  lte,
  min,
  mult,
  sub,
} from '@utils';
import { useEffect, useState } from 'react';
import { useBalance } from 'wagmi';

interface PurchaseStepPageProps {
  onFormValidChange: (valid: boolean) => void;
  sale: Sale;
  receipt: any;
  setReceipt: (receipt: any) => void;
  documentsToAcceptOrSign: any;
  eligibilityData: any;
  feeLevel: any;
}

export const PurchaseStepPage = ({
  onFormValidChange,
  sale,
  receipt,
  setReceipt,
  documentsToAcceptOrSign,
  eligibilityData,
  feeLevel,
}: PurchaseStepPageProps) => {
  const {
    user: { walletAddress },
  } = useAuth();
  const { getNetworkDetails } = useNetworks();

  const paymentMethods = getPaymentMethods(
    sale,
    getNetworkDetails(sale.chainId),
  );

  const [networkCheckbox, setNetworkCheckbox] = useState<boolean>(false);
  const [feesCheckbox, setFeesCheckbox] = useState<boolean>(false);
  const [tokensPaid, setTokensPaid] = useState<string>('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    getPaymentMethods(sale, getNetworkDetails(sale.chainId))[0],
  );
  const [purchaseError, setPurchaseError] = useState<Maybe<any>>(null);

  const { data } = useBalance({
    address: walletAddress as EvmAddress,
    token: selectedPaymentMethod.address as EvmAddress,
  });

  const { formatted: balance } = data || {};
  const buyerTotal = useBuyerTotal(sale.chainId, sale.id, walletAddress);
  const purchaseTotal = usePurchaseTotal(sale.id);
  const nativePaymentMethod = paymentMethods.find(
    (method) => method.token === null,
  );
  const nativeExchangeRate = useExchangeRate(
    sale?.chainId,
    nativePaymentMethod!,
  );
  const exchangeRate = useExchangeRate(sale?.chainId, selectedPaymentMethod);
  const { name: networkName } = getNetworkDetails(sale.chainId);

  const DISPLAY_DECIMALS = 7;
  useAnalytics(`/sale/${sale.id}/purchase`);

  useEffect(() => {
    const formValid =
      !!tokensPaid &&
      !!networkCheckbox &&
      !!feesCheckbox &&
      purchaseError === null;

    onFormValidChange(formValid);
  }, [tokensPaid, networkCheckbox, feesCheckbox, purchaseError]);

  const handlePaymentMethodSelect = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    setTokensPaid('');
  };

  const handleTokensPaidChange = ({ value }) => {
    setTokensPaid(value);
  };

  useEffect(() => {
    let error = getPurchaseError();
    setPurchaseError(error);
  }, [tokensPaid, sale]);

  const remainingUserAllocation = sub(sale?.userMaximum, buyerTotal);
  const remainingTotalAllocation = sub(sale?.saleMaximum, purchaseTotal);
  const maxPurchase = min(remainingUserAllocation, remainingTotalAllocation);

  const paymentTokenPerSaleToken = div(
    sale.price,
    exchangeRate,
    undefined,
    DISPLAY_DECIMALS,
  );
  const tokensPurchasedBeforeFee = div(
    tokensPaid,
    paymentTokenPerSaleToken,
    DISPLAY_DECIMALS,
  );

  let feeAmount, paymentTokenFeeAmount, contributionAmount;
  if (`${feeLevel}` === '0') {
    feeAmount = '0';
    paymentTokenFeeAmount = '0';
    contributionAmount = tokensPaid;
  } else {
    feeAmount = div(tokensPurchasedBeforeFee, feeLevel, DISPLAY_DECIMALS);
    paymentTokenFeeAmount = div(tokensPaid, feeLevel, DISPLAY_DECIMALS);
    contributionAmount = sub(
      tokensPaid,
      paymentTokenFeeAmount,
      DISPLAY_DECIMALS,
    );
  }
  const tokensPurchased = sub(tokensPurchasedBeforeFee, feeAmount);

  const saleTokenPrice = exchangeRate
    ? `1 ${sale.symbol} = ${paymentTokenPerSaleToken} ${selectedPaymentMethod.symbol}`
    : null;

  // Add a buffer to the purchase minimum (base units) to account price fluctuations
  const minBuffer = Number(sale.decimals) > 6 ? 100 : 1;
  const paddedPurchaseMinimum = add(sale.purchaseMinimum, minBuffer);
  const purchaseMinimumInSelectedPaymentMethod = div(
    paddedPurchaseMinimum,
    exchangeRate,
    undefined,
    DISPLAY_DECIMALS,
  );

  const purchaseMaximumInSelectedPaymentMethod = div(
    maxPurchase,
    exchangeRate,
    undefined,
    DISPLAY_DECIMALS,
  );

  const oneUSDInCents = '100';
  const platformFeeAmountInCents = !!sale.platformFeeInCents
    ? sale.platformFeeInCents
    : oneUSDInCents;
  const platformFeeAmountInDollars = div(platformFeeAmountInCents, 100);

  // Adjustment includes incrementing by the lowest digit in order to combat
  //    any rounding errors.
  const platformFeeInNativeCurrencyPreAdjustment = div(
    mult(platformFeeAmountInDollars, Math.pow(10, 8)),
    nativeExchangeRate,
    undefined,
    6,
  );
  const platformFeeInNativeCurrency = (
    (Number(platformFeeInNativeCurrencyPreAdjustment) * Math.pow(10, 6) + 1) /
    Math.pow(10, 6)
  ).toString();

  const purchaseMin = exchangeRate
    ? `${purchaseMinimumInSelectedPaymentMethod} ${selectedPaymentMethod.symbol}`
    : null;
  const purchaseMax = exchangeRate
    ? `${purchaseMaximumInSelectedPaymentMethod} ${selectedPaymentMethod.symbol}`
    : null;

  const getPurchaseError = () => {
    if (!tokensPaid || !tokensPurchased) return null;
    const baseCurrencyPaid = mult(tokensPaid, exchangeRate);
    const overMaxPurchase = gt(
      baseCurrencyPaid,
      maxPurchase,
      Number(DEFAULT_BASE_CURRENCY_DECIMALS),
    );
    const underMinPurchase =
      gt(baseCurrencyPaid, 0) && lt(baseCurrencyPaid, sale.purchaseMinimum);

    if (lte(remainingUserAllocation, 0)) {
      return 'Total user purchase amount limit reached';
    } else if (lte(remainingTotalAllocation, 0)) {
      return 'Total sale purchase amount limit reached';
    } else if (overMaxPurchase) {
      return `Purchase too large: the limit is ${purchaseMax}`;
    } else if (underMinPurchase) {
      return `Purchase too small: the minimum is ${purchaseMin}`;
    } else if (typeof balance === 'string' && gt(tokensPaid, balance)) {
      return `Not enough ${selectedPaymentMethod.symbol} in wallet`;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (
      !tokensPaid ||
      !selectedPaymentMethod ||
      !tokensPurchased ||
      !feeAmount ||
      !paymentTokenFeeAmount ||
      !contributionAmount
    ) {
      return;
    }

    setReceipt({
      tokensPaid: {
        amount: tokensPaid,
        label: selectedPaymentMethod.label,
      },
      selectedPaymentMethod,
      saleTokensPurchased: tokensPurchased,
      saleFeeAmount: feeAmount,
      paymentTokenFeeAmount: {
        amount: paymentTokenFeeAmount,
        label: selectedPaymentMethod.label,
      },
      contributionAmount,
      platformFee: {
        amount: platformFeeInNativeCurrency,
        label: nativePaymentMethod?.label,
      },
      nativeTokenName: nativePaymentMethod?.label,
    });
  }, [
    tokensPaid,
    selectedPaymentMethod,
    tokensPurchased,
    feeAmount,
    paymentTokenFeeAmount,
    contributionAmount,
    platformFeeInNativeCurrency,
  ]);

  return (
    <Card className='vertical'>
      <CardHeader>
        <CardTitle data-testid='eligibility-header'>Payment Details</CardTitle>
      </CardHeader>

      <CardBody>
        <Stacked>
          <div className='text-center text-2xl'>{saleTokenPrice}</div>

          <EventEligibilityAllocationSummary
            eligibilityData={eligibilityData}
          />

          <Divider />

          <Col className='gap-0 md:gap-5'>
            {paymentMethods?.length > 1 ? (
              <MultiSelect
                defaultValue='--'
                label='Method'
                options={paymentMethods}
                value={selectedPaymentMethod}
                onChange={handlePaymentMethodSelect}
                isMulti={false}
              />
            ) : null}

            <InputGroup
              label={`${selectedPaymentMethod.label} Value`}
              type='number'
              name='tokensPaid'
              placeholder='0'
              allowNegative={false}
              decimalScale={selectedPaymentMethod.decimals}
              value={tokensPaid}
              onChange={handleTokensPaidChange}
              error={purchaseError}
              colWidths={{ second: '2/3' }}
            />

            {paymentTokenFeeAmount !== '0' && (
              <InputGroup
                label={`Network Fee`}
                value={
                  (`${paymentTokenFeeAmount}` === 'NaN'
                    ? '0'
                    : paymentTokenFeeAmount) + ` ${selectedPaymentMethod.label}`
                }
                readonly
                hint={`This is a Network fee of 1%, paid in ${selectedPaymentMethod.label}.`}
                colWidths={{ second: '2/3' }}
              />
            )}

            {platformFeeAmountInDollars && platformFeeInNativeCurrency && (
              <InputGroup
                label={`Processing Fee`}
                value={
                  `${platformFeeInNativeCurrency}` === 'NaN'
                    ? '0'
                    : `${platformFeeInNativeCurrency} ETH`
                }
                readonly
                hint={`This is a flat processing fee of ${platformFeeAmountInDollars} USD, paid in ETH.`}
                colWidths={{ second: '2/3' }}
              />
            )}

            <InputGroup
              label={`${sale?.symbol.toUpperCase()} Tokens Purchased`}
              value={`${tokensPurchased}` === 'NaN' ? '0' : tokensPurchased}
              readonly
              colWidths={{ second: '2/3' }}
            />
          </Col>

          <Divider />

          <Col>
            <div>
              <CheckboxInput
                onClick={() => setNetworkCheckbox(!networkCheckbox)}
                name='networkCheckbox'
                checked={networkCheckbox}
              >
                I understand that my payment must be made on the{' '}
                <b>{networkName}</b> network, and that my payment will be lost
                if I do not follow instructions exactly.
              </CheckboxInput>
            </div>

            <div>
              <CheckboxInput
                onClick={() => setFeesCheckbox(!feesCheckbox)}
                name='feesCheckbox'
                checked={feesCheckbox}
              >
                I understand that I am responsible for all network fees
              </CheckboxInput>
            </div>
          </Col>
        </Stacked>
      </CardBody>
    </Card>
  );
};
