import {
  Col,
  FormLabel,
  HelpPopover,
  HexColorInput,
  ImageUploadButton,
  InfoIcon,
  InputGroup,
  LayoutIcon,
  Row,
  Section,
  SectionTitle,
  Stacked,
  Stretched,
  Text,
} from '@components';
import {
  DEFAULT_PROJECT_WELCOME_BG_COLOR,
  DEFAULT_PROJECT_WELCOME_FONT_COLOR,
} from '@constants';
import { LAYOUT_TYPE, LAYOUT_TYPE_NAME } from '@enums';
import {
  validateProjectWelcomeFormSchemaFormSchema
} from '@utils';
import { ReactNode, useEffect, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

export const ProjectWelcomePageSetup = ({
  wizardState,
  updateWizardState,
  children,
}: {
  wizardState: any;
  updateWizardState: Function;
  editMode?: boolean;
  children?: ReactNode;
}) => {
  const [formState, setFormState] = useState({
    welcomeAlignment: wizardState.welcomeAlignment,
    welcomeBgColor: wizardState.welcomeBgColor,
    welcomeFontColor: wizardState.welcomeFontColor,
    welcomeTitle: wizardState.welcomeTitle,
    welcomeSubtitle: wizardState.welcomeSubtitle,
    welcomeLogo: wizardState.welcomeLogo,
    welcomeBgImage: wizardState.welcomeBgImage,
    welcomeLayoutVersion: wizardState.welcomeLayoutVersion,
  });

  const validWelcomeLogoImage =
    !!formState.welcomeLogo && formState.welcomeLogo?.trim() !== '';

  const formValid =
    validateProjectWelcomeFormSchemaFormSchema(formState) &&
    (formState.welcomeLayoutVersion === LAYOUT_TYPE.TITLE_ICON_CENTERED
      ? validWelcomeLogoImage
      : true) &&
    formState.welcomeLayoutVersion;

  useEffect(() => {
    updateWizardState({
      ...wizardState,
      ...formState,
      projectWelcomePageFormValid: !!formValid,
    });
  }, [formState]);

  const handleColorChange = (e) => {
    let newColor = '';

    if (e.target.value.length === 0) {
      newColor = '#';
    } else {
      newColor =
        e.target.value[0] === '#' ? e.target.value : `#${e.target.value}`;
    }

    setFormState({ ...formState, [e.target.name]: newColor });
  };

  const handleImageChanged = (imagekey, value) => {
    setFormState({ ...formState, [imagekey]: value });
  };
  const setWelcomeLayoutVersion = (value) => {
    setFormState({ ...formState, welcomeLayoutVersion: value });
  };

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const welcomePagelayout = [
    {
      type: LAYOUT_TYPE.TITLE_ICON_CENTERED,
      name: LAYOUT_TYPE_NAME.TITLE_ICON_CENTERED,
    },
    {
      type: LAYOUT_TYPE.TITLE_ONLY_100_CENTERED,
      name: LAYOUT_TYPE_NAME.TITLE_ONLY_100_CENTERED,
    },
    {
      type: LAYOUT_TYPE.TITLE_ONLY_100_LEFT,
      name: LAYOUT_TYPE_NAME.TITLE_ONLY_100_LEFT,
    },
    {
      type: LAYOUT_TYPE.TITLE_ONLY_100_RIGHT,
      name: LAYOUT_TYPE_NAME.TITLE_ONLY_100_RIGHT,
    },
  ];

  return (
    <Stacked gap={5}>
      {/* Content SECTION */}
      <Section gap={5}>
        <Col>
          <SectionTitle>1. Select a Welcome Page Layout</SectionTitle>
          <Text>
            Please pick the general layout for your end-user welcome page. You
            have the option to upload a custom background and arrange how your
            content will look overlayed on top. This is what the user will see
            first when coming to sign-up for an event.
          </Text>
        </Col>
        <Stretched xgap={4} ygap={0}>
          {welcomePagelayout.map((layout, index) => (
            <LayoutIcon
              value={formState.welcomeLayoutVersion}
              setValue={setWelcomeLayoutVersion}
              layout={layout}
              key={index}
            />
          ))}
        </Stretched>
      </Section>
      {/* Content SECTION */}
      <Section gap={5}>
        <Col>
          <SectionTitle>2. Choose Your Welcome Content</SectionTitle>
        </Col>
        <Col xgap={0} ygap={2.5}>
          <Stretched xgap={4} ygap={0}>
            <InputGroup
              label='Headline'
              placeholder='Enter Here'
              value={formState.welcomeTitle}
              name='welcomeTitle'
              required={true}
              maxLength={100}
              onChange={handleChange}
            />
            <InputGroup
              label='Subtitle'
              placeholder='Enter Here'
              value={formState.welcomeSubtitle}
              name='welcomeSubtitle'
              required={true}
              maxLength={300}
              onChange={handleChange}
            />
          </Stretched>
          <Stretched xgap={4} ygap={0}>
            <HexColorInput
              label='Font Color'
              color={formState.welcomeFontColor}
              defaultColor={DEFAULT_PROJECT_WELCOME_FONT_COLOR}
              onChange={handleColorChange}
              name='welcomeFontColor'
              required={true}
              className={'w-full'}
            />

            <Col className={'w-full'}>
              <Row yalign={'center'} gap={2}>
                <FormLabel>Logo/Image</FormLabel>
                <HelpPopover
                  button={
                    <div className='block cursor-pointer' onClick={() => {}}>
                      <InfoIcon fill={'var(--neutral)'} />
                    </div>
                  }
                >
                  <div>
                    <Text>
                      Please ensure that the image is in .png format and has a
                      transparent background.
                    </Text>
                    <Text>It shouldn't exceed 2MB in size.</Text>
                  </div>
                </HelpPopover>
              </Row>
              {formState.welcomeLogo ? (
                <Row nowrap gap={2} place={'center'} className={'mt-4 w-44'}>
                  <Text>Logo.png</Text>
                  <button
                    onClick={() => {
                      handleImageChanged('welcomeLogo', '');
                    }}
                  >
                    <AiFillCloseCircle size={14} color='var(--danger)' />
                  </button>
                </Row>
              ) : (
                <Row className='w-44'>
                  <ImageUploadButton
                    fill={'outline'}
                    onFileUploaded={(result) => {
                      handleImageChanged('welcomeLogo', result.httpsUrl);
                    }}
                  />
                </Row>
              )}
            </Col>
          </Stretched>
          <Stretched xgap={4} ygap={0}>
            <HexColorInput
              label='Background Color'
              color={formState.welcomeBgColor}
              defaultColor={DEFAULT_PROJECT_WELCOME_BG_COLOR}
              onChange={handleColorChange}
              name='welcomeBgColor'
              required={true}
              className={'w-full'}
            />

            <Col className={'w-full'}>
              <Row yalign={'center'} gap={2}>
                <FormLabel>Background Image</FormLabel>
                <HelpPopover
                  button={
                    <div className='block cursor-pointer' onClick={() => {}}>
                      <InfoIcon fill={'var(--neutral)'} />
                    </div>
                  }
                >
                  <div>
                    <Text>
                      Please ensure that the image is in .png format and has a
                      transparent background.
                    </Text>
                    <Text>It shouldn't exceed 2MB in size.</Text>
                  </div>
                </HelpPopover>
              </Row>
              {formState.welcomeBgImage ? (
                <Row nowrap gap={2} place={'center'} className={'mt-4 w-44'}>
                  <Text>Background.png</Text>
                  <button
                    onClick={() => {
                      handleImageChanged('welcomeBgImage', '');
                    }}
                  >
                    <AiFillCloseCircle size={14} color='var(--danger)' />
                  </button>
                </Row>
              ) : (
                <Row className='w-44'>
                  <ImageUploadButton
                    fill={'outline'}
                    onFileUploaded={(result) => {
                      handleImageChanged('welcomeBgImage', result.httpsUrl);
                    }}
                  />
                </Row>
              )}
            </Col>
          </Stretched>
        </Col>
      </Section>
    </Stacked>
  );
};
