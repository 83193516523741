import { Button, Text } from '@components';
import { useNetworks, useWallet } from '@contexts';
import { ReactNode, useEffect } from 'react';

interface ChainGateProps {
  requiredChainId: number;
  onChainChanged?: (chainId: number, isRequiredChain: boolean) => void;
  title?: string;
  subtitle?: string;
  children: ReactNode;
}

export const ChainGate: React.FC<ChainGateProps> = ({
  requiredChainId,
  onChainChanged,
  title,
  subtitle,
  children,
}) => {
  const { switchChain, connectedChainId } = useWallet();
  const { getNetworkDetails } = useNetworks();

  const chain = getNetworkDetails(requiredChainId);

  const titleText = title || 'Switch Chain';
  const instructionText =
    subtitle ||
    `Please connect to the ${chain?.name || 'correct'} chain to continue`;

  useEffect(() => {
    if (onChainChanged === undefined) {
      return;
    }

    onChainChanged(connectedChainId, requiredChainId === connectedChainId);
  }, [requiredChainId, connectedChainId]);

  if (connectedChainId !== requiredChainId) {
    return (
      <div className='bg-yellow-50 border rounded-md shadow-sm m-4 p-6 flex flex-col items-center justify-center gap-4'>
        <div>
          <Text className='text-lg font-bold text-center'>{titleText}</Text>
          <Text className='text-md text-center'>{instructionText}</Text>
        </div>
        <Button color='primary' onClick={() => switchChain(requiredChainId)}>
          Switch Now
        </Button>
      </div>
    );
  }

  // User is on the correct chain, render the children
  return <>{children}</>;
};
