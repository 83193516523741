import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { AnyImage } from './any-image';

interface FadeInImageProps {
  src: string;
  className?: string;
  alt: string;
  width: string | number;
}

export const FadeInImage = ({
  src,
  className = '',
  alt,
  width,
}: FadeInImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <AnyImage
      src={src}
      alt={alt}
      className={twMerge(
        `transition-opacity duration-1000 ease-in-out`,
        isLoaded ? 'opacity-100' : 'opacity-0',
        className,
      )}
      width={width}
      onLoad={() => setIsLoaded(true)}
    />
  );
};
