import { Col, Text } from '@components';
import { SignaturePage } from '@pages';
import { useEffect, useState } from 'react';
import { EventDocument } from 'tokensoft-shared-types';
import { EventDocumentsList } from './event-documents-list';

interface EventDocumentsSigningProps {
  documents: EventDocument[];
  onFinishedReviewingOrSigning: (isFinished: boolean) => void;
  isReviewing?: boolean;
}

export const EventDocumentsSigning: React.FC<EventDocumentsSigningProps> = ({
  documents,
  onFinishedReviewingOrSigning,
  isReviewing = true,
}) => {
  const [checkedState, setCheckedState] = useState<boolean[]>(
    new Array(documents.length).fill(false),
  );

  const handleCheckboxChange = (
    index: number,
    changed: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedCheckedState = [...checkedState];
    updatedCheckedState[index] = changed.target.checked;

    setCheckedState(updatedCheckedState);
  };

  const [isSignatureValid, setIsSignatureValid] = useState(false);

  const onSignatureValidChanged = (valid: boolean) => {
    setIsSignatureValid(valid);
  };

  const someDocRequiresSignature = documents.some(
    (doc) => doc.requiresSignature,
  );

  const getAgreementIntroText = () => {
    if (!isReviewing) {
      return 'You have reviewed and signed the following documents:';
    }

    const docStr = documents.length === 1 ? 'document' : 'documents';
    if (someDocRequiresSignature) {
      return `Please review and sign the following ${docStr}:`;
    }

    return `Please review the following ${docStr}:`;
  };

  const isAllDocumentsChecked = checkedState.every(Boolean);

  useEffect(() => {
    if (someDocRequiresSignature && !isSignatureValid) {
      onFinishedReviewingOrSigning(false);
      return;
    }

    if (!isAllDocumentsChecked) {
      onFinishedReviewingOrSigning(false);
      return;
    }

    onFinishedReviewingOrSigning(true);
  }, [
    isAllDocumentsChecked,
    isSignatureValid,
    onFinishedReviewingOrSigning,
    someDocRequiresSignature,
  ]);

  return (
    <Col gap={5}>
      <Text>{getAgreementIntroText()}</Text>
      <EventDocumentsList
        documents={documents}
        checkedState={checkedState}
        onCheckboxChange={handleCheckboxChange}
        isReviewing={isReviewing}
      />
      {isReviewing && someDocRequiresSignature && (
        <SignaturePage
          setSignatureValid={onSignatureValidChanged}
          nameLabel={'Name'}
        />
      )}
    </Col>
  );
};
