import { useGetEventOverviews, useGetProjectTheme } from '@apiServices';
import { Button, LoadingIndicator, Text } from '@components';
import { useAutoRedirectToEventOnce } from '@hooks';
import { Card, EventCard } from '@newComponents';
import { useNavigate } from 'react-router-dom';
import './events.css';

export const Events = () => {
  const {
    events,
    totalFetchableCount,
    isPending,
    isError,
    error,
    loadMore,
    hasNextPage,
    isFetchingNextPage,
  } = useGetEventOverviews();

  const { data: projectTheme } = useGetProjectTheme();

  const navigate = useNavigate();

  const navigateToEvent = (eventId: ID) => {
    navigate(`/event/${eventId}`);
  };

  // If only one event, go directly to that event, but only
  // on first load; don't want to prevent user from getting
  // to dashboard entirely.
  useAutoRedirectToEventOnce(events, navigateToEvent);

  return (
    <Card>
      {events?.length > 0 && (
        <>
          <div className='flex flex-row flex-wrap gap-6 max-w-[1132px] justify-center'>
            {events.map((event, i) => {
              return (
                <div key={i} className='max-w-[360px]'>
                  <EventCard
                    event={event}
                    projectLogoUrl={projectTheme?.logo ?? null}
                    onClick={() => navigateToEvent(event.id)}
                  />
                  {/* <EventCard event={event} eventUrl={`/event/${event.id}`} /> */}
                </div>
              );
            })}
          </div>
        </>
      )}

      {!isPending && events.length === 0 && (
        <>
          <Text>
            There aren't any active or upcoming events right now. Please check
            back soon.
          </Text>
        </>
      )}

      {isPending ? (
        <LoadingIndicator text='Loading Events' />
      ) : (
        hasNextPage && (
          <div className='flex justify-center'>
            <Button className={'w-full'} fill={'outline'} onClick={loadMore}>
              Load More
            </Button>
          </div>
        )
      )}
    </Card>
  );
};
