import { useWebApiClient } from '@apiClients';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const usePostSignedOrReviewedDocuments = (eventId: ID) => {
  const client = useWebApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: { documents: any; eventId: ID }) => {
      const { documents, eventId } = data;
      await client(`events/${eventId}/documents/signed-or-reviewed`, {
        method: 'POST',
        data: { documents },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['event', eventId, 'documents-status'],
      });
    },
  });
};
