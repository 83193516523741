import { createContext, useContext } from 'react';

interface ProjectContextProps {
  project: any;
  loading: boolean;
  view: string;
  setView: Function;
  hasApprovedComplianceDocs: boolean;
  isAuthorizedSigner: boolean;
  authorizedSigner: any;
  hasCompletedKyb: boolean;
  hasSetProjectDetails: boolean;
  projectSetupComplete: boolean;
}

export const ProjectContext = createContext<Maybe<ProjectContextProps>>(null);
ProjectContext.displayName = 'ProjectContext';

export const useProject = (): ProjectContextProps => {
  const context = useContext(ProjectContext);

  if (!context) {
    throw new Error('useProject must be used within a ProjectProvider');
  }

  return context;
};
