import { PaymentMethod } from '@customTypes';
import { getChainlinkOracleABI } from '@utils';
import { getAddress } from 'viem';
import { useReadContract } from 'wagmi';

export const useExchangeRate = (
  chainId: number,
  paymentMethod: PaymentMethod,
) => {
  const { oracle: priceOracleAddress } = paymentMethod;

  const config = {
    address: getAddress(priceOracleAddress),
    abi: getChainlinkOracleABI(),
    functionName: 'latestRoundData',
    chainId,
  };

  let exchangeRate = '0';

  const { data } = useReadContract(config);

  const latestRoundData = data?.[1];
  if (latestRoundData) {
    exchangeRate = latestRoundData.toString();
  }

  return exchangeRate;
};
