import {
  Alert,
  Col,
  FormLabel,
  HelpText,
  InputGroup,
  MultiSelect,
  renderVestingChart,
  Row,
  Section,
  SectionTitle,
  Stacked,
  Stretched,
  Text,
  validateForm,
  VestingTypeCard,
} from '@components';
import { DATE_TIME_ISO_FORMAT } from '@constants';
import { useAccount, useEvent } from '@contexts';
import { IWizardStateEditDistributor } from '@customTypes';
import { DEPLOY_VESTING_TYPE_OPTIONS } from '@enums';
import {
  addToDate,
  capitalize,
  getTimezoneAbbreviation,
  isValidDate,
  localToUtcDateTime,
  utcToEditableDateTime,
  utcToLocalDateTime,
  utcToUtcUnixDateTime,
} from '@utils';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

interface DistributorVestingProps {
  context: IWizardStateEditDistributor;
  setContext: (context: IWizardStateEditDistributor) => void;
  // used on edit screen
  hideCards?: boolean;
}

export const DistributorVesting = ({
  context,
  setContext,
  hideCards,
}: DistributorVestingProps) => {
  const { account } = useAccount();
  const { event } = useEvent();
  const [vestingType, setVestingType] = useState(
    context.vestingType || DEPLOY_VESTING_TYPE_OPTIONS.INSTANT,
  );
  const [startTime, setStartTime] = useState<string>(context.startTime);
  const [cliffTime, setCliffTime] = useState<Dayjs | string>(context.cliffTime);
  const [endTime, setEndTime] = useState<string>(context.endTime);
  const [cliffIntervalLength, setCliffIntervalLength] = useState(
    context.cliffIntervalLength || 0,
  );
  const [cliffIntervalUnits, setCliffIntervalUnits] = useState(
    context.cliffIntervalUnits || 'month',
  );
  const [endIntervalLength, setEndIntervalLength] = useState(
    context.endIntervalLength || 12,
  );
  const [endIntervalUnits, setEndIntervalUnits] = useState(
    context.endIntervalUnits || 'month',
  );

  const editableStartTime = isValidDate(startTime)
    ? utcToEditableDateTime(startTime, account?.timezone)
    : null;
  const formattedCliffTime = utcToLocalDateTime(cliffTime, account?.timezone);
  const formattedEndTime = utcToLocalDateTime(endTime, account?.timezone);

  const unixStartTime = isValidDate(startTime)
    ? utcToUtcUnixDateTime(startTime, DATE_TIME_ISO_FORMAT)
    : null;
  const unixCliffTime = isValidDate(cliffTime)
    ? utcToUtcUnixDateTime(cliffTime, DATE_TIME_ISO_FORMAT)
    : null;
  const unixEndTime = isValidDate(endTime)
    ? utcToUtcUnixDateTime(endTime, DATE_TIME_ISO_FORMAT)
    : null;
  const unixEventEndTime = event.endTime
    ? utcToUtcUnixDateTime(event.endTime, DATE_TIME_ISO_FORMAT)
    : null;

  const supportedContinuousUnits = [
    { value: 'day', label: 'Days' },
    { value: 'week', label: 'Weeks' },
    { value: 'month', label: 'Months' },
    { value: 'year', label: 'Years' },
  ];

  const supportedMonthlyUnits = [{ value: 'month', label: 'Months' }];

  const { formValid, errorMessage } = validateForm(
    vestingType,
    startTime,
    cliffIntervalLength,
    cliffTime,
    endIntervalLength,
    endTime,
  );

  const handleVestingTypeSelect = (type) => {
    setVestingType(type);
  };

  // calculate cliff and end times based on current start time.
  const calculateIntervalTimes = () => {
    if (!startTime) {
      return;
    }

    if (!isValidDate(startTime)) {
      return;
    }

    if (vestingType === DEPLOY_VESTING_TYPE_OPTIONS.INSTANT) {
      setCliffTime(startTime);
      setEndTime(startTime);
      setCliffIntervalLength(0);
      setCliffIntervalUnits('month');
      setEndIntervalLength(12);
      setEndIntervalUnits('month');
      return;
    }

    let appliedCliffLength = 0;
    if (cliffIntervalLength) {
      appliedCliffLength = cliffIntervalLength;
    }

    const adjustedCliffTime = addToDate(
      startTime,
      appliedCliffLength,
      cliffIntervalUnits,
    );

    // let adjustedCliffTimeStr = `${adjustedCliffTime}`;
    // setCliffTime(adjustedCliffTimeStr);
    setCliffTime(adjustedCliffTime);

    let appliedEndLength = 0;
    if (endIntervalLength) {
      appliedEndLength = endIntervalLength;
    }

    const adjustedEndTime = addToDate(
      startTime,
      appliedEndLength,
      endIntervalUnits,
    );
    setEndTime(localToUtcDateTime(adjustedEndTime));
  };

  const renderVestingPeriodFields = (targetVestingType) => {
    const targetVestingTypeUnits =
      targetVestingType === DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS
        ? supportedContinuousUnits
        : supportedMonthlyUnits;

    return (
      <Stretched gap={5} place={'between-center'}>
        <Col place={'start'}>
          <InputGroup
            className={'w-full'}
            type='datetime-local'
            label={`Start Date (${getTimezoneAbbreviation(account?.timezone)})`}
            value={editableStartTime}
            min={editableStartTime}
            name='startTime'
            required={true}
            disabled={false}
            onChange={(e) => {
              if (isValidDate(e.target.value)) {
                setStartTime(
                  localToUtcDateTime(e.target.value, account?.timezone),
                );
              }
            }}
          />
        </Col>

        <Col place={'start-top'}>
          <FormLabel>{`Cliff Period *`}</FormLabel>
          <Row nowrap gap={2} place={'start'}>
            <InputGroup
              width={'1/3'}
              type='number'
              name='cliffIntervalLength'
              placeholder='0'
              allowNegative={false}
              value={cliffIntervalLength}
              required={false}
              onChange={({ value }, { event }) => {
                setCliffIntervalLength(value);
              }}
            />

            <MultiSelect
              width={'2/3'}
              name={'cliffIntervalUnits'}
              className={'text-left'}
              options={targetVestingTypeUnits}
              value={targetVestingTypeUnits.find(
                (unit) => unit.value == cliffIntervalUnits,
              )}
              defaultValue={targetVestingTypeUnits[0]}
              onChange={(selected) => {
                setCliffIntervalUnits(selected.value);
              }}
              isMulti={false}
            />
          </Row>

          {isValidDate(startTime) && startTime !== cliffTime ? (
            <HelpText
              data-testid={'cliff-interval-help-text'}
              className={'-mt-4'}
            >
              <strong>Cliff Date:</strong> {formattedCliffTime}
            </HelpText>
          ) : null}
        </Col>

        <Col place={'start-top'}>
          <FormLabel>{`Vesting Period *`}</FormLabel>
          <Row nowrap gap={2} place={'start'}>
            <InputGroup
              width={'1/3'}
              type='number'
              name='endIntervalLength'
              placeholder='0'
              allowNegative={false}
              value={endIntervalLength}
              required={false}
              onChange={({ value }, { event }) => {
                setEndIntervalLength(value);
              }}
            />

            <MultiSelect
              width={'2/3'}
              name={'endIntervalUnits'}
              className={'text-left'}
              options={targetVestingTypeUnits}
              value={targetVestingTypeUnits.find(
                (unit) => unit.value == endIntervalUnits,
              )}
              defaultValue={targetVestingTypeUnits[1]}
              onChange={(selected) => {
                setEndIntervalUnits(selected.value);
              }}
              isMulti={false}
            />
          </Row>

          <HelpText data-testid={'end-interval-help-text'} className={'-mt-4'}>
            <strong>End Date:</strong> {formattedEndTime}
          </HelpText>
        </Col>
      </Stretched>
    );
  };

  const renderChart = () => {
    if (!unixEndTime) {
      return null;
    }

    return renderVestingChart(
      context,
      vestingType,
      unixStartTime,
      unixCliffTime,
      unixEndTime,
      unixEventEndTime,
      account,
    );
  };

  // re-validate form when time fields are modified
  useEffect(() => {
    validateForm(
      vestingType,
      startTime,
      cliffIntervalLength,
      cliffTime,
      endIntervalLength,
      endTime,
    );
  }, [cliffTime, endTime]);

  // re-calculate cliff and end times when form fields are modified
  useEffect(() => {
    calculateIntervalTimes();
  }, [
    vestingType,
    startTime,
    cliffIntervalLength,
    cliffIntervalUnits,
    endIntervalLength,
    endIntervalUnits,
  ]);

  // update context whenever vesting type or form validity changes
  useEffect(() => {
    setContext({
      ...context!,
      totalAllocations: context.totalAllocations,
      tokenInfo: {
        id: context.tokenInfo?.id,
        name: context.tokenInfo?.name,
        symbol: context.tokenInfo?.symbol,
        decimals: context.tokenInfo?.decimals,
      },
      vestingType,
      startTime,
      cliffTime,
      endTime,
      cliffIntervalLength,
      cliffIntervalUnits,
      endIntervalLength,
      endIntervalUnits,
      vestingTypeFormValid: formValid,
    });
  }, [vestingType, formValid, startTime, cliffTime, endTime]);

  return (
    <Stacked data-testid={'distributor-vesting'}>
      {!hideCards && (
        <Section place={'center'}>
          <SectionTitle data-testid={'distributor-vesting-title'}>
            Vesting Type
          </SectionTitle>
          <Text data-testid={'distributor-vesting-subtitle'}>
            Select the type of vesting schedule for this event.
          </Text>
        </Section>
      )}

      <Section data-testid={'vesting-types-container'}>
        <Stretched gap={5} place={'between-center'}>
          <VestingTypeCard
            onClick={() =>
              !hideCards &&
              handleVestingTypeSelect(DEPLOY_VESTING_TYPE_OPTIONS.INSTANT)
            }
            title={capitalize(DEPLOY_VESTING_TYPE_OPTIONS.INSTANT)}
            vestingType={DEPLOY_VESTING_TYPE_OPTIONS.INSTANT}
            selectedVestingType={vestingType}
            description={'Tokens vest at a single date and time.'}
            disabled={
              hideCards && vestingType !== DEPLOY_VESTING_TYPE_OPTIONS.INSTANT
            }
            help={
              <Col gap={2.5} place={'center'}>
                <HelpText>
                  Tokens vest immediately upon the Vesting Date. Participants
                  will be able to claim all of their tokens any time on or after
                  this date.
                </HelpText>
              </Col>
            }
          />

          <VestingTypeCard
            onClick={() =>
              !hideCards &&
              handleVestingTypeSelect(DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS)
            }
            title={capitalize(DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS)}
            vestingType={DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS}
            selectedVestingType={vestingType}
            description={'Tokens vest at a constant rate over time.'}
            disabled={
              hideCards &&
              vestingType !== DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS
            }
            help={
              <Col gap={2.5} place={'center'}>
                <HelpText>
                  Tokens vest at a uniform rate starting on the Start Date and
                  ending when the Vesting Period has elapsed. Participants can
                  only claim vested tokens once the Cliff Date is reached.
                </HelpText>
              </Col>
            }
          />

          <VestingTypeCard
            onClick={() =>
              !hideCards &&
              handleVestingTypeSelect(DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY)
            }
            title={capitalize(DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY)}
            vestingType={DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY}
            selectedVestingType={vestingType}
            description={'Tokens vest on the same date each month.'}
            disabled={
              hideCards && vestingType !== DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY
            }
            help={
              <Col gap={2.5} place={'center'}>
                <HelpText>
                  Tokens vest in uniform monthly tranches until the Vesting
                  Period has elapsed. Participants can only claim vested tokens
                  once the Cliff Period has elapsed.
                </HelpText>
              </Col>
            }
          />
        </Stretched>
      </Section>

      {vestingType === 'instant' ? (
        <>
          <Section place={'center'}>
            <SectionTitle data-testid={'vesting-details-title'}>
              Instant Vesting
            </SectionTitle>
            <Text data-testid={'vesting-details-subtitle'}>
              Please select a vesting date.
            </Text>
          </Section>

          {renderChart()}

          <Section place={'center'}>
            <InputGroup
              className={'w-fit'}
              type='datetime-local'
              label={`Vesting Date (${getTimezoneAbbreviation(
                account?.timezone,
              )})`}
              value={editableStartTime}
              min={editableStartTime}
              name='startTime'
              required={true}
              disabled={false}
              onChange={(e) => {
                if (isValidDate(e.target.value)) {
                  setStartTime(
                    localToUtcDateTime(e.target.value, account?.timezone),
                  );
                }
              }}
            />

            {errorMessage ? (
              <Alert
                data-testid={'vesting-details-error-alert'}
                type='alert-danger'
              >
                {errorMessage}
              </Alert>
            ) : null}
          </Section>
        </>
      ) : null}

      {vestingType === 'continuous' ? (
        <>
          <Section place={'center'}>
            <SectionTitle data-testid={'vesting-details-title'}>
              Continuous Vesting
            </SectionTitle>
            <Text data-testid={'vesting-details-subtitle'}>
              Please select a start date, cliff period, and vesting period.
            </Text>
          </Section>

          {renderChart()}

          <Section gap={5}>
            {renderVestingPeriodFields(DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS)}

            {errorMessage ? (
              <Alert
                data-testid={'vesting-details-error-alert'}
                type='alert-danger'
              >
                {errorMessage}
              </Alert>
            ) : null}
          </Section>
        </>
      ) : null}

      {vestingType === 'monthly' ? (
        <>
          <Section place={'center'}>
            <SectionTitle data-testid={'vesting-details-title'}>
              Monthly Vesting
            </SectionTitle>
            <Text data-testid={'vesting-details-subtitle'}>
              Please select a start date, cliff period, and vesting period.
            </Text>
          </Section>

          {renderChart()}

          <Section gap={5}>
            {renderVestingPeriodFields(DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY)}

            {errorMessage ? (
              <Alert
                data-testid={'vesting-details-error-alert'}
                type='alert-danger'
              >
                {errorMessage}
              </Alert>
            ) : null}
          </Section>
        </>
      ) : null}
    </Stacked>
  );
};
