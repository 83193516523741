import { Flex, Text } from '@components';
import { useNetworks } from '@contexts';
import classNames from 'classnames';

type FlexProps = React.ComponentProps<typeof Flex>;

interface NetworkIconProps extends FlexProps {
  chainId?: Maybe<number>;
  showNetworkName?: boolean;
  logoSize?: 'lg' | 'sm';
  direction?: 'row' | 'col';
}

export const NetworkIcon: React.FC<NetworkIconProps> = ({
  chainId = null,
  showNetworkName = true,
  logoSize = 'lg',
  direction = 'row',
  ...restProps
}) => {
  const { getNetworkDetails } = useNetworks();

  const network = getNetworkDetails(chainId ?? -1);
  const size = logoSize === 'lg' ? 36 : 24;

  return (
    <>
      <Flex gap={2.5} {...restProps} direction={direction}>
        {network && (
          <img
            src={network.logoUri}
            width={size}
            height={size}
            alt={network.name}
            className={classNames(direction === 'col' ? 'm-auto' : '')}
          />
        )}
        {showNetworkName && (
          <Text textAlign={'right'}>
            {network ? network.name : 'Unknown Network'}
          </Text>
        )}
      </Flex>
    </>
  );
};
