import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: React.ReactNode;
  title?: string;
}

export const Card = ({ className, children, title, ...props }: CardProps) => {
  return (
    <div
      className={twMerge(
        'rounded-[8px] p-[24px] overflow-hidden bg-white',
        className,
      )}
      {...props}
    >
      {!!title && (
        <div className='mb-2'>
          <p className='font-dm-sans font-bold text-black mb-2 text-xl'>
            {title}
          </p>
        </div>
      )}
      {children}
    </div>
  );
};
