export const SweepIcon = ({ fill = 'var(--primary)', size = 18 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.68418 5.23393L4.06532 7.61505L6.04521 5.63517L8.25504 7.845L7.00001 9.1H10.5V5.60003L9.24498 6.85506L6.04521 3.65529L4.06532 5.63517L2.33342 3.9033C3.33669 2.39445 5.05221 1.40003 7.00001 1.40003C10.0928 1.40003 12.6 3.90722 12.6 7.00003C12.6 10.0928 10.0928 12.6 7.00001 12.6C3.9072 12.6 1.4 10.0928 1.4 7.00003C1.4 6.38291 1.49981 5.78917 1.68418 5.23393ZM0.607599 4.14319L0.600509 4.15026L0.603217 4.15299C0.215516 5.02274 0 5.98622 0 7.00003C0 10.866 3.13401 14 7.00001 14C10.866 14 14 10.866 14 7.00003C14 3.134 10.866 3.43323e-05 7.00001 3.43323e-05C4.1516 3.43323e-05 1.70056 1.70131 0.607599 4.14319Z'
        fill='currentColor'
      />
    </svg>
  );
};
