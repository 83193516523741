import { createContext, useContext } from 'react';

export interface LayoutContextProps {
  shouldHideSidebar: boolean;
  setShouldHideSidebar: (shouldHideSidebar: boolean) => void;
  className: Maybe<string>;
  setClassName: (className: Maybe<string>) => void;
}

export const LayoutContext = createContext<Maybe<LayoutContextProps>>(null);
LayoutContext.displayName = 'LayoutContext';

export const useLayout = (): LayoutContextProps => {
  const context = useContext(LayoutContext);

  if (!context) {
    throw new Error(
      'LayoutProvider context is undefined, please verify you are calling useLayout() as child of a <LayoutProvider> component.',
    );
  }

  return context;
};
