import { Row } from '@components';
import classNames from 'classnames';
import { ReactNode } from 'react';

type RowProps = React.ComponentProps<typeof Row>;

type CardBodyProps = RowProps & {
  className?: string;
  open?: boolean;
  children?: ReactNode;
};

export const CardBody: React.FC<CardBodyProps> = ({
  children,
  open = true,
  className = '',
  ...restProps
}) => {
  return (
    <Row
      className={classNames(
        'inner-card-body collapsible',
        className,
        open ? 'mt-5' : 'collapsed',
      )}
      {...restProps}
    >
      {children}
    </Row>
  );
};
