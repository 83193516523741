import { Helmet } from 'react-helmet';

interface TitleProps {
  title?: string;
}

export const BrowserTitle = ({ title = 'Welcome' }: TitleProps) => {
  return (
    <>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
    </>
  );
};
