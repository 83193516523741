export const TIMEZONES = [
  {
    value: 'Pacific/Pago_Pago',
    abbr: 'SST',
    offset: -11,
    label: '(GMT-11:00) Samoa Time',
  },
  {
    value: 'Pacific/Honolulu',
    abbr: 'HST',
    offset: -10,
    label: '(GMT-10:00) Hawaii Time',
  },
  {
    value: 'Pacific/Marquesas',
    abbr: '',
    offset: -9.5,
    label: '(GMT-09:30) Marquesas Islands',
  },
  {
    value: 'America/Anchorage',
    abbr: 'AKST',
    offset: -9,
    label: '(GMT-09:00) Alaska Time',
  },
  {
    value: 'America/Los_Angeles',
    abbr: 'PST',
    offset: -8,
    label: '(GMT-08:00) Pacific Time',
  },
  {
    value: 'America/Denver',
    abbr: 'MST',
    offset: -7,
    label: '(GMT-07:00) Mountain Time',
  },
  {
    value: 'America/Chicago',
    abbr: 'CST',
    offset: -6,
    label: '(GMT-06:00) Central Time',
  },
  {
    value: 'America/New_York',
    abbr: 'EST',
    offset: -5,
    label: '(GMT-05:00) Eastern Time',
  },
  {
    value: 'America/Halifax',
    abbr: 'SWST',
    offset: -4,
    label: '(GMT-04:00) Atlantic Time',
  },
  {
    value: 'America/St_Johns',
    abbr: 'NST',
    offset: -3.5,
    label: '(GMT-03:30) Newfoundland Time',
  },
  {
    value: 'America/Sao_Paulo',
    abbr: 'AST',
    offset: -3,
    label: '(GMT-03:00) Buenos Aires',
  },
  {
    value: 'Atlantic/South_Georgia',
    abbr: 'U',
    offset: -2,
    label: '(GMT-02:00) South Georgia',
  },
  {
    value: 'Atlantic/Cape_Verde',
    abbr: 'MDT',
    offset: -1,
    label: '(GMT-01:00) Cape Verde',
  },
  { value: 'Etc/UTC', abbr: 'GMT', offset: 0, label: '(GMT+00:00) GMT' },
  {
    value: 'Europe/Belgrade',
    abbr: 'CET',
    offset: 1,
    label: '(GMT+01:00) Central European Time',
  },
  {
    value: 'Europe/Helsinki',
    abbr: 'EET',
    offset: 2,
    label: '(GMT+02:00) Eastern European Time',
  },
  {
    value: 'Africa/Khartoum',
    abbr: 'MSK',
    offset: 3,
    label: '(GMT+03:00) Eastern African Time',
  },
  {
    value: 'Asia/Tehran',
    abbr: '',
    offset: 3.5,
    label: '(GMT+03:30) Iran Standard Time',
  },
  { value: 'Asia/Dubai', abbr: 'GET', offset: 4, label: '(GMT+04:00) Dubai' },
  {
    value: 'Asia/Kabul',
    abbr: 'AFT',
    offset: 4.5,
    label: '(GMT+04:30) Afghanistan Standard Time',
  },
  {
    value: 'Asia/Karachi',
    abbr: 'PKT',
    offset: 5,
    label: '(GMT+05:00) Pakistan Standard Time',
  },
  {
    value: 'Asia/Calcutta',
    abbr: 'IST',
    offset: 5.5,
    label: '(GMT+05:30) India Standard Time',
  },
  {
    value: 'Asia/Katmandu',
    abbr: 'NPT',
    offset: 5.75,
    label: '(GMT+05:45) Katmandu',
  },
  {
    value: 'Asia/Dhaka',
    abbr: 'BST',
    offset: 6,
    label: '(GMT+06:00) Bangladesh Standard Time',
  },
  {
    value: 'Asia/Rangoon',
    abbr: 'MST',
    offset: 6.5,
    label: '(GMT+06:30) Myanmar Standard Time',
  },
  {
    value: 'Asia/Jakarta',
    abbr: 'WIB',
    offset: 7,
    label: '(GMT+07:00) Western Indonesia Time',
  },
  {
    value: 'Asia/Shanghai',
    abbr: 'CST',
    offset: 8,
    label: '(GMT+08:00) China Standard Time',
  },
  {
    value: 'Asia/Tokyo',
    abbr: 'KST',
    offset: 9,
    label: '(GMT+09:00) Japan Standard Time',
  },
  {
    value: 'Australia/Adelaide',
    abbr: 'ACST',
    offset: 9.5,
    label: '(GMT+09:30) Australia Central Standard Time',
  },
  {
    value: 'Australia/Brisbane',
    abbr: 'AEST',
    offset: 10,
    label: '(GMT+10:00) Australia Eastern Time',
  },
  {
    value: 'Pacific/Guadalcanal',
    abbr: 'CPST',
    offset: 11,
    label: '(GMT+11:00) Central Pacific Standard Time',
  },
  {
    value: 'Pacific/Auckland',
    abbr: 'NZST',
    offset: 12,
    label: '(GMT+13:00) New Zealand Standard Time',
  },
  {
    value: 'Pacific/Tongatapu',
    abbr: 'TST',
    offset: 13,
    label: '(GMT+13:00) Tonga Standard Time',
  },
];
