import { Button, FrontArrowIcon, Text } from '@components';
import classNames from 'classnames';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useWizard } from 'react-use-wizard';

type ButtonProps = React.ComponentProps<typeof Button>;

interface SaveButtonProps extends ButtonProps {
  disabled?: boolean;
  label?: string;
  icon?: React.ReactNode;
  saving?: boolean;
  savingLabel?: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SaveButton: React.FC<SaveButtonProps> = ({
  disabled = false,
  label = 'Save',
  icon = <FrontArrowIcon />,
  saving = false,
  savingLabel = 'Saving...',
  className = '',
  onClick,
  ...restProps
}) => {
  const { nextStep, isLoading } = useWizard();

  return (
    <Button
      className={classNames('md:w-36 w-full', className)}
      onClick={(e) => (onClick ? onClick(e) : nextStep())}
      disabled={disabled || isLoading || saving}
      {...restProps}
    >
      {saving ? (
        <>
          <div className='animate-spin'>
            <AiOutlineLoading3Quarters size={24} />
          </div>
          <Text>{savingLabel}</Text>
        </>
      ) : (
        <>
          <Text>{label}</Text>
          {icon}
        </>
      )}
    </Button>
  );
};
