import { getFlatPriceSaleABI } from '@utils';
import { getAddress } from 'viem';
import { useReadContract } from 'wagmi';

export const useDelay = (sale?: any, walletAddress?: string) => {
  let delay: Maybe<number> = null;

  const config = {
    address: sale?.id ? getAddress(sale.id) : undefined,
    abi: getFlatPriceSaleABI(),
    functionName: 'getFairQueueTime',
    chainId: sale?.chainId ? sale.chainId : null,
    args: [walletAddress],
  } as const;

  const { data, isSuccess, isLoading } = useReadContract(config);

  if (data) {
    delay = Number(data);

    if (delay === 0) {
      delay = null;
    }
  }

  return {
    delay,
    isSuccess,
    isLoading,
  };
};
