import { DEFAULT_THEME_VERSION, DEFAULT_TYPOGRAPHY } from '@constants';
import { getResourceUri, getValidIPFSHash } from '@utils';
import { Helmet } from 'react-helmet';
import { ProjectTheme, ProjectWelcomeContent } from 'tokensoft-shared-types';

interface ProjectThemeConfigurationProps {
  projectTheme: ProjectTheme;
  welcomeContent: ProjectWelcomeContent;
}
export const ProjectThemeConfiguration = ({
  projectTheme,
  welcomeContent,
}: ProjectThemeConfigurationProps) => {
  return (
    <Helmet>
      <body
        data-typography={projectTheme.fonts.base || DEFAULT_TYPOGRAPHY}
        data-theme-version={projectTheme.version || DEFAULT_THEME_VERSION}
        data-env={import.meta.env.VITE_PROCESS_ENV}
      />

      {projectTheme && (
        <style type='text/css'>
          {`
            :root {
              ${
                projectTheme.colors.primary
                  ? `
                --primary: ${projectTheme.colors.primary} !important;
                --primary-light: ${projectTheme.colors.primary}4D  !important; /* 10% opacity */
                --primary-extra-light: ${projectTheme.colors.primary}1A  !important; /* 50% opacity */
              `
                  : ''
              }
              ${
                projectTheme.colors.secondary
                  ? `
                --secondary: ${projectTheme.colors.secondary} !important;
                --secondary-light: ${projectTheme.colors.secondary}1A !important;  /* 10% opacity */
                --secondary-extra-light: ${projectTheme.colors.secondary}80 !important; /* 50% opacity */
              `
                  : ''
              }
              ${
                projectTheme.fonts.base
                  ? `--font-family-base: ${projectTheme.fonts.base} !important;`
                  : ''
              }
              ${
                projectTheme.fonts.baseSize
                  ? `--font-size-base: ${projectTheme.fonts.baseSize} !important;`
                  : ''
              }
              ${
                projectTheme.fonts.title
                  ? `--font-family-title: ${projectTheme.fonts.title} !important;`
                  : ''
              }
              ${
                projectTheme.fonts.titleSize
                  ? `--font-size-title: ${projectTheme.fonts.titleSize} !important;`
                  : ''
              }
              ${
                projectTheme.colors.headerBackground
                  ? `--header-background: ${projectTheme.colors.headerBackground} !important;`
                  : ''
              }
              ${
                projectTheme.colors.sidebarBackground
                  ? `--sidebar-background: ${projectTheme.colors.sidebarBackground} !important;`
                  : ''
              }
              ${
                projectTheme.colors.contentBackground
                  ? `--content-background: ${projectTheme.colors.contentBackground} !important;`
                  : ''
              }
              ${
                projectTheme.colors.headerText
                  ? `--header-font-color: ${projectTheme.colors.headerText} !important;`
                  : ''
              }
              ${
                projectTheme.colors.sidebarText
                  ? `--sidebar-font-color: ${projectTheme.colors.sidebarText} !important;`
                  : ''
              }
              ${
                projectTheme.colors.containerBackground
                  ? `--container-background: ${projectTheme.colors.containerBackground} !important;`
                  : ''
              }
              ${
                welcomeContent.backgroundColor
                  ? `--welcome-content-background: ${welcomeContent.backgroundColor} !important;`
                  : ''
              }
              ${
                welcomeContent.textColor
                  ? `--welcome-content-color: ${welcomeContent.textColor} !important;`
                  : ''
              }    
            }

            ${
              welcomeContent.backgroundImage
                ? `
              .domain-content {
                  background-image: url("${
                    getValidIPFSHash(welcomeContent.backgroundImage)
                      ? getResourceUri(welcomeContent.backgroundImage)
                      : welcomeContent.backgroundImage
                  }") !important;
                  background-size: cover !important;
              }      
            `
                : ''
            }                
          `}
        </style>
      )}
    </Helmet>
  );
};
