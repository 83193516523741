import { useGetAdminLegacyPurchases } from '@apiServices';
import { useToast } from '@contexts';
import { getTableDataForExport, makeCsv } from '@utils';
import { useEffect } from 'react';

interface LegacyPurchasesDownload {
  children: React.ReactNode;
  projectId: string;
  isLoading: boolean;
  onDownload?: any;
  onLoading?: Function;
}

export const LegacyPurchasesDownload = ({
  children,
  projectId,
  onDownload,
  onLoading,
  isLoading,
}: LegacyPurchasesDownload) => {
  const { showErrorToast, showSuccessToast, showInfoToast } = useToast();

  useEffect(() => {
    if (onDownload && !isLoading) {
      downloadData();
    }
  }, [onDownload]);

  const { mutateAsync: getAdminLegacyPurchases } = useGetAdminLegacyPurchases();

  const getAdminLegacyPurchasesPromise = async (
    offset: number,
    queryLimit: number,
  ) => {
    return getAdminLegacyPurchases(
      {
        offset,
        queryLimit,
        projectId,
      },
      {
        onSuccess: (data) => {
          return data;
        },
        onError: (error) => {
          throw new Error(error.message);
        },
      },
    );
  };

  const downloadData = async () => {
    if (onLoading !== undefined) {
      onLoading(true);
    }
    showInfoToast({ description: 'Downloading data...' });

    let purchases = [];
    let nextLink;
    let offset = 0;
    let queryLimit = 200;

    try {
      do {
        const data = await getAdminLegacyPurchasesPromise(offset, queryLimit);

        if (data.nextLink) {
          let paramString = data.nextLink.split('?')[1];
          let searchParams = new URLSearchParams(paramString);
          offset = Number(searchParams.get('offset'));
          queryLimit = Number(searchParams.get('queryLimit'));
        }

        nextLink = data.nextLink;
        purchases = purchases.concat(data.purchases);
      } while (nextLink);
      if (onLoading !== undefined) {
        onLoading(false);
      }
      makeCsv(
        getTableDataForExport(purchases, columns),
        'legacy-purchases.csv',
      );
      showSuccessToast({ description: 'Download complete' });
    } catch (e: any) {
      if (onLoading !== undefined) {
        onLoading(false);
      }
      showErrorToast({ description: e.message });
    }
  };

  const columns = [
    { Header: 'user id', accessor: 'userId' },
    { Header: 'email', accessor: 'email' },
    { Header: 'wallet address', accessor: 'walletAddress' },
    { Header: 'payment type', accessor: 'paymentType' },
    { Header: 'transaction hash', accessor: 'transactionHash' },
    { Header: 'usd amount', accessor: 'usdAmount' },
    { Header: 'tokens purchase', accessor: 'tokensPurchase' },
    { Header: 'value', accessor: 'value' },
    { Header: 'saft', accessor: 'saft' },
    { Header: 'created at', accessor: 'createdAt' },
    { Header: 'updated at', accessor: 'updatedAt' },
  ];

  return <>{children}</>;
};
