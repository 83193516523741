export const SelectedIcon = ({ fill = 'var(--primary)' }) => {
  return (
    <svg
      data-testid={'selected-icon'}
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
    >
      <path
        d='M16 6.5252e-05C24.8365 6.5252e-05 32 7.16352 32 16C32 24.8365 24.8365 32 16 32C7.16352 32 6.63659e-05 24.8365 6.63659e-05 16C-0.0250774 7.18858 7.09757 0.025209 15.9089 6.5252e-05C15.9393 -2.17507e-05 15.9697 -2.17507e-05 16 6.5252e-05Z'
        fill='#4D3FCD'
      />
      <path
        d='M24.8632 11.5908L13.4996 22.9544L7.13599 16.6363L9.72693 14.0909L13.4996 17.8182L22.2724 9.04541L24.8632 11.5908Z'
        fill='white'
      />
    </svg>
  );
};
