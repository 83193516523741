import { useWebApiClient } from '@apiClients';
import { useQuery } from '@tanstack/react-query';
import { UserKycTierStatus } from 'tokensoft-shared-types';

export const useGetKycTierStatuses = (
  refetchInterval: number | false = 10000,
) => {
  const fetchWithClient = useWebApiClient();

  return useQuery<UserKycTierStatus[]>({
    queryKey: ['kyc', 'tier-statuses'],
    queryFn: async () => {
      const statusesObj = await fetchWithClient(`kyc/tier-statuses`);
      return statusesObj.kycTierStatuses;
    },
    refetchInterval: refetchInterval,
  });
};
