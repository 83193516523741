import { Text } from '@components';
import classNames from 'classnames';

type TextProps = React.ComponentProps<typeof Text>;
interface EventTitleProps extends TextProps {
  className?: string;
  children: React.ReactNode;
}

export const EventTitle: React.FC<EventTitleProps> = ({
  className,
  children,
  ...restProps
}) => {
  return (
    <Text
      className={classNames('text-6xl font-bold leading-16', className)}
      data-testid='event-banner-title'
      {...restProps}
    >
      {children}
    </Text>
  );
};
