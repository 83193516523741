import { useGetTokenInfo } from '@apiServices';
import {
  Alert,
  Col,
  InputGroup,
  LoadingIndicator,
  NetworkSelect,
  Section,
  SectionTitle,
  Stacked,
  TokenInfoCard,
} from '@components';
import { useNetworks, useToast } from '@contexts';
import { useEffect, useState } from 'react';

interface DistributorTokenSelectionProps {
  context: any;
  setContext: Function;
}

export const DistributorTokenSelection = ({
  context,
  setContext,
}: DistributorTokenSelectionProps) => {
  const { showErrorToast } = useToast();
  const { supportedNetworks } = useNetworks();

  const [tokenAddress, setTokenAddress] = useState(context?.tokenAddress || '');
  const [tokenAddressValid, setTokenAddressValid] = useState(
    context?.tokenAddressValid || false,
  );
  const [tokenInfo, setTokenInfo] = useState(context?.tokenInfo || null);
  const [networkId, setNetworkId] = useState(context.networkId);

  const { mutate: getTokenInfo, isPending } = useGetTokenInfo();

  const isAddress = tokenAddress?.length === 42;
  const formValid = isAddress && tokenAddressValid;
  const deployDistributorNetworks = supportedNetworks.filter(
    (network) =>
      !!network.continuousVestingDistributorFactoryAddress &&
      !!network.trancheVestingDistributorFactoryAddress,
  );

  const loadTokenInfoOnNetwork = (newAddress) => {
    setTokenAddress(newAddress);
    setTokenAddressValid(false);

    if (newAddress.length !== 42) {
      return;
    }

    getTokenInfo(
      { networkId: networkId, tokenAddress: newAddress },
      {
        onSuccess: (result) => {
          if (result.valid) {
            setTokenInfo(result);
            setTokenAddressValid(true);
          } else {
            setTokenInfo(null);
            setTokenAddressValid(false);
          }
        },
        onError: (error) => {
          showErrorToast({
            description: 'Error validating token.  Please try again.',
          });
          setTokenAddress('');
          setTokenInfo(null);
          setTokenAddressValid(false);
        },
      },
    );
  };

  useEffect(() => {
    loadTokenInfoOnNetwork(tokenAddress);
  }, [networkId]);

  const handleNetworkChange = (id) => {
    setNetworkId(id);
    setTokenAddressValid(false);
    setTokenInfo(null);
  };

  useEffect(() => {
    setContext({
      ...context,
      tokenAddress,
      tokenAddressValid,
      tokenInfo,
      networkId: networkId,
      tokenSelectionFormValid: formValid && !isPending,
    });
  }, [formValid]);

  return (
    <Stacked data-testid='distributor-token-selection'>
      <Section place={'start-center'}>
        <SectionTitle>Network</SectionTitle>
        <NetworkSelect
          supportedNetworks={deployDistributorNetworks}
          direction={'row'}
          size={'sm'}
          selected={networkId}
          setSelected={handleNetworkChange}
          subtitle='Please select the EVM network where your ERC-20 token contract is deployed.'
        />
      </Section>

      <Section gap={2} place={'start-center'}>
        <SectionTitle textAlign={'left'}>Enter Token Address *</SectionTitle>

        <Col>
          <InputGroup
            name={'tokenAddress'}
            value={tokenAddress}
            placeholder={'Enter Token Address'}
            required={false}
            onChange={(e) => loadTokenInfoOnNetwork(e.target.value)}
          />

          {isPending ? (
            <Col place={'center'}>
              <LoadingIndicator text={'Verifying Token Address'} />
            </Col>
          ) : null}

          {!isPending && formValid ? (
            <TokenInfoCard
              tokenInfo={tokenInfo}
              networkId={networkId}
              showSuccess={true}
            />
          ) : null}

          {!isPending && isAddress && !tokenAddressValid ? (
            <Alert data-testid={'invalid-token-address'} type='alert-danger'>
              Invalid Token Address. Please make sure you have selected the
              correct network.
            </Alert>
          ) : null}
        </Col>
      </Section>
    </Stacked>
  );
};
