import { useDistributorApiClient } from '@apiClients';
import { TierVesting, Tranche } from '@customTypes';
import { useMutation } from '@tanstack/react-query';
import {
  div,
  getVestedFractionForContinuous,
  getVestedFractionForPriceTier,
  getVestedFractionForTranche,
  isContinuousVestingType,
  isIPriceTierVesting,
  isTrancheVestingType,
  mult,
  toNumber,
} from '@utils';
import BigNumber from 'bignumber.js';

export const useGetDistributorSummary = () => {
  // const { results: events, loading: eventsLoading } = useGetEvents([
  //   EVENT_TYPE.DISTRIBUTE,
  // ]);
  // const { results: merkleDistributors, loading: merkleDistributorsLoading } =
  //   useGetDistributors(true);
  // const [distributorData, setDistributorData] = useState<any[]>([]);
  // useEffect(() => {
  //   if (!events) {
  //     return;
  //   }
  //   setDistributorData(events);
  // }, [events]);
  // useEffect(() => {
  //   if (eventsLoading) {
  //     return;
  //   }
  //   if (!distributorData) {
  //     return;
  //   }
  //   if (!merkleDistributors) {
  //     return;
  //   }
  //   const newDistributorData = [...events];
  //   merkleDistributors.forEach((distributor) => {
  //     const loadedDistributorIndex = newDistributorData.findIndex(
  //       (d) => d.distributor.id === distributor.id,
  //     );
  //     if (loadedDistributorIndex >= 0) {
  //       const loadedDistributor = newDistributorData[loadedDistributorIndex];
  //       newDistributorData[loadedDistributorIndex] = Object.assign(
  //         {},
  //         distributor,
  //         loadedDistributor,
  //         { name: loadedDistributor.name },
  //       );
  //     }
  //   });
  //   setDistributorData(newDistributorData);
  // }, [merkleDistributors, eventsLoading]);
  // return {
  //   results: distributorData,
  //   loading: eventsLoading,
  //   merkleDataLoading: merkleDistributorsLoading,
  // };
};

export const interpolateClaimbleForInterface = (
  interfaces: any[],
  recordTotal: number | string | BigNumber,
  start?: number,
  cliff?: number,
  end?: number,
  tranches?: Tranche[],
  fractionDenominator?: BigNumber,
  oraclePrice?: number,
  tierVesting?: TierVesting[],
) => {
  if (
    !!start &&
    !!cliff &&
    !!end &&
    !!fractionDenominator &&
    isContinuousVestingType(interfaces)
  ) {
    const vestedFraction = getVestedFractionForContinuous(
      start,
      cliff,
      end,
      fractionDenominator,
    );
    return div(mult(recordTotal, vestedFraction), fractionDenominator);
  } else if (
    isTrancheVestingType(interfaces) &&
    !!tranches &&
    !!fractionDenominator
  ) {
    // Manually calculate for tranche
    const vestedFraction = getVestedFractionForTranche(tranches);
    console.info('vested fraction:', vestedFraction);
    return div(mult(recordTotal, vestedFraction), fractionDenominator);
  } else if (isIPriceTierVesting(interfaces) && !!fractionDenominator) {
    // get vested fraction for price tier
    if (!tierVesting || !tierVesting.length || !oraclePrice) {
      return toNumber(0);
    }
    const vestedFraction = getVestedFractionForPriceTier(
      tierVesting,
      oraclePrice,
    );
    console.info('vested fraction:', vestedFraction);
    return div(mult(recordTotal, vestedFraction), fractionDenominator);
  }

  return recordTotal && !!fractionDenominator
    ? div(recordTotal, fractionDenominator)
    : toNumber(0);
};

export const useGetTokenInfo = () => {
  const client = useDistributorApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(
        `tokens/info?networkId=${data.networkId}&tokenAddress=${data.tokenAddress}`,
        {
          method: 'get',
        },
      );
    },
  });
};
