import {
  useGetDistributor,
  usePrepareDistributorParticipantsUpdate,
} from '@apiServices';
import {
  BackButton,
  ButtonRow,
  DistributorParticipants,
  LoadingIndicator,
  PageSubtitle,
  PageTitle,
  SaveButton,
  Step,
  StepFooter,
  StepHeader,
  SwitchChainWarning,
} from '@components';
import {
  useEvent,
  useModal,
  useNetworks,
  useToast,
  useWallet,
  WizardContext,
} from '@contexts';
import { VESTING_TYPE } from '@enums';
import { UpdateDistributorParticipantsSigningModalPage } from '@pages';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';

export const EditDistributorParticipantsWizardPage = () => {
  const { getNetworkDetails } = useNetworks();
  const navigate = useNavigate();
  const { event } = useEvent();
  const { distributor, loading } = useGetDistributor(event.distributor?.id);
  const { showErrorToast, showSuccessToast } = useToast();
  const { connectedChainId } = useWallet();

  const { mutate: prepareDistributor } =
    usePrepareDistributorParticipantsUpdate();

  const [wizardState, setWizardState] = useState<any>();
  const [preparingUpdate, setPreparingUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (distributor) {
      setWizardState({
        participantsFormValid: false,
        eventId: distributor.event?.id,
        saleId: distributor.id,
        networkId: distributor.chainId,
      });
    }
  }, [distributor]);

  const goBack = () => {
    navigate(-1);
  };

  const { showModal, closeModal } = useModal();

  const getVestingType = (distributor): VESTING_TYPE | null => {
    if (!distributor) return null;
    return distributor?.trancheVesting !== null
      ? VESTING_TYPE.TRANCHE
      : VESTING_TYPE.CONTINUOUS;
  };

  const showSignaturesModal = (prepareDistributorUpdateResult: any) => {
    // Show modal with sequence of two signatures, contract & config
    const vestingType = getVestingType(distributor);

    const { merkleRoot, ipfsConfigUri } = prepareDistributorUpdateResult;

    const handleFinished = () => {
      closeModal();
      goBack();
    };

    showModal({
      className: 'md',
      content: (
        <UpdateDistributorParticipantsSigningModalPage
          vestingType={vestingType}
          distributor={distributor}
          merkleRoot={merkleRoot}
          ipfsUri={ipfsConfigUri}
          onExit={closeModal}
          onFinish={handleFinished}
        />
      ),
      disableClose: true,
    });
  };

  const handleSave = async () => {
    setPreparingUpdate(true);

    prepareDistributor(
      {
        ...wizardState,
      },
      {
        onSuccess: (preparedUpdateData) => {
          setPreparingUpdate(false);
          if (preparedUpdateData.valid) {
            showSignaturesModal(preparedUpdateData);
          } else {
            showErrorToast({
              description:
                preparedUpdateData.errorMessage ||
                'Error updating distributor.  Please try again.',
            });
          }
        },
        onError: (error) => {
          setPreparingUpdate(false);
          console.log('On error: ', error);
          showErrorToast({
            description:
              error.toString() ||
              'Error updating distributor.  Please try again.',
          });
        },
      },
    );
  };

  const handleCancel = () => {
    goBack();
  };

  if (!distributor || !wizardState) {
    return <LoadingIndicator text={'Loading event'} />;
  }

  if (!loading && distributor && distributor.chainId !== connectedChainId) {
    const correctNetworkDetails = getNetworkDetails(distributor.chainId);
    return (
      <SwitchChainWarning
        chainId={distributor.chainId}
        subtitle={`Please connect to the ${correctNetworkDetails.name} network to continue.`}
      />
    );
  }

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        <Step>
          <StepHeader>
            <PageTitle>Participants</PageTitle>
            <PageSubtitle>
              Specify who can participate in the event. Everyone in the
              participant list must also meet all event eligibility requirements
              before they can purchase in this app.
            </PageSubtitle>
          </StepHeader>

          <DistributorParticipants
            context={wizardState}
            setContext={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton
                label={'Cancel'}
                onClick={handleCancel}
                disabled={preparingUpdate}
              />
              <SaveButton
                disabled={
                  preparingUpdate ||
                  (wizardState && !wizardState.participantsFormValid)
                }
                saving={preparingUpdate}
                onClick={handleSave}
              />
            </ButtonRow>
          </StepFooter>
        </Step>
      </Wizard>
    </WizardContext.Provider>
  );
};
