import { useGetAccountByEmail } from '@apiServices';
import {
  Alert,
  PageHeader,
  PermissionDeniedError,
  Stacked,
  UserDetails,
  UserDetailsTables,
  UserEvents,
} from '@components';
import { useEvent, useProject } from '@contexts';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const UserPage = ({}) => {
  const [userData, setUserData] = useState<Maybe<any>>(null);
  const { authId, id } = useParams();
  const { mutate: getAccountInfo } = useGetAccountByEmail();
  const { project } = useProject();
  const { event } = useEvent();
  const navigate = useNavigate();
  const [error, setError] = useState<Maybe<any>>(null);

  const getDomainName = () => {
    return project?.domains[0].domainName;
  };

  useEffect(() => {
    getAccountInfo(
      {
        authId,
        projectId: id,
      },
      {
        onSuccess: (data) => {
          setUserData(data);
        },
        onError: (err) => {
          setError(err);
        },
      },
    );
  }, []);

  return error ? (
    <>
      <PermissionDeniedError message={error.message} />
    </>
  ) : (
    <>
      <PageHeader
        title={`User Detail`}
        breadcrumb={
          <>
            <a
              className='cursor-pointer capitalize'
              onClick={() => {
                navigate(`/admin/project/${project?.id}`);
              }}
            >
              &lt; {project?.name}
            </a>{' '}
            &lt;{' '}
            <a
              className='cursor-pointer capitalize'
              onClick={() => {
                navigate(`/admin/project/${project?.id}/event/${event?.id}`);
              }}
            >
              &lt; {event?.name}
            </a>{' '}
            &lt; User Detail
          </>
        }
      />
      <Stacked>
        {userData?.consent === false ? (
          <Alert type='alert-warning'>
            <div>
              Once this user registers on{' '}
              <span className='text-primary-medium'>{getDomainName()}</span>{' '}
              their information will be visible.
            </div>
          </Alert>
        ) : null}
        <UserDetails data={userData} />
        <UserEvents data={userData} />
        <UserDetailsTables data={userData} />
      </Stacked>
    </>
  );
};
