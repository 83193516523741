import { ReactNode, createContext, useContext } from 'react';

export interface Modal {
  content: ReactNode;
  onClose?: Function;
  isOpen?: boolean;
  className?: string;
  disableClose?: boolean;
}

export interface ModalContextProps {
  modal: Maybe<Modal>;
  setModal(modal: Maybe<Modal>): void;
  showModal: Function;
  closeModal: Function;
  isOpen: boolean;
}

export const ModalContext = createContext<Maybe<ModalContextProps>>(null);
ModalContext.displayName = 'ModalContext';

export const useModal = (): ModalContextProps => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error(
      'ModalProvider context is undefined, please verify you are calling useModal() as child of a <ModalProvider> component.',
    );
  }

  return context;
};
