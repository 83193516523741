import { ButtonRow, DocumentUploader, PageHeader } from '@components';
import { useAnalytics } from '@contexts';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export const AccreditationPending = () => {
  useAnalytics('/account/accreditation-pending');

  const [showForm, setShowForm] = useState<boolean>(false);

  return (
    <>
      <PageHeader title={'Application pending'} />

      {showForm ? (
        <DocumentUploader
          onSetIsShowing={setShowForm}
        />
      ) : (
        <>
          <p className='text-xs md:text-base mb-8 text-center'>
            Your accreditation check is underway. We will notify you once your
            documents have been reviewed. This process typically takes between
            3-5 business days.
          </p>

          <ButtonRow place={'center'}>
            <Link className='btn btn-primary' to='/account'>
              My Profile
            </Link>
            <Link className='btn btn-primary' to='/'>
              Dashboard
            </Link>

            <button
              className='btn btn-primary'
              onClick={() => setShowForm(true)}
            >
              Submit More Documents
            </button>
          </ButtonRow>
        </>
      )}
    </>
  );
};
