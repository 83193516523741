import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  EditIcon,
  Text,
  TextLink,
} from '@components';
import { useState } from 'react';

type EventPublicOverviewPageProps = {
  onEdit?: () => void;
};

export const EventPublicOverviewPage = ({ onEdit }: EventPublicOverviewPageProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <Card>
      <CardHeader place={'center'}>
        <CardTitle open={isOpen} onOpen={setIsOpen}>
          Event Privacy
        </CardTitle>
        {onEdit && (
          <TextLink width={'fit'} onClick={onEdit}>
            <EditIcon />
            <Text>Edit Details</Text>
          </TextLink>
        )}
      </CardHeader>
      <CardBody open={isOpen}>
        <Text>This event is not restricted by a privacy list.</Text>
      </CardBody>
    </Card>
  );
};
