import { useEffect, useState } from 'react';
import { useWindowSize } from './use-window-size';

// Tailwind CSS default breakpoints
const defaultBreakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

export const useBreakpoint = (breakpoints = defaultBreakpoints) => {
  const [breakpoint, setBreakpoint] =
    useState<Maybe<keyof typeof defaultBreakpoints>>(null);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width !== undefined) {
      const sortedBreakpoints = Object.keys(breakpoints)
        .map((key) => [key, breakpoints[key]])
        .sort((a, b) => a[1] - b[1]);

      let activeBreakpoint = sortedBreakpoints[0][0];
      for (const [key, value] of sortedBreakpoints) {
        if (width >= value) {
          activeBreakpoint = key;
        }
      }
      setBreakpoint(activeBreakpoint);
    }
  }, [width, breakpoints]);

  return breakpoint;
};
