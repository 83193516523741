import { Col, Row, Stretched, TableHeader, Text } from '@components';
import { ELIGIBILITY_CATEGORY } from '@enums';
import { add, formatValue } from '@utils';
import './event-eligibility.css';

interface EventEligibilityAllocationSummaryProps {
  eligibilityData?: any;
}

export const EventEligibilityAllocationSummary = ({
  eligibilityData,
}: EventEligibilityAllocationSummaryProps) => {
  const eligibilityAllocations = eligibilityData?.eligibility.filter(
    (data) => data.category === ELIGIBILITY_CATEGORY.ELIGIBILITY_ALLOCATIONS,
  );

  const maxAllocationSaleToken =
    eligibilityAllocations?.reduce((acc, cur) => {
      const itemTotal = cur.subcriteria?.criteria?.reduce((subAcc, subCur) => {
        return add(subAcc, subCur.value.allocation_amount_sale_token);
      }, 0);
      return add(acc, itemTotal);
    }, 0) || 0;

  const maxBonusSaleToken =
    eligibilityAllocations?.reduce((acc, cur) => {
      const itemTotal = cur.subcriteria?.criteria?.reduce((subAcc, subCur) => {
        return add(subAcc, subCur.value.bonus_amount_sale_token);
      }, 0);
      return add(acc, itemTotal);
    }, 0) || 0;

  const saleTokenSymbol =
    eligibilityAllocations && eligibilityAllocations.length
      ? eligibilityAllocations[0]?.subcriteria?.criteria[0]?.value
          ?.sale_token_symbol
      : '';

  if (!eligibilityAllocations || eligibilityAllocations.length === 0) {
    return null;
  }

  return (
    <Col gap={5}>
      <TableHeader>Your Max Purchase Allocation</TableHeader>
      <Stretched ygap={5} xgap={5}>
        <Col>
          <Text>Max Purchase Allocation</Text>
          <Row
            nowrap
            className={'max-allocation'}
            place={'between'}
            yalign={'center'}
          >
            <Text className={'allocation-token-name'}>{saleTokenSymbol}</Text>
            <Text className={'allocation-amount'}>
              {formatValue(maxAllocationSaleToken, { commas: true })}
            </Text>
          </Row>
        </Col>

        <Col>
          <Text>Max Token Bonus</Text>
          <Row
            nowrap
            className={'max-bonus'}
            place={'between'}
            yalign={'center'}
          >
            <Text className={'bonus-token-name'}>{'Bonus'}</Text>
            <Text className={'bonus-amount'}>
              {formatValue(maxBonusSaleToken, { commas: true })}
            </Text>
          </Row>
        </Col>
      </Stretched>
    </Col>
  );
};
