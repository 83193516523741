import { useGetSale, useSaleConfigUpdated } from '@apiServices';
import {
  BackButton,
  ButtonRow,
  LoadingIndicator,
  PageSubtitle,
  PageTitle,
  SalePaymentConfig,
  SaveButton,
  Step,
  StepFooter,
  StepHeader,
  SwitchChainWarning,
} from '@components';
import {
  DEFAULT_BASE_CURRENCY,
  DEFAULT_BASE_CURRENCY_DECIMALS,
} from '@constants';
import {
  useAccount,
  useEvent,
  useNetworks,
  useToast,
  useWallet,
  WizardContext,
} from '@contexts';
import { useUpdateSaleOnChainConfig } from '@hooks';
import {
  getTxUrl,
  localToUtcUnixDateTime,
  unixToEditableDateTime,
} from '@utils';
import { useEffect, useState } from 'react';
import { VscLinkExternal } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';

export const EditSalePaymentConfigWizardPage = () => {
  const navigate = useNavigate();
  const { connectedChainId } = useWallet();
  const { account } = useAccount();
  const { event } = useEvent();
  const { sale, loading } = useGetSale(true, event?.id);
  const { showErrorToast, showSuccessToast } = useToast();
  const { getNetworkDetails, getNativeBaseCurrencyPriceOracle } = useNetworks();
  const [txPending, setTxPending] = useState<boolean>(false);
  const {
    mutate: saleConfigUpdated,
    isPending: saleConfigUpdatedLoading,
    isSuccess: saleConfigUpdatedSuccess,
  } = useSaleConfigUpdated();

  const {
    error: updateSaleConfigError,
    write: sendUpdateSaleConfig,
    isLoading: updateSaleConfigLoading,
    data: updateSaleConfigReceipt,
  } = useUpdateSaleOnChainConfig();

  const [wizardState, setWizardState] = useState<any>();

  useEffect(() => {
    if (sale) {
      const isNativeBaseCurrency = sale.baseCurrency !== DEFAULT_BASE_CURRENCY;

      const networkDetails = getNetworkDetails(sale.chainId);
      let nativePriceOracleAddress = networkDetails.nativePriceOracleAddress;

      if (!isNativeBaseCurrency && !!getNativeBaseCurrencyPriceOracle) {
        nativePriceOracleAddress = getNativeBaseCurrencyPriceOracle(
          sale.chainId,
        );
      }

      const formattedStartTime = unixToEditableDateTime(
        sale.startTime,
        account?.timezone,
      );
      const formattedEndTime = unixToEditableDateTime(
        sale.endTime,
        account?.timezone,
      );

      setWizardState({
        onChainConfigFormValid: true,
        eventId: sale.event?.id,
        saleId: sale.id,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        networkId: sale.chainId,
        paymentMethods: sale.paymentMethods
          ? sale.paymentMethods.map((method) => {
              return {
                ...method,
                address: method.token,
              };
            })
          : [],
        nativePaymentsEnabled: sale.paymentMethods
          ? sale.paymentMethods.findIndex((method) => method.native) >= 0
          : false,
        nativePriceOracleAddress: nativePriceOracleAddress,
        nativePriceOracleHeartbeat: networkDetails.nativePriceOracleHeartbeat,
        nativeBaseCurrency: {
          symbol: sale.baseCurrency,
          decimals: isNativeBaseCurrency
            ? networkDetails.decimals
            : DEFAULT_BASE_CURRENCY_DECIMALS,
        },
      });
    }
  }, [sale]);

  useEffect(() => {
    if (saleConfigUpdatedSuccess) {
      goBack();
    }
  }, [saleConfigUpdatedSuccess]);

  useEffect(() => {
    const sendSaleConfigUpdatedEvent = async () => {
      await saleConfigUpdated({
        id: sale.id,
        chainId: sale.chainId,
        eventId: sale.event?.id,
        startTime: wizardState.startTime,
        endTime: wizardState.endTime,
      });
    };

    if (updateSaleConfigReceipt) {
      console.log(updateSaleConfigReceipt);
      if (updateSaleConfigReceipt.status === 'success') {
        sendSaleConfigUpdatedEvent();
        setTxPending(false);
        showSuccessToast({
          description: (
            <div className='flex flex-row'>
              Successfully submitted transaction.
              <a
                target='_blank'
                rel='noreferrer'
                href={getTxUrl(
                  updateSaleConfigReceipt.transactionHash,
                  getNetworkDetails(sale.chainId),
                )}
                className='w-[30px] flex items-center justify-center text-white'
                onClick={(e) => e.stopPropagation()}
              >
                <VscLinkExternal color='white' />
              </a>
            </div>
          ),
        });
      }
    }
  }, [updateSaleConfigReceipt]);

  useEffect(() => {
    if (updateSaleConfigError) {
      setTxPending(false);
      showErrorToast({ description: updateSaleConfigError.toString() });
    }
  }, [updateSaleConfigError]);

  const goBack = () => {
    navigate(-1);
  };

  const handleSave = async () => {
    setTxPending(true);

    const unixStartTime = localToUtcUnixDateTime(
      wizardState.startTime,
      account?.timezone,
    );
    const unixEndTime = localToUtcUnixDateTime(
      wizardState.endTime,
      account?.timezone,
    );

    try {
      const newOnChainConfig = {
        recipient: sale.recipient.id,
        merkleRoot: sale.merkleRoot,
        saleMaximum: sale.saleMaximum,
        userMaximum: sale.userMaximum,
        purchaseMinimum: sale.purchaseMinimum,
        startTime: unixStartTime,
        endTime: unixEndTime,
        maxQueueTime: sale.maxQueueTime,
        URI: sale.uris[0],
      };

      await sendUpdateSaleConfig(sale.chainId, sale.id, [newOnChainConfig]);
    } catch (e: any) {
      showErrorToast({ description: e.message });
      setTxPending(false);
      console.log(e);
    }
  };

  const handleCancel = () => {
    goBack();
  };

  if (!sale || !wizardState) {
    return <LoadingIndicator text={'Loading event'} />;
  }

  if (!loading && sale && sale.chainId !== connectedChainId) {
    const correctNetworkDetails = getNetworkDetails(sale.chainId);
    return (
      <SwitchChainWarning
        chainId={sale.chainId}
        subtitle={`Please connect to the ${correctNetworkDetails.name} network to continue.`}
      />
    );
  }

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        <Step>
          <StepHeader>
            <PageTitle>Network &amp; Payment Configuration</PageTitle>
            <PageSubtitle>
              Now that we have the basics, let&apos;s set up some specific parameters
              before we move on.
            </PageSubtitle>
          </StepHeader>

          <SalePaymentConfig
            context={wizardState}
            setContext={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton
                label={'Cancel'}
                onClick={handleCancel}
                disabled={updateSaleConfigLoading}
              />
              <SaveButton
                disabled={
                  !wizardState?.onChainConfigFormValid ||
                  updateSaleConfigLoading ||
                  saleConfigUpdatedLoading
                }
                saving={updateSaleConfigLoading || saleConfigUpdatedLoading}
                onClick={handleSave}
              />
            </ButtonRow>
          </StepFooter>
        </Step>
      </Wizard>
    </WizardContext.Provider>
  );
};
