import { useValidatePrivateEventParticipants } from '@apiServices';
import { useToast } from '@contexts';
import { CsvParseResult } from '@customTypes';
import { useEffect, useState } from 'react';

interface ValidateParticipantsFileParams {
  participantsFileUri: string | null;
  isEmailRequired: boolean;
  isWalletRequired: boolean;
}

export function useValidateParticipantsFile({
  participantsFileUri,
  isEmailRequired,
  isWalletRequired,
}: ValidateParticipantsFileParams) {
  const [isPending, setIsPending] = useState<boolean>(false);
  const [uploadedData, setUploadedData] = useState<any>(null);
  const [uploadFileErrorMessage, setUploadFileErrorMessage] = useState<
    string | null
  >(null);
  const [areCsvEmailsValid, setAreCsvEmailsValid] = useState<boolean>(false);
  const [areCsvWalletsValid, setAreCsvWalletsValid] = useState<boolean>(false);

  const { showErrorToast } = useToast();
  const { mutate: validateParticipantsFile } =
    useValidatePrivateEventParticipants();

  const handleUploadError = (message?: string) => {
    setUploadedData(null);
    setAreCsvEmailsValid(false);
    setAreCsvWalletsValid(false);
    if (message) {
      setUploadFileErrorMessage(message);
    }
  };

  // Reset all validation state when participantsFileUri changes
  useEffect(() => {
    resetUpload();
  }, [participantsFileUri]);

  const resetUpload = () => {
    setUploadedData(null);
    setUploadFileErrorMessage(null);
    setAreCsvEmailsValid(false);
    setAreCsvWalletsValid(false);
  };

  useEffect(() => {
    if (!participantsFileUri) return;
    if (!isEmailRequired && !isWalletRequired) return;

    // Don't revalidate if email required and valid, and wallet isn't required
    if (isEmailRequired && !isWalletRequired && areCsvEmailsValid) {
      return;
    }

    // Don't revalidate if wallet required and valid, and email isn't required
    if (isWalletRequired && !isEmailRequired && areCsvWalletsValid) {
      return;
    }

    // Don't revalidate if both email and wallet are required and both are valid
    if (
      isEmailRequired &&
      isWalletRequired &&
      areCsvEmailsValid &&
      areCsvWalletsValid
    ) {
      return;
    }

    // There are some other conditions we could use to opt out of validation early, but above covers most cases

    setIsPending(true);
    validateParticipantsFile(
      {
        participantsFileUri,
        isEmailRequired,
        isWalletRequired,
      },
      {
        onSuccess: (result: CsvParseResult) => {
          if (result.valid) {
            setUploadedData(result.data);
            if (isEmailRequired) setAreCsvEmailsValid(true);
            if (isWalletRequired) setAreCsvWalletsValid(true);
          } else {
            handleUploadError(result.errorMessage);
          }
        },
        onError: (error) => {
          showErrorToast({
            description: 'Error validating file. Please try again.',
          });
          handleUploadError(error.message);
        },
        onSettled: () => {
          setIsPending(false);
        },
      },
    );
  }, [
    participantsFileUri,
    isEmailRequired,
    isWalletRequired,
    areCsvEmailsValid,
    areCsvWalletsValid,
  ]);

  return {
    isPending,
    uploadedData,
    uploadFileErrorMessage,
    areCsvEmailsValid,
    areCsvWalletsValid,
    resetUpload,
  };
}
