import { useTSWagmi } from '@contexts';
import { getErc20ABI, toBaseUnits } from '@utils';
import { simulateContract, writeContract } from '@wagmi/core';
import { useState } from 'react';
import { getAddress } from 'viem';
import { useWaitForTransactionReceipt } from 'wagmi';

export const useApproveErc20Transfer = () => {
  const { wagmiConfig } = useTSWagmi();
  const [transactionHash, setTransactionHash] = useState<Maybe<any>>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const write = async (chainId, paymentMethod, toAddress, value) => {
    setSubmitting(true);

    const txValue = toBaseUnits(value, paymentMethod.decimals);
    console.log('preparing approval', txValue.toString());

    try {
      const tokenAddress = getAddress(paymentMethod.address);
      let abi =
        tokenAddress === `0xdAC17F958D2ee523a2206206994597C13D831ec7`
          ? getErc20ABI(true)
          : getErc20ABI();
      const { request } = await simulateContract(wagmiConfig, {
        address: tokenAddress,
        abi,
        functionName: 'approve',
        chainId,
        args: [toAddress, txValue],
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      console.log('approval transaction response', writeContractResponse);
      return writeContractResponse;
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};
