import { ButtonRow, Card, InputGroup, PageHeader } from '@components';

export const RegisterProjectPage = ({
  projectName,
  onProjectNameChange,
  onSubmit,
}: {
  projectName: string;
  onProjectNameChange: Function;
  onSubmit: Function;
}) => {
  return (
    <>
      <PageHeader title={'Tell us about your project'} />

      <Card>
        <form>
          <InputGroup
            label='Project name'
            value={projectName}
            name='projectName'
            onChange={onProjectNameChange}
          />
        </form>

        <ButtonRow place={'end'}>
          <button
            className='btn btn-primary'
            disabled={!projectName}
            onClick={() => onSubmit()}
          >
            Next
          </button>
        </ButtonRow>
      </Card>
    </>
  );
};