import { useEffect } from 'react';
import { EventOverview } from 'tokensoft-shared-types';
import { useLocalStorage } from './use-local-storage';

export const useAutoRedirectToEventOnce = (
  events: EventOverview[],
  navigate: (eventId: ID) => void,
) => {
  const [hasRedirected, setHasRedirected] = useLocalStorage(
    'has-redirected-to-event',
    false,
  );

  // If only one event, go directly to that event
  useEffect(() => {
    if (events?.length === 1 && !hasRedirected) {
      navigate(events[0].id);
      setHasRedirected(true);
    }
  }, [events]);
};
