import { useUpdateEvent } from '@apiServices';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Divider,
  EditIcon,
  EventIcon,
  EventStatusBadge,
  EventSummary,
  IconLogo,
  LinkIcon,
  LoadingIndicator,
  NetworkIcon,
  OnchainIcon,
  RegistrationIcon,
  Row,
  SettingsIcon,
  Stacked,
  Stretched,
  SuccessAlertIcon,
  TableData,
  TableHeader,
  Text,
  TextLink,
  Title,
} from '@components';
import { DEFAULT_DATE_TIME_DISPLAY } from '@constants';
import { useAccount, useEvent, useToast } from '@contexts';
import { EVENT_STATUS, EVENT_TYPE } from '@enums';
import { useClipboard } from '@hooks';
import {
  eventDocumentsToEditEventDocuments,
  formatCreateEventDocumentsPayload,
  getDocumentCategoryForEventType,
  getTimezoneAbbreviation,
  utcToLocalDateTime,
} from '@utils';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FaLock, FaRocket, FaUnlockAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

type EventDetailsProps = {
  readonly?: boolean;
};

export const AdminEventDetails = ({ readonly = false }: EventDetailsProps) => {
  const navigate = useNavigate();
  const { account } = useAccount();
  const { event, loading, isOnChainEvent } = useEvent();
  const { copyToClipboard } = useClipboard();
  const { showErrorToast, showSuccessToast } = useToast();
  const [eventUpdates, setEventUpdates] = useState<Maybe<any>>(null);

  const allowedRegions = eventUpdates?.allowedRegions;
  const editedableDocuments = eventDocumentsToEditEventDocuments(
    eventUpdates?.documents,
  );
  const documentCategory = getDocumentCategoryForEventType(eventUpdates?.type);
  const formattedEventDocumentPayload = formatCreateEventDocumentsPayload(
    documentCategory,
    editedableDocuments,
    allowedRegions,
  );

  const formattedStartTime = event.registrationStartTime
    ? utcToLocalDateTime(
        event.registrationStartTime,
        account?.timezone,
        DEFAULT_DATE_TIME_DISPLAY,
        false,
      )
    : 'No start date';
  const formattedEndTime = event.registrationEndTime
    ? utcToLocalDateTime(
        event.registrationEndTime,
        account?.timezone,
        DEFAULT_DATE_TIME_DISPLAY,
        false,
      )
    : 'No end date';

  const formattedChainStartTime = event.startTime
    ? utcToLocalDateTime(
        event.startTime,
        account?.timezone,
        DEFAULT_DATE_TIME_DISPLAY,
        false,
      )
    : 'No start date';
  const formattedChainEndTime = event.endTime
    ? utcToLocalDateTime(
        event.endTime,
        account?.timezone,
        DEFAULT_DATE_TIME_DISPLAY,
        false,
      )
    : 'No end date';

  const isDeployed =
    [EVENT_TYPE.SALE, EVENT_TYPE.RAISE, EVENT_TYPE.DISTRIBUTE].includes(
      event.type,
    ) && isOnChainEvent;

  const { mutate: update, isPending: updateLoading } = useUpdateEvent();

  useEffect(() => {
    if (event) {
      setEventUpdates(event);
    }
  }, [event]);

  const handleDeploy = () => {
    navigate(
      `/admin/project/${event.project.id}/event/${event.id}/deploy/sale`,
    );
  };

  const handlePublish = () => {
    update(
      {
        ...eventUpdates,
        documents: formattedEventDocumentPayload,
        hidden: false,
      },
      {
        onSuccess: () =>
          showSuccessToast({ description: 'Successfully published event.' }),
        onError: (error) => showErrorToast({ description: error.message }),
      },
    );
  };

  const handleUnpublish = () => {
    update(
      {
        ...eventUpdates,
        documents: formattedEventDocumentPayload,
        hidden: true,
      },
      {
        onSuccess: () =>
          showSuccessToast({ description: 'Successfully unpublished event.' }),
        onError: (error) => showErrorToast({ description: error.message }),
      },
    );
  };

  const getReferralUrl = () => {
    const location = `https://${event.project?.domains[0]?.domainName}/event/${event.id}`;
    const url = new URL(location || window.location.href);
    if (event.referralCodes?.length) {
      url.searchParams.append(
        'referral_code',
        event.referralCodes[0].referralCode,
      );
    }
    return url.toString();
  };

  const handleCopyReferralLink = () => {
    copyToClipboard(getReferralUrl(), 'Invite link copied to clipboard');
  };

  if (loading) {
    return (
      <Card className={'h-full'}>
        <div className={'mb-12'}>
          <LoadingIndicator />
        </div>
      </Card>
    );
  }

  return (
    <>
      <Card>
        <CardHeader>
          <Stretched
            xgap={5}
            ygap={2.5}
            className={'justify-center items-center md:justify-end'}
          >
            {readonly ? (
              <TextLink
                width={'fit'}
                onClick={() => {
                  navigate(
                    `/admin/project/${event.project.id}/event/${event.id}/general`,
                  );
                }}
              >
                <EditIcon />
                <Text>Edit Details</Text>
              </TextLink>
            ) : (
              <>
                {!eventUpdates?.hidden &&
                event?.status !== EVENT_STATUS.COMPLETED ? (
                  <TextLink width={'fit'} onClick={handleCopyReferralLink}>
                    <LinkIcon />
                    <Text>Invite Link</Text>
                  </TextLink>
                ) : null}

                <TextLink
                  width={'fit'}
                  onClick={() => {
                    navigate(
                      `/admin/project/${event.project.id}/event/${event?.id}/edit`,
                    );
                  }}
                >
                  <SettingsIcon />
                  <Text>Settings</Text>
                </TextLink>
              </>
            )}
          </Stretched>
        </CardHeader>

        <CardBody
          className={classNames('mt-0 pt-1 md:pt-0', readonly ? '' : 'pb-5')}
        >
          <Stacked gap={5}>
            <Stretched
              xgap={5}
              ygap={5}
              className={'items-center justify-center md:justify-start'}
            >
              <Col width={'fit'}>
                <IconLogo mode={'event'}>
                  <EventIcon icon={event?.type} size={'xxl'} />
                </IconLogo>
              </Col>

              <Col gap={2.5}>
                <Row nowrap xgap={2.5}>
                  <Title
                    breakAnywhere
                    className={'shrink-2xl text-center md:text-left'}
                  >
                    {event?.name}
                  </Title>
                  <Row width={'fit'} className={'hidden md:inline-block'}>
                    <EventStatusBadge startTime={event.startTime} status={event.status} />
                  </Row>
                </Row>

                {event?.description ? (
                  <Text breakAnywhere className={'text-center md:text-left'}>
                    {event?.description}
                  </Text>
                ) : null}
              </Col>
            </Stretched>

            <Divider />

            <Stretched gap={5}>
              <Row nowrap xgap={2.5}>
                <RegistrationIcon />
                <Col gap={2.5}>
                  <TableHeader>
                    Registration Dates (
                    {getTimezoneAbbreviation(account?.timezone)})
                  </TableHeader>
                  <TableData>
                    <>
                      {formattedStartTime} &rarr; {formattedEndTime}
                    </>
                  </TableData>
                </Col>
              </Row>
              <Row nowrap xgap={2.5}>
                <OnchainIcon />
                <Col gap={2.5}>
                  <TableHeader>
                    On-Chain Dates ({getTimezoneAbbreviation(account?.timezone)}
                    )
                  </TableHeader>
                  <TableData>
                    <>
                      {formattedChainStartTime} &rarr; {formattedChainEndTime}
                    </>
                  </TableData>
                </Col>
              </Row>
            </Stretched>

            {!readonly ? (
              <>
                <Divider />
                <EventSummary event={event} />
              </>
            ) : null}
          </Stacked>
        </CardBody>

        {!readonly ? (
          <CardFooter>
            <Col gap={5}>
              <Divider />

              <Row xgap={10} ygap={5} className={'justify-start items-center'}>
                {eventUpdates?.hidden && (
                  <TextLink
                    className={'w-full md:w-fit'}
                    onClick={handlePublish}
                  >
                    {updateLoading ? (
                      <>
                        <div className='animate-spin'>
                          <AiOutlineLoading3Quarters size={18} />
                        </div>
                        <Text>Publishing...</Text>
                      </>
                    ) : (
                      <>
                        <FaUnlockAlt />
                        <Text>Publish Event</Text>
                      </>
                    )}
                  </TextLink>
                )}

                {!eventUpdates?.hidden && (
                  <TextLink
                    className={'w-full md:w-fit'}
                    onClick={handleUnpublish}
                  >
                    {updateLoading ? (
                      <>
                        <div className='animate-spin'>
                          <AiOutlineLoading3Quarters size={18} />
                        </div>
                        <Text>Unpublishing...</Text>
                      </>
                    ) : (
                      <>
                        <FaLock />
                        <Text>Unpublish Event</Text>
                      </>
                    )}
                  </TextLink>
                )}

                {isDeployed ? (
                  <Row
                    nowrap
                    className={
                      'w-full md:w-fit justify-center md:justify-start'
                    }
                    gap={2.5}
                    yalign={'center'}
                  >
                    <SuccessAlertIcon className={'success'} />
                    <Text className={'success'}>Deployed on Chain</Text>
                  </Row>
                ) : (
                  <>
                    {(event.type === EVENT_TYPE.RAISE ||
                      event.type === EVENT_TYPE.SALE) &&
                    event.startTime &&
                    event.endTime ? (
                      <TextLink
                        className={'w-full md:w-fit'}
                        onClick={handleDeploy}
                      >
                        <FaRocket />
                        <Text>Launch Sale</Text>
                      </TextLink>
                    ) : null}
                  </>
                )}

                {isDeployed ? (
                  <Row
                    nowrap
                    className={
                      'w-full md:w-fit justify-center md:justify-end ml-auto'
                    }
                  >
                    <NetworkIcon
                      nowrap
                      chainId={event.networkId}
                      logoSize={'sm'}
                      width={'fit'}
                    />
                  </Row>
                ) : null}
              </Row>
            </Col>
          </CardFooter>
        ) : null}
      </Card>
    </>
  );
};
