import { useGetProject, useUploadToS3 } from '@apiServices';
import { Button, Text, UploadIcon } from '@components';
import { useToast } from '@contexts';
import { FileUploadResult } from '@customTypes';
import { MouseEventHandler, useRef } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

type ButtonProps = React.ComponentProps<typeof Button>;

interface ImageUploadButtonProps extends ButtonProps {
  onFileUploaded: (uploadResult: FileUploadResult) => void;
  color?: 'primary' | 'secondary';
  className?: string;
}

export const ImageUploadButton: React.FC<ImageUploadButtonProps> = ({
  onFileUploaded = () => {},
  className,
  ...restProps
}) => {
  const { showErrorToast } = useToast();

  const { data: project } = useGetProject();
  const projectId = project?.id;
  const { mutate: uploadToS3, isPending } = useUploadToS3();

  const hiddenFileInput = useRef<Maybe<any>>(null);

  const handleOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };

  const handleImageUpload = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!projectId) {
      showErrorToast({ description: 'Project ID not found' });
      return;
    }

    const file = e.target.files[0];

    const twoMB = 2097152;
    if (file.size > twoMB) {
      hiddenFileInput.current.value = null;
      showErrorToast({ description: 'File size must be less than 2MB' });
      return;
    }

    uploadToS3(
      {
        file,
        // Image uploads are public so users can consume
        isPublicResource: true,
      },
      {
        onSuccess: (result: UploadToS3Result) => {
          const fileUploadResult: FileUploadResult = {
            filename: file.name,
            contentType: file.type,
            httpsUrl: result.fileUrl,
            s3Uri: result.s3Uri,
            file,
          };

          hiddenFileInput.current.value = null;
          onFileUploaded(fileUploadResult);
        },
        onError: (error) => {
          hiddenFileInput.current.value = null;
          showErrorToast({ description: error.message });
        },
      },
    );
  };

  return (
    <>
      <Button
        className={className}
        onClick={handleOnClick}
        disabled={isPending}
        {...restProps}
      >
        {isPending ? (
          <>
            <div className='animate-spin'>
              <AiOutlineLoading3Quarters size={16} />
            </div>
            <Text>Uploading</Text>
          </>
        ) : (
          <>
            <UploadIcon />
            <Text>Upload Image</Text>
          </>
        )}
      </Button>
      <input
        type='file'
        accept='.png, .jpg, .jpeg, .ico, .svg, .gif'
        onChange={handleImageUpload}
        ref={hiddenFileInput}
        style={{ display: 'none' }} // Make the file input element invisible
      />
    </>
  );
};
