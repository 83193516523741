import { Text } from '@components';
import classNames from 'classnames';

type TextProps = React.ComponentProps<typeof Text>;

interface PageSubtitleProps extends TextProps {}

export const PageSubtitle: React.FC<PageSubtitleProps> = ({
  className,
  children,
  ...restProps
}) => {
  return (
    <Text
      textAlign={'center'}
      className={classNames('page-subtitle default-contrast mt-3', className)}
      {...restProps}
    >
      {children}
    </Text>
  );
};
