import { EventDocumentsSigning } from '@newComponents';
import { userHasSignedOrReviewedAllDocuments } from '@utils';
import { EventDocument, EventUserDocumentStatus } from 'tokensoft-shared-types';

interface DocumentSigningProps {
  eventDocuments: EventDocument[];
  eventUserDocumentStatuses: EventUserDocumentStatus[];
  highlightColor: HexColorCode;
  onFinishedReviewingOrSigning: (isFinished: boolean) => void;
}

export const DocumentSigning = ({
  eventDocuments,
  eventUserDocumentStatuses,
  highlightColor,
  onFinishedReviewingOrSigning,
}: DocumentSigningProps) => {
  const handleDownload = () => {
    eventDocuments.forEach((doc) => {
      const link = document.createElement('a');
      link.href = doc.uri;
      link.download = doc.name;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const someDocRequiresSignature = eventDocuments.some(
    (doc) => doc.requiresSignature,
  );

  const userFinishedReviewingOrSigning = userHasSignedOrReviewedAllDocuments(
    eventDocuments,
    eventUserDocumentStatuses,
  );

  return (
    <>
      <div>
        <p className='text-2xl font-bold text-black mb-2'>
          {someDocRequiresSignature ? 'Document Signing' : 'Document Review'}
        </p>
        <EventDocumentsSigning
          documents={eventDocuments}
          onFinishedReviewingOrSigning={onFinishedReviewingOrSigning}
          isReviewing={!userFinishedReviewingOrSigning}
        />
      </div>
    </>
  );
};
