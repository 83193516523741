import { useWebApiClient } from '@apiClients';
import { useQuery } from '@tanstack/react-query';
import { EventUserDocumentStatus } from 'tokensoft-shared-types';

export const useGetEventUserDocumentsStatus = (eventId: ID) => {
  const client = useWebApiClient();

  return useQuery<EventUserDocumentStatus[]>({
    queryKey: ['event', eventId, 'documents-status'],
    queryFn: async () => {
      const userDocumentsStatusRaw = await client(
        `events/${eventId}/documents-status`,
      );

      const userDocumentsStatus =
        userDocumentsStatusRaw.userDocumentsStatus.map((status: any) => {
          return {
            ...status,
            signedAt: status.signedAt ? new Date(status.signedAt) : null,
            acceptedAt: status.acceptedAt ? new Date(status.acceptedAt) : null,
          };
        });

      return userDocumentsStatus;
    },
  });
};
