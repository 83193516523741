export enum QUALIFICATION_METHOD {
  NET_WORTH = 'NET_WORTH',
  INCOME = 'INCOME',
  PROFESSIONAL = 'PROFESSIONAL',
  LETTER = 'LETTER',
  NONE = 'NONE',
  ENTITY_NET_WORTH = 'ENTITY_NET_WORTH',
  TRUST_NET_WORTH = 'TRUST_NET_WORTH',
  FAMILY_OFFICE = 'FAMILY_OFFICE',
  FAMILY_CLIENT = 'FAMILY_CLIENT',
  ACCREDITED_OWNERS = 'ACCREDITED_OWNERS',
}
