import {
  Alert,
  ButtonLink,
  CheckboxInput,
  Col,
  DownloadIcon,
  FileUploadButton,
  LoadingIndicator,
  PrivateEventParticipantsTable,
  Row,
  Stacked,
  Text,
  Toggle,
} from '@components';
import { useProject } from '@contexts';

interface EventVisibilityOptionsProps {
  isPrivate: boolean;
  onIsPrivateToggled: () => void;
  isEmailRequired: boolean;
  isWalletRequired: boolean;
  uploadedFileName: string | null;
  isPendingValidation: boolean;
  uploadedData: any;
  uploadFileErrorMessage: string | null;
  onEmailRequiredToggled: () => void;
  onWalletRequiredToggled: () => void;
  onPrivateEventParticipantsFileUploaded: (data: any) => void;
}

export const EventVisibilityOptions: React.FC<
  EventVisibilityOptionsProps
> = ({
  isPrivate,
  onIsPrivateToggled,
  isEmailRequired,
  isWalletRequired,
  uploadedFileName,
  isPendingValidation,
  uploadedData,
  uploadFileErrorMessage,
  onEmailRequiredToggled,
  onWalletRequiredToggled,
  onPrivateEventParticipantsFileUploaded,
}) => {
  const { project } = useProject();

  return (
    <Stacked>
      <Row yalign='center' gap={3}>
        <Toggle checked={isPrivate} onChange={onIsPrivateToggled} />
        <Text className='text-md font-medium'>
          {isPrivate ? 'Private' : 'Public'}
        </Text>
      </Row>
      {isPrivate && (
        <>
          <Row yalign='start' xalign='between'>
            <Col className='w-3/4'>
              <Text className='font-bold'>Do you have a template?</Text>
              <Text>
                Use this template to create your CSV file. You may select
                whether you want to require either a wallet address, email
                address, or both.
              </Text>
            </Col>
            <ButtonLink
              fill='outline'
              href={
                'https://s3.amazonaws.com/media.tokensoft.io/private_event_participants.csv'
              }
              target={'blank'}
            >
              <Text>Download Template</Text>
              <DownloadIcon />
            </ButtonLink>
          </Row>
          <Col>
            <CheckboxInput
              name='email-required'
              checked={isEmailRequired}
              onClick={onEmailRequiredToggled}
            >
              Require Email?
            </CheckboxInput>
            <CheckboxInput
              name='wallet-required'
              checked={isWalletRequired}
              onClick={onWalletRequiredToggled}
            >
              Require Wallet Address?
            </CheckboxInput>
          </Col>
          <Col gap={2} xalign='start'>
            <Text className='font-semibold'>
              Upload your participants list*
            </Text>
            <FileUploadButton
              projectId={project.id}
              fileTypes='.csv'
              onFileUploaded={onPrivateEventParticipantsFileUploaded}
              disabled={isPendingValidation}
            />
            {uploadedFileName && (
              <Text className='text-sm'>{uploadedFileName}</Text>
            )}
            {isPendingValidation && (
              <Row xalign='center'>
                <LoadingIndicator
                  text={'Verifying participants list'}
                  className={'no-padding'}
                />
              </Row>
            )}
          </Col>
          {!isPendingValidation && uploadedData !== null && (
            <Row xalign='center'>
              <PrivateEventParticipantsTable data={uploadedData} />
            </Row>
          )}
          {!isPendingValidation && !uploadedData && uploadFileErrorMessage && (
            <Alert type='alert-danger'>{uploadFileErrorMessage}</Alert>
          )}
        </>
      )}
    </Stacked>
  );
};
