import {
  DangerAlertIcon,
  Flex,
  InfoAlertIcon,
  Row,
  SuccessAlertIcon,
  WarningAlertIcon,
} from '@components';
import classNames from 'classnames';
import './alert.css';

type RowProps = React.ComponentProps<typeof Row>;

interface AlertProps extends RowProps {
  type?: 'alert-info' | 'alert-danger' | 'alert-warning' | 'alert-success';
  alertStyle?: 'light' | 'dark';
  className?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
}

export const Alert: React.FC<AlertProps> = ({
  type = 'alert-success',
  alertStyle = 'light',
  className = '',
  icon = null,
  children,
  ...restProps
}) => {
  return (
    <Row
      className={classNames(className || '', `${type}` || '', alertStyle, 'alert leading-none')}
      nowrap
      {...restProps}
    >
      {icon ? (
        <div>{icon}</div>
      ) : (
        <div>
          {type.includes('alert-info') && <InfoAlertIcon />}
          {type.includes('alert-danger') && <DangerAlertIcon />}
          {type.includes('alert-warning') && <WarningAlertIcon />}
          {type.includes('alert-success') && <SuccessAlertIcon />}
        </div>
      )}
      <Flex width='fit' textAlign='left' className='pl-2 font-semibold'>
        {children}
      </Flex>
    </Row>
  );
};
