import {
  Breadcrumb,
  BrowserTitle,
  Col,
  PageSubtitle,
  PageTitle,
  Text,
} from '@components';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface PageHeaderProps {
  title?: string;
  subtitle?: string;
  breadcrumb?: ReactNode;
  instructions?: ReactNode;
  className?: string;
  children?: ReactNode;
  id?: string;
}

export const PageHeader = ({
  id,
  title,
  subtitle,
  instructions,
  className,
  breadcrumb,
  children,
}: PageHeaderProps) => {
  const key = id || 'page-header';

  return (
    <>
      <BrowserTitle title={title || ''} />

      <Col
        data-testid={key}
        gap={4}
        textAlign={'center'}
        place={'center'}
        className={classNames('page-header mb-10 mt-4', className)}
      >
        {breadcrumb && (
          <Breadcrumb data-testid={`${key}-breadcrumb`}>
            {breadcrumb}
          </Breadcrumb>
        )}

        {title && <PageTitle data-testid={`${key}-title`}>{title}</PageTitle>}

        {subtitle && (
          <PageSubtitle data-testid={`${key}-subtitle`} className={'mt-0'}>
            {subtitle}
          </PageSubtitle>
        )}

        {children}
      </Col>

      {instructions && (
        <div data-testid={`${key}-instructions`} className={classNames('mb-4')}>
          {typeof instructions === 'string' ? (
            <Text>{instructions}</Text>
          ) : (
            <>{instructions}</>
          )}
        </div>
      )}
    </>
  );
};
