import { useCreateReferral } from '@apiServices';
import { useAuth } from '@contexts';
import { useEffect, useState } from 'react';
import { useReferralCode } from './use-referral-code';

/**
 * Hook to capture referral code from querystring and create referral if authenticated.
 */
export const useReferralTracking = () => {
  const { isAuthenticated } = useAuth();
  const { mutate: createReferral, isPending: creatingReferral } =
    useCreateReferral();
  const referralCode = useReferralCode();

  // Technically the referral code could "change", and createReferral
  // could be called again, but that's not generally a case that should
  // be occurring, so not handling it.
  const [referralCreated, setReferralCreated] = useState<boolean>(false);

  const handleReferralCode = async (referralCode: string) => {
    // Avoid multiple calls
    if (creatingReferral) {
      return;
    }

    try {
      await createReferral(referralCode);

      setReferralCreated(true);
    } catch (err) {
      console.log('Error saving referral code!', err);
    }
  };

  // If the user is authenticated and a referral code is present,
  // send request to create referral.
  useEffect(() => {
    if (isAuthenticated && referralCode !== null && !referralCreated) {
      handleReferralCode(referralCode);
    }
  }, [isAuthenticated, referralCode]);

  return;
};
