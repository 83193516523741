import {
  EVENT_USER_FILTER_TYPE,
  EVENT_USER_TABLE_CONFIG,
  EVENT_USER_TABLE_RELATIONSHIP,
} from '@apiServices';
import {
  Card,
  EventReportDownload,
  EventUsers,
  LoadingIndicator,
} from '@components';
import { useEvent, useProject } from '@contexts';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import './event-users-container.css';

type EventUsersOrderBy = {
  sqlOrders: Function;
  desc: boolean;
};

type EventUsersFilterBy = {
  type: string;
  inTableFilter: string;
  searchTerm?: string;
  data: any;
  sqlFilters: Function;
};

export const EventUsersContainer = () => {
  const { event } = useEvent();
  const { project } = useProject();

  const [eventUserTableRelationship, setEventUserTableRelationship] =
    useState<Maybe<any>>(null);
  const [eventUsersTableConfig, setEventUsersTableConfig] = useState<
    any | null
  >(null);
  const [tableConfigDisplaying, setTableConfigDisplaying] = useState<
    any | null
  >(null);
  const [loadingDataCompleted, setLoadingDataCompleted] =
    useState<boolean>(false);
  const [filterBy, setFilterBy] = useState<Maybe<any>>(null);
  const [orderBy, setOrderBy] = useState<Maybe<any>>(null);
  const [searchValue, setSearchValue] = useState<Maybe<any>>(null);
  const [firstLoadIsEmpty, setFirstLoadIsEmpty] = useState<Maybe<any>>(null);
  const [rowsCount, setRowsCount] = useState<Maybe<any>>(null);

  useEffect(() => {
    if (event) {
      setEventUserTableRelationship(EVENT_USER_TABLE_RELATIONSHIP[event.type]);
    }
  }, [event]);

  useEffect(() => {
    if (eventUserTableRelationship) {
      setTableConfigDisplaying(eventUserTableRelationship[0]);
    }
  }, [eventUserTableRelationship]);

  useEffect(() => {
    if (tableConfigDisplaying) {
      if (eventUsersTableConfig) {
        setEventUsersTableConfig(null);
      } else {
        setEventUsersTableConfig(
          EVENT_USER_TABLE_CONFIG[tableConfigDisplaying.tableConfig],
        );
      }
    }
  }, [tableConfigDisplaying]);

  useEffect(() => {
    if (!eventUsersTableConfig && tableConfigDisplaying) {
      setEventUsersTableConfig(
        EVENT_USER_TABLE_CONFIG[tableConfigDisplaying.tableConfig],
      );
    }
  }, [eventUsersTableConfig]);

  const handleEventUserParameters = (offset, queryLimit) => {
    let filtersApplied = [];
    if (filterBy) {
      Object.entries(filterBy).forEach(([key, value]) => {
        if (
          (value as EventUsersFilterBy).type ===
          EVENT_USER_FILTER_TYPE.SEARCH_BAR
        ) {
          if (searchValue) {
            filtersApplied = filtersApplied.concat(
              ...(value as EventUsersFilterBy).sqlFilters(searchValue),
            );
          }
        } else {
          if ((value as EventUsersFilterBy).searchTerm) {
            filtersApplied = filtersApplied.concat(
              ...(value as EventUsersFilterBy).sqlFilters(
                (value as EventUsersFilterBy).searchTerm,
              ),
            );
          }
        }
      });
    }

    let orderApplied = [];
    if (orderBy) {
      Object.entries(orderBy).forEach(([key, value]) => {
        if ((value as EventUsersOrderBy).desc !== null) {
          orderApplied = orderApplied.concat(
            ...(value as EventUsersOrderBy).sqlOrders(
              (value as EventUsersOrderBy).desc,
            ),
          );
        }
      });
    }

    return {
      offset: offset,
      queryLimit: queryLimit,
      eventId: event.id,
      searchTerm: searchValue,
      filterBy: filtersApplied,
      orderBy: orderApplied,
    };
  };
  const handleExposeData = (data) => {
    setOrderBy(data.orderBy);
    setFilterBy(data.filterBy);
    setSearchValue(data.searchValue);
    setFirstLoadIsEmpty(data.firstLoadIsEmpty);
    setRowsCount(data.rowsCount);
  };

  return (
    <Card className='user-card'>
      {eventUsersTableConfig ? (
        <>
          <div className='flex sm:flex-row flex-col justify-between sm:items-center items-start pb-5'>
            <div>
              {eventUserTableRelationship?.map((report, index) => (
                <button
                  className={classNames(
                    'btn btn-sm btn-tab',
                    tableConfigDisplaying.type === report.type && 'active',
                  )}
                  onClick={() => setTableConfigDisplaying(report)}
                  disabled={!loadingDataCompleted}
                  key={index}
                >
                  {report.text}
                </button>
              ))}
            </div>
            <EventReportDownload
              project={project}
              event={event}
              className='sm:w-44 sm:mt-0 mt-5 w-full'
              button={{
                type: 'ACCOUNT',
                text: 'Users',
              }}
              eventUserTableConfig={eventUsersTableConfig}
              handleParameters={handleEventUserParameters}
              disabled={
                !loadingDataCompleted || firstLoadIsEmpty || rowsCount === 0
              }
            />
          </div>
          <EventUsers
            eventUsersTableConfig={eventUsersTableConfig}
            loadingDataCompleted={(value) => setLoadingDataCompleted(value)}
            event={event}
            handleEventUserParameters={handleEventUserParameters}
            handleExposeData={handleExposeData}
          />
        </>
      ) : (
        <LoadingIndicator className='no-padding' />
      )}
    </Card>
  );
};
