export const DepositIcon = ({ fill = 'var(--primary)' }) => {
  return (
    <svg
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.0001 0.000114191C43.464 0.000114191 56 12.5362 56 28.0001C56 43.464 43.464 56 28.0001 56C12.5362 56 0.00011614 43.464 0.00011614 28.0001C-0.0438855 12.58 12.4207 0.0441158 27.8406 0.000114191C27.8938 -3.80637e-05 27.9469 -3.80637e-05 28.0001 0.000114191Z'
        fill={fill}
      />
      <path
        d='M17.3684 31.5322L22.1307 26.7699L26.0904 30.7297L30.5101 26.31L28 23.8H35.0001V30.8L32.49 28.2899L26.0904 34.6895L22.1307 30.7297L18.6669 34.1934C20.6734 37.2112 24.1044 39.2 28 39.2C34.1857 39.2 39.2001 34.1856 39.2001 28C39.2001 21.8144 34.1857 16.8 28 16.8C21.8144 16.8 16.8 21.8144 16.8 28C16.8 29.2342 16.9996 30.4217 17.3684 31.5322ZM15.2152 33.7137L15.201 33.6995L15.2064 33.6941C14.431 31.9546 14 30.0276 14 28C14 20.268 20.268 14 28 14C35.732 14 42.0001 20.268 42.0001 28C42.0001 35.7321 35.732 42 28 42C22.3032 42 17.4011 38.5974 15.2152 33.7137Z'
        fill='white'
      />
    </svg>
  );
};
