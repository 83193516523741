import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { FaQuestion } from 'react-icons/fa';
import './icon-logo.css';

type IconLogoProps = {
  logo?: any;
  mode?: string;
  className?: string;
  children?: ReactNode;
};

export const IconLogo: FC<IconLogoProps> = ({
  logo,
  className,
  mode,
  children = <FaQuestion />,
}) => {
  return (
    <div
      className={classNames(
        'flex items-center justify-center',
        className,
        mode ? mode : null,
      )}
    >
      {logo ? (
        <img src={logo} className='icon-logo' />
      ) : (
        <div className='icon-logo flex items-center justify-center'>
          {children}
        </div>
      )}
    </div>
  );
};
