import { useWebApiClient } from '@apiClients';
import { useQuery } from '@tanstack/react-query';
import { BigNumber } from 'bignumber.js';
import { Erc20Token } from 'tokensoft-shared-types';

export const useGetTokenDetails = (
  chainId: ID,
  tokenAddress: Maybe<EvmAddress>,
) => {
  const fetchWithClient = useWebApiClient();

  return useQuery<Maybe<Erc20Token>>({
    queryKey: ['chains', chainId, 'tokens', tokenAddress],
    initialData: null,
    queryFn: async () => {
      const tokenRaw = await fetchWithClient(
        `chains/${chainId}/tokens/${tokenAddress}`,
      );
      const token: Erc20Token = {
        ...tokenRaw,
        totalSupply: new BigNumber(tokenRaw.totalSupply),
      };
      return token;
    },
    enabled: tokenAddress !== null,
  });
};
