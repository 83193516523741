import {
  Button,
  Col,
  FrontArrowIcon,
  InputGroup,
  Row,
  Section,
  SectionTitle,
  Stacked,
  TableData,
  Text,
} from '@components';
import { GENESIS_ADDRESS } from '@constants';
import { useNetworks, useToast } from '@contexts';
import {
  useClipboard,
  useUpdateDistributorOwner,
  useUpdateDistributorRecipient,
} from '@hooks';
import { getTruncatedAddress, getTxUrl } from '@utils';
import { useEffect, useState } from 'react';
import { AiOutlineCopy, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { VscLinkExternal } from 'react-icons/vsc';
import { isAddress } from 'viem';

interface DistributorOwnerProps {
  context: any;
  setContext: Function;
}

export const DistributorOwner: React.FC<DistributorOwnerProps> = ({
  context,
  setContext,
}) => {
  const { copyToClipboard } = useClipboard();
  const { getNetworkDetails } = useNetworks();
  const { showErrorToast, showSuccessToast } = useToast();
  const [owner, setOwner] = useState();
  const [recipient, setRecipient] = useState();
  const [ownerUpdatedAt, setOwnerUpdatedAt] = useState<Maybe<any>>(null);
  const [recipientUpdatedAt, setRecipientUpdatedAt] =
    useState<Maybe<any>>(null);

  const validRecipient =
    !recipient || (isAddress(recipient) && recipient !== GENESIS_ADDRESS);
  const recipientError = validRecipient ? null : 'Invalid address';

  const validOwner = !owner || (isAddress(owner) && owner !== GENESIS_ADDRESS);
  const ownerError = validOwner ? null : 'Invalid address';

  const {
    error: updateOwnerError,
    write: updateOwner,
    isLoading: updateOwnerLoading,
    data: updateOwnerReceipt,
  } = useUpdateDistributorOwner();

  const {
    error: updateRecipientError,
    write: updateRecipient,
    isLoading: updateRecipientLoading,
    data: updateRecipientReceipt,
  } = useUpdateDistributorRecipient();

  const saving = updateOwnerLoading || updateRecipientLoading;

  const key = 'distributor-owner';

  useEffect(() => {
    if (!ownerUpdatedAt) {
      return;
    }

    setContext({
      ...context,
      advanced: {
        ...context.advanced,
        owner: {
          id: owner,
        },
      },
    });
  }, [ownerUpdatedAt]);

  useEffect(() => {
    if (!recipientUpdatedAt) {
      return;
    }

    setContext({
      ...context,
      advanced: {
        ...context.advanced,
        sweepRecipient: {
          id: recipient,
        },
      },
    });
  }, [recipientUpdatedAt]);

  const saveOwner = async () => {
    try {
      await updateOwner(context.chainId, context.id, [owner]);
    } catch (e: any) {
      showErrorToast({ description: e.message });
      console.error(e);
    }
  };

  const saveRecipient = async () => {
    try {
      await updateRecipient(context.chainId, context.id, [recipient]);
    } catch (e: any) {
      showErrorToast({ description: e.message });
      console.error(e);
    }
  };

  useEffect(() => {
    if (updateOwnerReceipt) {
      if (updateOwnerReceipt.status === 'success') {
        setOwnerUpdatedAt(new Date());
        showSuccessToast({
          description: (
            <div className='flex flex-row'>
              Successfully submitted transaction.
              <a
                target='_blank'
                rel='noreferrer'
                href={getTxUrl(
                  updateOwnerReceipt.transactionHash,
                  getNetworkDetails(context.chainId),
                )}
                className='w-[30px] flex items-center justify-center text-white'
                onClick={(e) => e.stopPropagation()}
              >
                <VscLinkExternal color='white' />
              </a>
            </div>
          ),
        });
      }
    }
  }, [updateOwnerReceipt]);

  useEffect(() => {
    if (updateOwnerError) {
      showErrorToast({ description: updateOwnerError.toString() });
    }
  }, [updateOwnerError]);

  useEffect(() => {
    if (updateRecipientReceipt) {
      if (updateRecipientReceipt.status === 'success') {
        setRecipientUpdatedAt(new Date());
        showSuccessToast({
          description: (
            <div className='flex flex-row'>
              Successfully submitted transaction.
              <a
                target='_blank'
                rel='noreferrer'
                href={getTxUrl(
                  updateRecipientReceipt.transactionHash,
                  getNetworkDetails(context.chainId),
                )}
                className='w-[30px] flex items-center justify-center text-white'
                onClick={(e) => e.stopPropagation()}
              >
                <VscLinkExternal color='white' />
              </a>
            </div>
          ),
        });
      }
    }
  }, [updateRecipientReceipt]);

  useEffect(() => {
    if (updateRecipientError) {
      showErrorToast({ description: updateRecipientError.toString() });
    }
  }, [updateRecipientError]);

  return (
    <Stacked gap={12} data-testid={`${key}`}>
      <Section xgap={5} ygap={5}>
        <Col>
          <Row nowrap xalign={'between'}>
            <SectionTitle width={'1/2'}>Current Owner</SectionTitle>
            <Row width={'1/2'} nowrap gap={2} yalign={'center'} xalign={'end'}>
              <div
                className='text-primary-medium cursor-pointer'
                onClick={() => copyToClipboard(context?.advanced?.owner?.id)}
              >
                <AiOutlineCopy size={16} />
              </div>
              <TableData
                className='break-anywhere'
                data-testid={`${key}-owner-data`}
              >
                {!!context?.advanced?.owner?.id
                  ? getTruncatedAddress(context.advanced.owner.id)
                  : 'Loading...'}
              </TableData>
            </Row>
          </Row>

          <Text>
            Please add wallet address below to select a new owner and overwrite
            the existing one.
          </Text>
        </Col>

        <Col>
          <Row>
            <InputGroup
              name={'owner'}
              placeholder={'Enter Address'}
              required={false}
              value={owner}
              onChange={(changed) => {
                setOwner(changed.target.value);
              }}
              valid={validOwner}
              error={ownerError}
            />
          </Row>

          <Button
            className='w-fit'
            size={'md'}
            disabled={!owner || saving}
            onClick={() => saveOwner()}
          >
            {updateOwnerLoading ? (
              <>
                <div className='animate-spin'>
                  <AiOutlineLoading3Quarters size={18} />
                </div>
                <Text>Saving...</Text>
              </>
            ) : (
              <>
                <Text>Save</Text>
                <FrontArrowIcon />
              </>
            )}
          </Button>
        </Col>
      </Section>

      <Section xgap={5} ygap={5}>
        <Col>
          <Row nowrap xalign={'between'}>
            <SectionTitle width={'1/2'}>Current Recipient</SectionTitle>
            <Row width={'1/2'} nowrap gap={2} yalign={'center'} xalign={'end'}>
              <div
                className='text-primary-medium cursor-pointer'
                onClick={() =>
                  copyToClipboard(context?.advanced?.sweepRecipient?.id)
                }
              >
                <AiOutlineCopy size={16} />
              </div>
              <TableData
                className='break-anywhere'
                data-testid={`${key}-recipient-data`}
              >
                {getTruncatedAddress(context?.advanced?.sweepRecipient?.id)}
              </TableData>
            </Row>
          </Row>

          <Text>
            Please add wallet address below to select a new recipient and
            overwrite the existing one.
          </Text>
        </Col>

        <Col>
          <Row>
            <InputGroup
              name={'recipient'}
              placeholder={'Enter Address'}
              required={false}
              value={recipient}
              onChange={(changed) => {
                setRecipient(changed.target.value);
              }}
              valid={validRecipient}
              error={recipientError}
            />
          </Row>

          <Button
            className='w-fit'
            size={'md'}
            disabled={!recipient || saving}
            onClick={() => saveRecipient()}
          >
            {updateRecipientLoading ? (
              <>
                <div className='animate-spin'>
                  <AiOutlineLoading3Quarters size={18} />
                </div>
                <Text>Saving...</Text>
              </>
            ) : (
              <>
                <Text>Save</Text>
                <FrontArrowIcon />
              </>
            )}
          </Button>
        </Col>
      </Section>
    </Stacked>
  );
};
