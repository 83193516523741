import {
  Card,
  PageHeader,
  ProjectAuthorizedSigner,
  ProjectDetails,
  ProjectDomains,
  ProjectEvents,
  ProjectMembers,
  ProjectQuicklinks,
  ProjectReviewComplainceDocuments,
  ProjectSetup,
} from '@components';
import { useProject } from '@contexts';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './project-dashboard.css';

export const ProjectDashboardPage = () => {
  const { view, project, setView } = useProject();
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      setView(hash.replace('#', ''));
    }
  }, [hash]);

  const renderView = () => {
    switch (view) {
      case 'project-domains':
        return <ProjectDomains />;
      case 'project-members':
        return <ProjectMembers />;
      case 'project-compliance':
        return <ProjectReviewComplainceDocuments />;
      case 'project-authorized-signer':
        return <ProjectAuthorizedSigner />;
      default:
        return <ProjectDetails />;
    }
  };

  return (
    <>
      <PageHeader title={`${project?.name}`} />

      <div className={'grid grid-cols-3 gap-x-8 gap-y-8'}>
        <div className={'col-span-3 lg:col-span-2'}>{renderView()}</div>

        <div className={'col-span-3 lg:col-span-1'}>
          <Card className='no-padding'>
            <ProjectSetup />
            <ProjectQuicklinks />
          </Card>
        </div>

        <div className={'col-span-3'}>
          <ProjectEvents events={project.events} />
        </div>
      </div>
    </>
  );
};
