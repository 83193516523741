import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { useMemo } from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import './table-component.css';

type TableComponent = {
  config: any;
  columnsData: any;
  pagination: any;
  setPagination: any;
  paginationDisabled?: boolean;
};

export const TableComponent = ({
  config,
  columnsData,
  pagination,
  setPagination,
  paginationDisabled = false,
}: TableComponent) => {
  const defaultData = useMemo(() => [], []);

  const piiBlurCols = columnsData
    .filter((col) => col.piiBlur)
    .map((col) => col.accessorKey);

  const blurCell = (cellId, blur) => {
    cellId = cellId.split('_')[1];
    if (piiBlurCols.includes(cellId) && !blur) {
      return 'blur';
    }
    return '';
  };

  const columns = useMemo<ColumnDef<any>[]>(() => columnsData, []);

  const table = useReactTable({
    data: config?.rows ?? defaultData,
    columns,
    pageCount: config?.pages ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
    debugTable: true,
  });

  const pagesToShow = (pageIndex) => {
    let result: any[] = [];
    if (config.pages < 7) {
      for (let page = 0; page < config.pages; page++) {
        result.push(page);
      }
    } else {
      for (let page = 0; page < config.pages; page++) {
        if (page > pageIndex - 3 && page <= pageIndex) {
          if (
            !result.includes(page - 1) &&
            result[result.length - 1] != '...' &&
            page > 2
          ) {
            result.push('...');
          }
          result.push(page);
        } else if (pageIndex > 2 && page < 3) {
          result.push(page);
        }
        if (page > pageIndex) {
          let firstPartLength = 9;
          if (result.includes('...')) {
            firstPartLength = 10;
          }
          if (result.length != firstPartLength) {
            result.push(page);
          }
        }
      }
      if (!result.includes(config.pages - 2)) {
        let backPages = [config.pages - 1];
        let lastPartLength = 10;
        if (result.includes('...')) {
          lastPartLength = 13;
        }
        for (let page = config.pages - 1; page >= 2; page--) {
          if (
            result.length + backPages.length < lastPartLength &&
            page > result[0]
          ) {
            if (page - 1 > result[result.length - 1]) {
              backPages.push(page - 1);
            }
          }
        }
        if (!result.includes(backPages[backPages.length - 1] - 1)) {
          result.push('...');
        }
        result = result.concat(backPages.reverse());
      } else {
        if (!result.includes(config.pages - 1)) {
          result.push(config.pages - 1);
        }
      }
    }
    return result;
  };

  return (
    <>
      <table className='w-full mt-4 users-table' data-testid='table-component'>
        <thead className='users-table-head'>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className='font-medium relative'
                    data-testid='table-columns'
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={`text-left pl-2`}
                        style={{ width: header.getSize() }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {config.rows?.length != 0 ? (
          <tbody className='text-sm'>
            {table.getRowModel().rows.map((row, index) => {
              return (
                <tr
                  key={`${table.getState().pagination.pageIndex}-${row.id}`}
                  className={`${index % 2 ? 'colored-row' : ''}`}
                  data-testid='table-row'
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={classNames(
                          `text-left py-2`,
                          blurCell(cell.id, row.original.consent),
                          cell.id.includes('accountType') ||
                            cell.id.includes('country') ||
                            cell.id.includes('region')
                            ? ' text-center'
                            : ' px-2',
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td
                colSpan={10}
                className='text-center py-24'
                data-testid='no-records-label'
              >
                {' '}
                No records found.
              </td>
            </tr>
          </tbody>
        )}
      </table>
      {!paginationDisabled && config.rows?.length != 0 ? (
        <div className='pagination-container' data-testid='table-pagination'>
          <button
            className='previous-page'
            onClick={() =>
              table.setPageIndex(table.getState().pagination.pageIndex - 1)
            }
            disabled={!table.getCanPreviousPage()}
          >
            <BsArrowLeft />
          </button>
          <div className='flex gap-2'>
            {pagesToShow(table.getState().pagination.pageIndex).map((page) =>
              page != '...' ? (
                <div
                  key={page}
                  className={`${
                    page == table.getState().pagination.pageIndex
                      ? 'page-number-selected'
                      : 'page-number'
                  }`}
                  onClick={() => table.setPageIndex(page)}
                  data-testid='page-number-button'
                >
                  {page + 1}
                </div>
              ) : (
                <div key={page} className={`page-number-contracted`}>
                  {page}
                </div>
              ),
            )}
          </div>

          <button
            className='next-page'
            onClick={() =>
              table.setPageIndex(table.getState().pagination.pageIndex + 1)
            }
            disabled={!table.getCanNextPage()}
          >
            <BsArrowRight />
          </button>
          <span className='result-container' data-testid='results-label'>
            Results{' '}
            {table.getState().pagination.pageIndex *
              table.getState().pagination.pageSize +
              1}{' '}
            -{' '}
            {(table.getState().pagination.pageIndex + 1) *
              table.getState().pagination.pageSize <
            config.totalRecords
              ? (table.getState().pagination.pageIndex + 1) *
                table.getState().pagination.pageSize
              : config.totalRecords}{' '}
            from {config.totalRecords}
          </span>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
