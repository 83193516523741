import { ConfettiMessage, CountdownTimer } from '@newComponents';
import { ProjectColors } from 'tokensoft-shared-types';

type EligibilityRequirementsMetProps = {
  eventType: EventType;
  isKycRequired: boolean;
  onFinished: () => void;
  eventStartsAt: Maybe<Date>;
  themeColors?: ProjectColors;
};

export const EligibilityRequirementsMet = ({
  eventType,
  isKycRequired,
  onFinished,
  eventStartsAt,
  themeColors,
}: EligibilityRequirementsMetProps) => {
  const startsInFuture =
    eventStartsAt !== null &&
    eventStartsAt !== undefined &&
    eventStartsAt > new Date();

  const getMessage = () => {
    const baseMessage = 'You have met the requirements.';
    const kycMessage = isKycRequired
      ? 'Your identity has been verified, and you have met the requirements to participate in this event.'
      : baseMessage;

    if (eventType === 'identity') {
      return kycMessage;
    }

    const eventSpecificMessage =
      'Return to this page when the event starts to participate.';
    return `${kycMessage} ${eventSpecificMessage}`;
  };


  return (
    <ConfettiMessage
      message={getMessage()}
      onFinished={onFinished}
      themeColors={themeColors}
    >
      {startsInFuture && eventStartsAt && (
        <CountdownTimer date={eventStartsAt} />
      )}
    </ConfettiMessage>
  );
};
