import {
  useGetEventDocuments,
  useGetEventUserDocumentsStatus,
  usePostSignedOrReviewedDocuments,
} from '@apiServices';
import { LoadingIndicator } from '@components';
import { useSale } from '@contexts';
import { Receipt } from '@customTypes';
import { PurchaseConfirmStepPage, PurchaseStepPage } from '@pages';
import { userHasSignedOrReviewedAllDocuments } from '@utils';
import { useCallback, useEffect, useState } from 'react';
import { DocumentSigning } from '../../eligibility/DocumentSigning';

interface PurchaseFlowProps {
  eventId: ID;
  onFinished: () => void;
  onCancel: () => void;
  // sale: Sale;
}

export const PurchaseFlow = ({
  eventId,
  onFinished,
  onCancel,
  // sale
}: PurchaseFlowProps) => {
  const [startingStep, setStartingStep] = useState(0);
  const [step, setStep] = useState(0);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [nextHidden, setNextHidden] = useState(false);

  const {
    sale,
    setReceipt,
    documentsToAcceptOrSign,
    eligibilityData,
    feeLevel,
    handleGetReceipt,
    receipt,
  } = useSale();

  const {
    data: eventDocuments,
    isPending: isEventDocumentsPending,
    isError: isEventDocumentsError,
  } = useGetEventDocuments(eventId);

  const {
    data: eventUserDocumentsStatus,
    isPending: isEventUserDocumentsStatusPending,
    isError: isEventUserDocumentsStatusError,
  } = useGetEventUserDocumentsStatus(eventId);

  const {
    mutate: postSignedOrReviewed,
    isPending: isPostSignedOrReviewedPending,
    isSuccess,
  } = usePostSignedOrReviewedDocuments(eventId);

  useEffect(() => {
    if (isSuccess && step === 0) {
      goToNextStep();
    }
  }, [isSuccess]);


  const handleFinishedReviewingOrSigning = useCallback(async () => {
    const payload = {
      documents: eventDocuments?.map((doc) => ({
        eventDocumentVersionId: doc.id,
        requiredSignature: doc.requiresSignature,
        signed: doc.requiresSignature ? true : null,
        reviewed: true,
      })),
      eventId,
    };
    await postSignedOrReviewed(payload);
  }, [eventDocuments, eventId]);

  const didFinishPurchase = (receipt: Maybe<Receipt>) => {
    handleGetReceipt(receipt);
    onFinished();
  };

  const isCompletedWithDocumentSigningOrReview = (): boolean => {
    if (isEventDocumentsPending || isEventUserDocumentsStatusPending) {
      return false;
    }

    if (isEventDocumentsError || isEventUserDocumentsStatusError) {
      return false;
    }

    if (
      userHasSignedOrReviewedAllDocuments(
        eventDocuments,
        eventUserDocumentsStatus,
      )
    ) {
      return true;
    }

    return false;
  };

  const goToNextStep = () => {
    setStep(step + 1);
  };

  const goToPreviousStep = () => {
    if (step === startingStep) {
      onCancel();
      return;
    }

    setStep(step - 1);
  };

  useEffect(() => {
    if (isEventDocumentsPending || isEventDocumentsError) {
      return;
    }

    if (eventDocuments.length === 0) {
      setStartingStep(1);
      setStep(1);
      return;
    }
  }, [eventDocuments]);

  const getOnNextClickedFunction = () => {
    if (step === 0 && !isCompletedWithDocumentSigningOrReview()) {
      return () => handleFinishedReviewingOrSigning();
    }

    return () => goToNextStep();
  };

  useEffect(() => {
    if (step === 2) {
      setNextHidden(true);
    } else {
      setNextHidden(false);
    }
  }, [step]);

  useEffect(() => {
    if (isCompletedWithDocumentSigningOrReview() && step === 0) {
      setNextDisabled(false);
    }
  }, [isCompletedWithDocumentSigningOrReview]);

  if (isEventDocumentsPending || isEventUserDocumentsStatusPending) {
    return <LoadingIndicator />;
  }

  if (isEventDocumentsError || isEventUserDocumentsStatusError) {
    return (
      <div>There was an error loading the documents. Please try again.</div>
    );
  }

  const onNextClicked = getOnNextClickedFunction();

  return (
    <div>
      {step === 0 && (
        <DocumentSigning
          eventDocuments={eventDocuments}
          eventUserDocumentStatuses={eventUserDocumentsStatus}
          highlightColor='#000'
          onFinishedReviewingOrSigning={(finished) =>
            setNextDisabled(!finished)
          }
        />
      )}
      {step === 1 && (
        <PurchaseStepPage
          onFormValidChange={(valid) => setNextDisabled(!valid)}
          sale={sale}
          receipt={receipt}
          setReceipt={setReceipt}
          documentsToAcceptOrSign={documentsToAcceptOrSign}
          eligibilityData={eligibilityData}
          feeLevel={feeLevel}
        />
      )}
      {step === 2 && (
        <PurchaseConfirmStepPage
          sale={sale}
          handleGetReceipt={didFinishPurchase}
          receipt={receipt}
        />
      )}
      <div className='flex justify-between items-center mt-4 mx-auto p-6 w-2/3'>
        <button onClick={goToPreviousStep} className='btn btn-outline-dark'>
          {step === startingStep ? 'Cancel' : 'Back'}
        </button>
        {!nextHidden && (
          <button
            className='btn btn-primary'
            onClick={onNextClicked}
            disabled={isPostSignedOrReviewedPending || nextDisabled}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

// export interface EventDocument {
//   id: number;
//   eventId: number;
//   name: string;
//   documentId: number;
//   uri: string;
//   contentType: string;
//   requiresSignature: boolean;
// }
// export interface EventUserDocumentStatus {
//   id: number;
//   eventDocumentVersionId: ID;
//   signedAt: Maybe<Date>;
//   acceptedAt: Maybe<Date>;
// }
