import { useEffect, useRef } from 'react';

export const useDisableBodyScroll = (disabled: boolean): void => {
  const scrollPosition = useRef(0);

  const disableBodyScroll = () => {
    scrollPosition.current = window.scrollY;
    document.body.style.top = -window.scrollY + 'px';
    document.body.style.left = '0';
    document.body.style.right = '0';
    document.body.style.overflowY = 'hidden';
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
  };

  const enableBodyScroll = () => {
    document.body.style.overflowY = 'initial';
    document.body.style.overflow = 'initial';
    document.body.style.position = 'initial';
    document.body.style.top = '0';
    window.scrollTo(0, scrollPosition.current);
  };

  useEffect(() => {
    // Don't do anything if scroll is already blocked somewhere else.
    if (!disabled || document.body.style.position === 'fixed') {
      return undefined;
    }

    disableBodyScroll();

    return () => {
      enableBodyScroll();
    };
  }, [disabled]);
};
